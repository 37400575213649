import { Component, Input } from '@angular/core';
import { SnapFormSection } from '../../models/snapFormSection';


@Component({
    selector: 'snap-lp-section',
    templateUrl: './lp-section.component.html',
    styleUrls: ['./lp-section.component.scss'],
})
export class LPSectionComponent {

    @Input() snapFormSection: SnapFormSection;
}
