import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { ComponentState } from '../../../../app/models/enums';
import Utility from '../../utility';

@Component({
    selector: 'app-open-link',
    templateUrl: './open-link.component.html',
    styleUrls: ['./open-link.component.scss']
})
export class OpenLinkComponent implements OnInit, OnChanges {

    readonly imagePoints: string = Utility.svgOpenInNew;
    @Input() urlLink: string;
    @Input() lpTooltip = "Open in browser";
    @Input() imageForegroundColor: string = Utility.LPOrange;
    @Input() imageBackgroundColor: string = Utility.LPTransparent;
    @Input() componentState: ComponentState = ComponentState.Enabled;
    public opacity: number = Utility.LPLitOpacity;

    ngOnInit(): void { this.setOpacity(); }
    ngOnChanges() { this.setOpacity(); }
    public get getMousePointer(): string { return (this.componentState === ComponentState.Enabled) ? "auto" : "none"; }

    setOpacity() {
        if (this.componentState === ComponentState.Enabled)
            this.opacity = Utility.LPLitOpacity;
        //We only want 2 opacity states
        //else if (this.componentState === ComponentState.Disabled)
        //    this.opacity = Utility.LPDimOpacity;
        else
            this.opacity = Utility.LPOffOpacity;
    }

    onclick() {
        if (this.componentState === ComponentState.Enabled)
            Utility.openUrl(this.urlLink);
    }
}
