import { SublimeService } from "../core/services/sublime.service";
import Utility from "../shared/utility";
import { CardState } from "./cardState";
import { Cell } from "./cell";
import { Deck } from "./deck";
import { DeckType, LPVisibility, PouchClass } from "./enums";
import { Group } from "./group";
import { Header } from "./header";
import { IDeserializable } from "./interfaces/IDeserializable.interface";

export class Card implements IDeserializable {
    //***
    public id: string;
    //***
    public groupId: string;
    //***
    public tableLabel: string;
    //***
    public tableColor: string;
    //***
    public sortValue: number;
    //***
    public cells: Cell[] = [];
    //***
    public visibleCells: Cell[] = [];
    //***
    public supportsQuickComment = false;
    //***
    public isUpdateable: boolean;
    //***
    public isPrimary = false;
    //***
    public canSetPrimary = false;

    //***
    //***
    private _header: Header;;
    public get header(): Header {
        return this._header ?? new Header();
    }
    public set header(value: Header) {
        this._header = value;
    }


    public get whoWhatRank(): number {
        const id = this.id ?? "";
        const groupid = this.groupId ?? "";
        if (groupid === Utility.ConnectionsTableName)
            return 0
        else if (groupid === Utility.AttachmentTableName)
            return 10
        else if (id.startsWith("003"))
            return 5;
        else if (id.startsWith("00Q"))
            return 5;
        else
            return 1
    }

    public get titleRank(): string {
        const title = (this?.header?.title ?? "").toLocaleLowerCase();
        if (this.isPrimary)
            return "0" + title;
        else
            return "1" + title;
    }


    //***
    //***
    private _visibleDecks: DeckType[]
    public get visibleDecks(): DeckType[] {
        return this._visibleDecks ?? [];
    }
    public set visibleDecks(value: DeckType[]) {
        this._visibleDecks = value;
    }


    //***
    //***
    private _isChecked = false;
    public get isChecked(): boolean { return this._isChecked ?? false; }
    public set isChecked(value: boolean) { this._isChecked = value; }



    //***
    //***
    private _checkBoxState = LPVisibility.Visible;
    public get checkBoxState(): LPVisibility { return this._checkBoxState ?? LPVisibility.Visible; }
    public set checkBoxState(value: LPVisibility) { this._checkBoxState = value; }



    //***
    //***
    public get url(): string {
        if (!this.groupId || this.groupId.substr(0, 1) === "_")
            return null;
        else
            return Utility.getSalesforceUrl(this.id);
    }

    //***
    public deck: Deck = null;
    public group: Group = null;

    //***  SetDeckVisibility  SetDeckVisibility  SetDeckVisibility
    //***  SetDeckVisibility  SetDeckVisibility  SetDeckVisibility

    deserialize(input: Card): this {
        Object.assign(this, input);
        this.header = new Header().deserialize(input.header);
        if (input.cells)
            this.cells = input.cells.map((cell) => new Cell().deserialize(cell));

        if (input.visibleCells)
            this.visibleCells = input.visibleCells.map((cell) => new Cell().deserialize(cell));

        return this;
    }


    //***
    public setDeckVisibility(deckType: DeckType, visible: boolean) {
        const index = this.visibleDecks.indexOf(deckType);
        if (index >= 0 && !visible)
            this.visibleDecks.splice(index, 1);
        else if (index < 0 && visible)
            this.visibleDecks.push(deckType);
    }

    //***  GetDeckVisibility  GetDeckVisibility  GetDeckVisibility
    //***  GetDeckVisibility  GetDeckVisibility  GetDeckVisibility
    //***
    public getDeckVisibility(deckType: DeckType) { return this.visibleDecks.indexOf(deckType) < 0 ? false : true; }


    //***  SetState  SetState  SetState
    //***  SetState  SetState  SetState
    //***
    public setState(state: CardState) {
        if (state !== null && this.id === state.id) {
            this.checkBoxState = state.checkBoxState;
            this.isChecked = state.isChecked;
            this.visibleDecks.splice(0);
            for (const deckType of state.visibleDecks)
                this.setDeckVisibility(deckType, true);
        }
    }

    //***  GetState  GetState  GetState
    //***  GetState  GetState  GetState
    //***
    public getState(): CardState {
        const result = new CardState();
        result.id = this.id;
        result.groupId = this.groupId;
        result.checkBoxState = this.checkBoxState;
        result.isChecked = this.isChecked;
        for (const deckType of this.visibleDecks)
            result.visibleDecks.push(deckType);
        return result;
    }


    //***
    //***
    public get isCheckBoxVisible(): boolean {
        const deckvalue = (this.deck) ? this.deck.checkBoxState : LPVisibility.Undefined;
        if (SublimeService.POUCH.pouchClass === PouchClass.Undefined)
            return false;
        else if (deckvalue === LPVisibility.Hidden)
            return false;
        else if (deckvalue === LPVisibility.Visible)
            return true;
        else if (this.checkBoxState === LPVisibility.Hidden)
            return false;
        else
            return true;
    }


    //***
    //***
    public assignHeader(): void {
        const titleCell = this.cells.find(x => !x.isVisible);
        const subTitleCell = this.cells.find(x => !x.isVisible && x !== titleCell && x.value !== this.id);

        this.header.title = titleCell?.bestValue;
        this.header.subTitle = subTitleCell?.bestValue;

        if (subTitleCell) {
            this.header.subTitleLabel = subTitleCell.label;
            this.header.subTitleLabelVisible = true;
        }
    }


    //***
    //***
    public get isCheckBoxEnabled(): boolean {
        const deckvalue = (this.deck) ? this.deck.checkBoxState : LPVisibility.Undefined;
        if (deckvalue === LPVisibility.Disabled)
            return false;
        else if (this.checkBoxState === LPVisibility.Disabled)
            return false;
        else
            return true;
    }


    public get canOpenLink(): boolean { return !Utility.isNullOrEmpty(this.url); }
    public get isConnectionsTable(): boolean { return this.groupId === Utility.ConnectionsTableName; }
    private get isPseudoTable(): boolean { return this.groupId.startsWith("_"); }
}
