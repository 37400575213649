import { ClientState } from "./clientState";
import { DataSet } from "./dataSet";
import { IDeserializable } from "./interfaces/IDeserializable.interface";
import { ResponseBase } from "./responseBase";

export class SearchResponse extends ResponseBase implements IDeserializable {
    public dataSet: DataSet = new DataSet();
    public clientState: ClientState = new ClientState();
    public contextToken: string | undefined;

    deserialize(input: any): this {
        Object.assign(this, input);
        if (input?.dataSet)
            this.dataSet = new DataSet().deserialize(input.dataSet);
        if (input?.clientState)
            this.clientState = new ClientState().deserialize(input.clientState);
        return this;
    }
}
