<div class="lp-container">
    <div class="lp-body">

        <div class="lp-header-container">
            Use the email signature details below to complete the form. Drag and drop values to or between fields for quick updates.
        </div>

        <hr class="lp-hr">

        <div style="width: 100%; display: grid; grid-template-columns: min-content auto min-content;"
             *ngFor="let field of signatureFields">

            <app-lp-svg [imagePoints]="checkMarkImagePoints"
                        [imageForegroundColor]="getCheckMarkImageForeground(field)"></app-lp-svg>

            <div class="lp-field">
                {{field.value}}
                <hr class="lp-hr-field">
            </div>
        </div>

        <div style="margin-top: 10px; width: 100%; display: grid; grid-template-columns: min-content auto;">
            <app-lp-svg [imagePoints]="checkMarkImagePoints"
                        [imageSize]="16"
                        style="align-self: center;"></app-lp-svg>

            <div style="font-size: 10px;">
                Indicates valid signature field.
            </div>
        </div>

    </div>
</div>
