import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';

@Injectable()
export class GraphOauthGuardService implements CanActivate {

  // ***************************************
  // ***************************************
  constructor(private router: Router) {

  }

  // ***************************************
  // ***************************************
  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    //Graph is sending the response back incorrectly like this:
    //https://localhost:44345/?code=0.xxWbemCeRRVFBnh5YLEmywGDQqXVQj94Unq6gYcDoASiHCJVrmmLmeatsojBit0yezKwpjA%3D%3D#/
    //Note that it does not contain the correct route "graphOauth".
    //Need to use this guard to catch this top level request and redirect it to the correct graphOauth component 

    //We cant use built-in router methods of extracting the query param.
    //Instead we need to manually use string manipulation.
    const decodedUrl = decodeURIComponent(window.location.href);
    const match = decodedUrl.match("code=(.*)&");
    if (match) {
      const authCode = match[1];
      if (authCode) {
        //build the correct url and redirect to it
        let url = `${window.location.origin}/#/graphoauth?code=${authCode}`;
        window.location.href = url;
      }
      else {
        return true;
      }
    }
    else {
      return true;
    }
  }

}
