import { Card } from "./card";
import { DeckType } from "./enums";
import { CardState } from "./cardState";
import { IDeserializable } from "./interfaces/IDeserializable.interface";


export class CardSet implements IDeserializable {
    public cards: Card[] = [];


    deserialize(input: CardSet): this {
        Object.assign(this, input);
        if (Array.isArray(input?.cards)) {
            this.cards = input.cards.map((card) => new Card().deserialize(card));
        }
        return this;
    }

    //***  Clear  Clear  Clear
    //***  Clear  Clear  Clear
    //***
    public Clear() {
        for (const card of this.cards) {
            card.deck = null;
            card.group = null;
        }
        this.cards.splice(0, this.cards.length);
    }


    //***  SetDeckVisibility  SetDeckVisibility  SetDeckVisibility
    //***  SetDeckVisibility  SetDeckVisibility  SetDeckVisibility
    //***
    public setDeckVisibility(isVisible: boolean, deckTypes: DeckType[]) {

        for (const card of this.cards) {
            for (const deckType of deckTypes)
                card.setDeckVisibility(deckType, isVisible);
        }
    }


    //***  SetState  SetState  SetState
    //***  SetState  SetState  SetState
    //***
    public setState(sourceStates: CardState[]): Card[] {
        const result: Card[] = [];
        for (const sourceState of sourceStates) {
            //Attachments should only compare the last 30 characters because some characters in the beginning of the Id change from Compose to Sent
            const targetCard = this.cards.find((x) => (x.groupId != "_Attachment" && x.id === sourceState.id) ||
                (x.groupId === "_Attachment" && x.id.slice(-30) === sourceState.id.slice(-30)));
            if (targetCard) {
                targetCard.setState(sourceState);
                result.push(targetCard);
            }
        }
        return result;
    }


    //***  Upsert  Upsert  Upsert
    //***  Upsert  Upsert  Upsert
    //***
    public upsert(source: Card[]): Card[] {
        const result: Card[] = [];
        for (const sourceCard of source) {
            //Attachments should only compare the last 30 characters because some characters in the beginning of the Id change from Compose to Sent
            const targetCard = this.cards.find((x) => (x.groupId != "_Attachment" && x.id === sourceCard.id) ||
                (x.groupId === "_Attachment" && x.id.slice(-30) === sourceCard.id.slice(-30)));
            if (targetCard) {
                targetCard.setState(sourceCard.getState());
                result.push(targetCard);
            }
            else {
                this.cards.push(sourceCard);
                result.push(sourceCard);
            }
        }
        return result;
    }


    //***  Remove  Remove  Remove
    //***  Remove  Remove  Remove
    //***
    public remove(card: Card): boolean {
        let result = false;
        //Attachments should only compare the last 30 characters because some characters in the beginning of the Id change from Compose to Sent
        const target = this.cards.find((x) => (x.groupId != "_Attachment" && x.id === card.id) ||
            (x.groupId === "_Attachment" && x.id.slice(-30) === card.id.slice(-30)));
        if (target) {
            this.cards.splice(this.cards.indexOf(target), 1);
            result = true;
        }
        return result;
    }


    //***  FindCards  FindCards  FindCards
    //***  FindCards  FindCards  FindCards
    //***
    findCards(deckType: DeckType): Card[] {
        const result: Card[] = [];
        for (const card of this.cards) {
            if (card.getDeckVisibility(deckType))
                result.push(card);
        }
        return result;
    }


    //***  ClearOrphans  ClearOrphans  ClearOrphans
    //***  ClearOrphans  ClearOrphans  ClearOrphans
    //***
    clearOrphans() { for (const item of this.findOrphans()) this.remove(item); }


    //***  FindOrphans  FindOrphans  FindOrphans
    //***  FindOrphans  FindOrphans  FindOrphans
    //***
    findOrphans(): Card[] {
        const result = [];
        for (const card of this.cards)
            if (card.visibleDecks.length === 0)
                result.push(card);
        return result;
    }
}
