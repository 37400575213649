import Utility from "../../../shared/utility";
import { LPDataType, LPFieldType } from "../enums";
import { SnapFormField } from "../snapFormField";
import { Visitor } from "./Visitor";

export class LengthValidator extends Visitor {
    //***
    //***
    canVisit(field: SnapFormField): boolean {
        const result = super.commonCanVisitDataType(field, [LPDataType.Number, LPDataType.String])
        return result && field.maxLength > 0;
    }

    //***
    //***
    visit(field: SnapFormField, value: string) {
        if (super.commonVisit(field, value))
            if (value && value.length > field.maxLength) {
                field.validationMessage = `Value may not exceed ${field.maxLength} characters`;
            }
    }
}
