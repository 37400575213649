import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { SessionService } from '../../../core/services/session.service';
import { Card } from '../../../models/card';
import { Cell } from '../../../models/cell';
import { SnapFormValue } from '../../../snap-forms/models/snapFormValue';
import { MacHandlerSnapFormService } from '../../../snap-forms/services/mac-handler-snapform.service';
import Utility from '../../utility';

@Component({
    selector: 'app-signature',
    templateUrl: './signature.component.html',
    styleUrls: ['./signature.component.scss']
})
export class SignatureComponent {
    public readonly imagePoints: string = Utility.svgNetwork;
    public officeDialog: Office.Dialog;
    private officeDialogUrl = window.location.origin + "#/";


    @Input() card: Card;
    @Input() tableName: string;
    @Input() lpTooltip: string;
    @Input() imageBackgroundColor = "";
    @Input() imageUrl = "";

    constructor(private router: Router, private sessionService: SessionService, private macHandler: MacHandlerSnapFormService,) { }

    //***
    //***
    async onclick() {

        const formValues: SnapFormValue[] = [];
        this.card.cells.forEach(x => {
            if (x.isVisible && x.value) {
                const formValue: SnapFormValue = this.mapSnapFormValue(x);
                if (formValue) {
                    formValues.push(formValue);
                }

            }
        });

        //Pass the authtoken to the dialog
        this.sessionService.setPropertyBag(this.sessionService.propertyBagAuthToken, await this.sessionService.forceTokenUpdate()).subscribe(
            (response) => {
                this.sessionService.setPropertyBag(this.sessionService.propertyBagInjectSnapFormValues, formValues).subscribe(res => {
                    if (this.sessionService.isMAC)
                        this.macHandler.initializeMacFormSignature(this.tableName);
                    else
                        this.launchDialog(this.tableName);
                })
            },
            (error) => {
                Utility.debug(error);
            }
        );         
    }

    //***
    //***
    private async launchDialog(tableName: string) {
        const url = this.officeDialogUrl + this.router.serializeUrl(this.router.createUrlTree([`/SnapForm/${this.sessionService.lpUserId}/${tableName}/Signature`]));

        Office.context.ui.displayDialogAsync(url, { height: this.sessionService.dialogHeightLarge, width: this.sessionService.dialogWidthMedium, promptBeforeOpen: false, displayInIframe: true },
            (result) => {
                if (result.status === Office.AsyncResultStatus.Failed) {
                    Utility.debug(`Dialog failed: ${result.error.code} ${result.error.message}`);
                }
                else {
                    this.officeDialog = result.value;
                    this.officeDialog.addEventHandler(Office.EventType.DialogMessageReceived, this.processOfficeDialogMessage);
                    this.officeDialog.addEventHandler(Office.EventType.DialogEventReceived, this.processOfficeDialogEvent);
                }
            }
        );
    }

    //***
    //Fired when dialog calls Office.context.ui.messageParent()
    //***
    private processOfficeDialogMessage = () => { this.officeDialog.close(); };

    //***
    //Fired when dialog is closed by the user
    //***
    private processOfficeDialogEvent = () => {
        // do nothing
    };

    mapSnapFormValue(cell: Cell): SnapFormValue {
        let formValues: SnapFormValue = new SnapFormValue(cell.name, cell.value, "");

        switch (cell.name) {
            case "Score":
                formValues = null;
                break;

            case "JobTitle":
                formValues.fieldName = "Title";
                break;

            case "EmailAddress":
                formValues.fieldName = "Email";
                break;

            case "FaxPhone":
                formValues.fieldName = "Fax";
                break;

            case "HomePhone":
                formValues.fieldName = "OtherPhone";
                break;

            case "Street1":
                switch (this.tableName) {
                    case "Account":
                        formValues.fieldName = "BillingStreet";
                        break;

                    case "Lead":
                        formValues.fieldName = "Street";
                        break;

                    case "Contact":
                        formValues.fieldName = "MailingStreet";
                        break;

                    default:
                        formValues.fieldName = "MailingStreet";
                        break;
                }
                break;

            case "City":
                switch (this.tableName) {
                    case "Account":
                        formValues.fieldName = "BillingCity";
                        break;

                    case "Lead":
                        formValues.fieldName = "City";
                        break;

                    case "Contact":
                        formValues.fieldName = "MailingCity";
                        break;

                    default:
                        formValues.fieldName = "MailingCity";
                        break;
                }
                break;

            case "GeoRegion":
                switch (this.tableName) {
                    case "Account":
                        formValues.fieldName = "BillingState";
                        break;

                    case "Lead":
                        formValues.fieldName = "State";
                        break;

                    case "Contact":
                        formValues.fieldName = "MailingState";
                        break;

                    default:
                        formValues.fieldName = "MailingState";
                        break;
                }
                break;

            case "PostalCode":
                switch (this.tableName) {
                    case "Account":
                        formValues.fieldName = "BillingPostalCode";
                        break;

                    case "Lead":
                        formValues.fieldName = "PostalCode";
                        break;

                    case "Contact":
                        formValues.fieldName = "MailingPostalCode";
                        break;

                    default:
                        formValues.fieldName = "MailingPostalCode";
                        break;
                }
                break;

            default:
                break;
        }
        return formValues;
    }
}
