import { IDeserializable } from "./interfaces/IDeserializable.interface";

export class GroupPreference implements IDeserializable {
    public id: string;
    public label: string;
    public link: string;
    public color: string;
    public usageCount: number;
    public isEnabled: boolean = null;
    public isExpanded: boolean;
    public sortFieldName: string;
    public sortAscending: boolean;
    public visibleCellFieldNames: Map<string, string>;
    public effectiveIsEnabled: boolean;


    deserialize(input: GroupPreference): this {
        Object.assign(this, input);
        if (input.isEnabled == null) {
            this.isEnabled = false;
        }
        if (input.visibleCellFieldNames) {
            this.visibleCellFieldNames = new Map<string, string>();
            for (const value in input.visibleCellFieldNames) {
                this.visibleCellFieldNames.set(value, input.visibleCellFieldNames[value])
            }
        }
        return this;
    }

    public get isSortFieldsAvailable() {
        if (this.visibleCellFieldNames && this.visibleCellFieldNames.size > 0)
            return true;
        else
            false;
    }
}
