import { Component, OnInit } from '@angular/core';
import Utility from "../../shared/utility";
import { SublimeService } from "../../core/services/sublime.service";

@Component({
    selector: 'app-bootstrap-error',
    templateUrl: './bootstrap-error.component.html',
    styleUrls: ['./bootstrap-error.component.scss']
})
export class BootstrapErrorComponent implements OnInit {
    readonly keyImagePoints: string = Utility.svgKeyVariant;
    readonly cartImagePoints: string = Utility.svgCartPlus;
    readonly chatImagePoints: string = Utility.svgForumOutline;
    readonly phoneImagePoints: string = Utility.svgPhoneOutgoing;

    public isRetrying: boolean = false;

    //***
    //***
    constructor(public sublimeService: SublimeService) { }

    //***
    //***
    ngOnInit() {
        this.sublimeService.bootStrapSubject.subscribe(() => {
            this.isRetrying = false; //when a bootstrap completes ensure that the spinner is deactivated
        });
    }

    //***
    //***
    async retry() {
        if (!this.isRetrying) {
            this.isRetrying = true; //activate spinner
            this.sublimeService.broadcastReBootstrap();  //retry bootstrap
        }
    }

}
