<div class="modal-header">
    <h4 class="modal-title pull-left pl-2"></h4>
</div>


<div class="lp-container">
    <div class="p-3 text-center"><b>Something went wrong...</b><br />
    An error occured during initialization. We apologize for the inconvenience.</div>
    <div class="pt-3 pb-5 text-center">
        <button *ngIf="!isRetrying"
                class="btn btn-sm btn-primary"
                (click)="retry()">
            <span>Try Again</span>
        </button>
        <button *ngIf="isRetrying"
                class="btn btn-sm btn-primary"
                disabled>
            <span>Re-initializing... </span>
            <span class="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"></span>
        </button>
    </div>
    <div class="container">

        <div class="row align-items-center">
            <div class="col-2">
                <app-lp-svg [imagePoints]="chatImagePoints"
                            [imageSize]="36"
                            [lpTooltip]="'Chat with Support'"></app-lp-svg>
            </div>
            <div class="col-10 lp-link">
                <a href="http://messenger.providesupport.com/messenger/linkpoint360.html"
                   target="_blank">Click here to chat with LinkPoint Sales and Support.</a>
            </div>
        </div>
        <hr />

        <div class="row align-items-center">
            <div class="col-2">
                <app-lp-svg [imagePoints]="phoneImagePoints"
                            [imageSize]="36"
                            [lpTooltip]="'Call Support'"></app-lp-svg>
            </div>
            <div class="col-10 lp-link">
                Call +1.732.212.8403 to speak with a LinkPoint360 representative.
            </div>
        </div>
        <hr />
    </div>
</div>
<div class="modal-footer"></div>
