import { IDeserializable } from "./interfaces/IDeserializable.interface";


export class SimpleRequest implements IDeserializable {
    public lpUserId: string
    public emailAddress: string
    public payload: string;
    public contextToken: string;

    deserialize(source: SimpleRequest): this {
        Object.assign(this, source);
        return this;
    }

    //***
    //***
    constructor(lpUserId: string, emailAddress: string, contextToken: string, payload: string) {
        this.lpUserId = lpUserId;
        this.contextToken = contextToken;
        this.emailAddress = emailAddress;
        this.payload = payload;
    }
}
