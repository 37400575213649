import { IDeserializable } from "../../models/interfaces/IDeserializable.interface";
import { SnapFormLookupRow } from "./snapFormLookupRow";

export class SnapFormLookupTable implements IDeserializable {
    name: string;
    label: string;
    rows: SnapFormLookupRow[];

    //***
    //***
    constructor() {
        this.name = "";
        this.label = "";
        this.rows = []
    }

    //***
    //***
    deserialize(input: any): this {
        Object.assign(this, input);
        if (Array.isArray(input?.rows))
            this.rows = input.rows.map((row) => new SnapFormLookupRow().deserialize(row));
        return this;
    }
}
