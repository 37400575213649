
export class SnapConstants {
    public static readonly False = "false";
    public static readonly True = "true";

    public static readonly ActivityDate = "ActivityDate";
    public static readonly ClosedTaskStatus = "ClosedTaskStatus"
    public static readonly EndDateTime = "EndDateTime";
    public static readonly Event = "Event";
    public static readonly EventReminderDateTime = "EventReminderDateTime";
    public static readonly FirstName = "FirstName";
    public static readonly Id = "Id";
    public static readonly IsAllDayEvent = "IsAllDayEvent";
    public static readonly IsReminderSet = "IsReminderSet";
    public static readonly LastName = "LastName";
    public static readonly Name = "Name";
    public static readonly RecordTypeId = "RecordTypeId";
    public static readonly StartDateTime = "StartDateTime";
    public static readonly Status = "Status";
    public static readonly Task = "Task";
    public static readonly TaskReminderDateTime = "TaskReminderDateTime";

    public static readonly SubPart_TimeEnabled = "timeEnabled";
    public static readonly SubPart_Date = "date";
    public static readonly SubPart_DateEnabled = "dateEnabled";
    public static readonly SubPart_Offset = "offset";
    public static readonly SubPart_IsVisible = "isVisible";
    public static readonly SubPart_Time = "time";
    public static readonly SubPart_IsEnabled = "isEnabled";
}
