import { enableProdMode } from "@angular/core";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";
import { AppModule } from "./app/app.module";
import { environment } from "./environments/environment";
import { connectToOfficeJS } from "./handlers/officeMessageHandler";

export function getBaseUrl() {
    return document.getElementsByTagName("base")[0].href;
}

const providers = [{ provide: "BASE_URL", useFactory: getBaseUrl, deps: [] }];

if (environment.production) {
    enableProdMode();
}

Office.onReady(function (info) {
    platformBrowserDynamic(providers).bootstrapModule(AppModule)
        .then((value) => connectToOfficeJS())
        .catch((error) => console.log(error));
});
