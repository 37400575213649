import { Component, OnInit } from '@angular/core';
import { SnapFormService } from '../../snap-forms/services/snapform.service';
import { SnapForms } from '../../snap-forms/models/snapForms';
import { SessionService } from '../../core/services/session.service';
import { SnapFormSection } from '../../snap-forms/models/snapFormSection';
import { LPFormMode } from '../../snap-forms/models/enums';

@Component({
    selector: 'app-nart',
    templateUrl: './nart.component.html',
    styleUrls: ['./nart.component.scss'],
})
export class NartComponent implements OnInit {

    //***  !!nart.ts


    //***
    snapForms: SnapForms;
    //***
    title = 'dynamic-forms';


    //***
    //***
    constructor(private snapFormService: SnapFormService, private sessionService: SessionService) {
        sessionService.lpUserId = "03ece508-3b27-e820-7d47-7fa5fad5757b";
    }

    get firstsection(): SnapFormSection {
        return this.snapForms.forms[0].editSections[0];
    }

    //***
    //***
    ngOnInit(): void {
        // "Contact", "0031C00002vlFzn"
        // "Account", "0011C00002W3ODN"
        // "Lead", "00Q1500000saxhZ"
        this.snapFormService.getSnapForms("Account", LPFormMode.Full, "0011C00002W3ODN"); //event "00U6O00000obsNqUAI");
        this.snapFormService.snapFormsSubject.subscribe(resp => {
            if (resp) {
                this.snapForms = resp as SnapForms;
            }
        });
    }
}
