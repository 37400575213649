<div>
    <snap-lp-field-label [snapFormField]="snapFormField"></snap-lp-field-label>
    <div>
        <input class="lp-input-element"
               type="checkbox"
               [id]="snapFormField.fieldName"
               [name]="snapFormField.fieldName"
               [disabled]="isEnabled ? null : 'disabled'"
               [(ngModel)]="value"
               [attr.maxlength]="snapFormField.maxLength">
    </div>
</div>
