import { ClientState } from "./clientState";
import { PropertySetAction } from "./enums";
import { IDeserializable } from "./interfaces/IDeserializable.interface";
import { ReferencePacket } from "./referencePacket";
import { ResponseBase } from "./responseBase";

export class SelectResponse extends ResponseBase implements IDeserializable {
    public contextToken: string;
    public referencePacket: ReferencePacket;
    public referencePacketAction: PropertySetAction = PropertySetAction.Undefined;;
    public category: string;
    public categoryAction: PropertySetAction = PropertySetAction.Undefined;
    public clientState: ClientState = new ClientState();
    public success = false;
    public message: string;

    //***
    //***
    deserialize(input: any): this {
        Object.assign(this, input);
        if (input?.referencePacket)
            this.referencePacket = new ReferencePacket().deserialize(input.referencePacket);
        if (input?.clientState)
            this.clientState = new ClientState().deserialize(input.clientState);
        return this;
    }
}
