import { IDeserializable } from "../../models/interfaces/IDeserializable.interface";
import { SnapFormPicklistControllerValue } from "./snapFormPicklisControllerValue";
import { SnapFormPicklistValue } from "./snapFormPicklistValue";


export class SnapFormPicklist implements IDeserializable {

    //***
    fieldName: string;
    //***
    controllerFieldName: string;


    //***
    //***
    private _isController: boolean;
    public get isController(): boolean {
        if (!this._isController)
            this._isController = false;
        return this._isController;
    }
    public set isController(value: boolean) { this._isController = value; }

    //***
    //***
    private _isDependentPicklist: boolean;
    public get isDependentPicklist(): boolean {
        if (!this._isDependentPicklist)
            this._isDependentPicklist = false;
        return this._isDependentPicklist;
    }
    public set isDependentPicklist(value: boolean) { this._isDependentPicklist = value; }


    //***
    //***
    private _controllerValues: SnapFormPicklistControllerValue[] = [];
    public get controllerValues(): SnapFormPicklistControllerValue[] {
        if (!this._controllerValues)
            this._controllerValues = [];
        return this._controllerValues;
    }
    public set controllerValues(value: SnapFormPicklistControllerValue[]) { this._controllerValues = value; }


    //***
    private _values: SnapFormPicklistValue[] = [];
    public get values(): SnapFormPicklistValue[] {
        if (!this._values)
            this._values = [];
        return this._values;
    }
    public set values(value: SnapFormPicklistValue[]) { this._values = value; }


    //***
    public get defaultValue(): SnapFormPicklistValue {
        return this.values.find(x => x.isDefaultValue);
    }
    public set defaultValue(value: SnapFormPicklistValue) { }


    deserialize(input: any): this {
        Object.assign(this, input);

        this.controllerValues = [];
        if (Array.isArray(input.controllerValues))
            for (const source of input.controllerValues)
                this.controllerValues.push(new SnapFormPicklistControllerValue().deserialize(source));


        this.values = [];
        if (Array.isArray(input.values))
            for (const source of input.values)
                this.values.push(new SnapFormPicklistValue().deserialize(source));

        if (input?.defaultValue)
            this.defaultValue = new SnapFormPicklistValue().deserialize(input.defaultValue);

        return this;
    }

}
