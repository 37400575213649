import { PouchBase } from "./pouchBase";
import { BusyStatus, PouchClass } from "./enums";
import { LPEmailAddress } from "./lpEmailAddress";
import { IPouch } from "./interfaces/IPouch.interface";
import { Attachment } from "./attachment";
import { IDeserializable } from "./interfaces/IDeserializable.interface";

export class CalendarPouch extends PouchBase implements IPouch, IDeserializable {
    public attachments: Attachment[];
    public bcc: LPEmailAddress[] = [];
    public body: string;
    public busyStatus: BusyStatus = BusyStatus.Busy;
    public cc: LPEmailAddress[] = [];
    public end: Date;
    public from: LPEmailAddress;
    public isAllDayEvent: boolean;
    public isInBound: boolean;
    public location: string;
    public organizerIsExternalOrMe: boolean;
    public start: Date;
    public subject: string;
    public to: LPEmailAddress[] = [];

    //***
    //***
    constructor() {
        super(PouchClass.Calendar);
    }


    //***
    //***
    deserialize(input: CalendarPouch): this {
        Object.assign(this, input);

        super.deserialize(input);
        this.from = new LPEmailAddress().deserialize(input.from);
        if (input.to) {
            this.to = input.to.map((to) => new LPEmailAddress().deserialize(to));
        }
        if (input.cc) {
            this.cc = input.cc.map((cc) => new LPEmailAddress().deserialize(cc));
        }
        if (input.bcc) {
            this.bcc = input.to.map((bcc) => new LPEmailAddress().deserialize(bcc));
        }
        return this;
    }


    //***
    public get searchHints(): string[] {
        const result = [];
        if (this.isInBound) {
            if (this?.from?.address)
                result.push(this.from.address);

            for (const item of this.to)
                result.push(item.address);

            for (const item of this.cc)
                result.push(item.address);

            for (const item of this.bcc)
                result.push(item.address);

        }
        else {
            for (const item of this.to)
                result.push(item.address);

            for (const item of this.cc)
                result.push(item.address);

            for (const item of this.bcc)
                result.push(item.address);

            if (this?.from?.address)
                result.push(this.from.address);
        }
        return result;
    }


    //***
    public get naturalKey(): string {
        return `${this.start}.${this.subject}`;
    }
}
