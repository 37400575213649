import { BehaviorSubject } from "rxjs";
import { IDeserializable } from "../../models/interfaces/IDeserializable.interface";
import { LPDataType, LPEntryMode, LPFieldType } from "./enums";
import { SnapFormPicklistValue } from "./snapFormPicklistValue";
import { IVisitor } from "./Validators/IVisitor";

export class SnapFormField implements IDeserializable {
    compoundFieldName: string;
    controllerFieldName: string;
    dataType: LPDataType;
    digitsRight: number;
    displayLines: number;
    entryMode: LPEntryMode;
    fieldName: string;
    fieldType: LPFieldType;
    isAddressPartField: boolean;
    isAutoNumber: boolean;
    isController: boolean;
    isDependentPicklist: boolean
    isEditableForNew: boolean;
    isEditableForUpdate: boolean;
    isHtml: boolean;
    isLookup: boolean;
    IsLookupPolymorphic: boolean;
    isNameField: boolean;
    isNamePartField: boolean;
    isPicklist: boolean;
    isRequired: boolean;
    label: string;
    maxLength: number;
    ordinalColumn: number;
    tabOrder: number;
    tooltip: string;
    relationshipName: string;
    dependencyComment = "";
    orginalIsRequired: boolean;


    //***
    //***
    get isEnabled(): boolean { return this.fieldType !== LPFieldType.EmptySpace && (this.isEditableForNew && this.entryMode === LPEntryMode.Create) || (this.isEditableForUpdate && this.entryMode === LPEntryMode.Update); }
    set isEnabled(value: boolean) { ; }

    //***
    //***
    private _referenceTo: string[] = [];
    public get referenceTo(): string[] {
        if (!this._referenceTo)
            this._referenceTo = [];
        return this._referenceTo;
    }
    public set referenceTo(value: string[]) { this._referenceTo = value; }

    //***
    private _picklistvalues: SnapFormPicklistValue[] = [];
    public get picklistValues(): SnapFormPicklistValue[] {
        if (!this._picklistvalues)
            this._picklistvalues = [];
        return this._picklistvalues;
    }
    public set picklistValues(value: SnapFormPicklistValue[]) { this._picklistvalues = value; }

    //***
    //***
    private _validators: IVisitor[];
    public get validators(): IVisitor[] {
        if (!this._validators)
            this._validators = [];
        return this._validators;
    }
    public set validators(value: IVisitor[]) { this._validators = value; }


    //***
    //***
    private _dependentPickListFieldNames: string[];
    public get dependentPickListFieldNames(): string[] {
        if (!this._dependentPickListFieldNames)
            this._dependentPickListFieldNames = [];
        return this._dependentPickListFieldNames;
    }
    public set dependentPickListFieldNames(value: string[]) { this._dependentPickListFieldNames = value; }

    //used so that a component knows when the Validation message changes
    public validationSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);
    private _validationMessage: string;
    get validationMessage(): string { return this._validationMessage; }
    set validationMessage(value: string) {
        this._validationMessage = value;
        this.validationSubject.next(value);
    }



    //***
    // Clear any existing validation message and run the validators again
    //***
    public validate(value: string) {
        this.validationMessage = "";
        for (const val of this.validators) {
            val.visit(this, value);
        }
    }

    //***
    //***
    deserialize(input: any): this {
        Object.assign(this, input);

        this.orginalIsRequired = this.isRequired;
        this.referenceTo = [];
        this.picklistValues = [];
        this.dependentPickListFieldNames = [];
        if (Array.isArray(input?.referenceTo))
            for (const source of input.referenceTo)
                this.referenceTo.push(source);

        if (Array.isArray(input?.picklistValues))
            this.picklistValues = input.picklistValues.map((picklistValue) => new SnapFormPicklistValue().deserialize(picklistValue));

        if (Array.isArray(input?.dependentPickListFieldNames))
            for (const source of input.dependentPickListFieldNames)
                this.dependentPickListFieldNames.push(source);

        return this;
    }
}
