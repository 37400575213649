import { CalendarPouch } from "./calendarPouch";
import { ClientState } from "./clientState";
import { EmailPouch } from "./emailPouch";
import { PouchClass, SelectCardAction } from "./enums";
import { IDeserializable } from "./interfaces/IDeserializable.interface";
import { IPouch } from "./interfaces/IPouch.interface";



export class SelectRequest implements IDeserializable {
    public action: SelectCardAction;
    public clientState: ClientState = new ClientState();
    public contextToken: string;
    public lpUserId: string;


    deserialize(input: SelectRequest): this {
        Object.assign(this, input);

        if (input?.clientState)
            this.clientState = new ClientState().deserialize(input.clientState);
        return this;
    }

    public emailPouch: EmailPouch | undefined;
    public calendarPouch: CalendarPouch | undefined;
    get pouch(): IPouch | undefined {
        if (this.emailPouch)
            return this.emailPouch;
        else if (this.calendarPouch)
            return this.calendarPouch;
        else
            return undefined;
    }
    set pouch(value: IPouch | undefined) {
        this.emailPouch = undefined;
        this.calendarPouch = undefined;
        if (value) {
            if (value.pouchClass === PouchClass.Email)
                this.emailPouch = value as EmailPouch;
            else if (value.pouchClass === PouchClass.Calendar)
                this.calendarPouch = value as CalendarPouch;
        }
    }


    //***
    //***
    constructor(action: SelectCardAction, clientState: ClientState, contextToken: string, lpUserId: string, pouch: IPouch) {
        this.action = action;
        this.clientState = clientState;
        this.contextToken = contextToken;
        this.lpUserId = lpUserId;
        this.pouch = pouch;
    }
}
