import { Attachment } from "../../models/attachment";
import { CalendarPouch } from "../../models/calendarPouch";
import { EmailPouch } from "../../models/emailPouch";
import { EmptyPouch } from "../../models/emptyPouch";
import { PouchClass } from "../../models/enums";
import { IPouch } from "../../models/interfaces/IPouch.interface";
import { LPEmailAddress } from "../../models/lpEmailAddress";
import { PouchKey } from "../../models/pouchKey";
import { ReferencePacket } from "../../models/referencePacket";
import Utility from "../../shared/utility";
import { SublimeService } from "./sublime.service";




//***
//***
export class OfficeHelper {
    //***
    public static readonly customPropertyReferencePacket: string = "LP360_RP";
    //***
    public static officeUserEmailAddress: string;


    //***
    //***
    public static getPouch(target: Office.Item & Office.ItemCompose & Office.ItemRead & Office.Message & Office.MessageCompose & Office.MessageRead & Office.Appointment & Office.AppointmentCompose & Office.AppointmentRead): Promise<IPouch> {
        return new Promise(async (onSuccess) => {
            let result = new EmptyPouch();
            try {
                const pouchClass: PouchClass = await this.getPouchClass(target);
                if (pouchClass === PouchClass.Email)
                    result = await this.getEmailPouch(target);
                else if (pouchClass === PouchClass.Calendar)
                    result = await this.getCalendarPouch(target);
            }
            catch (ex) {
                //Do Nothing // onFailure(ex);
            }
            onSuccess(result);
        });
    }


    private static async lpGetCustomProperties(source: Office.Item & Office.ItemCompose & Office.ItemRead & Office.Message & Office.MessageCompose & Office.MessageRead & Office.Appointment & Office.AppointmentCompose & Office.AppointmentRead): Promise<Office.CustomProperties> {
        return new Promise((onSuccess) => {
            source.loadCustomPropertiesAsync((asyncResult) => {
                if (asyncResult.status === Office.AsyncResultStatus.Failed)
                    onSuccess(null);
                else
                    onSuccess(asyncResult.value);
            })
        });
    }


    private static getEmailPouch(target: Office.Item & Office.ItemCompose & Office.ItemRead & Office.Message & Office.MessageCompose & Office.MessageRead & Office.Appointment & Office.AppointmentCompose & Office.AppointmentRead): Promise<EmailPouch> {
        return new Promise((onSuccess, onFailure) => {
            try {
                if (!target)
                    throw "source is undefined";

                Promise.all([
                    this.getFrom(target.from),                                                                 // 0
                    this.getRecipientsAsync(target.to),                                                        // 1
                    this.getRecipientsAsync(target.cc),                                                        // 2
                    this.getSubjectAsync(target.subject),                                                      // 3
                    this.getBodyHtmlAsync(target.body),                                                        // 4
                    this.getCustomPropertyAsync(target, this.customPropertyReferencePacket),                   // 5
                    this.getAttachmentsAsync(target),                                                          // 6
                    this.getBccRecipientsAsync(target.bcc)                                                    // 7
                ]).then((vals) => {
                    const result = new EmailPouch();
                    result.from = vals[0];
                    result.to = vals[1];
                    result.cc = vals[2];
                    result.subject = vals[3];
                    result.body = vals[4];
                    result.referencePacket = ReferencePacket.fromJson(vals[5]);
                    result.attachments = vals[6];
                    result.bcc = vals[7];

                    result.isInBound = (result?.from?.address !== this.officeUserEmailAddress);;
                    result.conversationId = target.conversationId;
                    result.isComposeMode = this.getIsComposeMode(target);
                    result.pouchKey = this.getPouchKey(target.itemId, target.dateTimeModified);
                    result.sendDate = target.dateTimeCreated;
                    onSuccess(result);
                }).catch((err) => {
                    onFailure(err);
                });
            }
            catch (ex) {
                onFailure(ex);
            }
        });
    }


    //***
    //***
    private static getCalendarPouch(target: Office.Item & Office.ItemCompose & Office.ItemRead & Office.Message & Office.MessageCompose & Office.MessageRead & Office.Appointment & Office.AppointmentCompose & Office.AppointmentRead): Promise<CalendarPouch> {
        return new Promise((onSuccess, onFailure) => {
            try {
                if (!target)
                    throw "source is undefined";

                const isComposeMode = OfficeHelper.getIsComposeMode(target);
                if (isComposeMode) {
                    Promise.all([
                        this.getFrom(target.organizer),                                                  // 0
                        this.getRecipientsAsync(target.requiredAttendees),                               // 1
                        this.getRecipientsAsync(target.optionalAttendees),                               // 2
                        this.getSubjectAsync(target.subject),                                            // 3
                        this.getBodyTextAsync(target.body),                                              // 4
                        this.getCustomPropertyAsync(target, this.customPropertyReferencePacket),         // 5
                        this.getAttachmentsAsync(target),                                                // 6
                        this.getLocationAsync(target.location),                                          // 7
                        this.getTimeAsync(target.start),                                                 // 8
                        this.getTimeAsync(target.end)                                                    // 9
                    ]).then((vals) => {
                        const result = new CalendarPouch();
                        result.from = vals[0];
                        result.to = vals[1];
                        result.cc = vals[2];
                        result.subject = vals[3];
                        result.body = vals[4];
                        result.referencePacket = ReferencePacket.fromJson(vals[5]);
                        result.attachments = vals[6];
                        result.location = vals[7];
                        result.start = vals[8];
                        result.end = vals[9];

                        result.conversationId = target.conversationId;
                        result.isComposeMode = this.getIsComposeMode(target);
                        result.pouchKey = this.getPouchKey(target.itemId, target.dateTimeModified);
                        result.organizerIsExternalOrMe = this.getOrganizerIsExternalOrMe(result.from);
                        result.isInBound = (result?.from?.address !== this.officeUserEmailAddress);
                        onSuccess(result);
                    }).catch((err) => {
                        onFailure(err)
                    });
                }
                else {
                    Promise.all([
                        this.getFrom(target.organizer),                                              // 0
                        this.getRecipientsAsync(target.requiredAttendees),                           // 1
                        this.getRecipientsAsync(target.optionalAttendees),                           // 2
                        this.getSubjectAsync(target.subject),                                        // 3
                        this.getBodyTextAsync(target.body),                                          // 4
                        this.getCustomPropertyAsync(target, this.customPropertyReferencePacket),     // 5
                        this.getAttachmentsAsync(target)                                             // 6
                    ]).then((vals) => {
                        const result = new CalendarPouch();
                        result.from = vals[0];
                        result.to = vals[1];
                        result.cc = vals[2];
                        result.subject = vals[3];
                        result.body = vals[4];
                        result.referencePacket = ReferencePacket.fromJson(vals[5]);
                        result.attachments = vals[6];

                        result.location = target.location;
                        result.start = target.start;
                        result.end = target.end;
                        result.conversationId = target.conversationId;
                        result.isComposeMode = this.getIsComposeMode(target);
                        result.pouchKey = this.getPouchKey(target.itemId, target.dateTimeModified);
                        result.organizerIsExternalOrMe = this.getOrganizerIsExternalOrMe(result.from);
                        result.isInBound = (result?.from?.address !== this.officeUserEmailAddress);
                        onSuccess(result);
                    }).catch((err) => {
                        onFailure(err)
                    });
                }
            }
            catch (ex) { onFailure(ex); }
        });
    }


    //***
    //***
    public static getFrom(target: Office.Organizer & Office.From & Office.EmailAddressDetails): Promise<LPEmailAddress> {
        return new Promise((onSuccess, onFailure) => {
            let result: LPEmailAddress = null;
            let error = null;
            if (target?.getAsync) {
                target.getAsync((asyncResult) => {
                    try {
                        this.assertSuccess(asyncResult.status, asyncResult.error);
                        result = this.toLPEmailAddress(asyncResult.value);
                    }
                    catch (ex) { error = ex; }
                    finally {
                        if (error)
                            onFailure(error);
                        else
                            onSuccess(result);
                    }
                });
            }
            else if (target.emailAddress)
                onSuccess(this.toLPEmailAddress(target as Office.EmailAddressDetails));

            else
                onFailure(this.valueIsUndefined("Office.Organizer"));
        });
    }


    //***
    //***
    public static getRecipientsAsync(target: Office.Recipients & Office.EmailAddressDetails[]): Promise<LPEmailAddress[]> {
        return new Promise((onSuccess, onFailure) => {
            let result: LPEmailAddress[] = [];
            let error = null;
            if (target.getAsync) {
                target.getAsync((asyncResult) => {
                    try {
                        this.assertSuccess(asyncResult.status, asyncResult.error);
                        result = this.toLPEmailAddresses(asyncResult.value);
                    }
                    catch (ex) { error = ex; }
                    finally {
                        if (error)
                            onFailure(error);

                        else
                            onSuccess(result);
                    }
                });
            }
            else if (Array.isArray(target))
                onSuccess(this.toLPEmailAddresses(target as Office.EmailAddressDetails[]) ?? []);

            else
                onFailure(this.valueIsUndefined("Office.Recipients"));
        });
    }

    //***
    //*** ND 2024-02-19:  For some reason, BCC fails in the above method.
    //*** By added "& any" as the params, it works.  decided to make its own method and call explicilty just for bcc.
    public static getBccRecipientsAsync(target: Office.Recipients & Office.EmailAddressDetails[] & any): Promise<LPEmailAddress[]> {
        return new Promise((onSuccess, onFailure) => {
            let result: LPEmailAddress[] = [];
            let error = null;
            if (target.getAsync) {
                target.getAsync((asyncResult) => {
                    try {
                        this.assertSuccess(asyncResult.status, asyncResult.error);
                        result = this.toLPEmailAddresses(asyncResult.value);
                    }
                    catch (ex) { error = ex; }
                    finally {
                        if (error)
                            onFailure(error);

                        else
                            onSuccess(result);
                    }
                });
            }
            else if (Array.isArray(target))
                onSuccess(this.toLPEmailAddresses(target as Office.EmailAddressDetails[]) ?? []);

            else
                onFailure(this.valueIsUndefined("Office.Recipients"));
        });
    }


    //***
    //***
    public static getSubjectAsync(target: Office.Subject & string): Promise<string> {
        return new Promise((onSuccess, onFailure) => {
            let result: string = null;
            let error = null;
            if (target) {
                if (target.getAsync) {
                    target.getAsync((asyncResult) => {
                        try {
                            this.assertSuccess(asyncResult.status, asyncResult.error);
                            result = asyncResult.value;
                        }
                        catch (ex) { error = ex; }
                        finally {
                            if (error)
                                onFailure(error);

                            else
                                onSuccess(result);
                        }
                    });
                }
                else if (target.length >= 0) {
                    onSuccess(target);  //Allow blank Email Subject
                }
                else
                    onFailure(this.valueIsUndefined("Office.Subject"));
            } else {
                onSuccess(''); //Allow blank Calendar subject
            }
        });
    }


    //***
    //***
    public static getBodyTextAsync(target: Office.Body): Promise<string> {
        return new Promise((onSuccess, onFailure) => {
            let result: string = null;
            let error = null;
            if (target) {
                target.getAsync(Office.CoercionType.Text, (asyncResult) => {
                    try {
                        this.assertSuccess(asyncResult.status, asyncResult.error);
                        result = asyncResult.value;
                    }
                    catch (ex) { error = ex; }
                    finally {
                        if (error)
                            onFailure(error);

                        else
                            onSuccess(result);
                    }
                });
            }

            else
                onFailure(this.valueIsUndefined("Office.Body"));
        });
    }


    //***
    //***
    public static getBodyHtmlAsync(target: Office.Body): Promise<string> {
        return new Promise((onSuccess, onFailure) => {
            let result: string = null;
            let error = null;
            if (target) {
                target.getAsync(Office.CoercionType.Html, (asyncResult) => {
                    try {
                        this.assertSuccess(asyncResult.status, asyncResult.error);
                        result = asyncResult.value;
                    }
                    catch (ex) { error = ex; }
                    finally {
                        if (error)
                            onFailure(error);

                        else
                            onSuccess(result);
                    }
                });
            }

            else
                onFailure(this.valueIsUndefined("Office.Body"));
        });
    }


    //***
    //***
    public static getLocationAsync(target: Office.Location & string): Promise<string> {
        return new Promise((onSuccess, onFailure) => {
            let result: string = null;
            let error = null;

            if (target.getAsync) {
                target.getAsync((asyncResult) => {
                    try {
                        this.assertSuccess(asyncResult.status, asyncResult.error);
                        result = asyncResult.value;
                    }
                    catch (ex) { error = ex; }
                    finally {
                        if (error)
                            onFailure(error);
                        else
                            onSuccess(result);
                    }
                });
            }
            else if (target.length)
                onSuccess(target);
            else
                onFailure("undefined");
        });
    }


    //***
    //***
    public static getTimeAsync(source: Office.Time & Date): Promise<Date> {
        return new Promise((onSuccess, onFailure) => {
            let result: Date = null;
            let error = null;

            if (source.getAsync) {
                source.getAsync((asyncResult) => {
                    try {
                        this.assertSuccess(asyncResult.status, asyncResult.error);
                        result = asyncResult.value;
                    }
                    catch (ex) { error = ex; }
                    finally {
                        if (error)
                            onFailure(error);

                        else
                            onSuccess(result);
                    }
                });
            }

            else
                onFailure("undefined");
        });
    }


    //***
    //***
    private static valueIsUndefined(propertyName: string): string { return `${propertyName} is undefined`; }


    //***
    //***
    public static getCustomPropertyAsync(source: Office.Item & Office.ItemCompose & Office.ItemRead & Office.Message & Office.MessageCompose & Office.MessageRead & Office.Appointment & Office.AppointmentCompose & Office.AppointmentRead, propertyName: string): Promise<string> {
        return new Promise((onSuccess, onFailure) => {
            if (!source)
                onFailure(this.valueIsUndefined("target"));

            else if (Utility.isNullOrEmpty(propertyName))
                onFailure(this.valueIsUndefined("propertyName"));

            else {
                source.loadCustomPropertiesAsync((asyncResult) => {
                    try {
                        if (asyncResult.status === Office.AsyncResultStatus.Failed) {
                            onFailure(asyncResult.error);
                        }
                        else {
                            const result: string = asyncResult.value.get(propertyName);
                            onSuccess(result);
                        }
                    }
                    catch (ex) { onFailure(ex); }
                });
            }
        });
    }

    //***
    //***
    public static setCustomPropertyAsync(source: Office.Item & Office.ItemCompose & Office.ItemRead & Office.Message & Office.MessageCompose & Office.MessageRead & Office.Appointment & Office.AppointmentCompose & Office.AppointmentRead, propertyName: string, propertyValue: string): Promise<boolean> {
        return new Promise((onSuccess, onFailure) => {
            if (!source) {
                onFailure(this.valueIsUndefined("target"));
            }

            else if (Utility.isNullOrEmpty(propertyName)) {
                onFailure(this.valueIsUndefined("propertyName"));
            }

            else {
                source.loadCustomPropertiesAsync((asyncResult) => {
                    try {
                        let action = "";
                        this.assertSuccess(asyncResult.status, asyncResult.error);
                        if (Utility.isNullOrEmpty(propertyValue)) {
                            action = "Removed";
                            asyncResult.value.remove(propertyName);
                        }
                        else {
                            action = "Set";
                            asyncResult.value.set(propertyName, propertyValue);
                        }

                        asyncResult.value.saveAsync((saveResult) => {
                            if (asyncResult.status === Office.AsyncResultStatus.Failed) {
                                onFailure(saveResult.error);
                            }
                            else {
                                onSuccess(true);
                            }
                        });
                    }
                    catch (ex) { onFailure(ex); }
                });
            }
        });
    }


    //***
    //***
    public static setCategory(source: Office.Item & Office.ItemCompose & Office.ItemRead & Office.Message & Office.MessageCompose & Office.MessageRead & Office.Appointment & Office.AppointmentCompose & Office.AppointmentRead, categoriesToAdd: string[], sublimeService: SublimeService): Promise<boolean> {
        return new Promise((resolve, reject) => {
            categoriesToAdd = categoriesToAdd.filter(Boolean); //strip out any empty entries
            if (!categoriesToAdd || categoriesToAdd.length <= 0) {
                let logText = `SetCategory Failed A:  CategoriesToAdd is null or empty`;
                sublimeService.logAppend(logText);
                reject("No category set.");
            }
            else {
                //Get the list of Master Categories for this user
                Office.context.mailbox.masterCategories.getAsync(function (asyncGetMasterResult) {
                    if (asyncGetMasterResult.status === Office.AsyncResultStatus.Failed) {
                        let logText = `SetCategory Failed B:  Name=${asyncGetMasterResult.error.name}  Code=${asyncGetMasterResult.error.code}    Message=${asyncGetMasterResult.error.message}`;
                        sublimeService.logAppend(logText);
                        reject(asyncGetMasterResult.error.message);
                    }

                    const masterCategories: Office.CategoryDetails[] = asyncGetMasterResult.value;

                    //Build a list of all categoriesToAdd that do not currently exist in the Master Categories list
                    const missingMasterCategoriesFromRequest: string[] = categoriesToAdd.filter(function (categoryName) {
                        return !masterCategories.some((x) => x.displayName === categoryName);
                    });

                    //If there are any missing, we need to add those categories to the master list before being able to add them to the current document
                    if (missingMasterCategoriesFromRequest.length > 0) {
                        //build the corresponding CategoryDetails objects to add to the Master List
                        const masterCategoriesToAdd: Office.CategoryDetails[] = [];
                        missingMasterCategoriesFromRequest.forEach(function (categoryName) {
                            const newMasterCategory: Office.CategoryDetails = {
                                displayName: categoryName,
                                color: Office.MailboxEnums.CategoryColor.Preset1,
                            };
                            masterCategoriesToAdd.push(newMasterCategory);
                        });

                        //Add them to the master list
                        Office.context.mailbox.masterCategories.addAsync(
                            masterCategoriesToAdd,
                            function (asyncSetMasterResult) {
                                if (asyncSetMasterResult.status === Office.AsyncResultStatus.Failed) {
                                    let logText = `SetCategory Failed C  Name=${asyncSetMasterResult.error.name}  Code=${asyncSetMasterResult.error.code}    Message=${asyncSetMasterResult.error.message}`;
                                    sublimeService.logAppend(logText);
                                    reject(asyncSetMasterResult.error.message);
                                }
                                else {
                                    //Successfully added categories to master list
                                    //Now we can finally add the categories to the document
                                    source.categories.addAsync(categoriesToAdd, (asyncResult) => {
                                        if (asyncResult.status === Office.AsyncResultStatus.Failed) {
                                            let logText = `SetCategory Failed D  Name=${asyncResult.error.name}  Code=${asyncResult.error.code}    Message=${asyncResult.error.message}`;
                                            sublimeService.logAppend(logText);
                                            reject(asyncResult.error.message);
                                        }
                                        else {
                                            let logText = "SetCategory Success (master Category created)";
                                            sublimeService.logAppend(logText);
                                            resolve(true);
                                        }
                                    });
                                }
                            }
                        );
                    }
                    else {
                        //We dont need to add the categories to the master list so just continue as normal and add them to the document
                        source.categories.addAsync(categoriesToAdd, (asyncResult) => {
                            if (asyncResult.status === Office.AsyncResultStatus.Failed) {
                                let logText = `SetCategory Failed E:  Name=${asyncResult.error.name}  Code=${asyncResult.error.code}    Message=${asyncResult.error.message}`;
                                sublimeService.logAppend(logText);
                                reject(asyncResult.error.message);
                            }
                            else {
                                let logText = "SetCategory Success";
                                resolve(true);
                            }
                        });
                    }
                });
            }
        });
    }


    //***
    //***
    public static removeCategories(source: Office.Item & Office.ItemCompose & Office.ItemRead & Office.Message & Office.MessageCompose & Office.MessageRead & Office.Appointment & Office.AppointmentCompose & Office.AppointmentRead, categoriesToRemove: string[]): Promise<boolean> {
        return new Promise((resolve, reject) => {
            categoriesToRemove = categoriesToRemove.filter(Boolean); //strip out any empty entries
            if (!categoriesToRemove || categoriesToRemove.length <= 0) {
            }
            else {
                source.categories.removeAsync(
                    categoriesToRemove,
                    (asyncResult) => {
                        if (asyncResult.status === Office.AsyncResultStatus.Failed) {
                            reject(asyncResult.error.message);
                        }
                        else {
                            resolve(true);
                        }
                    }
                );
            }
        });
    }


    //***
    //***
    public static getAttachmentsAsync(source: Office.Item & Office.ItemCompose & Office.ItemRead & Office.Message & Office.MessageCompose & Office.MessageRead & Office.Appointment & Office.AppointmentCompose & Office.AppointmentRead, loadContents = false): Promise<Attachment[]> {
        const result: Attachment[] = [];
        let error = null;

        if (source.attachments) {
            return new Promise((onSuccess, onFailure) => {
                try {
                    source.attachments.forEach(x => {
                        result.push(this.toAttachment(x));
                    });
                }
                catch (ex) { error = ex; }
                finally {
                    if (error)
                        onFailure(error);

                    else
                        onSuccess(result);
                }
            });
        }
        else if (source.getAttachmentsAsync) {
            return new Promise((onSuccess, onFailure) => {
                source.getAttachmentsAsync((asyncResult) => {
                    try {
                        this.assertSuccess(asyncResult.status, asyncResult.error);
                        asyncResult.value.forEach(x => {
                            result.push(this.toAttachmentCompose(x));
                        });
                    }
                    catch (ex) { error = ex; }
                    finally {
                        if (error)
                            onFailure(error);

                        else
                            onSuccess(result);
                    }
                });
            });
        }
    }

    //***
    // Loops through the list of attachments and fetches the content of each of them
    //***
    public static loadAttachmentContents(target: Office.Item & Office.ItemCompose & Office.ItemRead & Office.Message & Office.MessageCompose & Office.MessageRead & Office.Appointment & Office.AppointmentCompose & Office.AppointmentRead, attachments: Attachment[]): Promise<boolean> {
        return new Promise((onSuccess, onFailure) => {
            let error = null;
            try {
                if (target) {
                    const contentPromises = [];
                    attachments.forEach(x => {
                        contentPromises.push(OfficeHelper.getAttachmentContentAsync(target, x.id));
                    });

                    Promise.all(contentPromises)
                        .then((vals) => {
                            for (let i = 0; i < vals.length; i++) {
                                attachments[i].data = vals[i];
                            }
                            onSuccess(true);
                        })
                        .catch((err) => {
                            onFailure(err);
                        });
                }
            }
            catch (ex) {
                error = ex;
                onFailure(error);
            }
        });
    }


    //***
    // Wrapper on Office.js getAttachmentContentAsync that forces it to return as a promise
    //***
    public static getAttachmentContentAsync(target: Office.Item & Office.ItemCompose & Office.ItemRead & Office.Message & Office.MessageCompose & Office.MessageRead & Office.Appointment & Office.AppointmentCompose & Office.AppointmentRead, attachmentId: string): Promise<string> {
        return new Promise((onSuccess, onFailure) => {
            let result: string = null;
            let error = null;

            target.getAttachmentContentAsync(attachmentId, (asyncResult) => {
                try {
                    this.assertSuccess(asyncResult.status, asyncResult.error);
                    result = asyncResult.value.content;
                }
                catch (ex) {
                    Utility.debug(ex, "getAttachmentContentAsync");
                    error = ex;
                }
                finally {
                    if (error)
                        onFailure(error);

                    else
                        onSuccess(result);
                }
            });
        });
    }


    //***
    //***
    private static getOrganizerIsExternalOrMe(value: LPEmailAddress): boolean {
        if (!value?.address || value.address.indexOf("@") < 0)
            return false;
        else if (Utility.equalsCaseInsensative(value.address, this.officeUserEmailAddress))
            return true;

        const userParts = this.officeUserEmailAddress.split("@");
        const sourceParts = value.address.split("@");
        return Utility.equalsCaseInsensative(userParts.lastItem, sourceParts.lastItem);
    }


    //***
    //***
    private static getPouchKey(id: string, timestamp: Date): PouchKey {
        const result = new PouchKey();
        result.id = id;
        result.timestamp = timestamp;
        return result;
    }


    //***
    //***
    public static getPouchClass(value: Office.Item & Office.ItemCompose & Office.ItemRead & Office.Message & Office.MessageCompose & Office.MessageRead & Office.Appointment & Office.AppointmentCompose & Office.AppointmentRead): Promise<PouchClass> {
        return new Promise(async (onSuccess) => {
            let result = PouchClass.Undefined;
            if (value) {
                const itemType = value.itemType;
                const itemClass = value.itemClass;
                const composeType = await this.getComposeTypeAsync(value);

                if (itemType === Office.MailboxEnums.ItemType.Message) {
                    if (itemClass === "IPM.Note" || itemClass === "IPM.Note.Rules.ReplyTemplate.Microsoft" || composeType === Office.MailboxEnums.ComposeType.Forward || composeType === Office.MailboxEnums.ComposeType.Reply || composeType === Office.MailboxEnums.ComposeType.NewMail) {
                        result = PouchClass.Email;
                    }
                }
                else if (itemType === Office.MailboxEnums.ItemType.Appointment) {
                    result = PouchClass.Calendar;
                }
                else {
                    Utility.debug(result, "PouchClass");
                    Utility.debug(itemType, "ItemType")
                    Utility.debug(itemClass, "ItemClass")
                    Utility.debug(composeType, "ComposeType")
                }
            }
            onSuccess(result);
        });
    }


    //***
    //***
    public static getComposeTypeAsync(target: Office.MessageCompose & Office.ItemCompose): Promise<string> {
        return new Promise((onSuccess) => {
            let result: string = null;
            if (target.getComposeTypeAsync) {
                target.getComposeTypeAsync((asyncResult) => {
                    try {
                        result = asyncResult.value.composeType;
                    }
                    catch (ex) {
                        Utility.debug(ex, "getComposeTypeAsync");
                    }
                    finally {
                        onSuccess(result);
                    }
                });
            }
            else
                onSuccess(result);
        });
    }


    //***
    //***
    public static getIsComposeMode(value: Office.Item & Office.ItemCompose & Office.ItemRead & Office.Message & Office.MessageCompose & Office.MessageRead & Office.Appointment & Office.AppointmentCompose & Office.AppointmentRead): boolean {
        return value && typeof Office.context.mailbox.item.addFileAttachmentAsync !== "undefined";
    }


    //***
    //***
    private static toAttachment(value: Office.AttachmentDetails): Attachment {
        let result: Attachment = null;
        try {
            result = new Attachment();
            result.id = value.id;
            result.name = value.name;
            result.fileSize = value.size;
        }
        catch (error) {
            Utility.debug(error, "toAttachment");
        }
        return result;
    }


    //***
    //***
    private static toAttachmentCompose(value: Office.AttachmentDetailsCompose): Attachment {
        let result: Attachment = null;
        try {
            result = new Attachment();
            result.id = value.id;
            result.name = value.name;
            result.fileSize = value.size;
        }
        catch (error) {
            Utility.debug(error, "toAttachmentCompose");
        }
        return result;
    }


    //***
    //***
    public static toLPEmailAddresses(value: Office.EmailAddressDetails[]): LPEmailAddress[] {
        const result: LPEmailAddress[] = [];
        try {
            if (value) {
                for (const item of value) {
                    const newitem = this.toLPEmailAddress(item);
                    if (newitem)
                        result.push(newitem);
                }
            }
        }
        catch (error) {
            Utility.debug(`toLPEmailAddresses: ${error}`);
        }
        return result;
    }


    //***
    //***
    public static toLPEmailAddress(value: Office.EmailAddressDetails): LPEmailAddress {
        let result: LPEmailAddress = null;
        try {
            if (value)
                result = new LPEmailAddress(value.displayName, value.emailAddress);
        }
        catch (error) {
            Utility.debug(`toLPEmailAddress: ${error}`);
        }
        return result;
    }


    //***
    //***
    private static assertSuccess(value: Office.AsyncResultStatus, error: Office.Error) {
        if (value && value === Office.AsyncResultStatus.Failed)
            throw error;
    }
}
