import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class FocusService {
    private renderer: Renderer2;

    //***
    //***
    constructor(rendererFactory: RendererFactory2) {
        this.renderer = rendererFactory.createRenderer(null, null);
    }

    //***
    // Sets cursor focus on the first element that matches the given css selector
    //***
    setFocusFirstMatch(cssSelectorString: string) {

        //need to set a timeout with 0 seconds in order trigger Angular change detection which is required before setting focus on an element
        setTimeout(() => {
            let currentInput = this.renderer.selectRootElement(cssSelectorString, true);
            currentInput.focus();
        }, 0);
        
    }

    //***
    // Sets scroll focus on the first element that matches the given css selector
    //***
    setScrollFirstMatch(cssSelectorString: string) {

        //need to set a timeout with 0 seconds in order trigger Angular change detection which is required before setting focus on an element
        setTimeout(() => {
            let currentElement = this.renderer.selectRootElement(cssSelectorString, true);
            currentElement.scrollIntoView();
        }, 0);

    }
}
