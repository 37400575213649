import { IDeserializable } from "./interfaces/IDeserializable.interface";
import { RemoteAction } from "./remoteAction";

export class RemoteRequest implements IDeserializable {
    public lpUserId: string;
    public actions: RemoteAction[];


    //***
    //***
    constructor(lpUserId: string, actions: RemoteAction[]) {
        this.lpUserId = lpUserId;
        this.actions = actions;
    }

    deserialize(input: RemoteRequest): this {
        Object.assign(this, input);

        if (Array.isArray(input?.actions)) {
            this.actions = input.actions.map((action) => new RemoteAction().deserialize(action));
        }
        return this;
    }
}
