<div *ngIf="isVisible">
    <snap-lp-field-label [snapFormField]="snapFormField"></snap-lp-field-label>
    <div>
        <div class="row lp-no-gutters">
            <div class="col-6 lp-no-gutters"
                 style="padding-right: 2px;">
                <input class="lp-input-element"
                       type="date"
                       [id]="snapFormField.fieldName + '.date'"
                       [name]="snapFormField.fieldName + '.date'"
                       [disabled]="isEnabled==true ? null : 'disabled'"
                       [(ngModel)]="date" />
            </div>

            <select class="col-6 lp-no-gutters lp-input-element"
                    style="padding: 4px 4px 4px 4px"
                    [id]="snapFormField.fieldName + '.time'"
                    [name]="snapFormField.fieldName + '.time'"
                    [disabled]="isEnabled==true ? null : 'disabled'"
                    [(ngModel)]="time">
                <option *ngFor="let item of timeValues"
                        [value]="item.value">
                    {{item.label}}
                </option>
            </select>
        </div>
    </div>
</div>
