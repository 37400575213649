<div class="question">
    <snap-lp-field-label [snapFormField]="snapFormField"></snap-lp-field-label>
    <div>
        <div class="btn-group full-width" dropdown #dropdown="bs-dropdown">
            <input type="text"
                   class="lp-input-element"
                   style="border-top-left-radius: 0.25rem; border-bottom-left-radius: 0.25rem; border-top-right-radius: 0px; border-bottom-right-radius: 0px"
                   [id]="snapFormField.fieldName"
                   [name]="snapFormField.fieldName"
                   [(ngModel)]="value"
                   [disabled]="!snapFormField.isEnabled ? 'disabled': null"
                   [attr.maxlength]="snapFormField.maxLength > 0 ? snapFormField.maxLength : null">

            <!-- Have to have 2 different ngif versions of the control because you cannot disable a dropdownToggle directive through html -->
            <button *ngIf="snapFormField.isEnabled"
                    dropdownToggle
                    type="button"
                    class="btn btn-primary dropdown-toggle">
                <span class="caret"></span>
            </button>
            <button *ngIf="!snapFormField.isEnabled"
                    type="button"
                    disabled
                    class="btn btn-primary dropdown-toggle">
                <span class="caret"></span>
            </button>

            <ul *dropdownMenu class="dropdown-menu noselect full-width"
                role="menu">
                <list-item *ngFor="let item of snapFormField.picklistValues"
                           (click)="selectItem(item)">
                    {{item.value}}
                </list-item>
            </ul>
        </div>
    </div>
</div>
