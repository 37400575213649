import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from "../../../environments/environment";
import { EmptyResponse } from '../../models/emptyResponse';
import { ProfileId, Visibility } from '../../models/enums';
import { PropertyBagRequest } from '../../models/propertyBagRequest';
import { PropertyBagResponse } from '../../models/propertyBagResponse';
import Utility from '../../shared/utility';
import { SnapForms } from '../../snap-forms/models/snapForms';
import { PreflightResponse } from '../../models/preflightResponse';

@Injectable({
    providedIn: 'root'
})
export class SessionService {
    constructor(private httpClient: HttpClient) {
    }

    //***
    //Docker replacement for Environment variables
    //Gets set from the constructor of sublime.service.ts
    //***
    public API_ENDPOINT: string;
    public ASSIST_ENDPOINT: string;
    public SF_OAUTH_CID: string;
    public GRAPH_OAUTH_CID: string;


    //**
    //**
    private preflightUrl = "api/preflight"; //relative path so it will add the current url to the front of it when making a https request
    public preflightCall(): Promise<PreflightResponse> {
        return this.httpClient.get<PreflightResponse>(`${this.preflightUrl}`, { responseType: "json" }).toPromise();
    }

    //***
    //***
    public readonly propertyBagInjectSnapFormValues = "injectSnapFormValues"
    public readonly propertyBaginjectSnapFormRecordType = "injectSnapFormRecordType"
    public readonly propertyBagSnapFormSaved = "snapFormSaved"
    public readonly propertyBagAuthToken = "injectSnapFormAuthToken"


    //***  OfficeHostApplication  OfficeHostApplication  OfficeHostApplication
    //***  OfficeHostApplication  OfficeHostApplication  OfficeHostApplication
    //***
    private _officeApplication: string;
    get officeApplication(): string { return this._officeApplication ?? (this._officeApplication = "Undefined"); }
    set officeApplication(value: string) { this._officeApplication = value ?? "Undefined"; }

    //***
    //***
    private _officeVersion: string;
    get officeVersion(): string { return this._officeVersion ?? (this._officeVersion = "Undefined"); }
    set officeVersion(value: string) { this._officeVersion = value ?? "Undefined"; }

    //***
    //***
    private _touchEnabled: string;
    get touchEnabled(): string { return this._touchEnabled ?? (this._touchEnabled = "Undefined"); }
    set touchEnabled(value: string) { this._touchEnabled = value ?? "Undefined"; }

    //***
    //***
    private _displayLanguage: string;
    get displayLanguage(): string { return this._displayLanguage ?? (this._displayLanguage = "Undefined"); }
    set displayLanguage(value: string) { this._displayLanguage = value ?? "Undefined"; }


    //***
    //***
    private _officeEwsUrl: string;
    get officeEwsUrl(): string { return this._officeEwsUrl ?? (this._officeEwsUrl = "Undefined"); }
    set officeEwsUrl(value: string) { this._officeEwsUrl = value ?? "Undefined"; }

    //***
    //***
    private _officeRestUrl: string;
    get officeRestUrl(): string { return this._officeRestUrl ?? (this._officeRestUrl = "Undefined"); }
    set officeRestUrl(value: string) { this._officeRestUrl = value ?? "Undefined"; }


    //***  OfficeHostPlatform  OfficeHostPlatform  OfficeHostPlatform
    //***  OfficeHostPlatform  OfficeHostPlatform  OfficeHostPlatform
    //***
    private _officePlatform: string;
    get officePlatform(): string { return this._officePlatform ?? (this._officePlatform = "Undefined"); }
    set officePlatform(value: string) { this._officePlatform = value ?? "Undefined"; }


    //***
    //***
    private _officeAccountType: string;
    get officeAccountType(): string { return this._officeAccountType ?? (this._officeAccountType = "Undefined"); }
    set officeAccountType(value: string) { this._officeAccountType = value ?? "Undefined"; }

    //***
    //***
    get isOfficeIdentitySupported(): boolean { return Office.context.requirements.isSetSupported('IdentityAPI', '1.3'); } //Check to confirm if SSO is available on this client



    // Dialog heights (percents)
    public readonly dialogHeightLarge = 80;
    public readonly dialogHeightMedium = 60;
    public readonly dialogHeightSmall = 40;
    // Dialog Widths (percents)
    public readonly dialogWidthLarge = 80;
    public readonly dialogWidthMedium = 60;
    public readonly dialogWidthSmall = 40;


    //***
    //***
    private _officeUserDisplayName: string;
    get officeUserDisplayName(): string { return this._officeUserDisplayName ?? (this._officeUserDisplayName = "Undefined"); }
    set officeUserDisplayName(value: string) { this._officeUserDisplayName = value ?? "Undefined"; }


    //***
    //***
    private _officeUserEmailAddress: string;
    get officeUserEmailAddress(): string { return this._officeUserEmailAddress ?? (this._officeUserEmailAddress = "Undefined"); }
    set officeUserEmailAddress(value: string) { this._officeUserEmailAddress = value ?? "Undefined"; }


    //***
    //***
    private _officeUserTimezone: string;
    get officeUserTimezone(): string { return this._officeUserTimezone ?? (this._officeUserTimezone = "Undefined"); }
    set officeUserTimezone(value: string) { this._officeUserTimezone = value ?? "Undefined"; }


    //***
    //***
    private _followUpTableName: string;
    get followUpTableName(): string { return this._followUpTableName ?? (this._followUpTableName = ""); }
    set followUpTableName(value: string) { this._followUpTableName = value ?? ""; }

    //***
    //***
    private _officeAuthToken: string;
    get officeAuthToken(): string { return this._officeAuthToken ?? (this._officeAuthToken = ""); }
    set officeAuthToken(value: string) { this._officeAuthToken = value ?? ""; }

    //***  LPUserId  LPUserId  LPUserId
    //***  LPUserId  LPUserId  LPUserId
    //***
    private _lpUserId: string;
    public get lpUserId(): string { return this._lpUserId; }
    public set lpUserId(value: string) { this._lpUserId = value; }

    public multiWhoEnabled = false;
    public categoryEmail = "CRM";
    public categoryCalendar = "CRM";

    public salesforceInstanceUrl = "";
    public getSalesforceUrl(id: string) { return this.salesforceInstanceUrl + "/" + id; }

    public profileId: ProfileId = ProfileId.StandardUser;
    //***
    public searchOnlyNameFields = false;
    public searchOnlyNameFieldsVisibility = Visibility.Visible;
    //***
    public autoSelectAttachmentsEnabled: boolean
    public autoSelectAttachmentsIgnore: string;
    public autoSelectAttachmentsVisibility = Visibility.Visible
    //***
    public ignoreSearchHintsEnabled = false;
    public ignoreSearchHints = "";
    public ignoreSearchHintsVisibility = Visibility.Visible;
    public searchHintHistory: string[] = [];
    //***
    public supportVisibility = Visibility.Visible;
    public knowledgeBaseVisibility = Visibility.Visible;
    public chatVisibility = Visibility.Visible;
    //***
    public subjectBodyEnabled = false;
    public subjectBodyVisibility = Visibility.Visible;
    //***
    public editModeMessageEnabled = false;
    public editModeMessageVisibility = Visibility.Visible;
    ////***
    //public timeScoutEnabled = false;
    //public timeScoutVisibility = Visibility.Visible;

    public sidePanelSplitterHeight = 150;
    public sidekickVisibility = Visibility.Visible;
    public openInBrowserVisibility = Visibility.Visible;

    public removeAllVisibility = Visibility.Visible;
    //***
    public connectionsEnabled = false;
    public connectionsVisibility = Visibility.Visible;
    //***
    public salesforceConnected = false;
    public salesforceUserName = "";
    public salesforceOAuthMode = "";
    public salesforceOAuthEndPoint = "";

    public microsoftManagedAppEndPoint: string;
    public microsoftManagedAppScope: string;
    public microsoftConnected = false;
    public microsoftUserName = "";

    public calendarSyncEnabled = false;
    public emailSyncEnabled = false;
    public contactSyncEnabled = false;

    public followUpVisibility = Visibility.Visible;
    //***
    public suggestUsingConversation = true;
    public suggestUsingConversationVisibility = Visibility.Visible
    //***
    public suggestUsingRelevance = true;
    public suggestUsingRelevanceVisibility = Visibility.Visible;
    //***
    public hasActivated = false;
    public appVersion = "";
    public isLicenseActive = true; //true until bootstrap changes it so that we dont get premptive License expired messages

    //***
    public discoverySuccessCount: number = 0;


    //***
    public miniSnapFormTask: SnapForms;
    public get miniSnapFormTaskExists(): boolean { return this.miniSnapFormTask !== undefined && this.miniSnapFormTask !== null; }
    //***
    public miniSnapFormEvent: SnapForms;
    public get miniSnapFormEventExists(): boolean { return this.miniSnapFormEvent !== undefined && this.miniSnapFormEvent !== null; }

    public headerCreateVisibility = Visibility.Visible;
    public cardCreateVisibility = Visibility.Visible;
    public cardEditVisibility = Visibility.Visible;
    public primaryWhoVisibility = Visibility.Visible;

    public cardQuickCommentVisibility = Visibility.Visible;
    public helpVisibility = Visibility.Hidden;

    public autoExpandVisibility = Visibility.Visible;
    public sortVisibility = Visibility.Visible;


    get isMAC(): boolean { return navigator.userAgent.toLowerCase().indexOf('macintosh') > -1; }

    //***  ApiPropertyBag  ApiPropertyBag  ApiPropertyBag
    //***  ApiPropertyBag  ApiPropertyBag  ApiPropertyBag
    //***
    private get propertyBagUrl() {
        return this.API_ENDPOINT + "api/PropertyBag";
    }
    public setPropertyBag(propertyName: string, value: any): Observable<EmptyResponse> {
        let payload: string = null;

        const type = typeof value;
        if (type === "object")
            payload = JSON.stringify(value);
        else if (type === "undefined")
            payload = null;
        else
            payload = value.toString();

        return this.httpClient.post<EmptyResponse>(this.propertyBagUrl, JSON.stringify(new PropertyBagRequest(this.lpUserId, propertyName, payload)));
    }

    //***
    //***
    public getPropertyBag(propertyName: string): Observable<PropertyBagResponse> {
        return this.httpClient.get<PropertyBagResponse>(`${this.propertyBagUrl}/${this.lpUserId}/${propertyName}`, { responseType: "json" });
    }

    //***
    //***
    public async forceTokenUpdate(): Promise<string> {
        try {
            // Get the auth token from office.js
            this.officeAuthToken = await Office.auth.getAccessToken({ allowSignInPrompt: true, allowConsentPrompt: true, forMSGraphAccess: true });  //forMSGraphAccess should be true for production but breaks in desktop when sideloaded
        } catch (exception) {
            //Catch and ignore any exception from getAccessToken
            //GetAccessToken is expected to fail in the snapform dialog
            //To get around this issue we are expecting to get a valid token from the propertybag when the snapform service starts up
            Utility.debug(`Fetching Access Token Failed: ${exception?.code} ${exception.message}`);
        }

        return this.officeAuthToken;
    }
}
