import { Component, Input } from '@angular/core';
import Utility from '../../shared/utility';

@Component({
    selector: 'app-lp-billboard',
    templateUrl: './lp-billboard.component.html',
    styleUrls: ['./lp-billboard.component.scss']
})
export class LPBillboardComponent {

    @Input() imagePoints = "";
    @Input() title = ""
    @Input() subTitle = "";
    @Input() isVisible = false;


    get titleVisible(): boolean { return !Utility.isNullOrEmpty(this.title); }
    get subTitleVisible(): boolean { return !Utility.isNullOrEmpty(this.subTitle); }
}
