<div class="container-small lp-no-gutters">
    <div class="row lp-no-gutters">

        <div class="col-sm-12 lp-no-gutters"
             style="padding-right: 3px;">
            <div *ngFor="let field of allFields">
                <snap-lp-field class="w-100"
                               [snapFormField]="field"></snap-lp-field>
            </div>
        </div>

    </div>
</div>
