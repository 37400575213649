<div class="container lp-card"
     [ngClass]="{'lp-card-hover' : isShowHoverItems}"
     (mouseenter)="mouseEnter()"
     (mouseleave)="mouseLeave()"
     (dblclick)="mouseDoubleClick($event)">

    <!--  Header  Header  Header-->
    <!--  Header  Header  Header-->
    <div style="width: 100%; display: grid; grid-template-columns: min-content 1fr max-content;">

        <!-- CheckBox -->
        <div style="align-self: center"
             *ngIf="card.isCheckBoxVisible">
            <input class="lp-checkbox"
                   #selectCheckBox
                   id="selectCheckBox"
                   type="checkbox"
                   *ngIf="card.isCheckBoxVisible"
                   [disabled]="!isCheckboxEnabled"
                   [(ngModel)]="card.isChecked"
                   (change)="onSelectChange(selectCheckBox.checked)"/>
        </div>

        <!-- Titles -->
        <div class="align-self-center">
            <app-lp-header [title]="card.header.title"
                           [subTitle]="card.header.subTitle"
                           [subTitleLabel]="card.header.subTitleLabel"></app-lp-header>
        </div>


        <!-- Charms -->
        <div class="lp-charms">
            <div>
                <!-- TableLabel -->
                <div class="col-auto lp-no-gutters lp-table-label"
                     style="float: right;"
                     [style.color]="card.tableColor"
                     *ngIf="displayTableLabel">
                    {{card.tableLabel}}
                </div>

                <!-- Primary Dim/Lit Start -->
                <app-lp-svg class="col-auto lp-no-gutters"
                            [tourAnchor]="isFirst ? 'tourPrimary': ''"
                            style="float: right;"
                            *ngIf="canSetPrimary"
                            [imagePoints]="primaryImagePoints"
                            role="button"
                            [lpTooltip]="primaryTooltip"
                            delay="750"
                            (click)="setPrimary_Click()"
                            imageSize="14"></app-lp-svg>
            </div>


            <!-- SideKick -->
            <div *ngIf="effectiveDisplaySidekick"
                 [tourAnchor]="isFirst ? 'tourSidekick': ''"
                 class="float-right align-self-center lp-mouse-cursor-pointer"
                 role="button"
                 [style.visibility]="isShowHoverItems ? 'visible' : 'hidden'"
                 (click)="sidekickClick(card)">
                <app-lp-svg [imagePoints]="sidekickImagePoints"
                            [imageForegroundColor]="sidekickImageForeground"
                            [imageBackgroundColor]="sidekickImageBackground"
                            imageSize="25"></app-lp-svg>
            </div>
        </div>
    </div>


    <!--  Cells  Cells  Cells-->
    <!--  Cells  Cells  Cells-->
    <div class="row lp-no-gutters"
         *ngIf="displayCardCells">
        <div class="col lp-no-gutters">
            <div *ngFor="let cell of leftCells">
                <app-lp-cell [cell]="cell"></app-lp-cell>
            </div>
        </div>
        <div class="col lp-no-gutters">
            <div *ngFor="let cell of rightCells">
                <app-lp-cell [cell]="cell"></app-lp-cell>
            </div>
        </div>
    </div>

    <!--  Actions  Actions  Actions-->
    <!--  Actions  Actions  Actions-->
    <div class="row lp-no-gutters"
         style="margin-top: 3px;"
         [style.visibility]="isShowHoverActions ? 'visible' : 'hidden'">

        <app-update-record *ngIf="canSnapFormEdit"
                           [tourAnchor]="isFirst ? 'tourEdit': ''"
                           [tableName]="card.groupId"
                           [id]="card.id"></app-update-record>

        <app-create-record *ngIf="canSnapFormCreate"
                           [tourAnchor]="isFirst ? 'tourCreate': ''"
                           [tableName]="card.groupId"
                           [parentId]="card.id"
                           [parentLabel]="card.header.title"
                           [dropdownClass]="'dropdown-menu noselect'"></app-create-record>

        <app-signature *ngIf="canImportContact"
                       [tableName]="'Contact'"
                       [card]="card"
                       [lpTooltip]="'Create Contact'"
                       delay="750"
                       [imageBackgroundColor]="sublimeService.contactImagebgColor"
                       [imageUrl]="sublimeService.contactImageUrl"></app-signature>

        <app-signature *ngIf="canImportAccount"
                       [tableName]="'Account'"
                       [card]="card"
                       [lpTooltip]="'Create Account'"
                       delay="750"
                       [imageBackgroundColor]="sublimeService.accountImagebgColor"
                       [imageUrl]="sublimeService.accountImageUrl"></app-signature>

        <app-signature *ngIf="canImportLead"
                       [tableName]="'Lead'"
                       [card]="card"
                       [lpTooltip]="'Create Lead'"
                       delay="750"
                       [imageBackgroundColor]="sublimeService.leadImagebgColor"
                       [imageUrl]="sublimeService.leadImageUrl"></app-signature>

        <app-quick-comment *ngIf="canQuickComment"
                           [tourAnchor]="isFirst ? 'tourQuickComment': ''"
                           [tableName]="card.groupId"
                           [recordId]="card.id"></app-quick-comment>

        <app-open-link *ngIf="canOpenLink"
                       [tourAnchor]="isFirst ? 'tourOpenInSF': ''"
                       [urlLink]="card.url"
                       [lpTooltip]="'Open in Salesforce'"
                       delay="750"></app-open-link>
    </div>
</div>
