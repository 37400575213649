<div>
    <snap-lp-field-label [snapFormField]="snapFormField"></snap-lp-field-label>
    <div>
        <select class="lp-input-element"
                style="padding: 0.4rem 0.25rem"
                [id]="snapFormField.fieldName"
                [name]="snapFormField.fieldName"
                [(ngModel)]="value"
                [disabled]="!isEnabled ? 'disabled': null">
            <option *ngFor="let item of snapFormField.picklistValues"
                    [value]="item.value">
                {{item.label}}
            </option>
        </select>
        <div *ngIf="dependencyCommentVisible"
             class="lp-dependency-message">
            {{snapFormField.dependencyComment}}
        </div>

    </div>
</div>
