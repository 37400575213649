import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { TooltipModule } from 'ngx-bootstrap/tooltip';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { AlertModule, AlertConfig } from 'ngx-bootstrap/alert';
import { ButtonsModule } from 'ngx-bootstrap/buttons';
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { CollapseModule } from 'ngx-bootstrap/collapse';

import { BsDatepickerModule, BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { BsDropdownModule, BsDropdownConfig } from 'ngx-bootstrap/dropdown';
import { ClipboardModule } from 'ngx-clipboard';

import { OpenLinkComponent } from './command-components/open-link/open-link.component';
import { QuickCommentComponent } from './command-components/quick-comment/quick-comment.component';
import { DataEntryTextboxComponent } from './command-components/data-entry-textbox/data-entry-textbox.component';
import { CreateRecordComponent } from './command-components/create-record/create-record.component';
import { RouterModule } from '@angular/router';
import { UpdateRecordComponent } from './command-components/update-record/update-record.component';
import { ToastNotificationComponent } from './toast-notification/toast-notification.component';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { LPSvgComponent } from './lp-svg/lp-svg.component';
import { TitleComponent } from './title/title.component';
import { HighlighterService } from './highlighter.service';
import { SignatureComponent } from './command-components/signature/signature.component';
/*import { TimeScoutComponent } from './command-components/time-scout/time-scout.component';*/
import { FocusService } from './focus.service';
import { AboutDialogComponent } from './about-dialog/about-dialog.component';
import { StartSplashComponent } from './start-splash/start-splash.component';


@NgModule({
    declarations: [
        OpenLinkComponent,
        QuickCommentComponent,
        DataEntryTextboxComponent,
        CreateRecordComponent,
        UpdateRecordComponent,
        ToastNotificationComponent,
        ConfirmDialogComponent,
        AboutDialogComponent,
        StartSplashComponent,
        LPSvgComponent,
        TitleComponent,
        SignatureComponent,
        /*TimeScoutComponent,*/
    ],
    imports: [
        BrowserModule,
        FormsModule,
        BrowserAnimationsModule,
        AccordionModule,
        AlertModule,
        ButtonsModule,
        FormsModule,
        CarouselModule,
        CollapseModule,
        BsDatepickerModule.forRoot(),
        BsDropdownModule,
        TooltipModule.forRoot(),
        ClipboardModule
    ],
    exports: [
        RouterModule,
        OpenLinkComponent,
        QuickCommentComponent,
        DataEntryTextboxComponent,
        CreateRecordComponent,
        UpdateRecordComponent,
        ToastNotificationComponent,
        ConfirmDialogComponent,
        AboutDialogComponent,
        LPSvgComponent,
        TitleComponent,
        SignatureComponent,
        /*TimeScoutComponent,*/
    ],
    providers: [AlertConfig, BsDatepickerConfig, BsDropdownConfig, HighlighterService, FocusService],
})
export class SharedModule { }
