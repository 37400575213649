<ng-template #expanderTemplate
             style="padding: 3px">

    <app-lp-svg *ngIf="!isExpanded"
                [imagePoints]="expanderImageOpenPoints"
                [imageBackgroundColor]="expanderImageBackground"
                [imageForegroundColor]="expanderImageForeground"
                role="button"
                (click)="onclick()"
                imageSize="25"></app-lp-svg>

    <app-lp-svg *ngIf="isExpanded"
                [imagePoints]="expanderImageClosePoints"
                [imageBackgroundColor]="expanderImageBackground"
                [imageForegroundColor]="expanderImageForeground"
                role="button"
                (click)="onclick()"
                imageSize="25"></app-lp-svg>
</ng-template>


<div (dblclick)="onDoubleClick($event)"
     class="noselect"
     style="margin: 5px 0px 0px 0px; -webkit-tap-highlight-color: transparent;">
    <app-lp-header [header]="group.header"
                   [filler]="expanderTemplate"
                   [title]="title"
                   [padding]="3"
                   [roundedBottom]="isExpanded ? false : true"
                   [backgroundColor]="headerBackgroundColor"
                   [foregroundColor]="headerForegroundColor"></app-lp-header>
</div>


<div *ngIf="isExpanded"
     class="lp-body-colors">
    <div class="lp-cards"
         *ngFor="let card of group.sortedCards; first as isFirstCard">
        <app-lp-card [card]="card"
                     [isFirst]="isFirst && isFirstCard"
                     [displayCardCells]="displayCardCells"
                     [displayTableLabel]="displayTableLabel"></app-lp-card>
    </div>
</div>


<div *ngIf="isExpanded"
     class="lp-body-colors lp-last-card"></div>
