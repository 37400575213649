import { HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { from } from 'rxjs';
import { SessionService } from './session.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {


    //***
    //***
    constructor(private sessionService: SessionService) { }

    //***
    //***
    intercept(req: HttpRequest<any>, next: HttpHandler) {
        // convert promise to observable using 'from' operator
        return from(this.handle(req, next));
    }

    //***
    //***
    async handle(req: HttpRequest<any>, next: HttpHandler) {
        //if the token stored in memory exists and isn't expired then use it
        if (!(this.sessionService.officeAuthToken && !this.isTokenExpired())) {
            await this.sessionService.forceTokenUpdate();
        }

        // Clone headers, updated with the authorization token
        const authReq = req.clone({
            setHeaders: {
                Authorization: this.sessionService.officeAuthToken
            }
        });

        // send cloned request with header to the next handler.
        return next.handle(authReq).toPromise();
    }

    //***
    //***
    isTokenExpired(): boolean {
        let result = false;

        //Decode token
        const jwtPayload = JSON.parse(window.atob(this.sessionService.officeAuthToken.split('.')[1]))

        //Compare token expiration date to current datetime
        if (Date.now() >= jwtPayload.exp * 1000) {
            result = true;
        }

        return result;
    }
}
