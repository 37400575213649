import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { SessionService } from '../../../app/core/services/session.service';
import { SublimeService } from '../../../app/core/services/sublime.service';
import { LoginState, SignalType } from '../../../app/models/enums';
import { ToastNotificationService } from '../../core/services/toast-notification.service';
import { RemoteAction } from '../../models/remoteAction';
import Utility from '../../shared/utility';

@Component({
    selector: 'app-microsoft-login',
    templateUrl: './microsoft-login.component.html',
    styleUrls: ['./microsoft-login.component.scss']
})
export class MicrosoftLoginComponent {

    private dialogLoginUrl = window.location.origin + "/#/graphLoginDialog"; //full url for the dialog target
    private loginDialog: Office.Dialog; //instance of the dialog window
    private errorMessage = ''; //Displays error messages to the user
    private loginState: LoginState = LoginState.Initial; //current state of the login process
    private loginStateType = LoginState; //necessary to access the enum values in the html ngif 

    //***
    //***
    constructor(private sublimeService: SublimeService, public sessionService: SessionService, private router: Router, private toastNotificationService: ToastNotificationService) { }

    //***
    //***
    //Launch the Oauth Dialog box
    //***
    private async login() {
        //Need to send the login url to the child window through a query param
        //because MessageChild has a timing issue where the window may not be initialized to receive it yet
        const dialogUrlWithParam = this.dialogLoginUrl + "?cid=" + encodeURIComponent(this.sessionService.SF_OAUTH_CID) + "&redirect=" + encodeURIComponent(this.sessionService.microsoftManagedAppEndPoint) + "&scope=" + encodeURIComponent(this.sessionService.microsoftManagedAppScope);

        //Start the dialog and clear any previous error messages
        this.loginState = LoginState.Dialog;
        this.errorMessage = "";

        await Office.context.ui.displayDialogAsync(dialogUrlWithParam, { height: 40, width: 40, promptBeforeOpen: false },
            (result) => {
                if (result.status === Office.AsyncResultStatus.Failed) {
                    Utility.debug(result.error, "DialogFailed");
                }
                else {
                    //loginDialog must exist outside this method
                    this.loginDialog = result.value;
                    this.loginDialog.addEventHandler(Office.EventType.DialogMessageReceived, this.processLoginDialogMessage);
                    this.loginDialog.addEventHandler(Office.EventType.DialogEventReceived, this.processLoginDialogEvent);
                }
            }
        );
    }

    //***
    //Fired when dialog calls Office.context.ui.messageParent()
    //***
    private processLoginDialogMessage = (arg: any) => {
        const authCode = arg.message;
        this.loginDialog.close();
        this.loginState = LoginState.Server;


        //send code to server API here to complete the flow.
        this.sublimeService.GraphLogin(authCode).subscribe(
            (response) => {
                this.loginState = LoginState.Initial;

                this.sessionService.microsoftConnected = response.success;
                if (response.success) {
                    this.sessionService.microsoftUserName = response.graphUserName;
                    this.toastNotificationService.removeTopicNotifications('PreferencesHost'); //Close the permanent toast about the user not being connected to a host
                }
                else {
                    this.errorMessage = response.message;
                }
            },
            (error) => {
                Utility.debug("Error Logging into Graph: " + JSON.stringify(error));
                this.loginState = LoginState.Initial;
                this.errorMessage = JSON.stringify(error);
            }
        );
    }

    //***
    //Fired when dialog is closed by the user
    //***
    private processLoginDialogEvent = (arg) => {
        this.loginState = LoginState.Initial;
        this.errorMessage = "Dialog canceled";
    }

    //***
    //***
    private disconnect() {

        const action = new RemoteAction(SignalType.DisconnectGraph.toString());
        action.signal = SignalType.DisconnectGraph;
        this.sublimeService.apiRemoteAction(action);
        this.sessionService.microsoftConnected = false;
        this.loginState = LoginState.Initial;
        this.errorMessage = "";
    }
}
