import Utility from "../shared/utility";
import { IDeserializable } from "./interfaces/IDeserializable.interface";

export class ImageLink implements IDeserializable {
    public link: string;
    public color: string;
    public tooltip: string;

    deserialize(input: ImageLink): this {
        Object.assign(this, input);
        return this;
    }

    //***
    //***
    public get isVisible(): boolean {
        return !Utility.isNullOrEmpty(this.link);
    }
}
