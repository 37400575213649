import { Component, Input } from '@angular/core';
import Utility from '../utility';

@Component({
    selector: 'app-lp-svg',
    templateUrl: './lp-svg.component.html',
    styleUrls: ['./lp-svg.component.scss']
})
export class LPSvgComponent {

    //***
    //***
    private _imageSize = 20;
    get imageSize(): number { return this._imageSize ?? (this._imageSize = 20); }
    @Input() set imageSize(value: number) { this._imageSize = value; }

    //***
    //***
    private _imagePoints = "";
    get imagePoints(): string { return this._imagePoints ?? (this._imagePoints = ""); }
    @Input() set imagePoints(value: string) { this._imagePoints = value; }


    //***
    //***
    private _imageBackgroundColor: string = Utility.LPTransparent;
    get imageBackgroundColor(): string { return this._imageBackgroundColor ?? (this._imageBackgroundColor = Utility.LPTransparent); }
    @Input() set imageBackgroundColor(value: string) { this._imageBackgroundColor = value; }

    //***
    //***
    private _imageForegroundColor: string = Utility.LPOrange;
    get imageForegroundColor(): string { return this._imageForegroundColor ?? (this._imageForegroundColor = Utility.LPOrange); }
    @Input() set imageForegroundColor(value: string) { this._imageForegroundColor = value; }


    //***
    //***
    private _borderRadius: number = Utility.LPBorderRadius;
    get borderRadius(): number { return this._borderRadius ?? (this._borderRadius = Utility.LPBorderRadius); }
    @Input() set borderRadius(value: number) { this._borderRadius = value; }


    //***
    //***
    private _padding = 2;
    get padding(): number { return this._padding ?? (this._padding = 2); }
    @Input() set padding(value: number) { this._padding = value; }


    //***
    //***
    private _opacity = 1;
    get opacity(): number { return this._opacity ?? (this._opacity = 1); }
    @Input() set opacity(value: number) { this._opacity = value > 1 ? 1 : value < 0 ? 0 : value; }

    //***
    //***
    private _lpTooltip = "";
    get lpTooltip(): string { return this._lpTooltip ?? (this._lpTooltip = ""); }
    @Input() set lpTooltip(value: string) { this._lpTooltip = value; }


    get borderRadiusPixels(): string { return this.toPixels(this.borderRadius); }
    get imageSizePixels(): string { return this.toPixels(this.imageSize); }
    get paddingPixels(): string { return this.toPixels(this.padding); }
    private toPixels(size: number): string { return `${size}px`; }
}
