import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";

@Component({
    selector: 'app-graph-dialog-oauth-callback',
    templateUrl: './graph-dialog-oauth-callback.component.html',
    styleUrls: ['./graph-dialog-oauth-callback.component.scss']
})
export class GraphDialogOauthCallbackComponent implements OnInit {
    //***
    //***
    constructor(private route: ActivatedRoute) { }

    //***
    //***
    ngOnInit() {
        this.complete();
    }

    //***
    //***
    complete() {
        const authCode = this.route.snapshot.queryParamMap.get('code');
        if (authCode) {
            Office.context.ui.messageParent(authCode);
        }
    }

}
