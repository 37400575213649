import { Component, Input } from '@angular/core';
import { SnapConstants } from '../../models/snapConstants';
import { SnapFormField } from '../../models/snapFormField';
import { SnapFormService } from '../../services/snapform.service';

@Component({
    selector: 'snap-lp-boolean',
    templateUrl: './lp-boolean.component.html',
    styleUrls: ['./lp-boolean.component.scss']
})
export class LPBooleanComponent {
    @Input() snapFormField: SnapFormField;


    //***
    //***
    get value(): boolean { return this.snapFormService.getValue(this.snapFormField.fieldName) === SnapConstants.True; }
    set value(value: boolean) { this.snapFormService.setValue(this.snapFormField.fieldName, value.toString()); }


    //***
    //***
    public get isEnabled(): boolean {
        if (this.snapFormService.isTask && this.snapFormField.fieldName === SnapConstants.IsReminderSet)
            return !this.snapFormService.isClosedTaskStatus(this.snapFormService.getValue(SnapConstants.Status))
        else
            return true;
    }
    public set isEnabled(value: boolean) { ; }


    //***
    //***
    constructor(private snapFormService: SnapFormService) { }
}
