import { IDeserializable } from "./interfaces/IDeserializable.interface";
import { IRequest } from "./interfaces/IRequest.interface";

export class SyncRequest implements IRequest, IDeserializable {
    public lpUserId: string;
    public pouchClass: string;

    constructor(lpUserId: string, pouchClass: string) {
        this.lpUserId = lpUserId;
        this.pouchClass = pouchClass;
    }

    deserialize(input: any): this {
        Object.assign(this, input);
        return this;
    }
}
