import { IDeserializable } from "./interfaces/IDeserializable.interface";

export class FooterStatus implements IDeserializable {
    rowCount = 0;
    durationMilliseconds = 0;

    private counter = 0;
    private start: Date;

    //***
    //***
    private _isSpinning = false;
    public get isSpinning(): boolean {
        return this._isSpinning;
    }
    public set isSpinning(value: boolean) {
        if (value !== this._isSpinning) {

            if (value)
                this.counter++;
            else
                this.counter--;

            if (this.counter < 1) {
                this._isSpinning = false;
                this.durationMilliseconds = (new Date().getTime() - this.start.getTime()) / 1000;
            }
            else if (this.counter === 1) {
                this._isSpinning = true;
                this.start = new Date();
                this.durationMilliseconds = 0;
            }
        }
    }


    constructor(isSpinning: boolean, rowCount: number = null, durationMilliseconds = 0) {
        this.isSpinning = isSpinning;
        this.rowCount = rowCount;
        this.durationMilliseconds = durationMilliseconds;
    }

    deserialize(input: FooterStatus): this {
        Object.assign(this, input);
        return this;
    }

}
