import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { SessionService } from "../../../app/core/services/session.service";
import { LoginState, ProfileId, SignalType } from '../../../app/models/enums';
import { PanelService } from "../../core/services/panelService";
import { SublimeService } from "../../core/services/sublime.service";
import { ToastNotificationService } from "../../core/services/toast-notification.service";
import { RemoteAction } from "../../models/remoteAction";
import Utility from "../../shared/utility";
import { environment } from "../../../environments/environment";
import { AssistTokenResponse } from "../../models/assistTokenResponse";

@Component({
    selector: "app-salesforce-login",
    templateUrl: "./salesforce-login.component.html",
    styleUrls: ["./salesforce-login.component.scss"],
})
export class SalesforceLoginComponent {
    private dialogLoginUrl = window.location.origin + "/#/loginDialog"; //full url for the dialog target
    private loginDialog: Office.Dialog; //instance of the dialog window
    private errorMessage = ''; //Displays error messages to the user
    private loginState: LoginState = LoginState.Initial; //current state of the login process
    private loginStateType = LoginState; //necessary to access the enum values in the html ngif
    private profileIdType = ProfileId; //necessary to access the enum values in the html ngif

    //****
    protected salesforceOAuthModes: any[] = [
        { label: "Standard", value: "Standard" },
        { label: "Sandbox/Test", value: "Sandbox" },
        { label: "Community", value: "Community" },
        { label: "Custom", value: "Custom" },
    ];


    //***
    //***
    private _salesforceOAuthMode: string;
    protected get salesforceOAuthMode(): string {
        return this._salesforceOAuthMode;
    }
    protected set salesforceOAuthMode(value: string) {
        this._salesforceOAuthMode = value;
    }

    //***
    //***
    protected salesforceStandardEndPoint = "https://login.salesforce.com";
    protected salesforceSandboxEndPoint = "https://test.salesforce.com";


    //***
    //***
    protected _salesforceCommunityEndPoint: string;
    protected get salesforceCommunityEndPoint(): string {
        return this._salesforceCommunityEndPoint;
    }
    protected set salesforceCommunityEndPoint(value: string) {
        this._salesforceCommunityEndPoint = value;
    }


    //***
    //***
    protected _salesforceCustomEndPoint: string;
    protected get salesforceCustomEndPoint(): string {
        return this._salesforceCustomEndPoint;
    }
    protected set salesforceCustomEndPoint(value: string) {
        this._salesforceCustomEndPoint = value;
    }


    //***
    //***
    protected get salesforceEndPoint(): string {
        if (this.salesforceOAuthMode == "Custom")
            return this._salesforceCustomEndPoint;
        else if (this.salesforceOAuthMode == "Sandbox")
            return this.salesforceSandboxEndPoint;
        else if (this.salesforceOAuthMode == "Community")
            return this.salesforceCommunityEndPoint;
        else
            return this.salesforceStandardEndPoint;
    }


    //***
    //***
    constructor(private sublimeService: SublimeService, public sessionService: SessionService, private router: Router, private toastNotificationService: ToastNotificationService, private panelService: PanelService) { }


    //**
    //**
    ngOnInit() {
        this.setInitialValues();
    }


    //***
    //***
    private async login() {
        //Need to send the login url to the child window through a query param
        //because MessageChild has a timing issue where the window may not be initialized to receive it yet
        const dialogUrlWithParam = this.dialogLoginUrl + "?cid=" + encodeURIComponent(this.sessionService.SF_OAUTH_CID) + "&login=" + encodeURIComponent(this.salesforceEndPoint) + "&redirect=" + encodeURIComponent(Utility.salesforceManagedAppEndPoint);

        //Start the dialog and clear any previous error messages
        this.loginState = LoginState.Dialog;
        this.errorMessage = "";
        this.toastNotificationService.removeTopicNotifications("PreferencesHost");

        await Office.context.ui.displayDialogAsync(dialogUrlWithParam, { height: 40, width: 40, promptBeforeOpen: false },
            (result) => {
                if (result.status === Office.AsyncResultStatus.Failed) {
                    Utility.debug(`Dialog failed: ${result.error.code} ${result.error.message}`);
                }
                else {
                    //loginDialog must exist outside this method
                    this.loginDialog = result.value;
                    this.loginDialog.addEventHandler(Office.EventType.DialogMessageReceived, this.processLoginDialogMessage);
                    this.loginDialog.addEventHandler(Office.EventType.DialogEventReceived, this.processLoginDialogEvent);
                }
            }
        );
    }
    private async loginAssist() {
        this.sublimeService.apiAssistTokenCall().subscribe((response: AssistTokenResponse) => {
            try {
                if (response)
                    Utility.openUrl(`${this.sessionService.ASSIST_ENDPOINT}login?token=${response.token}&goto=sflogin`);
            } catch (error) {
                Utility.debug(error, "AssistToken Error");
            }
        });
    }



    //***
    //***
    private disconnect() {
        const action = new RemoteAction(SignalType.DisconnectSalesforce.toString());
        action.signal = SignalType.DisconnectSalesforce;
        this.sublimeService.apiRemoteAction(action);
        this.sessionService.salesforceConnected = false;
        this.loginState = LoginState.Initial;
        this.errorMessage = "";

        this.setInitialValues();
    }


    //***
    //***
    private setInitialValues() {
        this.salesforceOAuthMode = this.sessionService.salesforceOAuthMode;

        if (this.salesforceOAuthMode == "Community")
            this.salesforceCommunityEndPoint = this.sessionService.salesforceOAuthEndPoint;
        else if (this.salesforceOAuthMode == "Custom")
            this.salesforceCustomEndPoint = this.sessionService.salesforceOAuthEndPoint;
    }


    //***
    //***
    private processLoginDialogMessage = (arg: any) => {
        const authCode = arg.message;
        this.loginDialog.close();
        this.loginState = LoginState.Server;

        //send code to server API here to complete the flow.
        this.sublimeService.SalesforceLogin(authCode, this.salesforceEndPoint).subscribe(
            (response) => {
                this.loginState = LoginState.Initial;

                this.sessionService.salesforceConnected = response.success;
                if (response.success) {
                    this.sessionService.salesforceUserName = response.salesforceUserName;
                    this.toastNotificationService.removeTopicNotifications('PreferencesHost'); //Close the permanent toast about the user not being connected to a host
                    this.panelService.clearModal(); //close the preferences window
                    this.sublimeService.broadcastReBootstrap();

                    if (this.sessionService.discoverySuccessCount <= 1)
                        this.sublimeService.isStartTourNextBootstrap = true;
                }
                else {
                    this.errorMessage = response.message;
                }
            },
            (error) => {
                Utility.debug(error, "SF Login");
                this.loginState = LoginState.Initial;
                this.errorMessage = JSON.stringify(error);
            }
        );
    };


    //***
    //***
    private processLoginDialogEvent = (arg) => {
        this.loginState = LoginState.Initial;
        this.errorMessage = "Dialog canceled";
    };
}
