import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from "@angular/router";
import { CookieService } from 'ngx-cookie-service';
import { NgcCookieConsentService, NgcInitializationErrorEvent, NgcInitializingEvent, NgcNoCookieLawEvent, NgcStatusChangeEvent } from "ngx-cookieconsent";
import { TourService } from "ngx-ui-tour-ngx-bootstrap";
import { Subscription } from 'rxjs';
import { RemoteAction } from './models/remoteAction';
import { RemoteRequest } from './models/remoteRequest';
import { RemoteResponse } from './models/remoteResponse';
import { HttpClient } from '@angular/common/http';
import { SessionService } from './core/services/session.service';
import { environment } from '../environments/environment';


@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit, OnDestroy {

    //keep refs to subscriptions to be able to unsubscribe later
    private popupCloseSubscription!: Subscription;

    constructor(private router: Router, private tourService: TourService, private ccService: NgcCookieConsentService, private cookieService: CookieService, private sessionService: SessionService, private httpClient: HttpClient) { }

    ngOnInit() {
         //subscribe to cookieconsent observables to react to main events
        this.popupCloseSubscription = this.ccService.popupClose$.subscribe(
            () => {
                //3rd party cookies are going to be blocked by chrome and edge so we have to store the consent on the server
                this.saveConsent();
            });
    }

    //**
    //**
    saveConsent() {
        let saveAction = new RemoteAction(`UserPreference.HasCookieConsent=true`);
        this.apiRemoteAction(saveAction);
    }

    //**
    //**
    apiRemoteAction(remoteAction: RemoteAction) {
        const request = new RemoteRequest(this.sessionService.lpUserId, [remoteAction]);

        const remoteActionUrl = this.sessionService.API_ENDPOINT + "api/Remote";
        this.httpClient.post<RemoteResponse>(remoteActionUrl, JSON.stringify(request)).subscribe((response: RemoteResponse) => {});
    }

    ngOnDestroy() {
        // unsubscribe to cookieconsent observables to prevent memory leaks
        this.popupCloseSubscription.unsubscribe();
    }
}
