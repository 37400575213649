import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";

@Component({
    selector: "app-dialog-login",
    templateUrl: "./dialog-login.component.html",
    styleUrls: ["./dialog-login.component.scss"],
})
export class DialogLoginComponent implements OnInit {
    private loginUrlEndPoint = "/services/oauth2/authorize";
    private loginUrlDomain: string;
    private redirectUrl: string;
    private loginUrlParams: string;
    private cid: string;

    //***
    //***
    constructor(private activatedRoute: ActivatedRoute) {}

    //***
    //***
    ngOnInit() {
        //Get the urls from the query param that the parent sent
        this.activatedRoute.queryParams.subscribe((params) => {
            this.cid = decodeURIComponent(params["cid"]);
            this.loginUrlDomain = decodeURIComponent(params["login"]);
            this.redirectUrl = decodeURIComponent(params["redirect"]);

            // now that we have the redirectUrl we can build the paramaters
            // 2022-03-08 - due to update of office.js, we need to add a state param and remove the "#" from the SF oauth endpint
            this.loginUrlParams = `?client_id=${encodeURIComponent(this.cid)}&redirect_uri=${encodeURIComponent(this.redirectUrl)}&response_type=code&state=lp360`;
        });
        this.start();
    }

    //***
    //***
    start() {
        let finalLoginUrl =
            this.loginUrlDomain + this.loginUrlEndPoint + this.loginUrlParams;
        window.location.href = finalLoginUrl;
    }
}
