import { Component, Input } from '@angular/core';
import { SnapFormField } from '../../models/snapFormField';
import { SnapFormPicklistValue } from '../../models/snapFormPicklistValue';
import { SnapFormService } from '../../services/snapform.service';

@Component({
    selector: 'snap-lp-combobox',
    templateUrl: './lp-combobox.component.html',
    styleUrls: ['./lp-combobox.component.scss']
})
export class LPComboBoxComponent {
    @Input() snapFormField: SnapFormField;


    //***
    //***
    get value(): string { return this.snapFormService.getValue(this.snapFormField.fieldName); }
    set value(newValue: string) { this.snapFormService.setValue(this.snapFormField.fieldName, newValue); }

    //***
    //*** Fires when item is selected in dropdownlist
    selectItem(selectedItem: SnapFormPicklistValue) { this.value = selectedItem.value; }


    //***
    //***
    constructor(private snapFormService: SnapFormService) { }
}
