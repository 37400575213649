import { Injectable } from "@angular/core";
import { ToastNotificationService } from "./toast-notification.service";
import { environment } from "../../../environments/environment";
import { HubConnection, HubConnectionBuilder, HubConnectionState } from "@microsoft/signalr";

@Injectable({
    providedIn: 'root'
})
export class SignalRService {
    public hubConnection: HubConnection;
    public currentUserId: string; //Retain the last User Id that we connected as

    constructor(private toastService: ToastNotificationService) { }


    //***
    //***
    public get IsConnected(): boolean {
        return this.hubConnection && this.hubConnection.state == HubConnectionState.Connected;
    }

    //**
    //**
    public startHubConnection(lpUserId: string): Promise<void> {
        //If we had a previous Connection
        if (this.IsConnected) {
            this.clearHubConnection(); //Clear it
        }

        this.currentUserId = lpUserId; //Set the new currentUserId value

        this.hubConnection = new HubConnectionBuilder()
            .withUrl("messagehub")
            .withAutomaticReconnect()
            .build();

        return this.hubConnection
            .start();
    }

    //**
    //**
    private clearHubConnection() {
        this.currentUserId = null;

        this.hubConnection.off('BroadcastReBootstrap');
        this.hubConnection.off('BroadcastToast');

        this.hubConnection.stop();
    }

    //**
    //**
    public joinGroup(groupName: string) {
        if (this.IsConnected && groupName) {
            this.hubConnection.invoke("JoinGroup", groupName)
                .catch(err => console.log('Error joining SignalR group: ' + err))
        }
    }

    //**
    //**
    public broadcastReBootstrap(lpUserId: string) {
        if (this.IsConnected) {
            this.hubConnection.invoke("BroadcastReBootstrap", lpUserId)
                .catch(err => console.log('Error broadcasting SignalR ReBootstrap: ' + err))
        }
    }
}
