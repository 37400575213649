import { Component, Input } from '@angular/core';
import Utility from '../../../shared/utility';

@Component({
    selector: 'snap-lp-section-header',
    templateUrl: './lp-section-header.component.html',
    styleUrls: ['./lp-section-header.component.scss'],
})
export class LPSectionHeaderComponent {

    @Input() title: string;
    @Input() isTitleVisible: boolean;
    @Input() subTitle: string;


    get isSubTitleVisible(): boolean { return !Utility.isNullOrEmpty(this.subTitle); }
}
