import { Injectable, NgZone } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { CalendarPouch } from "../../models/calendarPouch";
import { EmailPouch } from "../../models/emailPouch";
import { EmptyPouch } from "../../models/emptyPouch";
import { OfficeEvent, PouchClass } from "../../models/enums";
import { IPouch } from "../../models/interfaces/IPouch.interface";
import { ReferencePacket } from "../../models/referencePacket";
import Utility from "../../shared/utility";
import { OfficeHelper } from "./OfficeHelper";
import { SessionService } from "./session.service";
import { SublimeService } from "./sublime.service";


@Injectable()
export class OfficeService {
    //***
    //***
    private pouchSubject = new BehaviorSubject<IPouch>(new EmptyPouch());
    public onPouchUpdate(): Observable<IPouch> { return this.pouchSubject.asObservable(); }


    //***
    //***
    private _lastOfficeEvent: OfficeEvent = OfficeEvent.Undefined;
    public get lastOfficeEvent(): OfficeEvent { return this._lastOfficeEvent; }


    //***
    //***
    private pushPouch(pouch: IPouch, eventType: OfficeEvent) {
        this._lastOfficeEvent = eventType;
        this.pouchSubject.next(pouch);
    }


    //***
    //***
    constructor(private _ngZone: NgZone, private sessionService: SessionService) {
        if (!sessionService)
            throw "injected sessionService is undefined";

        const context = Office?.context;
        if (!context)
            throw "Office context is undefined";

        sessionService.touchEnabled = context?.touchEnabled.toString();
        sessionService.displayLanguage = context?.displayLanguage.toString();
        sessionService.officeApplication = context?.diagnostics?.host.toString();
        sessionService.officePlatform = context?.diagnostics?.platform.toString();
        sessionService.officeVersion = context?.diagnostics?.version;
        sessionService.officeEwsUrl = context?.mailbox?.ewsUrl;
        sessionService.officeRestUrl = context?.mailbox?.restUrl;
        sessionService.officeAccountType = context?.mailbox?.userProfile?.accountType;
        sessionService.officeUserDisplayName = context?.mailbox?.userProfile?.displayName;
        sessionService.officeUserEmailAddress = context?.mailbox?.userProfile?.emailAddress;
        sessionService.officeUserTimezone = context?.mailbox?.userProfile?.timeZone;
        OfficeHelper.officeUserEmailAddress = sessionService.officeUserEmailAddress;
        this.onItemChanged();
    }


    //***
    //***
    public setReferencePacket(referencePacket: ReferencePacket, category: string, sublimeService: SublimeService): Promise<boolean> {
        return new Promise((onSuccess, onFailure) => {
            const target = Office.context.mailbox.item;
            if (target) {
                const json: string = (referencePacket) ? JSON.stringify(referencePacket) : null;
                OfficeHelper.setCustomPropertyAsync(target, OfficeHelper.customPropertyReferencePacket, json)
                    .then((success) => {
                        if (success) {
                            const pouch = this.pouchSubject.value;
                            if (referencePacket) {
                                OfficeHelper.setCategory(target, [category], sublimeService)
                                    .catch((err) => {
                                        Utility.debug(err, "SetReferencePacketError");
                                        sublimeService.logAppend(err);
                                    })
                                    .finally(() => {
                                        onSuccess(true);
                                        pouch.referencePacket = referencePacket;
                                        this.pushPouch(pouch, OfficeEvent.ReferencePacketSet)
                                    });
                            }
                            else {
                                OfficeHelper.removeCategories(target, [category])
                                    .catch((err) => {
                                        Utility.debug(err, "SetReferencePacketError");
                                        sublimeService.logAppend(err);
                                    })
                                    .finally(() => {
                                        onSuccess(true);
                                        pouch.referencePacket = referencePacket;
                                        this.pushPouch(pouch, OfficeEvent.ReferencePacketSet)
                                    });
                            }
                        }
                    })
                    .catch((err) => {
                        Utility.debug(err, "SetReferencePacketError");
                        sublimeService.logAppend(err);
                        onFailure(err);
                    });
            }
        })
    }


    //***
    //***
    public loadAttachmentContents(pouch: IPouch): Promise<boolean> {
        return new Promise((onSuccess, onFailure) => {
            const target = Office.context.mailbox.item;
            if (target) {
                OfficeHelper.loadAttachmentContents(target, pouch.attachments)
                    .then((loadAttachmentResult) => {
                        onSuccess(loadAttachmentResult);
                    })
                    .catch((err) => onFailure(err));
            }
        })
    }


    //***
    //***
    public onOfficeEvent(eventType: Office.EventType) {
        this._ngZone.run(() => {
            switch (eventType) {
                case Office.EventType.ItemChanged:
                    this.onItemChanged();
                    break;
                case Office.EventType.RecipientsChanged:
                    this.onRecipientsChanged();
                    break;
                case Office.EventType.AttachmentsChanged:
                    this.onAttachmentsChanged();
                    break;
                case Office.EventType.AppointmentTimeChanged:
                    this.onAppointmentTimeChanged();
                    break;
                default:
                    Utility.debug(eventType, "Unhandled EventType");
                    break;
            }
        });
    }


    //***
    //***
    private onItemChanged() {
        try {
            OfficeHelper.getPouch(Office.context.mailbox.item)
                .then((val) => this.pushPouch(val, OfficeEvent.ItemChanged))
                .catch((err) => Utility.debug(err))
        }
        catch (ex) {
            Utility.Dump(ex, "onItemChanged");
        }
    }


    //***
    //***
    private onRecipientsChanged() {
        const pouchClass = this.pouchSubject.value.pouchClass;
        if (pouchClass === PouchClass.Email) {
            const target = Office.context.mailbox.item;
            if (target) {
                Promise.all([
                    OfficeHelper.getFrom(target.from),
                    OfficeHelper.getRecipientsAsync(target.to),
                    OfficeHelper.getRecipientsAsync(target.cc)])
                    .then((vals) => {
                        const pouch = this.pouchSubject.value as EmailPouch;
                        if (pouch) {
                            pouch.from = vals[0];
                            pouch.to = vals[1];
                            pouch.cc = vals[2];
                            this.pushPouch(pouch, OfficeEvent.RecipientsChanged)
                        }
                    })
                    .catch((err) => { Utility.debug(err, "onRecipientsChanged failed") });
            }
        }
        else if (pouchClass === PouchClass.Calendar) {
            const target = Office.context.mailbox.item;
            if (target) {
                Promise.all([
                    OfficeHelper.getFrom(target.organizer),
                    OfficeHelper.getRecipientsAsync(target.requiredAttendees),
                    OfficeHelper.getRecipientsAsync(target.optionalAttendees)])
                    .then((vals) => {
                        const pouch = this.pouchSubject.value as CalendarPouch;
                        if (pouch) {
                            pouch.from = vals[0];
                            pouch.to = vals[1];
                            pouch.cc = vals[2];
                            this.pushPouch(pouch, OfficeEvent.RecipientsChanged)
                        }
                    })
                    .catch((err) => { Utility.debug(err, "onRecipientsChanged failed") });
            }
        }
    }


    //***
    //***
    private async onAppointmentTimeChanged() {
        const pouch = this.pouchSubject.value as CalendarPouch;
        if (pouch) {
            const item = Office.context.mailbox.item;
            if (item) {
                pouch.start = await OfficeHelper.getTimeAsync(item.start);
                pouch.end = await OfficeHelper.getTimeAsync(item.end);;
                this.pushPouch(pouch, OfficeEvent.AppointmentTimeChanged)
            }
        }
    }


    //***
    //***
    private onAttachmentsChanged() {
        const item = Office.context.mailbox.item;
        if (item) {
            const isComposeMode = OfficeHelper.getIsComposeMode(item);
            if (isComposeMode && this.pouchSubject.value) {
                if (this.pouchSubject.value.pouchClass === PouchClass.Email)
                    OfficeHelper.getAttachmentsAsync(item)
                        .then((val) => {
                            const pouch = this.pouchSubject.value as EmailPouch;
                            pouch.attachments = val;
                            this.pushPouch(pouch, OfficeEvent.AttachmentsChanged)
                        })
                        .catch((err) => { Utility.debug(err, "onAttachmentsChanged failed") });
                else if (this.pouchSubject.value.pouchClass === PouchClass.Calendar)
                    OfficeHelper.getAttachmentsAsync(item)
                        .then((val) => {
                            const pouch = this.pouchSubject.value as CalendarPouch;
                            pouch.attachments = val;
                            this.pushPouch(pouch, OfficeEvent.AttachmentsChanged)
                        })
                        .catch((err) => { Utility.debug(err, "onAttachmentsChanged failed") });
            }
        }
    }


    //***
    //***
    public prependToBody(value: string): Promise<boolean> {
        return new Promise((resolve, reject) => {
            const target = Office.context.mailbox.item;
            if (target) {
                //Get the body type of the composed item
                target.body.getTypeAsync(
                    function (typeResult) {
                        if (typeResult.status === Office.AsyncResultStatus.Failed) {
                            reject(typeResult.error.message);
                        }
                        else {
                            // Set data of the appropriate type in body.
                            if (typeResult.value === Office.CoercionType.Html) {
                                // Body is HTML type.
                                // Specify HTML in the coercionType parameter of setSelectedDataAsync.
                                target.body.prependAsync(
                                    value,
                                    { coercionType: Office.CoercionType.Html },
                                    (asyncResult) => {
                                        if (asyncResult.status === Office.AsyncResultStatus.Failed)
                                            reject(asyncResult.error.message);
                                        else
                                            resolve(true);
                                    });
                            }
                        }
                    }
                );
            }
            else
                resolve(false);
        });
    }


    //***
    //***
    public appendToCursorBody(value: string): Promise<boolean> {
        return new Promise((resolve, reject) => {
            const target = Office.context.mailbox.item;
            if (target) {
                //Get the body type of the composed item
                target.body.getTypeAsync(
                    function (typeResult) {
                        if (typeResult.status === Office.AsyncResultStatus.Failed) {
                            reject(typeResult.error.message);
                        }
                        else {
                            // Set data of the appropriate type in body.
                            if (typeResult.value === Office.CoercionType.Html) {
                                // Body is HTML type.
                                // Specify HTML in the coercionType parameter of setSelectedDataAsync.
                                target.body.setSelectedDataAsync(
                                    value,
                                    { coercionType: Office.CoercionType.Html },
                                    (asyncResult) => {
                                        if (asyncResult.status === Office.AsyncResultStatus.Failed)
                                            reject(asyncResult.error.message);
                                        else
                                            resolve(true);
                                    });
                            }
                        }
                    }
                );
            }
            else
                resolve(false);
        });
    }


    //***
    //***
    public appendToBody(value: string): Promise<boolean> {
        return new Promise((resolve, reject) => {
            const target = Office.context.mailbox.item;
            if (target) {
                //Get the body type of the composed item
                target.body.getTypeAsync(
                    function (typeResult) {
                        if (typeResult.status === Office.AsyncResultStatus.Failed) {
                            reject(typeResult.error.message);
                        }
                        else {
                            // Set data of the appropriate type in body.
                            if (typeResult.value === Office.CoercionType.Html) {
                                // Body is HTML type.
                                OfficeHelper.getBodyHtmlAsync(target.body)
                                    .then((bodyValue) => {
                                        //append the value onto the end of the body
                                        const newBody = bodyValue + value;

                                        //save back to the outlook body
                                        target.body.setAsync(
                                            newBody,
                                            { coercionType: Office.CoercionType.Html },
                                            (asyncResult) => {
                                                if (asyncResult.status === Office.AsyncResultStatus.Failed)
                                                    reject(asyncResult.error.message);
                                                else
                                                    resolve(true);
                                            });
                                    })
                                    .catch((err) => { Utility.debug(err, "appendToBody failed") });
                            }
                        }
                    }
                );
            }
            else
                resolve(false)
        });
    }


    //***
    //***
    public existsInBody(regex: RegExp): Promise<boolean> {
        return new Promise((resolve, reject) => {
            let result = false;
            const target = Office.context.mailbox.item;
            if (target) {
                //Get the body type of the composed item
                target.body.getTypeAsync(
                    function (typeResult) {
                        if (typeResult.status === Office.AsyncResultStatus.Failed) {
                            reject(typeResult.error.message);
                        }
                        else {
                            // Set data of the appropriate type in body.
                            if (typeResult.value === Office.CoercionType.Html) {
                                // Body is HTML type.
                                OfficeHelper.getBodyHtmlAsync(target.body)
                                    .then((bodyValue) => {
                                        //search through bodyValue
                                        const found = bodyValue.match(regex);
                                        result = !!found; //does a match exist?
                                        resolve(result);
                                    })
                                    .catch((err) => { Utility.debug(err, "existsInBody failed") });
                            }
                        }
                    }
                );
            }
            else
                resolve(false);
        });
    }


    //***
    //***
    public replaceInBody(regex: RegExp, newValue: string): Promise<boolean> {
        return new Promise((resolve, reject) => {
            const target = Office.context.mailbox.item;
            if (target) {
                //Get the body type of the composed item
                target.body.getTypeAsync(
                    function (typeResult) {
                        if (typeResult.status === Office.AsyncResultStatus.Failed) {
                            reject(typeResult.error.message);
                        }
                        else {
                            // Set data of the appropriate type in body.
                            if (typeResult.value === Office.CoercionType.Html) {
                                // Body is HTML type.
                                OfficeHelper.getBodyHtmlAsync(target.body)
                                    .then((bodyValue) => {
                                        //search through bodyValue. Find and replace with string param
                                        const newBody = bodyValue.replace(regex, newValue);

                                        //save back to the outlook body
                                        target.body.setAsync(
                                            newBody,
                                            { coercionType: Office.CoercionType.Html },
                                            (asyncResult) => {
                                                if (asyncResult.status === Office.AsyncResultStatus.Failed)
                                                    reject(asyncResult.error.message);
                                                else
                                                    resolve(true);
                                            });
                                    })
                                    .catch((err) => { Utility.debug(err, "replaceInBody failed") });
                            }
                        }
                    }
                );
            }
            else
                resolve(false);
        });
    }
}
