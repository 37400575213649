import { SignalType } from "./enums" // ActionType, TargetType } from "./enums";
import { IDeserializable } from "./interfaces/IDeserializable.interface";

export class RemoteAction implements IDeserializable {
    //***
    public signal: SignalType;
    public action: string;
    public result: string;
    public errorMessage: string;

    //***
    constructor(action?: string) {
        this.action = action;
    }

    //***
    //***
    deserialize(input: any): this {
        Object.assign(this, input);
        return this;
    }
}


