import { IDeserializable } from "../../models/interfaces/IDeserializable.interface";

export class SignatureField implements IDeserializable {

    fieldName: string;
    value: string;
    selected = false;


    //***
    //***
    constructor(fieldName: string = null, value: string = null, selected = false) {
        this.fieldName = fieldName;
        this.value = value;
        this.selected = selected;
    }


    //***
    //***
    deserialize(input: any): this {
        Object.assign(this, input);
        return this;
    }
}
