import { BehaviorSubject, Observable } from "rxjs";
import { Header } from "./header";
import { IDeserializable } from "./interfaces/IDeserializable.interface";

export class ToastNotification implements IDeserializable {
    state: string; //used for controlling the removal animation in the component
    header: Header; //contains all of the text of the message in 3 parts
    millisecondsToDisplay: number; //how long to show the notification. <=0 would indicate a permanent toast until the user closes it
    topic: string; //if I topic is specified then only 1 notification with this topic can show at the same time
    private stateRemovedSubject = new BehaviorSubject<ToastNotification>(null);

    //***
    // Observable that fires when this item is marked for removal
    //***
    getRemovedNotification(): Observable<ToastNotification> { return this.stateRemovedSubject.asObservable(); }

    //***
    //***
    constructor(header: Header, millisecondsToDisplay: number, topic = "") {
        //Notification always starts in active mode
        this.state = "active";
        this.header = header;
        this.millisecondsToDisplay = millisecondsToDisplay;
        this.topic = topic;

        //Start the removal timer if this item has millisecondsToDisplay
        if (this.millisecondsToDisplay > 0) {
            setTimeout(() => {
                this.remove();
            }, this.millisecondsToDisplay);
        }
    }

    deserialize(input: ToastNotification): this {
        Object.assign(this, input);

        if (input?.header)
            this.header = new Header().deserialize(input.header);
        return this;
    }

    //***
    // Mark the item for removal and trigger the observable event
    //***
    remove() {
        this.state = "removed";
        this.stateRemovedSubject.next(this);
    }
}
