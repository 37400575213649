import { IDeserializable } from "./interfaces/IDeserializable.interface";
import { IRequest } from "./interfaces/IRequest.interface";
import { SessionService } from "../core/services/session.service";

export class BootstrapRequest implements IRequest, IDeserializable {
    public emailAddress: string;
    public displayName: string;
    public officePlatform: string;
    public accountType: string;
    public displayLanguage: string;
    public touchEnabled: string;

    constructor(sessionService: SessionService) {
        this.emailAddress = sessionService.officeUserEmailAddress;
        this.displayName = sessionService.officeUserDisplayName;
        this.officePlatform = sessionService.officePlatform;
        this.accountType = sessionService.officeAccountType;
        this.displayLanguage = sessionService.displayLanguage;
        this.touchEnabled = sessionService.touchEnabled;
    }

    deserialize(input: any): this {
        Object.assign(this, input);

        return this;
    }
}
