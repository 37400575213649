<div>
    <snap-lp-field-label [snapFormField]="snapFormField"></snap-lp-field-label>
    <div>
        <input class="lp-input-element"
               [type]="inputType"
               [id]="snapFormField.fieldName"
               [name]="snapFormField.fieldName"
               [disabled]="!snapFormField.isEnabled ? 'disabled': null"
               [(ngModel)]="value"
               [attr.maxlength]="snapFormField.maxLength">
    </div>
</div>
