<div class="container-small lp-no-gutters">
    <div class="row lp-no-gutters">

        <div class="col-sm-6 lp-no-gutters"
             style="padding-right: 3px;">
            <div *ngFor="let field of leftFields">
                <snap-lp-field class="w-100"
                               [snapFormField]="field"></snap-lp-field>
            </div>
        </div>

        <div class="col-sm-6 lp-no-gutters"
             style="padding-left: 2px;">
            <div *ngFor="let field of rightFields">
                <snap-lp-field [snapFormField]="field"></snap-lp-field>
            </div>
        </div>

    </div>
</div>