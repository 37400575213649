<div class="lp-cell">

    <div class="lp-label">
        {{cell.label}}
    </div>

    <div class="lp-value">
        {{cell.bestValue}}
    </div>

</div>