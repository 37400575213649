import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { HighlighterService } from '../../../shared/highlighter.service';
import Utility from '../../../shared/utility';
import { SnapFormField } from '../../models/snapFormField';
import { SnapFormLookupRow } from '../../models/snapFormLookupRow';
import { SnapFormService } from '../../services/snapform.service';
import { LPLookupDialogComponent } from '../lp-lookup-dialog/lp-lookup-dialog.component';

@Component({
    selector: 'snap-lp-lookup',
    templateUrl: './lp-lookup.component.html',
    styleUrls: ['./lp-lookup.component.scss']
})
export class LPLookupComponent {
    public readonly clearImagePoints: string = Utility.svgClearX;
    public readonly lookupImagePoints: string = Utility.svgLookingGlass;
    public readonly imageBackgroundColor: string = Utility.LPTransparent;
    public readonly imageForegroundColor: string = Utility.LPOrange;

    @Input() snapFormField: SnapFormField;
    modalRef?: BsModalRef;

    @ViewChild('lookupButton') lookupButtonElement: ElementRef;


    //***
    //***
    private _displayValue: string;
    get displayValue(): string { return this.snapFormService.getDisplayValue(this.snapFormField.fieldName); }
    set displayValue(value: string) { this.snapFormService.setDisplayValue(this.snapFormField.fieldName, value); }


    //***
    //***
    get value(): string { return this.snapFormService.getValue(this.snapFormField.fieldName); }
    set value(value: string) { this.snapFormService.setValue(this.snapFormField.fieldName, value); }



    //***
    //***
    clear() {
        this.value = "";
        this.displayValue = "";
    }


    //***
    //***
    lookup() {
        const dialogInitialState = { snapFormField: this.snapFormField, title: this.snapFormField.label, searchHint: this.displayValue, tables: null, selectedTable: null, selectedTableName: '' };
        this.modalRef = this.modalService.show(LPLookupDialogComponent,
            {
                backdrop: true,
                ignoreBackdropClick: true,
                class: 'lp-modal-lookup', //style in master src\styles.scss because the modal is outside this component
                initialState: dialogInitialState
            }
        );

        //If the user confirmed: remove all selections
        this.modalRef.content.selectedValue.subscribe((value: SnapFormLookupRow) => {
            if (value) {
                this.snapFormService.setValue(this.snapFormField.fieldName, value.id);
                this.displayValue = value.fields.find(x => x.name === value.nameField).value;
            }
        });
    }


    //***
    //***
    highlightButton() {
        if (this.snapFormField.isEnabled) {
            this.highlighterService.highlightElement(this.lookupButtonElement);
        }
    }


    //***
    //***
    constructor(private snapFormService: SnapFormService, private modalService: BsModalService, private highlighterService: HighlighterService) { }
}
