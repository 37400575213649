import { Component, OnInit } from "@angular/core";
import { OfficeService } from "../../core/services/office.service";
import { SessionService } from "../../core/services/session.service";
import { SublimeService } from "../../core/services/sublime.service";
import { ToastNotificationService } from "../../core/services/toast-notification.service";
import { SearchType } from "../../models/enums";
import { RemoteAction } from "../../models/remoteAction";
import Utility from "../../shared/utility";

@Component({
    selector: "app-lp-search-bar",
    templateUrl: "./lp-search-bar.component.html",
    styleUrls: ["./lp-search-bar.component.scss"],
})
export class LPSearchBarComponent implements OnInit {
    searchText = '';
    private searchTextMinimum = 3;
    public searchHistory: string[] = [];

    //***
    //***
    constructor(private sublimeService: SublimeService, private officeservice: OfficeService, private sessionService: SessionService, private toastNotificationService: ToastNotificationService,) {
        //Subscribe to Pouch Updates so that we can clear the search bar
        this.officeservice.onPouchUpdate().subscribe(() => {
            this.clear();
        });
        this.sublimeService.bootStrapSubject.subscribe(() => {
            this.searchHistory = this.sessionService.searchHintHistory;
        });
    }


    //***
    //***
    ngOnInit(): void {
        this.sublimeService.searchRefreshSubject.subscribe(() => {
            this.searchText = "";
        });
    }


    //***
    //***
    search() {
        //update history
        if (this.searchText && !this.searchText.startsWith("!!"))
            this.addToHistory(this.searchText);


        if (this.sessionService.salesforceConnected) {
            //perform search
            if (this.sublimeService.search(SearchType.SearchBar, [this.searchText]))
                this.searchText = "";
        }
        else {
            this.toastNotificationService.send('Salesforce Not Connected', "", "You must connect your Salesforce account before being able to search.", 3000, 'Search');
        }
    }


    //***
    //***
    public get isShowClearButton(): boolean {
        if (this.searchText.length >= 1) {
            return true;
        }
        else {
            return false;
        }
    }


    //***
    //***
    public get isShowSearchButton(): boolean {
        if (this.searchText.trim().length >= this.searchTextMinimum) {
            return true;
        }
        else {
            return false;
        }
    }


    //***
    //***
    clear(): void {
        this.searchText = '';
    }


    //***
    //***
    selectItem(selectedItem: string) {
        this.searchText = selectedItem;
    }


    //***
    //***
    addToHistory(searchTerm: string): void {
        const foundIndex: number = this.searchHistory.findIndex(x => x.toLowerCase() === searchTerm.toLowerCase())

        //If the searchTerm already exists in the history
        if (foundIndex !== 0) {
            if (foundIndex > -1) {
                this.searchHistory.splice(foundIndex, 1);
                this.searchHistory.unshift(this.searchText);
            }
            //If the searchTerm doesn't exist in the history
            else {
                this.searchHistory.unshift(this.searchText);
                if (this.searchHistory.length > 10) {
                    this.searchHistory.pop();
                }
            }
            this.sublimeService.apiRemoteAction(new RemoteAction(`UserPreference.SearchHintHistory=${Utility.toDelimitedString(this.searchHistory, "|")}`));
        }
    }
}
