import { Component, Input } from '@angular/core';
import { Cell } from '../../models/cell';

@Component({
    selector: 'app-lp-cell',
    templateUrl: './lp-cell.component.html',
    styleUrls: ['./lp-cell.component.scss']
})
export class LPCellComponent {

    @Input() cell: Cell;


    //***
    //***
    constructor() {
        this.cell = null;
    }
}
