<div *ngIf="!sessionService.salesforceConnected">

    <div>
        Environment:
        <select placeholder="Login Url"
                style="margin-bottom: 4px; width: 100%"
                [(ngModel)]="salesforceOAuthMode">
            <option *ngFor="let type of salesforceOAuthModes"
                    [value]="type.value">
                {{ type.label }}
            </option>
        </select>


        <div class="loginUrl">
            <div *ngIf="salesforceOAuthMode == 'Standard'">
                <span>{{salesforceStandardEndPoint}}</span>
            </div>

            <div *ngIf="salesforceOAuthMode == 'Sandbox'">
                <span>{{salesforceSandboxEndPoint}}</span>
            </div>

            <div *ngIf="salesforceOAuthMode == 'Community'">
                Community EndPoint:
                <input type="text"
                       style="width: 100%"
                       [(ngModel)]="salesforceCommunityEndPoint" />
                <br />
                <span>Sample: myportal.my.site.com/partnerportal</span>
            </div>


            <div *ngIf="salesforceOAuthMode == 'Custom'">
                Custom EndPoint:
                <input type="text"
                       style="width: 100%"
                       [(ngModel)]="salesforceCustomEndPoint" />
                <br />
                <span>Sample: https://myorgname.my.salesforce.com</span>
            </div>
        </div>
        <br />
    </div>
    

    
    <button *ngIf="loginState === loginStateType.Initial"
            class="btn btn-sm btn-primary"
            (click)="login()">
        <span>Connect to Salesforce</span>
    </button>
    <button *ngIf="loginState === loginStateType.Initial && sessionService.profileId === profileIdType.SystemAdmin"
            class="btn btn-sm btn-primary"
            (click)="loginAssist()">
        <span>Connect to Salesforce in Assist</span>
    </button>
    <button *ngIf="loginState === loginStateType.Dialog"
            class="btn btn-sm btn-primary"
            disabled>
        <span>Waiting for dialog... </span>
        <span class="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"></span>
    </button>
    <button *ngIf="loginState === loginStateType.Server"
            class="btn btn-sm btn-primary"
            disabled>
        <span>Performing discovery of your Salesforce environment which may take up to 30 seconds to complete.</span>
        <span class="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"></span>
    </button>
    <div class="error-message">{{ errorMessage }}</div>
</div>
<div *ngIf="sessionService.salesforceConnected">
    <p>Connected as: <b>{{ sessionService.salesforceUserName }}</b></p>
    <button class="btn btn-sm btn-primary"
            (click)="disconnect()">
        Disconnect
    </button>
</div>
