<div *ngIf="isVisible">
    <snap-lp-field-label [snapFormField]="snapFormField"></snap-lp-field-label>
    <div>
        <div class="row lp-no-gutters">
            <select class="col-6 lp-no-gutters lp-input-element"
                    style="padding: 3px 4px 3px 4px"
                    [id]="offset"
                    [name]="offset"
                    [(ngModel)]="offset"
                    [disabled]="!isEnabled">
                <option *ngFor="let item of offsetValues"
                        [value]="item.value">
                    {{item.label}}
                </option>
            </select>
            <input type="hidden"
                   id="startDateTime"
                   name="startDateTime"
                   [(ngModel)]="startDateTime" />
        </div>
    </div>
</div>
