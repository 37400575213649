import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { PanelService } from "../../core/services/panelService";
import { ToastNotificationService } from "../../core/services/toast-notification.service";
import { Card } from "../../models/card";
import { PanelType } from "../../models/enums";
import { LPFormMode } from "../models/enums";
import { MiniFormResult } from "../models/miniFormResult";
import { SnapFormService } from "./snapform.service";



@Injectable()
export class MacHandlerSnapFormService {
    private readonly toastTopic = "SnapForm";

    //***
    public tableName: string;
    //***
    public formMode = LPFormMode.Undefined;
    //***
    public id: string;
    //***
    public card: Card;

    public miniFormSubject: BehaviorSubject<MiniFormResult> = new BehaviorSubject<MiniFormResult>(null);
    


    //***
    //***
    constructor(private panelService: PanelService, private toastService: ToastNotificationService, private snapFormService: SnapFormService) {
    }

    //***
    //***
    public initializeMacFormFull(tableName: string, id: string = '') {
        this.formMode = LPFormMode.Full;

        this.launchForm(tableName, id);
    }
    //***
    //***
    public initializeMacFormMini(tableName: string, id: string = '') {
        this.formMode = LPFormMode.Mini;

        this.launchForm(tableName, id);
    }
    //***
    //***
    public initializeMacFormMiniWithCard(tableName: string, card: Card, id: string = '') {
        this.formMode = LPFormMode.Mini;
        this.card = card;

        this.launchForm(tableName, id);
    }
    //***
    //***
    public initializeMacFormSignature(tableName: string, id: string = '') {
        this.formMode = LPFormMode.Signature;

        this.launchForm(tableName, id);
    }
    //***
    //***
    private launchForm(tableName: string, id: string) {
        this.tableName = tableName;
        this.id = id;

        this.panelService.displayModal(PanelType.SnapForm);
    }

    //***
    //***
    public submitForm(itemId: string) {
        this.toastService.send('Success', "", "Salesforce item created.", 3000, this.toastTopic);
        this.closeForm();
    }
    //***
    //***
    public submitMiniForm(message: string) {
        this.miniFormSubject.next(new MiniFormResult().deserialize(JSON.parse(message) as MiniFormResult));
        
        this.closeForm();        
    }

    //***
    //***
    public cancelForm() {
        //if the user was selecting a card we need to unselect it here
        if (this.card) {
            this.undoSelection();
        }

        this.closeForm();
    }

    //***
    //***
    private closeForm() {
        //Close the panel
        this.panelService.clearModal();

        //Reset all of the values in this handler
        this.miniFormSubject.next(null);
        this.tableName = '';
        this.formMode = LPFormMode.Undefined;
        this.id = '';
        this.card = null;

        //Hide the snapform ui so that it doesn't display anything when the panel is reopened
        this.snapFormService.isReadyToShow = false;
    }

    //**
    //**
    private undoSelection() {
        this.card.isChecked = false;
        this.toastService.send('Selection Undone', "", "You must fill in required fields to apply selection", 5000, "Select");
    }
}
