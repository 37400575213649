import { Card } from "./card";
import { Header } from "./header";
import { IDeserializable } from "./interfaces/IDeserializable.interface";
import { orderBy } from "lodash";
import Utility from "../shared/utility";

export class Group implements IDeserializable {
    public id: string;
    public header: Header;
    public isExpanderVisible: boolean;
    public isExpanded: boolean;
    public cards: Card[] = [];


    //***
    //***
    public get sortedCards(): Card[] {
        return orderBy(this.cards, ["sortValue"]);
    }

    //***
    //***
    public get rank(): string {
        const title = (this.header.title ?? "").toLocaleLowerCase();

        if (this.id === Utility.ConnectionsTableName)
            return "0" + title;
        else if (this.id === Utility.AttachmentTableName)
            return "9" + title;
        else
            return "5" + title;
    }


    //***
    //***
    public Clone(): Group {
        const result = new Group();
        result.id = this.id;
        result.header = this.header;
        result.isExpanderVisible = this.isExpanderVisible;
        result.isExpanded = this.isExpanded;
        return result;
    }


    //***
    //***
    public clear() {
        this.cards.splice(0, this.cards.length);
    }


    //***
    //***
    deserialize(input: Group): this {
        Object.assign(this, input);

        if (input?.header)
            this.header = new Header().deserialize(input.header);
        if (Array.isArray(input?.cards)) {
            this.cards = input.cards.map((card) => new Card().deserialize(card));
        }
        return this;
    }
}
