import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { take } from "rxjs/operators";
import { ToastNotificationService } from "../../../app/core/services/toast-notification.service";
import { MiniFormDialogService } from "../../../app/core/services/miniFormDialog.service";
import { PanelService } from "../../core/services/panelService";
import { SessionService } from "../../core/services/session.service";
import { SublimeService } from "../../core/services/sublime.service";
import { PanelType, SelectCardAction } from "../../models/enums";
import { FooterStatus } from "../../models/footerStatus";
import Utility from "../../shared/utility";
import { MiniFormResult } from "../../snap-forms/models/miniFormResult";
import { MacHandlerSnapFormService } from "../../snap-forms/services/mac-handler-snapform.service";


@Component({
    selector: "app-footer",
    templateUrl: "./footer.component.html",
    styleUrls: ["./footer.component.scss"],
})
export class FooterComponent implements OnInit {
    //***
    private readonly MaxSpinDuration: number = 5000;
    public readonly preferenceImagePoints: string = Utility.svgTune;
    public readonly connectionsImagePoints: string = Utility.svgNetwork;
    public readonly dropZoneImagePoints: string = Utility.svgDropZone;


    public readonly imageBackgroundColor: string = Utility.LPTransparent;
    public readonly imageForegroundColor: string = Utility.LPBlue;
    //***
    public rowCount: number;
    public durationMilliseconds: number;
    public isSpinning: boolean;
    public isMiniOpening: boolean = false;

    private toastTopic = "SnapForm";

    //Determines if we should display the mini form button
    public get isShowMiniFormButton(): boolean {
        let pouch = this.sublimeService.getPouch();

        //Must have a miniForm TableName and values
        return (this.sublimeService.miniFormTableName == "Task" || this.sublimeService.miniFormTableName == "Event");
        //    return this.sublimeService.miniFormTableName && this.sublimeService.miniFormValues && (
        //        //If this is not compose mode then make sure there is at least 1 field
        //        (!pouch.isComposeMode && this.sublimeService.miniFormValues.fieldValues.length > 0) ||
        //        (
        //            //If this is compose mode and the edit/subject option is not enabled then make sure there is at least 1 field
        //            (pouch.isComposeMode && !this.sessionService.subjectBodyEnabled && this.sublimeService.miniFormValues.fieldValues.length > 0) ||
        //            //If this is compose mode and the edit/subject option is enabled then make sure there is at least 3 fields
        //            //The reason for 3 is because Subject and Body will count as 2 fields but wont be displayed for compose mode so we need at least 1 other one
        //            (pouch.isComposeMode && this.sessionService.subjectBodyEnabled && this.sublimeService.miniFormValues.fieldValues.length > 2)
        //        )
        //    );
    }

    constructor(private panelService: PanelService, public sublimeService: SublimeService, public sessionService: SessionService, private toastService: ToastNotificationService,
        private macHandler: MacHandlerSnapFormService, private router: Router, private miniFormDialogService: MiniFormDialogService) {
    }

    //***
    //***
    ngOnInit(): void {
        this.sublimeService.footerStatusSubject.subscribe((value: FooterStatus) => {
            if (value) {
                if (value.isSpinning === true) {
                    this.isSpinning = true;
                    this.startSpinTimer();
                }
                else if (value.isSpinning === false) {
                    this.isSpinning = false;
                }

                if (value.rowCount)
                    this.rowCount = value.rowCount;
                if (value.durationMilliseconds)
                    this.durationMilliseconds = value.durationMilliseconds;
            }
        });
    }


    //***
    //***
    public get canShowPreferences(): boolean { return true; }


    //***
    //***
    public showPreferences_click() {
        if (this.canShowPreferences)
            this.panelService.displayModal(PanelType.Preferences);
    }


    //***
    //***
    public get canConnections(): boolean { return false; }


    //***
    //***
    public readonly calendarSyncImagePoint: string = Utility.svgCalendarSync;
    public get canCalendarSync(): boolean { return false; }
    public startCalendarSync(): void { this.sublimeService.calendarSync(); }


    //***
    //***
    public readonly emailSyncImagePoint: string = Utility.svgEmailSync;
    public get canEmailSync(): boolean { return false; }
    public startEmailSync(): void { this.sublimeService.emailSync(); }

    //***
    //***
    async openMiniForm() {
        if (this.isShowMiniFormButton && !this.isMiniOpening) {
            this.isMiniOpening = true;
            await this.miniFormDialogService.openMiniForm(this.processOfficeDialogMessage, this.processOfficeDialogEvent);
        }
    }


    //***
    //***
    private processOfficeDialogMessage = (arg: any) => {
        if (arg.message) {
            this.sublimeService.miniFormValues = new MiniFormResult().deserialize(JSON.parse(arg.message) as MiniFormResult);

            if (this.sublimeService.getPouch().referencePacket.hostRecordId) {
                this.sublimeService.select(SelectCardAction.Reapply, []).then((response) => {
                    if (response) {
                        //If saving to SF was a success tell the user
                        this.toastService.send('Success', "", "Values updated for selected items.", 3000, this.toastTopic);
                        this.isMiniOpening = false;
                    } else {
                        //reopen the miniform if there is a sf error during select
                        this.miniFormDialogService.openMiniForm(this.processOfficeDialogMessage, this.processOfficeDialogEvent);
                    }
                });
            }
        }
        this.miniFormDialogService.officeDialog.close();
        this.isMiniOpening = false;
    };


    //***
    //***
    private processOfficeDialogEvent = (arg) => { this.isMiniOpening = false; };


    //***
    //***
    private startSpinTimer() { }

    //***
    // Event that fires when content is dragged over the html tag
    //***
    public allowDrop(ev) {
        ev.preventDefault();
    }

    //***
    // Event that fires when content is dropped on the html tag
    //***
    public drop(ev) {
        ev.preventDefault();
        var dropData = ev.dataTransfer.getData("text");
    }
}
