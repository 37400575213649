<div class="row no-gutters lp-no-gutters lp-footer">


    <!--  DropZone DropZone DropZone -->
    <!--  DropZone DropZone DropZone -->
    <!--<div class="col-auto lp-no-gutters lp-align-vertical-center"
         (drop)="drop($event)"
         (dragover)="allowDrop($event)">
        <div role="button"
             (click)="showPreferences_click()">
            <app-lp-svg [imagePoints]="dropZoneImagePoints"
                        [imageSize]="22"
                        [imageBackgroundColor]="imageBackgroundColor"
                        [imageForegroundColor]="imageForegroundColor"
                        lpTooltip="Drop a Signature here"></app-lp-svg>
        </div>
    </div>-->


    <!--  EmailSync  EmailSync  EmailSync-->
    <!--  EmailSync  EmailSync  EmailSync-->
    <div *ngIf="canEmailSync"
         class="col-auto lp-no-gutters float-left lp-align-vertical-center">
        <div role="button"
             class="row no-gutters">
            <app-lp-svg class="col-auto lp-no-gutters"
                        [imageSize]="22"
                        [imagePoints]="emailSyncImagePoint"
                        [imageBackgroundColor]="imageBackgroundColor"
                        [imageForegroundColor]="imageForegroundColor"
                        lpTooltip="Initiate Background Email Sync"
                        (click)="startEmailSync()"></app-lp-svg>
        </div>
    </div>


    <!--  CalendarSync  CalendarSync  CalendarSync-->
    <!--  CalendarSync  CalendarSync  CalendarSync-->
    <div *ngIf="canCalendarSync"
         class="col-auto lp-no-gutters float-left lp-align-vertical-center">
        <div role="button"
             class="row no-gutters">
            <app-lp-svg class="col-auto lp-no-gutters"
                        [imageSize]="22"
                        [imagePoints]="calendarSyncImagePoint"
                        [imageBackgroundColor]="imageBackgroundColor"
                        [imageForegroundColor]="imageForegroundColor"
                        lpTooltip="Initiate Background Calendar Sync"
                        (click)="startCalendarSync()"></app-lp-svg>
        </div>
    </div>




    <div class="col lp-no-gutters">
    </div>

    <!--  Connections  Connections  Connections-->
    <!--  Connections  Connections  Connections-->
    <div *ngIf="canConnections"
         class="col-auto lp-no-gutters float-right lp-align-horizontal-center lp-align-vertical-center">
        <div role="button"
             class="row no-gutters">
            <app-lp-svg class="col-auto lp-no-gutters"
                        [imagePoints]="connectionsImagePoints"
                        [imageBackgroundColor]="imageBackgroundColor"
                        [imageForegroundColor]="imageForegroundColor"
                        lpTooltip="Connections"></app-lp-svg>
        </div>
    </div>
    <!--</div>-->
    <!--  MiniForm  MiniForm  MiniForm-->
    <!--  MiniForm  MiniForm  MiniForm-->
    <div class="col">
        <button *ngIf="isShowMiniFormButton"
                tourAnchor="tourMiniForm"
                type="button"
                [disabled]="isMiniOpening"
                class="btn btn-warning btn-sm lp-mini-form-button"
                (click)="openMiniForm()">
            View
            Salesforce
            Fields
        </button>
    </div>


    <!--  Spinner  Spinner  Spinner-->
    <!--  Spinner  Spinner  Spinner-->
    <div class="col-auto lp-no-gutters float-right lp-align-vertical-center"
         *ngIf="isSpinning">
        <button class="btn btn-lp"
                type="button">
            <span class="spinner-border spinner-border-sm d-flex align-items-center lp-spinner"
                  role="status"
                  aria-hidden="true"></span>
        </button>
    </div>


    <!--  Stats  Stats  Stats-->
    <!--  Stats  Stats  Stats-->
    <div class="col-auto lp-no-gutters float-right lp-align-vertical-center"
         *ngIf="!isSpinning">
        <div class="lp-align-horizontal-right">{{rowCount}} rows&nbsp;</div>
        <div class="lp-align-horizontal-right">{{durationMilliseconds | number:'1.2-2'}} sec&nbsp;</div>
    </div>


    <!--  Prefs  Prefs  Prefs-->
    <!--  Prefs  Prefs  Prefs-->
    <div *ngIf="canShowPreferences"
         tourAnchor="tourPref"
         class="col-auto lp-no-gutters float-right lp-align-vertical-center">
        <div role="button"
             (click)="showPreferences_click()">
            <app-lp-svg [imagePoints]="preferenceImagePoints"
                        [imageSize]="22"
                        [imageBackgroundColor]="imageBackgroundColor"
                        [imageForegroundColor]="imageForegroundColor"
                        lpTooltip="Preferences"></app-lp-svg>
        </div>
    </div>
</div>
