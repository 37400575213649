import { IDeserializable } from "./interfaces/IDeserializable.interface";

export class GraphLoginRequest implements IDeserializable {
    lpUserId: string;
    authToken: string = '';

    //***
    // AuthToken now sent in header by the authInterceptor.ts
    //***
    constructor(lpUserId: string, authToken: string) {
        this.lpUserId = lpUserId;

        if (authToken)
            this.authToken = authToken;
    }

    deserialize(input: GraphLoginRequest): this {
        Object.assign(this, input);

        return this;
    }

}
