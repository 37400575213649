import { Component, OnInit } from '@angular/core';
import Utility from "../../shared/utility";

@Component({
  selector: 'app-license-expired',
  templateUrl: './license-expired.component.html',
  styleUrls: ['./license-expired.component.scss']
})
export class LicenseExpiredComponent implements OnInit {
    readonly keyImagePoints: string = Utility.svgKeyVariant;
    readonly cartImagePoints: string = Utility.svgCartPlus;
    readonly chatImagePoints: string = Utility.svgForumOutline;
    readonly phoneImagePoints: string = Utility.svgPhoneOutgoing;

  constructor() { }

  ngOnInit() {
  }

}
