<div class="lp-container"
     style="display: grid; grid-template-columns: max-content min-content auto">

    <label class="lp-label"
           [for]="fieldName">
        {{label}}
    </label>

    <app-lp-svg *ngIf="isTooltipVisible"
                style="padding-top: -3px;"
                [imagePoints]="imagePoints"
                [lpTooltip]="tooltip"
                [imageBackgroundColor]="imageBackgroundColor"
                [imageForegroundColor]="imageForegroundColor"
                imageSize="16"></app-lp-svg>

</div>
