import Utility from "../shared/utility";
import { CustomSyncFields } from "./customSyncFields";
import { IDeserializable } from "./interfaces/IDeserializable.interface";
import { ReferenceItem } from "./referenceItem";

export class ReferencePacket implements IDeserializable {
    public hostRecordId: string;
    public hostUserId: string;
    public autoSelectAttachmentsPerformed: boolean;
    public followUpHostRecordId: string;
    public errorMessage: string;
    public customSyncFields: CustomSyncFields = new CustomSyncFields();
    public referenceItems: ReferenceItem[] = [];

    constructor() {
        this.hostRecordId = "";
        this.hostUserId = "";
        this.autoSelectAttachmentsPerformed = false;
        this.errorMessage = "";
        this.customSyncFields = new CustomSyncFields();
        this.referenceItems = [];
    }

    deserialize(input: ReferencePacket): this {
        Object.assign(this, input);

        if (input?.customSyncFields)
            this.customSyncFields = new CustomSyncFields().deserialize(input.customSyncFields);

        if (input?.referenceItems)
            this.referenceItems = input.referenceItems.map((referenceItem) => new ReferenceItem().deserialize(referenceItem));
        return this;
    }


    public static fromJson(value: string): ReferencePacket {
        let result: ReferencePacket = null;
        try {
            if (value)
                result = new ReferencePacket().deserialize(JSON.parse(value)); // as ReferencePacket;
        }
        catch (error) {
            Utility.debug(error, "fromJson");
        }
        if (!result)
            result = new ReferencePacket();

        return result;
    }
}
