import { Component, Input } from '@angular/core';
import { SnapConstants } from '../../models/snapConstants';
import { SnapFormField } from '../../models/snapFormField';
import { SnapFormService } from '../../services/snapform.service';

@Component({
    selector: 'snap-lp-date-time',
    templateUrl: './lp-date-time.component.html',
    styleUrls: ['./lp-date-time.component.scss']
})
export class LPDateTimeComponent {
    private get dateFieldName(): string { return `date${this.snapFormField.fieldName}` }
    private get timeFieldName(): string { return `time${this.snapFormField.fieldName}` }

    //***
    @Input() snapFormField: SnapFormField;


    //***
    //***
    public get value(): string { return this.snapFormService.getValue(this.snapFormField.fieldName); }
    public set value(value: string) { this.snapFormService.setValue(this.snapFormField.fieldName, value); }


    //***
    //***
    private gather() { this.value = `${this.date} ${this.time}`; }


    //***
    //***
    public get date(): string {
        let result = this.snapFormService.getValue(this.dateFieldName);
        if (!result) {
            const value = this.value
            if (value) {
                const parts = value.split(" ");
                if (parts.length === 2) {
                    result = parts[0];
                    this.snapFormService.setValue(this.dateFieldName, result);
                }
            }
        }
        return result;
    }
    public set date(value: string) {
        this.snapFormService.setValue(this.dateFieldName, value);
        this.gather();
    }


    //***
    //***
    public get dateEnabled(): boolean { return true }
    public set dateEnabled(value: boolean) { ; }


    //***
    //***
    public get time(): string {
        let result = this.snapFormService.getValue(this.timeFieldName);
        if (!result) {
            const value = this.value;
            if (value) {
                const parts = value.split(" ");
                if (parts.length === 2) {
                    result = parts[1];
                    this.snapFormService.setValue(this.timeFieldName, result);
                }
            }
        }
        return result;
    }
    public set time(value: string) {
        this.snapFormService.setValue(this.timeFieldName, value);
        this.gather();
    }


    //***
    public get timeEnabled(): boolean {
        if (this.snapFormService.isEvent && this.snapFormField.fieldName === SnapConstants.StartDateTime)
            return this.snapFormService.getValue(SnapConstants.IsAllDayEvent) !== SnapConstants.True;
        else if (this.snapFormService.isEvent && this.snapFormField.fieldName === SnapConstants.EndDateTime)
            return this.snapFormService.getValue(SnapConstants.IsAllDayEvent) !== SnapConstants.True;
        else
            return true
    }
    public set timeEnabled(value: boolean) { ; }



    //***
    constructor(private snapFormService: SnapFormService) { }
}
