import { Component, Input } from '@angular/core';
import Utility from '../../../shared/utility';
import { SnapFormField } from '../../models/snapFormField';
import { SnapFormPicklistValue } from '../../models/snapFormPicklistValue';
import { SnapFormService } from '../../services/snapform.service';

@Component({
    selector: 'snap-lp-multipicklist',
    templateUrl: './lp-multipicklist.component.html',
    styleUrls: ['./lp-multipicklist.component.scss']
})
export class LPMultiPickListComponent {
    public readonly rightImagePoints: string = Utility.svgChevronRight;
    public readonly rightAllImagePoints: string = Utility.svgChevronRightDouble;
    public readonly leftImagePoints: string = Utility.svgChevronLeft;
    public readonly leftAllImagePoints: string = Utility.svgChevronLeftDouble;


    @Input() snapFormField: SnapFormField;


    //***
    //***
    public get value(): string { return this.snapFormService.getValue(this.snapFormField.fieldName) ?? ""; }
    public set value(value: string) { this.snapFormService.setValue(this.snapFormField.fieldName, value); }

    get dependencyCommentVisible(): boolean { return !Utility.isNullOrEmpty(this.snapFormField.dependencyComment); }

    //***
    //***
    private get allValues(): SnapFormPicklistValue[] { return [...this.snapFormField.picklistValues]; }
    public leftLitValues: SnapFormPicklistValue[] = [];
    public rightLitValues: SnapFormPicklistValue[] = [];
    public get moveRightEnabled(): boolean { return this.snapFormField.isEnabled && this.leftLitValues.length > 0 }
    public get moveLeftEnabled(): boolean { return this.snapFormField.isEnabled && this.rightLitValues.length > 0 }


    //***
    //***
    public get leftValues(): SnapFormPicklistValue[] {
        const result: SnapFormPicklistValue[] = [];
        const picks = this.value.split(";");
        this.allValues.forEach(x => {
            if (!picks.includes(x.value))
                result.push(x);
        })
        return result;
    }


    //***
    //***
    public get rightValues(): SnapFormPicklistValue[] {
        const result: SnapFormPicklistValue[] = [];
        const values: string[] = [];
        const picks = this.value.split(";");
        this.allValues.forEach(x => {
            if (picks.includes(x.value)) {
                result.push(x);
                values.push(x.value);
            }
        })
        const newValue = Utility.toDelimitedString(values, ";");
        if (newValue !== this.value)
            this.value = newValue;
        return result;
    }


    //***
    //***
    chooseItems() {
        const result: string[] = []
        this.allValues.forEach(x => {
            const isRight = this.rightValues.find(y => y.value === x.value);
            const isLit = this.leftLitValues.find(y => y.value === x.value);
            if (isRight || isLit)
                result.push(x.value);
        });
        this.value = Utility.toDelimitedString(result, ";");
    }


    //***
    //***
    removeItems() {
        const result: string[] = []
        this.allValues.forEach(x => {
            const isRight = this.rightValues.find(y => y.value === x.value);
            const isLit = this.rightLitValues.find(y => y.value === x.value);
            if (isRight && !isLit)
                result.push(x.value);
        });
        this.value = Utility.toDelimitedString(result, ";");
    }


    //***
    //***
    constructor(private snapFormService: SnapFormService) { }
}
