import { HttpClient } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { BsModalRef } from "ngx-bootstrap/modal";
import { environment } from "../../../environments/environment";
import { OfficeService } from "../../core/services/office.service";
import { PanelService } from "../../core/services/panelService";
import { SessionService } from "../../core/services/session.service";
import { SublimeService } from "../../core/services/sublime.service";
import { ToastNotificationService } from "../../core/services/toast-notification.service";
import { AssistTokenResponse } from "../../models/assistTokenResponse";
import { ProfileId, Visibility } from "../../models/enums";
import { GroupPreference } from "../../models/groupPreference";
import { RemoteAction } from "../../models/remoteAction";
import Utility from "../../shared/utility";


@Component({
    selector: "app-preferences",
    templateUrl: "./preferences.component.html",
    styleUrls: ["./preferences.component.scss"],
})
export class PreferencesComponent implements OnInit {
    showOutlookButtons = false;
    data: any[] = [];
    preferences: GroupPreference[] = [];
    generalExpanded = false;
    supportExpanded = false;
    salesforceExpanded = false;
    microsoftExpanded = false;
    performDiscoveryOnClose = false;
    syncExpanded = false;

    //True if any setting gets changed. Not exposed on the UI
    private performBootstrapOnClose = false;
    private toastTopic = "Preferences";
    private currentHostConnectionMessage = "";
    private assistToken = "";

    //For some unknown reason this is necessary to fix a build error
    readonly keyImagePoints: string = '';
    readonly cartImagePoints: string = '';
    readonly chatImagePoints: string = '';
    readonly phoneImagePoints: string = '';



    //  General  General  General
    //  General  General  General
    onGeneralDoubleClick(event: MouseEvent) { this.generalExpanded = !this.generalExpanded; }


    //**
    //**
    public get searchOnlyNameFieldsVisibility(): Visibility { return this.sessionService.searchOnlyNameFieldsVisibility; }
    public get searchOnlyNameFields(): boolean { return this.sessionService.searchOnlyNameFields; }
    public set searchOnlyNameFields(value: boolean) {
        if (value !== this.sessionService.searchOnlyNameFields) {
            this.sessionService.searchOnlyNameFields = value;
            this.savePreferenceChange(new RemoteAction(`UserPreference.Salesforce.SearchOnlyNameFields=${value}`));
        }
    }


    //**
    //**
    public get autoSelectAttachmentsVisibility(): Visibility { return this.sessionService.autoSelectAttachmentsVisibility; }
    public get autoSelectAttachmentsEnabled(): boolean { return this.sessionService.autoSelectAttachmentsEnabled; }
    public set autoSelectAttachmentsEnabled(value: boolean) {
        if (value !== this.sessionService.autoSelectAttachmentsEnabled) {
            this.sessionService.autoSelectAttachmentsEnabled = value;
            this.savePreferenceChange(new RemoteAction(`UserPreference.AutoSelectAttachmentsEnabled=${value}`));
        }
    }
    onAutoSelectAttachmentsIgnore(event: any) { this.savePreferenceChange(new RemoteAction(`UserPreference.AutoSelectAttachmentsIgnore=${event.target.value}`)); }


    public get supportVisibility(): Visibility { return this.sessionService.supportVisibility; }
    public get knowledgeBaseVisibility(): Visibility { return this.sessionService.knowledgeBaseVisibility; }
    public get chatVisibility(): Visibility { return this.sessionService.chatVisibility; }


    public get isSortVisible(): boolean { return this.sessionService.sortVisibility == Visibility.Visible; }
    public get isAutoExpandVisible(): boolean { return this.sessionService.autoExpandVisibility == Visibility.Visible; }


    //**
    //**
    public get ignoreSearchHintsVisibility(): Visibility { return this.sessionService.ignoreSearchHintsVisibility; }
    public get ignoreSearchHintsEnabled(): boolean { return this.sessionService.ignoreSearchHintsEnabled; }
    public set ignoreSearchHintsEnabled(value: boolean) {
        if (value !== this.sessionService.ignoreSearchHintsEnabled) {
            this.sessionService.ignoreSearchHintsEnabled = value;
            this.savePreferenceChange(new RemoteAction(`UserPreference.IgnoreSearchHintsEnabled=${value}`));
        }
    }
    onIgnoreSearchHints(event: any) { this.savePreferenceChange(new RemoteAction(`UserPreference.IgnoreSearchHints=${event.target.value}`)); }

    //**
    //**
    public get subjectBodyVisibility(): Visibility { return this.sessionService.subjectBodyVisibility; }
    public get subjectBodyEnabled(): boolean { return this.sessionService.subjectBodyEnabled; }
    public set subjectBodyEnabled(value: boolean) {
        if (value !== this.sessionService.subjectBodyEnabled) {
            this.sessionService.subjectBodyEnabled = value;
            this.savePreferenceChange(new RemoteAction(`UserPreference.Salesforce.SubjectBodyEnabled=${value}`));

            //Force Discovery when this value changes
            this.performDiscoveryOnClose = true;
        }
    }

    public get suggestUsingConversationVisibility(): Visibility { return this.sessionService.suggestUsingConversationVisibility; }
    public get suggestUsingConversation(): boolean { return this.sessionService.suggestUsingConversation; }
    public set suggestUsingConversation(value: boolean) {
        if (value !== this.sessionService.suggestUsingConversation) {
            this.sessionService.suggestUsingConversation = value;
            this.savePreferenceChange(new RemoteAction(`UserPreference.SuggestUsingConversation=${value}`));
        }
    }

    public get suggestUsingRelevanceVisibility(): Visibility { return this.sessionService.suggestUsingRelevanceVisibility; }
    public get suggestUsingRelevance(): boolean { return this.sessionService.suggestUsingRelevance; }
    public set suggestUsingRelevance(value: boolean) {
        if (value !== this.sessionService.suggestUsingRelevance) {
            this.sessionService.suggestUsingRelevance = value;
            this.savePreferenceChange(new RemoteAction(`UserPreference.SuggestUsingRelevance=${value}`));
        }
    }



    //**
    //**
    public get editModeMessageVisibility(): Visibility { return this.sessionService.editModeMessageVisibility; }
    public get editModeMessageEnabled(): boolean { return this.sessionService.editModeMessageEnabled; }
    public set editModeMessageEnabled(value: boolean) {
        if (value !== this.sessionService.editModeMessageEnabled) {
            this.sessionService.editModeMessageEnabled = value;
            this.savePreferenceChange(new RemoteAction(`UserPreference.EditModeMessageEnabled=${value}`));
        }
    }

    //**
    //**
    public get connectionsVisibility(): Visibility { return this.sessionService.connectionsVisibility; }
    public get connectionsEnabled(): boolean { return this.sessionService.connectionsEnabled; }
    public set connectionsEnabled(value: boolean) {
        if (value !== this.sessionService.connectionsEnabled) {
            this.sessionService.connectionsEnabled = value;
            this.savePreferenceChange(new RemoteAction(`UserPreference.ConnectionsEnabled=${value}`));
        }
    }


    ////**
    ////**
    //public get timeScoutVisibility(): Visibility { return this.sessionService.timeScoutVisibility; }
    //public get timeScoutEnabled(): boolean { return this.sessionService.timeScoutEnabled; }
    //public set timeScoutEnabled(value: boolean) {
    //    if (value !== this.sessionService.timeScoutEnabled) {
    //        this.sessionService.timeScoutEnabled = value;
    //        this.savePreferenceChange(new RemoteAction(`UserPreference.TimeScout.Enabled=${value}`));
    //    }
    //}

    public get isMicrosoftConnectionVisible(): boolean { return !this.sessionService.microsoftConnected; }



    //  Support  Support  Support
    //  Support  Support  Support
    onSupportDoubleClick(event: MouseEvent) { this.supportExpanded = !this.supportExpanded; }



    //  Salesforce  Salesforce  Salesforce
    //  Salesforce  Salesforce  Salesforce
    onSalesforceDoubleClick(event: MouseEvent) { this.salesforceExpanded = !this.salesforceExpanded; }




    //  Sync  Sync  Sync
    //  Sync  Sync  Sync
    onSyncDoubleClick(event: MouseEvent) { this.syncExpanded = !this.syncExpanded; }


    //***
    //***
    onOpenBladeAssistClick(event: any) {
        //if (this.assistToken)
        //    Utility.openUrl(`${environment.ASSIST_ENDPOINT}?token=${this.assistToken}`);

        this.sublimeService.apiAssistTokenCall().subscribe((response: AssistTokenResponse) => {
            try {
                if (response)
                    Utility.openUrl(`${this.sessionService.ASSIST_ENDPOINT}login?token=${response.token}`);
            } catch (error) {
                Utility.debug(error, "AssistToken Error");
            }
        });

        //Below fixed OWA on the Ipad but broke the Outlook App on the Ipad so reverting back
        ////Open a window immediately after user click so that pop-up blockers dont try to prevent it
        //var newWindow = window.open('', '_blank');
        //newWindow.document.write('Establishing Connection...');

        ////Set the token
        //this.sublimeService.apiAssistTokenCall().subscribe((response: AssistTokenResponse) => {
        //    try {
        //        if (response)
        //            newWindow.location.href = `${environment.ASSIST_ENDPOINT}?token=${response.token}`; //Point the window to the correct url
        //    } catch (error) {
        //        Utility.debug(error, "AssistToken Error");
        //        newWindow.close(); //Close the window if there was a problem with the token
        //    }
        //});
    }


    public readonly calendarSyncImagePoint: string = Utility.svgCalendarSync;
    public get canCalendarSync(): boolean { return this.sessionService.salesforceConnected && this.sessionService.calendarSyncEnabled; }
    public startCalendarSync(): void { this.sublimeService.calendarSync(); }

    //***
    //***
    public readonly emailSyncImagePoint: string = Utility.svgEmailSync;
    public get canEmailSync(): boolean { return this.sessionService.salesforceConnected && this.sessionService.emailSyncEnabled; }
    public startEmailSync(): void { this.sublimeService.emailSync(); }

    //  Tables  Tables  Tables
    //  Tables  Tables  Tables


    //**
    //**
    get syncGroupTitle(): string {
        if (this.sessionService.profileId === ProfileId.StandardUser)
            return "Synchronization";
        else
            return "Administration & Sync";
    }


    //**
    //**
    onEnabledChange(event: any, groupPreference: GroupPreference) {
        this.performDiscoveryOnClose = true;
        this.savePreferenceChange(new RemoteAction(`GroupPreferences[${groupPreference.id}].IsEnabled=${event.target.checked}`));
    }

    //**
    //**
    onExpandedChange(event: any, groupPreference: GroupPreference) { this.savePreferenceChange(new RemoteAction(`GroupPreferences[${groupPreference.id}].IsExpanded=${event.target.checked}`)); }

    onAscendingDescendingClick(preference: GroupPreference) {
        preference.sortAscending = !preference.sortAscending;
        const remoteAction = new RemoteAction(`GroupPreferences[${preference.id}].SortAscending=${preference.sortAscending}`);
        this.savePreferenceChange(remoteAction);
    }

    onSortFieldNameChange(preference: GroupPreference) {
        const remoteAction = new RemoteAction(`GroupPreferences[${preference.id}].SortFieldName=${preference.sortFieldName}`);
        this.savePreferenceChange(remoteAction);
    }


    //***
    //***
    constructor(public bsModalRef: BsModalRef,
        private httpClient: HttpClient,
        private sublimeService: SublimeService,
        private router: Router,
        private toastNotificationService: ToastNotificationService,
        private officeService: OfficeService,
        public sessionService: SessionService,
        private panelService: PanelService) {
    }


    //***
    //***
    ngOnInit(): void {
        this.sublimeService.preferencesSubject.subscribe(res => {
            if (res) {
                this.preferences = res;
            }
        });

        this.validateHostConnections();
    }

    //**
    //Notify the user if the hosts are not connected
    //**
    validateHostConnections() {
        this.currentHostConnectionMessage = "";

        //Prompt the user to sign into Salesforce if they are not connected
        if (!this.sessionService.salesforceConnected) {
            this.salesforceExpanded = true;
            this.currentHostConnectionMessage = "Please connect your Salesforce account to continue";
        }

        //Prompt the user to sign into Microsoft if they are not connected and cant use Identity SSO
        if (!this.sessionService.microsoftConnected) {
            this.microsoftExpanded = true;
            this.currentHostConnectionMessage = "Please connect your Microsoft account to continue";
        }

        //Alter the message if both hosts are disconnected at the same time
        if (!this.sessionService.salesforceConnected && !this.sessionService.microsoftConnected) {
            this.currentHostConnectionMessage = "Please connect both your Salesforce and Microsoft accounts to continue";
        }

        //If we ever set a message then display it as a toast now
        if (this.currentHostConnectionMessage) {
            this.toastNotificationService.send('Sign In', "", this.currentHostConnectionMessage, 0, this.toastTopic + 'Host');
        }
    }


    //**
    //**
    onClose() {
        //Notify the user if the hosts are not connected
        this.validateHostConnections();

        //Only allow the preferences to close if Salesforce and Microsoft are connected
        if (this.sessionService.salesforceConnected && this.sessionService.microsoftConnected) {
            this.bsModalRef.hide();
        } else {
            this.toastNotificationService.send('Sign In', "", this.currentHostConnectionMessage, 0, this.toastTopic + 'Host');
        }
    }


    //**
    //**
    ngOnDestroy() {
        if (this.performDiscoveryOnClose) {
            this.toastNotificationService.send("Discovery", "", "Discovery of your Salesforce environment has started and may take up to 30 seconds to complete.  This panel will refresh when discovery is complete.", 2000, this.toastTopic);
            this.sublimeService.discoveryEnqueue();
        }
        else if (this.performBootstrapOnClose) {
            this.sublimeService.broadcastReBootstrap();
        }
    }


    //**
    //**
    savePreferenceChange(remoteAction: RemoteAction) {
        this.sublimeService.apiRemoteAction(remoteAction);
        //Since at least one setting changed we need to set the flag to perform a bootstrap when the preferences are closed
        this.performBootstrapOnClose = true;
    }


    //**
    //**
    toggleOutlookButtons() { this.showOutlookButtons = !this.showOutlookButtons; }


    //***
    //***
    mouseDoubleClick(event: MouseEvent) {
        if (event && event.ctrlKey === true) {
            Utility.debug(this, `Preferences`);
            event.cancelBubble = true;
        }
    }
}
