<div>
    <snap-lp-field-label [snapFormField]="snapFormField"></snap-lp-field-label>
    <div class="container-small lp-no-gutters">
        <div class="row lp-no-gutters">
            <div class="col-6 lp-no-gutters"
                 style="padding-right: 2px;">
                <input class="lp-input-element"
                       type="date"
                       [id]="snapFormField.fieldName"
                       [name]="snapFormField.fieldName"
                       [(ngModel)]="date"
                       [disabled]="dateEnabled ? null : 'disabled'"
                       [attr.maxlength]="snapFormField.maxLength">
            </div>

            <input class="col-6 lp-no-gutters lp-input-element"
                   type="time"
                   [id]="snapFormField.fieldName"
                   [name]="snapFormField.fieldName"
                   [(ngModel)]="time"
                   [disabled]="timeEnabled ? null : 'disabled'"
                   [attr.maxlength]="snapFormField.maxLength">
        </div>
        <!--{{value}}<br />
        {{date}}<br />
        {{time}}<br />-->
    </div>
</div>
