import { IDeserializable } from "./interfaces/IDeserializable.interface";
import { IResponse } from "./interfaces/IResponse.interface";
import { ResponseBase } from "./responseBase";

export class QuickCommentResponse extends ResponseBase implements IResponse, IDeserializable {

    //***
    public success: boolean;
    public contextToken: boolean;
    public message: string;

    //***
    //***
    deserialize(input: any): this {
        Object.assign(this, input);
        return this;
    }
}
