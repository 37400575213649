import { Component, Input } from '@angular/core';
import { LPFieldType } from '../../models/enums';
import { SnapFormField } from '../../models/snapFormField';
import { SnapFormSection } from '../../models/snapFormSection';

@Component({
    selector: 'snap-lp-section-body-left-right',
    templateUrl: './lp-section-body-left-right.component.html',
    styleUrls: ['./lp-section-body-left-right.component.scss'],
})
export class LPSectionBodyLeftRightComponent {

    //***
    @Input() snapFormSection: SnapFormSection;


    //***
    //***
    private get leftFields(): SnapFormField[] {
        const result = this.getFieldsByColumn(0);
        return result;
    }


    //***
    //***
    private get rightFields(): SnapFormField[] {
        const result = this.getFieldsByColumn(1);
        return result;
    }


    //***
    //***
    private _RowFields: LPSectionRow[] = null;
    get rowFields(): LPSectionRow[] {
        if (!this._RowFields) {
            const result: LPSectionRow[] = [];
            const left: SnapFormField[] = this.leftFields;
            const right: SnapFormField[] = this.rightFields;
            const max: number = Math.max(left.length, right.length);
            for (let i = 0; i < max; i++) {
                const pair = new LPSectionRow();

                pair.leftField = i < left.length ? left[i] : this.getEmptyField();
                pair.rightField = i < right.length ? right[i] : this.getEmptyField();
                result.push(pair);
            }
            this._RowFields = result;
        }
        return this._RowFields;
    }


    //***
    //***
    private static counter = 0;
    private getEmptyField(): SnapFormField {
        const result = new SnapFormField();
        result.fieldType = LPFieldType.EmptySpace;
        result.fieldName = `Empty${LPSectionBodyLeftRightComponent.counter++}`;
        return result;
    }


    //***
    //***
    private getFieldsByColumn(ordinalColumn: number): SnapFormField[] {
        const result: SnapFormField[] = [];
        for (const field of this.snapFormSection.fields)
            if (field.ordinalColumn === ordinalColumn)
                result.push(field);
        return result;
    }
}

export class LPSectionRow {
    leftField: SnapFormField;
    rightField: SnapFormField;
}

