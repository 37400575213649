import { IDeserializable } from "./interfaces/IDeserializable.interface";

export class Attachment implements IDeserializable {
  public id: string;
  public name: string;
  public data: string;
  public fileSize = 0;

    deserialize(input: Attachment): this {
        Object.assign(this, input);
        return this;
    }

}
