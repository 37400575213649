<a class="btn-lp "
   (click)="onclick(tableName)">

    <span>
        <app-lp-svg [imagePoints]="imagePoints"
                    [imageBackgroundColor]="imageBackgroundColor"
                    [imageForegroundColor]="imageForegroundColor"
                    [lpTooltip]="'Edit Record'"></app-lp-svg>
    </span>

</a>
