<div style="width: 100%; display: grid; grid-template-columns: auto;">

    <div class="btn-group full-width"
         dropdown
         #dropdown="bs-dropdown">       

        <div *ngIf="helpVisible" class="lp-icon" dropdown>
            <a class="inline-link"
               dropdownToggle>
                <span>
                    <app-lp-svg [imagePoints]="helpImagePoints"
                                lpTooltip="Resources and Help"
                                [imageBackgroundColor]="imageBackgroundColor"
                                [imageForegroundColor]="imageForegroundColor"
                                imgeSize="21">
                    </app-lp-svg>
                </span>
            </a>
            <ul *dropdownMenu class="dropdown-menu"
                role="menu" aria-labelledby="button-basic">
                <li role="menuitem"><a class="dropdown-item" (click)="onclickTutorial()">Tutorial</a></li>
                <li role="menuitem"><a class="dropdown-item" (click)="onclickHelp()">Open Support Page</a></li>
                <li role="menuitem"><a class="dropdown-item" (click)="onclickAbout()">About Blade</a></li>
            </ul>
        </div>

        <div class="full-width">
            <span [style.color]="fontColor">&nbsp;&nbsp;{{titleDescription}}</span>
        </div>

        <div *ngIf="snapFormHeaderVisible">
            <a class="inline-link"
               routerLink=""
               dropdownToggle>
                <span>
                    <app-lp-svg [imagePoints]="imagePoints"
                                [imageBackgroundColor]="imageBackgroundColor"
                                [imageForegroundColor]="imageForegroundColor"
                                [imageSize]="imageSize"
                                lpTooltip="Create New Records"></app-lp-svg>
                </span>
            </a>
            <ul *dropdownMenu
                class="dropdown-menu noselect full-width"
                role="menu">
                <list-item *ngFor="let item of snapFormMenu">
                    <a class="dropdown-item"
                       (click)="onclick(item.tableName)"
                       title="Create New Records">
                        <img class="rounded float-left"
                             [style.background]="item.color"
                             width="21"
                             height="21"
                             [src]="item.imageUrl" />
                        &nbsp;
                        {{item.tableLabel}}
                    </a>
                </list-item>
            </ul>
        </div>

        <!--<app-time-scout *ngIf="timeScoutVisible"
                        [tableName]=""
                        class="lp-icon"
                        [imageSize]="21"
                        [imageBackgroundColor]="imageBackgroundColor"
                        [imageForegroundColor]="imageForegroundColor">
        </app-time-scout>-->

        <div *ngIf="!displayCloseButton"
             (click)="refresh()"
             role=button
             class="lp-icon">
            <app-lp-svg [imagePoints]="refreshImagePoints"
                        lpTooltip="Refresh"
                        [imageBackgroundColor]="imageBackgroundColor"
                        [imageForegroundColor]="imageForegroundColor"
                        [imageSize]="21"></app-lp-svg>
        </div>
        <div *ngIf="displayCloseButton"
             class="lp-icon">
            <button type="button"
                    class="btn-close close pull-right"
                    aria-label="Close"
                    (click)="bsModalRef.hide()">
                <img src='assets/icons/close-circle-red.png'
                     width="21" height="21"
                     style="vertical-align: initial;" />
            </button>
        </div>
    </div>

</div>
