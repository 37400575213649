import { CustomSyncField } from "./customSyncField";
import { IDeserializable } from "./interfaces/IDeserializable.interface";

export class CustomSyncFields implements IDeserializable {
    public tableName: string;
    public tableLabel: string;
    public recordTypeId: string;
    public recordTypeLabel: string;
    public exception: string;
    public fields: CustomSyncField[] = [];

    deserialize(input: CustomSyncFields): this {
        Object.assign(this, input);
        if (Array.isArray(input?.fields)) {
            this.fields = input.fields.map((field) => new CustomSyncField().deserialize(field));
        }
        return this;
    }
}
