import { IDeserializable } from "../../models/interfaces/IDeserializable.interface";
import { ResponseBase } from "../../models/responseBase";
import { SnapFormLookupTable } from "./SnapFormLookupTable";

export class LookUpSnapFormResponse extends ResponseBase implements IDeserializable {
    tables: SnapFormLookupTable[];

    //***
    //***
    deserialize(input: any): this {
        Object.assign(this, input);
        this.tables = [];
        if (Array.isArray(input.tables))
            for (const source of input.tables)
                this.tables.push(new SnapFormLookupTable().deserialize(source));
        return this;
    }
}
