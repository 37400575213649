import { Component, Input } from '@angular/core';
import { SnapFormSection } from '../../models/snapFormSection';
import { SnapFormField } from '../../models/snapFormField';

@Component({
    selector: 'snap-lp-section-body-single',
    templateUrl: './lp-section-body-single.component.html',
    styleUrls: ['./lp-section-body-single.component.scss'],
})
export class LPSectionBodySingleComponent {

    @Input() snapFormSection: SnapFormSection;


    //***
    //This layout expects all fields are only in column 0
    //***
    private _AllFields: SnapFormField[];
    get allFields(): SnapFormField[] {
        if (!this._AllFields) {
            this._AllFields = this.getFieldsByColumn(0);
        }
        return this._AllFields;
    }

    //***
    //***
    private getFieldsByColumn(ordinalColumn: number): SnapFormField[] {
        const result: SnapFormField[] = [];
        for (const field of this.snapFormSection.fields) {
            if (field.ordinalColumn === ordinalColumn)
                result.push(field);
        }
        return result;
    }
}
