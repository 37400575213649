import { LPDataType } from "../enums";
import { SnapFormField } from "../snapFormField";
import { Visitor } from "./Visitor";
import Utility from "../../../shared/utility";

export class NumberValidator extends Visitor {
    //***
    //***
    canVisit(field: SnapFormField): boolean {
        return super.commonCanVisitDataType(field, [LPDataType.Number]);
    }

    //***
    //***
    visit(field: SnapFormField, value: string) {
        if (super.commonVisit(field, value))
            if (!Utility.isNullOrEmpty(value) && !Utility.isNumber(value)) {
                field.validationMessage = "Not a valid number";
            }
    }
}
