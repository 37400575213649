<div *ngIf="hasSnapFormMenus || sublimeService.isTourMode"

     style="width:100%"
     class="float-left btn-group"
     dropdown>

    <div style="width:100%"
         id="button-custom-html"
         dropdownToggle
         aria-controls="dropdown-custom-html">
        <a>
            <span>
                <app-lp-svg [imagePoints]="imagePoints"
                            [imageBackgroundColor]="imageBackgroundColor"
                            [imageForegroundColor]="imageForegroundColor"
                            [imageSize]="imageSize"
                            lpTooltip="Create New Records"></app-lp-svg>
            </span>
        </a>
    </div>

    <ul id="dropdown-custom-html"
        *dropdownMenu
        [class]="dropdownClass"
        role="menu"
        aria-labelledby="button-custom-html">

        <li *ngFor="let item of snapFormMenu"
            role="menuitem">
            <a class="dropdown-item" (click)="onclick(item.tableName)" title="Create New Records">
                <img class="rounded float-left" [style.background]="item.color" width="20" height="20" [src]="item.imageUrl" />
                &nbsp;
                {{item.tableLabel}}
            </a>
        </li>
    </ul>

</div>
