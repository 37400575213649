// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

////Docker Compose
//export const environment = {
//    LP_Version: "0.98.8356.21587",
//    production: false,
//    API_ENDPOINT: "https://localhost:8201/",
//    SIGNALR_ENDPOINT: "https://localhost:44345/messagehub",
//    ASSIST_ENDPOINT: "https://localhost:8401/login",
//    SF_OAUTH_CID: "3MVG9A2kN3Bn17hszjJ.Fn8ZQ1hDjxJXkwhQJBuMZUECbWAlWnk8nbv7jBAMJP2MHKAMtI44R2a89fg_3a15D",
//    TimeScout_Endpoint: "https://timescout.linkpoint360.com/#/meetingtypes",
//    GRAPH_OAUTH_CID: "2d96810b-c286-41bd-be75-2e16fec892e3",
//    cookieDomain: 'localhost', // -<< must be 'localhost'
//};

//Kubernetes
export const environment = {
    LP_Version: "0.98.8356.21587",
    production: true,
    //API_ENDPOINT: "https://localhost:30101/", //moved to preflight
    //SIGNALR_ENDPOINT: "https://localhost:32345/messagehub", //removed because it is not necessary, just used a relative path instead
    //ASSIST_ENDPOINT: "https://localhost:30401/", //moved to preflight
    //SF_OAUTH_CID: "3MVG9A2kN3Bn17hszjJ.Fn8ZQ1hDjxJXkwhQJBuMZUECbWAlWnk8nbv7jBAMJP2MHKAMtI44R2a89fg_3a15D", //moved to preflight
    //TimeScout_Endpoint: "https://timescout.linkpoint360.com/#/meetingtypes", //removed for docker
    //GRAPH_OAUTH_CID: "2d96810b-c286-41bd-be75-2e16fec892e3", //move to preflight
    cookieDomain: 'localhost', // -<< must be 'localhost'
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
