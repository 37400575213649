<p>Johns Snapform touched</p>
<button class="btn btn-sm btn-default"
        (click)="callGet()">
    Call Get(Fetch)
</button>
<p *ngIf="getValue">Get Message: {{getValue}}</p>

<br />
<br />
<button class="btn btn-sm btn-default"
        (click)="callPost()">
    Call Post(Fetch)
</button>
<p *ngIf="postValue">Post Message: {{postValue}}</p>

<br />
<br />
<button class="btn btn-sm btn-default"
        (click)="callExternal()">
    Call External(Fetch)
</button>
<p *ngIf="externalValue">External Message: {{externalValue}}</p>

<br />
<br />
<p>PAGE END</p>
