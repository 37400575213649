import { animate, state, style, transition, trigger } from "@angular/animations";
import { Component, OnInit } from "@angular/core";
import { ToastNotificationService } from "../../core/services/toast-notification.service";
import { ToastNotification } from "../../models/toastNotification";

@Component({
    selector: "app-toast-notification",
    templateUrl: "./toast-notification.component.html",
    styleUrls: ["./toast-notification.component.scss"],
    animations: [
        trigger("fade", [
            state("void", style({ opacity: 0 })),
            state(
                "active",
                style({
                    opacity: 1,
                })
            ),
            state(
                "removed",
                style({
                    opacity: 0,
                })
            ),
            transition("void => active", animate("500ms ease-in")),
            transition("active => removed", animate("250ms ease-out")),
        ]),
    ],
})
export class ToastNotificationComponent implements OnInit {
    //***
    //***
    constructor(public toastNotificationService: ToastNotificationService) { }

    //***
    //***
    ngOnInit() {
        //tell the notification service how long the fadeout animation will be
        this.toastNotificationService.setAnimationTime(250);
    }

    //***
    // Called when user clicks the x on a notification
    //***
    markForRemoval(notification: ToastNotification) {
        //Set the state to trigger the fading out animation
        notification.remove();
    }
}
