import { ResponseBase } from "./responseBase";
import { IDeserializable } from "./interfaces/IDeserializable.interface";

export class AssistTokenResponse extends ResponseBase implements IDeserializable {
    public token = '';


    deserialize(input: any): this {
        Object.assign(this, input);
        super.deserialize(input);

        this.token = input.token;

        return this;
    }


}
