import { Component, Input } from '@angular/core';
import { SnapFormField } from '../../models/snapFormField';
import { SnapFormSection } from '../../models/snapFormSection';

@Component({
    selector: 'snap-lp-section-body-top-down',
    templateUrl: './lp-section-body-top-down.component.html',
    styleUrls: ['./lp-section-body-top-down.component.scss'],
})
export class LPSectionBodyTopDownComponent {

    @Input() snapFormSection: SnapFormSection;


    //***
    //***
    private _LeftFields: SnapFormField[];
    get leftFields(): SnapFormField[] {
        if (!this._LeftFields)
            this._LeftFields = this.getFieldsByColumn(0);
        return this._LeftFields;
    }

    //***
    //***
    private _RightFields: SnapFormField[];
    get rightFields(): SnapFormField[] {
        if (!this._RightFields)
            this._RightFields = this.getFieldsByColumn(1);
        return this._RightFields;
    }

    //***
    //***
    private getFieldsByColumn(ordinalColumn: number): SnapFormField[] {
        const result: SnapFormField[] = [];
        for (const field of this.snapFormSection.fields)
            if (field.ordinalColumn === ordinalColumn)
                result.push(field);
        return result;
    }
}
