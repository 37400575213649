import { IDeserializable } from "./interfaces/IDeserializable.interface";

export class SalesforceLoginRequest implements IDeserializable {
    lpUserId: string;
    salesforceAuthCode: string;
    salesforceOAuthEndPoint: string;

    //***
    //***
    constructor(lpUserId: string, sfAuthCode: string, salesforceOAuthEndPoint: string) {
        this.lpUserId = lpUserId;
        this.salesforceAuthCode = sfAuthCode;
        this.salesforceOAuthEndPoint = salesforceOAuthEndPoint;
    }


    deserialize(input: SalesforceLoginRequest): this {
        Object.assign(this, input);
        return this;
    }
}
