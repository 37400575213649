import Utility from "../../../shared/utility";
import { LPFieldType } from "../enums";
import { SnapFormField } from "../snapFormField";
import { Visitor } from "./Visitor";

export class RequiredValidator extends Visitor {
    //***
    //***
    canVisit(field: SnapFormField): boolean {
        return super.commonCanVisitField(field) && field.isRequired;
    }

    //***
    //***
    visit(field: SnapFormField, value: string) {
        if (super.commonVisit(field, value))
            if (Utility.isNullOrEmpty(value)) {
                if (field.fieldType == LPFieldType.Picklist && field.isDependentPicklist) {
                    // Dependent picklist can have their isRequired changed during input.
                    // so we have to double check if the isRequired has changed.
                    if (field.isRequired)
                        field.validationMessage = "Value is required";
                }
                else if (field.fieldType == LPFieldType.MultiPicklist && field.isDependentPicklist) {
                    // Dependent picklist can have their isRequired changed during input.
                    // so we have to double check if the isRequired has changed.
                    if (field.isRequired)
                        field.validationMessage = "Value is required";
                }
                else {
                    field.validationMessage = "Value is required";
                }
            }
    }
}
