import { Component, Input, OnInit } from '@angular/core';
import LPDateTime from '../../../shared/LPDateTime';
import Utility from '../../../shared/utility';
import { SnapConstants } from '../../models/snapConstants';
import { SnapFormField } from '../../models/snapFormField';
import { SnapFormPicklistValue } from '../../models/snapFormPicklistValue';
import { SnapFormService } from '../../services/snapform.service';

@Component({
    selector: 'snap-lp-event-reminder',
    templateUrl: './lp-event-reminder.component.html',
    styleUrls: ['./lp-event-reminder.component.scss']
})
export class LPEventReminderComponent implements OnInit {
    public offsetValues: SnapFormPicklistValue[] = [];


    //***
    //***
    @Input() snapFormField: SnapFormField;


    //***
    //***
    public get value(): string { return this.snapFormService.getValue(this.snapFormField.fieldName); }
    public set value(value: string) { this.snapFormService.setValue(this.snapFormField.fieldName, value); }


    //***
    //***
    get isVisible(): boolean { return this.snapFormService.getValue(SnapConstants.IsAllDayEvent) === SnapConstants.False; }
    set isVisible(value: boolean) { ; }


    //***
    //***
    private _startDateTime: string = undefined;
    public get startDateTime(): string {
        const result = this.snapFormService.getValue(SnapConstants.StartDateTime);
        if (this._startDateTime && result !== this._startDateTime)
            this.computeReminderDateTime();
        this._startDateTime = result;
        return result;
    }
    public set startDateTime(value: string) { ; }

    public get endDateTime(): string { return this.snapFormService.getValue("endDateTime") ?? "none"; }
    public set endDateTime(value: string) { ; }

    //***
    //***
    get isEnabled(): boolean { return this.snapFormService.getValue(SnapConstants.IsReminderSet) === SnapConstants.True; }
    set isEnabled(value: boolean) { ; }


    //***
    //***
    public get offset(): string {
        let result = this.snapFormService.getValue(SnapConstants.SubPart_Offset);
        if (!result) {
            result = this.computeOffset().toString();
            this.snapFormService.setValue(SnapConstants.SubPart_Offset, result)
        }
        return result;
    }
    public set offset(value: string) {
        this.snapFormService.setValue(SnapConstants.SubPart_Offset, value)
        this.computeReminderDateTime();
    }


    //***
    //***
    private computeOffset(): number {
        let result = 15;
        const startString = this.snapFormService.getValue(SnapConstants.StartDateTime);
        const reminderString = this.value;

        if (!Utility.isNullOrEmpty(startString) && !Utility.isNullOrEmpty(reminderString)) {
            const startDate = new Date(startString);
            const reminderDate = new Date(reminderString);
            result = Math.abs(LPDateTime.minuteDifference(startDate, reminderDate));
        }
        return result;
    }


    //***
    //***
    private busy = 0;
    private computeReminderDateTime(): void {
        if (this.busy++ === 0) {
            try {
                const startString = this.startDateTime;
                const offsetString = this.offset;
                if (!Utility.isNullOrEmpty(startString) && !Utility.isNullOrEmpty(offsetString)) {
                    const startDate = new Date(startString);
                    const offsetMins = parseInt(offsetString);
                    const reminderDate = LPDateTime.addMinutes(startDate, -offsetMins);
                    this.value = LPDateTime.formatAsDateTime(reminderDate);
                }
            }
            finally {
                this.busy = 0;
            }
        }
    }


    //***
    //***
    ngOnInit() {
        if (this.snapFormService.snapForms) {
            const pickValues = this.snapFormService.snapForms.selectedSnapForm.recordType.picklists.find(x => x.fieldName === SnapConstants.EventReminderDateTime);
            if (pickValues)
                this.offsetValues = pickValues.values;
        }
        
        this.snapFormService.fixReminderState(SnapConstants.IsAllDayEvent);
    }


    //***
    //***
    constructor(public snapFormService: SnapFormService) { }
}
