import { Component, Input } from '@angular/core';
import Utility from '../../../shared/utility';

@Component({
    selector: 'snap-lp-section-footer',
    templateUrl: './lp-section-footer.component.html',
    styleUrls: ['./lp-section-footer.component.scss'],
})
export class LPSectionFooterComponent {

    @Input() label: string;

    get isLabelVisible(): boolean { return !Utility.isNullOrEmpty(this.label); }
}
