import { Component, Input } from '@angular/core';
import { Header } from '../../models/header';

@Component({
    selector: 'app-bill-board',
    templateUrl: './bill-board.component.html',
    styleUrls: ['./bill-board.component.scss']
})
export class BillboardComponent {
    @Input() header: Header;
}
