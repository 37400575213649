import { Component, Input } from '@angular/core';
import { SnapForms } from '../../models/snapForms';


@Component({
    selector: 'snap-lp-record-type',
    templateUrl: './lp-record-type.component.html',
    styleUrls: ['./lp-record-type.component.scss']
})
export class LPRecordTypeComponent {

    //***
    @Input() snapForms: SnapForms;

    //***
    //***
    onSelectedRecordTypeChange(newValue: any): void {
        this.snapForms.selectedSnapForm = newValue;
    }
}
