<div class="float-right"
     data-flex-direction="column-reverse"
     style="min-width: 50%;">

    <div *ngFor="let notification of toastNotificationService.notifications"
         class="flex-container flex-container-style fixed-height">

        <div class="notification flex-item"
             [@fade]="notification.state">

            <div class="header-section">
                <button (click)="markForRemoval(notification)"
                        class="notify-close-button"
                        aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>

                <div class="header-content">
                    <div *ngIf="notification.header.title"
                         class="notify-title">
                        {{ notification.header.title }}
                    </div>
                    <div *ngIf="notification.header.subTitle"
                         class="notify-subtitle">
                        {{ notification.header.subTitle }}
                    </div>
                </div>
            </div>
            <!-- <hr /> -->

            <div class="body-section">
                <div *ngIf="notification.header.description"
                     class="notify-message"
                     [innerHTML]="notification.header.description">

                </div>
            </div>
        </div>
    </div>
</div>