import { CommonModule } from "@angular/common";
import { HttpClientModule } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { OfficeService } from "./services/office.service";
import { PanelService } from "./services/panelService";
import { SessionService } from "./services/session.service";
import { SignalRService } from "./services/signalr.service";
import { SublimeService } from "./services/sublime.service";
import { ToastNotificationService } from "./services/toast-notification.service";
import { MiniFormDialogService } from "./services/miniFormDialog.service";

@NgModule({
    imports: [CommonModule, HttpClientModule],
    declarations: [],
    providers: [SublimeService, OfficeService, ToastNotificationService, PanelService, SessionService, SignalRService, MiniFormDialogService],
    exports: [],
})
export class CoreModule { }
