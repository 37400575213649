import { Deck } from "./deck";
import { DeckType, PaneType } from "./enums";
import { Header } from "./header";
import { IDeserializable } from "./interfaces/IDeserializable.interface";
import { PaneState } from "./paneState";


export class Pane implements IDeserializable {
    public paneType: PaneType;
    public billboard: Header;
    public decks: Deck[] = [];


    //***
    //***
    constructor(paneType: PaneType, decks: Deck[]) {
        this.paneType = paneType;
        for (const deck of decks) {
            this.setDeck(deck);
        };
    }

    deserialize(input: Pane): this {
        Object.assign(this, input);

        if (input?.billboard)
            this.billboard = new Header().deserialize(input.billboard);
        if (Array.isArray(input?.decks)) {
            this.decks = input.decks.map((deck) => new Deck().deserialize(deck));
        }
        return this;
    }


    //***
    //***
    public getDeck(deckType: DeckType) { return this.decks.find(x => x.deckType === deckType); }


    //***
    //***
    public setDeck(deck: Deck) {
        if (deck) {
            const old = this.getDeck(deck.deckType);
            if (old)
                this.decks.splice(this.decks.indexOf(old), 1);
            this.decks.push(deck);
        }
    }


    //***
    //***
    public getState(): PaneState {
        const result = new PaneState();
        result.paneType = this.paneType;
        result.billboard = this.billboard;
        for (const deck of this.decks) {
            result.deckStates.push(deck.getState());
        }
        return result;
    }


    //***
    //***
    public setState(state: PaneState) {
        if (state && this.paneType === state.paneType) {
            this.billboard = state.billboard;
            for (const deck of this.decks)
                deck.isVisible = false;

            for (const deckState of state.deckStates) {
                const target = this.getDeck(deckState.deckType);
                if (target) {
                    target.setState(deckState);
                }
            }
        }
    }
}
