import { PouchClass } from "./enums";
import { IDeserializable } from "./interfaces/IDeserializable.interface";
import { IPouch } from "./interfaces/IPouch.interface";
import { PouchBase } from "./pouchBase";

export class EmptyPouch extends PouchBase implements IPouch, IDeserializable {
    //***
    constructor() {
        super(PouchClass.Undefined);
    }

    //***
    //***
    deserialize(input: EmptyPouch): this {
        Object.assign(this, input);
        super.deserialize(input);
        return this;
    }


    //***
    public get searchHints(): string[] {
        const result = [];
        return result;
    }

    //***
    public get naturalKey(): string {
        return "Empty";
    }
}
