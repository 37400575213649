import { IVisitor } from "./IVisitor";
import { LPDataType, LPEntryMode, LPFieldType } from "../enums";
import { SnapFormField } from "../snapFormField";
import Utility from "../../../shared/utility";

export abstract class Visitor implements IVisitor {

    abstract canVisit(field: SnapFormField): boolean;
    abstract visit(field: SnapFormField, value: string);

    //***
    //***
    protected commonCanVisitField(field: SnapFormField): boolean {
        let result = false;
        if (field)
            if (field.isEditableForNew && field.entryMode === LPEntryMode.Create || field.isEditableForUpdate && field.entryMode === LPEntryMode.Update)
                result = true;
        return result;
    }

    //***
    //***
    protected commonCanVisitFieldType(field: SnapFormField, fieldTypes: LPFieldType[]): boolean {
        let result = false;
        if (this.commonCanVisitField(field))
            if (fieldTypes.indexOf(field.fieldType) > -1)
                result = true;
        return result;
    }

    //***
    //***
    protected commonCanVisitDataType(field: SnapFormField, dataTypes: LPDataType[]): boolean {
        let result = false;
        if (this.commonCanVisitField(field))
            if (dataTypes.indexOf(field.dataType) > -1)
                result = true;
        return result;
    }

    //***
    //***
    protected commonVisit(field: SnapFormField, value: string): boolean {
        if (field.isEnabled || Utility.isNullOrEmpty(field.validationMessage))
            return true;
        else
            return false;
    }
}
