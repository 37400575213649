import Utility from "./utility";

export default class LPDateTime {
    public static isDate(value: string): boolean { return !isNaN(new Date(value).getTime()); }
    public static toDate(value: string): Date {
        const result = new Date(value);
        if (isNaN(result.getTime()))
            return null;
        else
            return result;
    }

    public static formatAsDateTime(value: Date): string {
        if (!this.isNullOrEmpty(value))
            return `${value.getFullYear().toString().padStart(4, "0")}-${(value.getMonth() + 1).toString().padStart(2, "0")}-${value.getDate().toString().padStart(2, "0")} ${value.getHours().toString().padStart(2, "0")}:${value.getMinutes().toString().padStart(2, "0")}`;
        else
            return null;
    }

    public static formatAsDate(value: Date): string {
        if (!this.isNullOrEmpty(value))
            return `${value.getFullYear().toString().padStart(4, "0")}-${(value.getMonth() + 1).toString().padStart(2, "0")}-${value.getDate().toString().padStart(2, "0")}`;
        else
            return null;
    }


    public static formatAsDateHour(value: Date): string {
        if (!this.isNullOrEmpty(value))
            return `${value.getFullYear().toString().padStart(4, "0")}-${(value.getMonth() + 1).toString().padStart(2, "0")}-${value.getDate().toString().padStart(2, "0")}.${value.getHours().toString()}`;
        else
            return null;
    }


    public static formatAsTime(value: Date): string {
        if (!this.isNullOrEmpty(value))
            return `${value.getHours().toString().padStart(2, "0")}:${value.getMinutes().toString().padStart(2, "0")}`;
        else
            return null;
    }

    public static getDatePart(value: string): string[] {
        const result: string[] = [];
        if (!Utility.isNullOrEmpty(value)) {
            const parts = value.trim().split("-");
            if (parts.length > 2) {
                const year = parseInt(parts[0]);
                const month = parseInt(parts[1]);
                const day = parseInt(parts[2]);
                if (year > 1980 && year < 9999 && month >= 1 && month <= 12 && day >= 1 && day <= 31) {
                    result.push(year.toString());
                    result.push(month.toString().padStart(2, "0"));
                    result.push(day.toString().padStart(2, "0"));
                }
            }
        }
        return result;
    }


    //***
    //***
    public static getDateStringParts(value: string): string[] {
        const result: string[] = [];
        if (!Utility.isNullOrEmpty(value)) {
            const parts = value.trim().split("-");
            if (parts.length > 2) {
                const year = parseInt(parts[0]);
                const month = parseInt(parts[1]);
                const day = parseInt(parts[2]);
                if (year > 1980 && year < 9999 && month >= 1 && month <= 12 && day >= 1 && day <= 31) {
                    result.push(year.toString());
                    result.push(month.toString().padStart(2, "0"));
                    result.push(day.toString().padStart(2, "0"));
                }
            }
        }
        return result;
    }


    //***
    //***
    public static getTimeStringParts(value: string): string[] {
        const result: string[] = [];
        if (!Utility.isNullOrEmpty(value)) {
            const parts = value.trim().split(":");
            if (parts.length > 1) {
                const hour = parseInt(parts[0]);
                const mins = parseInt(parts[1]);
                if (hour >= 0 && hour <= 23 && mins >= 0 && mins <= 59) {
                    result.push(hour.toString().padStart(2, "0"));
                    result.push(mins.toString().padStart(2, "0"));
                }
            }
        }
        return result;
    }


    //***
    //***
    public static getDateTimeStringParts(value: string): string[] {
        const result: string[] = [];
        if (value && value.trim().length > 0) {
            let parts = value.trim().split(" ");
            //if (parts.length === 1)
            //    parts = value.trim().split("T");
            if (parts.length > 1) {
                const dateParts = this.getDateStringParts(parts[0]);
                const timeParts = this.getTimeStringParts(parts[1]);
                if (dateParts.length === 3 && timeParts.length === 2) {
                    dateParts.forEach(x => result.push(x));
                    timeParts.forEach(x => result.push(x));
                }
            }
        }
        return result;
    }


    //***
    //***
    public static isDateString(value: string) {
        this.getDateStringParts(value).length
        return this.getDateStringParts(value).length === 3;
    }

    //***
    //***
    public static isTimeString(value: string) { return this.getTimeStringParts(value).length === 2; }

    //***
    //***
    public static isDateTimeString(value: string) { return this.getDateTimeStringParts(value).length === 5; }

    //***
    //***
    public static addMinutes(value: Date, minutes: number): Date {
        let result = null;
        if (!this.isNullOrEmpty(value))
            result = new Date(value.getTime() + (minutes ?? 0) * 60000);
        return result;
    }

    //***
    //***
    public static toDateString(value: string[]): string {
        let result = "";
        if (value.length === 3) {
            result = `${value[0].trim().padStart(4, "0")}-${value[1].trim().padStart(2, "0")}-${value[2].trim().padStart(2, "0")}`;
            if (!this.isDateString(result))
                result = "";
        }
        return result;
    }

    //***
    //***
    public static toTimeString(value: string[]): string {
        let result = "";
        if (value && value.length > 1) {
            result = `${value[0].trim().padStart(2, "0")}:${value[1].trim().padStart(2, "0")}`;
            if (!this.isTimeString(result))
                result = "";
        }
        return result;
    }

    //***
    //***
    public static toDateTimeString(value: string[] | Date): string {
        let result = "";

        if (value instanceof Date)
            result = this.dateToDateTimeString(value as Date)
        else
            result = this.arrayToDateTimeString(value as string[])

        if (!this.isDateTimeString(result))
            result = "";
        return result;
    }
    private static arrayToDateTimeString(value: string[]): string {
        let result = "";
        if (value && value.length > 4)
            result = `${this.toDateString([value[0], value[1], value[2]])} ${this.toTimeString([value[3], value[4]])}`;
        return result;
    }
    private static dateToDateTimeString(value: Date): string {
        let result = "";
        if (!this.isNullOrEmpty(value))
            result = this.toDateTimeString([value.getFullYear().toString(), (value.getMonth() + 1).toString(), value.getDate().toString(), value.getHours().toString(), value.getMinutes().toString()]);
        return result;
    }


    //***
    //***
    public static toDateTime(value: string[]): Date {
        let result: Date = null;
        if (value && value.length > 4) {
            result = new Date(parseInt(value[0]), parseInt(value[1]) - 1, parseInt(value[2]), parseInt(value[3]), parseInt(value[4]));
            if (isNaN(result.getTime()))
                result = null;
        }
        return result;
    }


    //***
    //***
    public static millisecondDifference(earlier: Date, later: Date): number { return this.isNullOrEmpty(earlier) || this.isNullOrEmpty(later) ? null : later.getTime() - earlier.getTime(); }
    public static secondDifference(earlier: Date, later: Date): number { const result: number = this.millisecondDifference(earlier, later); return result === null ? null : result / 1000; }
    public static minuteDifference(earlier: Date, later: Date): number { const result: number = this.secondDifference(earlier, later); return result === null ? null : result / 60; }
    public static hourDifference(earlier: Date, later: Date): number {
        const result: number = this.minuteDifference(earlier, later);
        return result === null ? null : result / 60;
    }
    public static dayDifference(earlier: Date, later: Date): number {
        const result: number = this.hourDifference(earlier, later);
        return result === null ? null : result / 24;
    }


    //***
    //***
    public static isNullOrEmpty(value: Date): boolean {
        if (!value)
            return true;
        else if (value === null)
            return true;
        else if (isNaN(value.getTime()))
            return true;
        else
            return false;
    }


    //***
    //***
    public static isTime(value: string): boolean {
        if (!value)
            return false;
        else if (value === null)
            return false;
        else if (value.trim().length === 0)
            return false;
        else
            return !isNaN(Date.parse(`2000-01-01 ${value}`));
    }
}
