import { Attachment } from "./attachment";
import { DeckState } from "./deckState";
import { EmailPouch } from "./emailPouch";
import { PouchClass, SearchType } from "./enums";
import { IDeserializable } from "./interfaces/IDeserializable.interface";
import { IRequest } from "./interfaces/IRequest.interface";
import { ReferencePacket } from "./referencePacket";



export class SearchRequest implements IRequest, IDeserializable {
    public searchType: SearchType;
    public searchHints: string[];
    public lpUserId: string;
    public contextToken: string;
    public conversationId: string;
    public referencePacket: ReferencePacket;
    public pouchClass: PouchClass;
    public selectDeckState: DeckState;
    public attachments: Attachment[];
    public emailPouch: EmailPouch | undefined;


    //***
    //***
    constructor(lpUserId: string, contextToken: string, conversationId: string, searchType: SearchType, referencePacket: ReferencePacket, pouchClass: PouchClass, attachments: Attachment[], searchHints: string[], selectDeckState: DeckState, emailPouch: EmailPouch | undefined) {
        this.lpUserId = lpUserId;
        this.contextToken = contextToken;
        this.conversationId = conversationId;
        this.searchType = searchType;
        this.referencePacket = referencePacket;
        this.pouchClass = pouchClass;
        this.attachments = attachments;
        this.searchHints = searchHints;
        this.selectDeckState = selectDeckState;
        this.emailPouch = emailPouch;
    }


    //***
    //***
    deserialize(input: SearchRequest): this {
        Object.assign(this, input);
        this.referencePacket = new ReferencePacket().deserialize(input.referencePacket);
        this.selectDeckState = new DeckState().deserialize(input.selectDeckState);
        this.emailPouch = new EmailPouch().deserialize(input.emailPouch as EmailPouch);
        if (input.attachments) {
            this.attachments = input.attachments.map((attachment) => new Attachment().deserialize(attachment));
        }
        return this;
    }
}
