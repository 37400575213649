import { IDeserializable } from "../../models/interfaces/IDeserializable.interface";
import { LPEntryMode, LPFormMode } from "./enums";
import { SnapForm } from "./snapForm";
import { SnapFormRecordType } from "./snapFormRecordType";
import { SnapFormValue } from "./snapFormValue";

export class SnapForms implements IDeserializable {
    //***
    tableName = "";
    id: string;
    tableLabel = "";
    tableLabelPlural = "";
    imageUrl = "";
    editUrl = "";
    openAfterCreate = false;
    color = "";
    isRecordTypeSelectorRequired: boolean;
    isCreatable = false;
    isUpdateable = false;
    hasMini: boolean;
    formMode: LPFormMode = LPFormMode.Undefined;


    private _selectedSnapForm: SnapForm;
    public get selectedSnapForm(): SnapForm { return this._selectedSnapForm; }
    public set selectedSnapForm(value: SnapForm) {
        if (value !== this._selectedSnapForm) {
            this._selectedSnapForm = value;
            this.fixDependencyState();
        }
    }


    //***
    //***
    private _forms: SnapForm[] = [];
    public get forms(): SnapForm[] { return this._forms ?? (this._forms = []); }
    public set forms(value: SnapForm[]) { this._forms = value; }

    //***
    //***
    private _recordTypes: SnapFormRecordType[] = [];
    public get recordTypes(): SnapFormRecordType[] { return this._recordTypes ?? (this._recordTypes = []); }
    public set recordTypes(value: SnapFormRecordType[]) { this._recordTypes = value; }


    //***
    //***
    private _entryMode: LPEntryMode;
    public get entryMode(): LPEntryMode {
        return this._entryMode;
    }
    public set entryMode(value: LPEntryMode) {
        this._entryMode = value;
        for (const item of this.forms)
            item.entryMode = value;
    }

    //***
    //***
    private _fieldValues: SnapFormValue[] = [];
    public get fieldValues(): SnapFormValue[] { return this._fieldValues ?? (this._fieldValues = []); }
    public set fieldValues(value: SnapFormValue[]) { this._fieldValues = value; }


    //***
    //***
    private _injectedFields: { key: string, value: string }[] = [];
    public get injectedFields(): { key: string, value: string }[] { return this._injectedFields ?? (this._injectedFields = []); }
    public set injectedFields(value: { key: string, value: string }[]) { this._injectedFields = value; }


    //***
    //***
    public fixDependencyState() {
        if (this.selectedSnapForm)
            this.selectedSnapForm.fixDependencyState(this.fieldValues);
    }



    //***
    //***
    deserialize(input: any): this {
        Object.assign(this, input);

        this.forms = [];
        if (Array.isArray(input.forms)) {
            for (const source of input.forms)
                this.forms.push(new SnapForm().deserialize(source));
        }

        this.recordTypes = [];
        if (Array.isArray(input.recordTypes)) {
            for (const source of input.recordTypes)
                this.recordTypes.push(new SnapFormRecordType().deserialize(source));
        }


        this._fieldValues = [];
        if (input.fieldValues) {
            for (const source of input.fieldValues)
                this.fieldValues.push(new SnapFormValue().deserialize(source));
        }

        this._injectedFields = [];
        if (Array.isArray(input.injectedFields)) {
            for (const source of input.injectedFields)
                this.injectedFields.push({ key: source.key, value: source.value });
        }

        return this;
    }
}
