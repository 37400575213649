import { IDeserializable } from "../../models/interfaces/IDeserializable.interface";

export class SnapFormPicklistValue implements IDeserializable {

    //***
    label: string;
    //***
    value: string;


    //***
    //***
    private _isDefaultValue: boolean;
    public get isDefaultValue(): boolean {
        if (!this._isDefaultValue)
            this._isDefaultValue = false;
        return this._isDefaultValue;
    }
    public set isDefaultValue(value: boolean) { this._isDefaultValue = value; }

    //***
    //***
    private _isActive: boolean;
    public get isActive(): boolean {
        if (!this._isActive)
            this._isActive = false;
        return this._isActive;
    }
    public set isActive(value: boolean) { this._isActive = value; }

    //***
    private _validForIndex: Number[] = [];
    public get validForIndex(): Number[] {
        if (!this._validForIndex)
            this._validForIndex = [];
        return this._validForIndex;
    }
    public set validForIndex(value: Number[]) { this._validForIndex = value; }


    deserialize(input: any): this {
        Object.assign(this, input);
        return this;
    }

    //***
    public static Create(value: string, isActive: boolean, isDefaultValue: boolean, label: string, validForIndex: number[]): SnapFormPicklistValue {
        const result = new SnapFormPicklistValue();
        result.value = value
        result.isActive = false;
        result.isDefaultValue = true;
        result.label = label;
        result.validForIndex = validForIndex;
        return result;
    }
}
