<div (dblclick)="mouseDoubleClick($event)">

    <ng-container *ngIf="displayGroups"
                  style="overflow-y: auto">
        <app-lp-group *ngFor="let group of deck.groups; first as isFirst"
                      [isFirst]="isFirst"
                      [group]="group"
                      [displayTableLabel]="displayTableLabel"
                      [displayCardCells]="displayCardCells"
                      [headerBackgroundColor]="groupHeaderBackgroundColor"
                      [headerForegroundColor]="groupHeaderForegroundColor"></app-lp-group>
    </ng-container>

    <ng-container *ngIf="!displayGroups"
                  style="overflow-y: auto">
        <app-lp-card *ngFor="let card of deck.cards"
                     [card]="card"
                     [displayCardCells]="displayCardCells"
                     [displayTableLabel]="displayTableLabel"
                     [displaySidekick]="displaySidekick"></app-lp-card>
    </ng-container>
</div>
