import { ElementRef, Injectable, Renderer2, RendererFactory2 } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class HighlighterService {
    private renderer: Renderer2;
    private className = "highlighting";


    //***
    //***
    constructor(rendererFactory: RendererFactory2) {
        this.renderer = rendererFactory.createRenderer(null, null);
    }


    //***
    //***
    highlightElement(element: ElementRef) {
        if (element) {
            this.renderer.addClass(element.nativeElement, this.className);
            setTimeout(function () { this.renderer.removeClass(element.nativeElement, this.className); }.bind(this), 750)
        }
    }
}
