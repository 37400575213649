<div *ngIf="snapForms"
     class="lp-container-fixed"
     [ngClass]="this.sessionService.isMAC ? 'lp-mac-container-padding': ''"
     (dblclick)="mouseDoubleClick($event)">

    <!--  Header  Header  Header  Header-->
    <!--  Header  Header  Header  Header-->
    <!--  -->
    <snap-lp-forms-header [title]="title"
                          [subTitle]=""
                          [imageUrl]="snapForms.imageUrl"
                          [imageBackgroundColor]="snapForms.color"
                          [editUrl]="snapForms.editUrl"></snap-lp-forms-header>


    <!--  RecordType  RecordType  RecordType  RecordType-->
    <!--  RecordType  RecordType  RecordType  RecordType-->
    <!--  -->
    <snap-lp-record-type [snapForms]="snapForms"
                         *ngIf="isRecordTypeVisible"></snap-lp-record-type>

    <!--  Form  Form  Form  Form  Form-->
    <!--  Form  Form  Form  Form  Form-->
    <!--  -->
    <div *ngIf="isFormModeSignature" class="lp-container-signature">
        <div class="lp-no-gutters">
            <app-lp-signature-section [signatureFields]="signatureFields"></app-lp-signature-section>
        </div>

        <div class="lp-container-scroll lp-no-gutters">
            <snap-lp-form [snapForm]="snapForms.selectedSnapForm"></snap-lp-form>
        </div>

    </div>

    <!--  Form  Form  Form  Form  Form-->
    <div *ngIf="!isFormModeSignature" class="lp-container-scroll" [ngClass]="this.sessionService.isMAC ? 'lp-mac-panel-height': ''">
        <snap-lp-form [snapForm]="snapForms.selectedSnapForm">
        </snap-lp-form>
    </div>



</div>
<div class="toast-footer">
    <app-toast-notification *ngIf="!this.sessionService.isMAC"></app-toast-notification>
</div>
