<div>
    <snap-lp-field-label [snapFormField]="snapFormField"></snap-lp-field-label>
    <div>
        <div class="container">
            <div class="row align-items-center">

                <div class="col select-list">
                    <select class="form-control lp-input-element"
                            multiple
                            [disabled]="!snapFormField.isEnabled ? 'disabled': null"
                            [(ngModel)]="leftLitValues">
                        <option *ngFor="let item of leftValues"
                                (dblclick)="chooseItems()"
                                [value]="item">
                            {{item.label}}
                        </option>
                    </select>
                </div>

                <div class="col-auto lp-no-gutters">
                    <button type="button" class="btn"
                            (click)="chooseItems()"
                            [disabled]="!moveRightEnabled ? 'disabled': null">
                        <app-lp-svg [imagePoints]="rightImagePoints"
                                    lpTooltip="Add"
                                    [imageSize]="25"></app-lp-svg>
                    </button>
                    <br />
                    <button type="button"
                            class="btn"
                            (click)="removeItems()"
                            [disabled]="!moveLeftEnabled ? 'disabled': null">
                        <app-lp-svg [imagePoints]="leftImagePoints"
                                    lpTooltip="Remove"
                                    [imageSize]="25"></app-lp-svg>
                    </button>
                </div>

                <div class="col select-list">
                    <select class="form-control lp-input-element"
                            multiple
                            [disabled]="!snapFormField.isEnabled ? 'disabled': null"
                            [(ngModel)]="rightLitValues"
                            [id]="snapFormField.fieldName"
                            [name]="snapFormField.fieldName">
                        <option *ngFor="let item of rightValues"
                                (dblclick)="removeItems()"
                                [value]="item">
                            {{item.label}}
                        </option>
                    </select>
                </div>

            </div>
        </div>
        <div *ngIf="dependencyCommentVisible"
             class="lp-dependency-message">
            {{snapFormField.dependencyComment}}
        </div>
    </div>
</div>
