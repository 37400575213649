import { IDeserializable } from "./interfaces/IDeserializable.interface";

export class SnapFormHeader implements IDeserializable {
    tableName: string;
    tableLabel: string;
    tableLabelPlural: string;
    color: string;
    imageUrl: string;
    isCreateable: boolean;
    isUpdateable: boolean;
    hasMini: boolean;
    joinFieldName: string;


    //***
    //***
    private _items: SnapFormHeader[] = [];
    public get items(): SnapFormHeader[] {
        if (!this._items)
            this._items = [];
        return this._items;
    }
    public set items(value: SnapFormHeader[]) { this._items = value; }


    deserialize(input: SnapFormHeader): this {
        Object.assign(this, input);

        if (Array.isArray(input?.items)) {
            this.items = input.items.map((item) => new SnapFormHeader().deserialize(item));
        }
        return this;
    }
}
