import { Component, Input } from '@angular/core';
import { SnapFormField } from '../../models/snapFormField';
import { SnapFormService } from '../../services/snapform.service';

@Component({
    selector: 'snap-lp-read-only',
    templateUrl: './lp-read-only.component.html',
    styleUrls: ['./lp-read-only.component.scss']
})
export class LPReadOnlyComponent {

    //***
    @Input() snapFormField: SnapFormField;


    //***
    //***
    get value(): string { return this.snapFormService.getValue(this.snapFormField.fieldName); }
    set value(value: string) { ; }


    //***
    //***
    constructor(private snapFormService: SnapFormService) { }
}
