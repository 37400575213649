import { IDeserializable } from "../../models/interfaces/IDeserializable.interface";

export class SnapFormValue implements IDeserializable {

    public fieldName: string;
    public displayValue: string;
    public value: string;
    public priorValue: string;

    //***
    //***
    constructor(fieldName: string = null, value: string = null, displayValue: string = null) {
        this.fieldName = fieldName;
        this.value = value;
        this.displayValue = displayValue;
    }


    //***
    //***
    deserialize(input: any): this {
        Object.assign(this, input);
        return this;
    }
}
