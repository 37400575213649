
//***
//***
export enum LPEntryMode {
    Undefined = "Undefined",
    Create = "Create",
    Update = "Update",
}

//***
//***
export enum LPFormMode {
    Undefined = "Undefined",
    Full = "Full",
    Mini = "Mini",
    Signature = "Signature",
}

//***
//***
export enum LPTabOrder {
    LeftToRight = "LeftToRight",
    TopToBottom = "TopToBottom",
    Single = "Single"
}

export enum LPDataType {
    Undefined = "Undefined",
    String = "String",
    Number = "Number",
    Boolean = "Boolean",
    DateTime = "DateTime",
}


//***
//***
export enum LPFieldType {
    Undefined = "Undefined",
    AutoNumber = "AutoNumber",
    Boolean = "Boolean",
    Combobox = "Combobox",
    Currency = "Currency",
    Date = "Date",
    DateTime = "DateTime",
    Double = "Double",
    Email = "Email",
    EmptySpace = "EmptySpace",
    Html = "Html",
    Id = "Id",
    Int = "Int",
    Json = "Json",
    Long = "Long",
    MultiPicklist = "MultiPicklist",
    Percent = "Percent",
    Phone = "Phone",
    Picklist = "Picklist",
    Reference = "Reference",
    String = "String",
    TextArea = "TextArea",
    Time = "Time",
    Url = "Url",
    TaskReminderDateTime = "TaskReminderDateTime",
    EventReminderDateTime = "EventReminderDateTime",
    Label = "Label"
}


export enum ReminderMode {
    Event = "Event",
    Task = "Task"
}
