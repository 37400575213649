import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SessionService } from '../../../core/services/session.service';
import { LPFormMode } from '../../models/enums';
import { SignatureField } from '../../models/signatureField';
import { SnapFormField } from '../../models/snapFormField';
import { SnapForms } from '../../models/snapForms';
import { MacHandlerSnapFormService } from '../../services/mac-handler-snapform.service';
import { SnapFormService } from '../../services/snapform.service';
import { PreflightResponse } from '../../../models/preflightResponse';

@Component({
    selector: 'lp-snap-form',
    templateUrl: './lp-snap-form.component.html',
    styleUrls: ['./lp-snap-form.component.scss']
})
export class SnapFormComponent implements OnInit {
    tableName = "";
    id = "";
    formMode: LPFormMode = LPFormMode.Undefined;
    snapForms: SnapForms;
    signatureFields: SignatureField[] = [];

    //For some unknown reason this is necessary to fix a build error
    readonly keyImagePoints: string = '';
    readonly cartImagePoints: string = '';
    readonly chatImagePoints: string = '';
    readonly phoneImagePoints: string = '';

    constructor(private route: ActivatedRoute, private router: Router, public snapFormService: SnapFormService, private sessionService: SessionService, private macHandler: MacHandlerSnapFormService) {
    }


    ngOnInit(): void {
        //Collect values based on entrypoint
        if (this.sessionService.isMAC) {
            this.tableName = this.macHandler.tableName;
            this.formMode = this.macHandler.formMode;
            this.id = this.macHandler.id;
        }
        else {
            this.route.params.subscribe((params) => {
                this.sessionService.lpUserId = this.route.snapshot.params.userId;
                this.tableName = this.route.snapshot.params.tableName;
                this.formMode = this.route.snapshot.params.formMode;
                this.id = this.route.snapshot.params.id;
            });
        }

        //Perform preflight to get the correct urls for the other servers
        this.sessionService.preflightCall().then((response: PreflightResponse) => {
            if (response) {
                this.sessionService.API_ENDPOINT = response.apiEndpoint;
                this.sessionService.ASSIST_ENDPOINT = response.assistEndpoint;
                this.sessionService.SF_OAUTH_CID = response.sfOauthCid;
                this.sessionService.GRAPH_OAUTH_CID = response.graphOauthCid;

                this.snapFormService.getSnapForms(this.tableName, this.formMode, this.id);

                this.snapFormService.snapFormsSubject.subscribe(resp => {
                    if (resp) {
                        this.snapForms = resp as SnapForms;
                        if (this.formMode === LPFormMode.Signature) {
                            this.snapForms.injectedFields.forEach(x => {
                                const snapFormField: SnapFormField = this.snapForms.selectedSnapForm.findField(x.key);
                                if (snapFormField && x.value)
                                    this.signatureFields.push(new SignatureField(x.key, x.value, true));
                                else
                                    this.signatureFields.push(new SignatureField(x.key, x.value, false));
                            });
                        }
                    }
                });
            }
        });        
    }
}
