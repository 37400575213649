import { IDeserializable } from "../../models/interfaces/IDeserializable.interface";

export class SnapFormPicklistControllerValue implements IDeserializable {
    value: string;
    index: number;

    //***
    //***
    deserialize(input: any): this {
        Object.assign(this, input);
        return this;
    }
}
