<div class="modal-header">
    <h4 class="modal-title pull-left pl-2">License Expired</h4>
    <!--<button type="button"
            class="btn-close close pull-right"
            aria-label="Close"
            (click)="onClose()">
        <img src='assets/icons/close-circle-red.png'
             width="21"
             height="21"
             style="vertical-align: initial;"/>
    </button>-->
</div>


<div class="lp-container">
    <div class="p-3 text-center">We apologize for the inconvenience. Your license period has expired.</div>
    <br />
    <div class="container">
        <!--<div class="row align-items-center">
        <div class="col-2">
            <app-lp-svg [imagePoints]="keyImagePoints"
                        [imageSize]="36"
                        [lpTooltip]="'Apply License Key'"></app-lp-svg>
        </div>
        <div class="col-10 lp-link">
            Click to apply your license key if you have purchased.
        </div>
    </div>
    <hr />

    <div class="row align-items-center">
        <div class="col-2">
            <app-lp-svg [imagePoints]="cartImagePoints"
                        [imageSize]="36"
                        [lpTooltip]="'Purchase License Key'"></app-lp-svg>
        </div>
        <div class="col-10 lp-link">
            Ready to purchase LinkPoint Blade? Click to get started.
        </div>
    </div>
    <hr />-->

        <div class="row align-items-center">
            <div class="col-2">
                <app-lp-svg [imagePoints]="chatImagePoints"
                            [imageSize]="36"
                            [lpTooltip]="'Chat with Support'"></app-lp-svg>
            </div>
            <div class="col-10 lp-link">
                <a href="http://messenger.providesupport.com/messenger/linkpoint360.html"
                   target="_blank">Click here to chat with LinkPoint Sales and Support.</a>
            </div>
        </div>
        <hr />

        <div class="row align-items-center">
            <div class="col-2">
                <app-lp-svg [imagePoints]="phoneImagePoints"
                            [imageSize]="36"
                            [lpTooltip]="'Call Support'"></app-lp-svg>
            </div>
            <div class="col-10 lp-link">
                Call +1.732.212.8403 to speak with a LinkPoint360 representative.
            </div>
        </div>
        <hr />
    </div>

</div>
<div class="modal-footer"></div>
