import { Component, Input } from '@angular/core';
import { SublimeService } from '../../core/services/sublime.service';
import { Group } from '../../models/group';
import Utility from '../../shared/utility';

@Component({
    selector: 'app-lp-group',
    templateUrl: './lp-group.component.html',
    styleUrls: ['./lp-group.component.scss']
})
export class LPGroupComponent {
    public readonly expanderImageOpenPoints: string = Utility.svgChevronDown;
    public readonly expanderImageClosePoints: string = Utility.svgChevronUp;
    public readonly expanderImageBackground: string = Utility.LPTransparent;
    public readonly expanderImageForeground: string = Utility.LPBlue;
    //***
    @Input() group: Group;
    @Input() displayTableLabel: boolean;
    @Input() displayCardCells: boolean;
    @Input() isFirst: boolean; //Used to mark the first group in a deck
    //***
    @Input() headerBackgroundColor: string;
    @Input() headerForegroundColor: string;
    //***
    headerRoundedBottom: boolean;


    //***
    //***
    constructor(private sublimeService: SublimeService) {
        this.group = null;
        this.displayTableLabel = false;
        this.displayCardCells = true;
    }


    //***
    //***
    get title(): string { return `${this.group.header.title} (${this.group.cards.length})` }


    //***
    //***
    get isExpanded(): boolean { return this.group.isExpanded || (this.sublimeService.isTourMode && this.isFirst); }
    set isExpanded(value: boolean) { this.group.isExpanded = value; }


    //***
    //***
    onclick() { this.isExpanded = !this.isExpanded; }


    //***
    //***
    onDoubleClick(event: MouseEvent) {
        if (event && event.ctrlKey === true && this.group) {
            Utility.debug(this, `Group: ${this.group.header.title}`);
            event.cancelBubble = true;
        }
        else {
            this.onclick();
        }
    }
}
