import Utility from "../shared/utility";
import { ImageLink } from "./imageLink";
import { IDeserializable } from "./interfaces/IDeserializable.interface";


export class Header implements IDeserializable {
    public title: string;
    public subTitle: string;
    public subTitleLabel: string;
    public description: string;


    //***
    //***
    private _image: ImageLink;
    public get image(): ImageLink { return this._image ?? new ImageLink() }
    public set image(value: ImageLink) { this._image = value; }


    //***
    //***
    private _subTitleLabelVisible: boolean;
    public get subTitleLabelVisible(): boolean { return !Utility.isNullOrEmpty(this.subTitleLabel) && (this._subTitleLabelVisible ?? false); }
    public set subTitleLabelVisible(value: boolean) { this._subTitleLabelVisible = value; }


    //***
    //***
    private _isVisible: boolean;
    public get isVisible(): boolean { return this._isVisible ?? true; }
    public set isVisible(value: boolean) { this._isVisible = value; }


    //**
    //**
    constructor(
        image: ImageLink = new ImageLink(),
        title: string = '',
        subTitle: string = '',
        description: string = '',
        isVisible: boolean = false
    ) {
        this.image = image;
        this.title = title;
        this.subTitle = subTitle;
        this.description = description;
        this.isVisible = isVisible;
        this.subTitleLabel = "";
        this.subTitleLabelVisible = false;
    }

    deserialize(input: Header): this {
        if (input.title)
            this.title = input.title;

        if (input.subTitle)
            this.subTitle = input.subTitle;

        if (input.description)
            this.description = input.description;

        if (input.isVisible)
            this.isVisible = input.isVisible;

        if (input.subTitleLabel)
            this.subTitleLabel = input.subTitleLabel;

        if (input.subTitleLabelVisible)
            this.subTitleLabelVisible = input.subTitleLabelVisible;

        this.image = new ImageLink().deserialize(input.image);
        return this;
    }

    //**
    //**
    clear() {
        this.image = null;
        this.title = null;
        this.subTitle = null;
        this.description = null;
        this.isVisible = false;
    }

    //***
    public get imageVisible(): boolean { return this.image && this.image.isVisible; }
    public get titleVisible(): boolean { return !Utility.isNullOrEmpty(this.title); }
    public get isSubTitleVisible(): boolean { return !Utility.isNullOrEmpty(this.subTitle); }
    public get descriptionVisible(): boolean { return !Utility.isNullOrEmpty(this.description); }
}
