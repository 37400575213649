import { IDeserializable } from "./interfaces/IDeserializable.interface";

export class SnapFormMenu implements IDeserializable {
    tableName: string;
    tableLabel: string;
    imageUrl: string;
    color: string;
    joinFieldName: string;

    constructor(tableName?: string, tableLabel?: string, imageUrl?: string, color?: string, joinFieldName?: string) {
        this.tableName = tableName ?? "";
        this.tableLabel = tableLabel ?? "";
        this.imageUrl = imageUrl ?? "";
        this.color = color ?? "";
        this.joinFieldName = joinFieldName ?? "";
    }

    deserialize(input: SnapFormMenu): this {
        Object.assign(this, input);
        return this;
    }
}
