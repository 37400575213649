import { LPFieldType } from "../enums";
import { SnapFormField } from "../snapFormField";
import { Visitor } from "./Visitor";
import Utility from "../../../shared/utility";

export class EmailAddressValidator extends Visitor {
    //***
    //***
    canVisit(field: SnapFormField): boolean {
        return super.commonCanVisitFieldType(field, [LPFieldType.Email]);
    }

    //***
    //***
    visit(field: SnapFormField, value: string) {
        if (super.commonVisit(field, value))
            if (value && !Utility.isEmailAddress(value)) {
                field.validationMessage = "Invalid email address";
            }
    }
}
