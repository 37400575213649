import { HttpClient } from "@angular/common/http";
import { Injectable, NgZone } from "@angular/core";
import { cloneDeep } from "lodash";
import { BehaviorSubject, Observable } from "rxjs";
import { map } from "rxjs/operators";
import { environment } from "../../../environments/environment";
import { AssistTokenRequest } from "../../models/assistTokenRequest";
import { AssistTokenResponse } from "../../models/assistTokenResponse";
import { Attachment } from "../../models/attachment";
import { BootstrapRequest } from "../../models/bootstrapRequest";
import { BootstrapResponse } from "../../models/bootstrapResponse";
import { CalendarPouch } from "../../models/calendarPouch";
import { Card } from "../../models/card";
import { CardSet } from "../../models/cardSet";
import { Cell } from "../../models/cell";
import { ClientState } from "../../models/clientState";
import { CustomSyncField } from "../../models/customSyncField";
import { CustomSyncFields } from "../../models/customSyncFields";
import { DataSet } from "../../models/dataSet";
import { Deck } from "../../models/deck";
import { DiscoveryEnqueueResponse } from "../../models/discoveryEnqueueResponse";
import { EmailPouch } from "../../models/emailPouch";
import { EmptyPouch } from "../../models/emptyPouch";
import { CellValueType, DeckType, OfficeEvent, PanelType, PaneType, PouchClass, PropertySetAction, SearchType, SelectCardAction, SignalType, SuggestionSource } from "../../models/enums";
import { FooterStatus } from "../../models/footerStatus";
import { GraphLoginRequest } from "../../models/graphLoginRequest";
import { GraphLoginResponse } from "../../models/graphLoginResponse";
import { Group } from "../../models/group";
import { GroupPreference } from "../../models/groupPreference";
import { Header } from "../../models/header";
import { IPouch } from "../../models/interfaces/IPouch.interface";
import { Pane } from "../../models/pane";
import { PreflightResponse } from "../../models/preflightResponse";
import { QuickCommentRequest } from "../../models/quickCommentRequest";
import { QuickCommentResponse } from "../../models/quickCommentResponse";
import { ReferencePacket } from "../../models/referencePacket";
//import { RefreshTimeScoutRequest } from "../../models/refreshTimeScoutRequest";
//import { RefreshTimeScoutResponse } from "../../models/refreshTimeScoutResponse";
import { RemoteAction } from "../../models/remoteAction";
import { RemoteRequest } from "../../models/remoteRequest";
import { RemoteResponse } from "../../models/remoteResponse";
import { SalesforceLoginRequest } from "../../models/salesforceLoginRequest";
import { SalesforceLoginResponse } from "../../models/salesforceLoginResponse";
import { SearchRequest } from "../../models/searchRequest";
import { SearchResponse } from "../../models/searchResponse";
import { SelectRequest } from "../../models/selectRequest";
import { SelectResponse } from "../../models/selectResponse";
import { SimpleRequest } from "../../models/SimpleRequest";
import { SimpleResponse } from "../../models/SimpleResponse";
import { SnapFormHeader } from "../../models/snapFormHeader";
import { SyncRequest } from "../../models/syncRequest";
import { SyncResponse } from "../../models/syncResponse";
/*import { TimeScoutOrganizer } from "../../models/timeScoutOrganizer";*/
import BroadcastHelper from "../../shared/broadcastHelper";
import LPDateTime from "../../shared/LPDateTime";
import Utility from "../../shared/utility";
import { MiniFormResult } from "../../snap-forms/models/miniFormResult";
import { SnapFormValue } from "../../snap-forms/models/snapFormValue";
import { MiniSnapFormService } from "../../snap-forms/services/mini-snapform.service";
import { OfficeService } from "./office.service";
import { PanelService } from "./panelService";
import { SessionService } from "./session.service";
import { SignalRService } from "./signalr.service";
import { ToastNotificationService } from "./toast-notification.service";
import { TourService } from 'ngx-ui-tour-ngx-bootstrap';
import { INgxbStepOption } from "ngx-ui-tour-ngx-bootstrap/lib/step-option.interface";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { StartSplashComponent } from "../../shared/start-splash/start-splash.component";
import { LogAppendRequest } from "../../models/logAppendRequest";
import { EmptyResponse } from "../../models/emptyResponse";
import { NgcCookieConsentService } from "ngx-cookieconsent";

//***
//***
@Injectable()
export class SublimeService {
    
    private get searchUrl() {
        return this.sessionService.API_ENDPOINT + "api/Search/sidepanel";
    }
    private get bootstrapUrl() {
        return this.sessionService.API_ENDPOINT + "api/bootstrap/sidepanel";
    }
    private get discoveryEnqueueUrl() {
        return this.sessionService.API_ENDPOINT + "api/Discovery/sidepanel";
    }
    private get selectUrl() {
        return this.sessionService.API_ENDPOINT + "api/Select";
    }
    private get salesforceLoginUrl() {
        return this.sessionService.API_ENDPOINT + "api/salesforce/sidepanel/login";
    }
    private get remoteActionUrl() {
        return this.sessionService.API_ENDPOINT + "api/Remote";
    }
    private get logAppendUrl() {
        return this.sessionService.API_ENDPOINT + "api/simple/LogAppend";
    }
    private get htmlToTextUrl() {
        return this.sessionService.API_ENDPOINT + "api/simple/HtmlToText";
    }
    private get quickCommentUrl() {
        return this.sessionService.API_ENDPOINT + "api/QuickComment";
    }
    //private get timeScoutUrl() {
    //    return this.sessionService.API_ENDPOINT + "api/TimeScout";
    //}
    private get utilityGuidUrl() {
        return this.sessionService.API_ENDPOINT + "api/utility/sidepanel/guid";
    }
    private get utilityPingUrl() {
        return this.sessionService.API_ENDPOINT + "api/utility/sidepanel/ping";
    }
    private get graphLoginUrl() {
        return this.sessionService.API_ENDPOINT + "api/graph/sidepanel/login";
    }
    private get assistTokenUrl() {
        return this.sessionService.API_ENDPOINT + "api/assistToken";
    }
    private get syncUrl() {
        return this.sessionService.API_ENDPOINT + "api/syncjob/sidepanel/enqueue";
    }

    public suggestionSource: SuggestionSource = SuggestionSource.Undefined;
    public isTaskMiniFormExist = false;
    public isEventMiniFormExist = false;
    public isBodyFromReferencePacket = false; //Used mainly in apiHtmlToText() to know if the body field in the miniform should be replaced with the current pouch body. Gets reset with every new pouch.
    public isCardSelecting = false;  //Used to determine if there are any cards currently processing a selection to prevent multiple cards from being selected at once
    public isStartSplashClosed = false;  //Used to determine if the starting splash screen is closed

    //***
    //***
    constructor(
        private _ngZone: NgZone,
        private httpClient: HttpClient,
        private toastService: ToastNotificationService,
        private sessionService: SessionService,
        private officeService: OfficeService,
        private miniSnapFormService: MiniSnapFormService,
        private panelService: PanelService,
        private signalRService: SignalRService,
        private tourService: TourService,
        private bsModalRef: BsModalRef,
        private modalService: BsModalService,
        private ccService: NgcCookieConsentService,
    ) {
        Utility.assert<ToastNotificationService>(toastService, "sublimeService.toastService");
        Utility.assert<SessionService>(sessionService, "sublimeService.sessionService");
        Utility.assert<OfficeService>(officeService, "sublimeService.officeService");

        this.searchPanePipe = new BehaviorSubject<Pane>(this.findPane(PaneType.Search));
        this.selectPanePipe = new BehaviorSubject<Pane>(this.findPane(PaneType.Select));
        this.sideKickPanePipe = new BehaviorSubject<Pane>(this.findPane(PaneType.SideKick));
        this.preferencesSubject = new BehaviorSubject<GroupPreference[]>(this.groupPreferences);
        this.footerStatusSubject = new BehaviorSubject<FooterStatus>(new FooterStatus(false, 0, 0));
        this.bootstrapSnapFormHeaderSubject = new BehaviorSubject<boolean>(false);
       /* this.timeScoutSubject = new BehaviorSubject<TimeScoutOrganizer>(this.timeScoutOrganizer);*/
        this.searchRefreshSubject = new BehaviorSubject<boolean>(false);
        this.bootStrapSubject = new BehaviorSubject<boolean>(false);
        this.onSnapFormsSaved = new BehaviorSubject<Map<string, string>>(null);

        //Perform preflight to get the correct urls for the other servers
        this.sessionService.preflightCall().then((response: PreflightResponse) => {
            if (response) {
                this.sessionService.API_ENDPOINT = response.apiEndpoint;
                this.sessionService.ASSIST_ENDPOINT = response.assistEndpoint;
                this.sessionService.SF_OAUTH_CID = response.sfOauthCid;
                this.sessionService.GRAPH_OAUTH_CID = response.graphOauthCid;

                this.bootstrap(false, false);
            }
        });       
                
        this._pouch = new EmptyPouch();

        BroadcastHelper.registerBroadcastHandler();
    }

    //***   Api.DiscoveryEnqueue   Api.DiscoveryEnqueue   Api.DiscoveryEnqueue
    //***   Api.DiscoveryEnqueue   Api.DiscoveryEnqueue   Api.DiscoveryEnqueue
    //***
    private discoveryEnqueueBusy = 0;
    public discoveryEnqueue() {
        if (this.discoveryEnqueueBusy++ === 0) {
            this.setFooterStatus(true);
            this.apiDiscoveryEnqueueCall().subscribe((response: DiscoveryEnqueueResponse) => {
                try {
                    if (!response || !response.success)
                        this.toastService.send("Discovery Completed", `Duration: ${response?.responseDuration} ms`, "", 3000);
                }
                finally {
                    this.setFooterStatus(false);
                    this.discoveryEnqueueBusy = 0;
                }
            });
        }
    }
    apiDiscoveryEnqueueCall(): Observable<DiscoveryEnqueueResponse> {
        return this.httpClient.get<DiscoveryEnqueueResponse>(`${this.discoveryEnqueueUrl}/${this.sessionService.lpUserId}`, { responseType: "json" });
    }


    //***  ApiBootstrap  ApiBootstrap  ApiBootstrap  ApiBootstrap
    //***  ApiBootstrap  ApiBootstrap  ApiBootstrap  ApiBootstrap
    //***
    private bootstrapCompleted: boolean;
    private bootstrapBusy = 0;
    public bootstrap(force: boolean, loadClientState = true) {
        // use a standard gate to allow only one process in.
        if (this.bootstrapBusy++ === 0) {
            try {
                if (!this.bootstrapCompleted || force) {
                    this.setFooterStatus(true);

                    //Clear any toasts about connection issues before running the next bootstrap
                    this.toastService.removeTopicNotifications("PreferencesHost");

                    this.apiBootstrapCall().subscribe(async (response: BootstrapResponse) => {
                        try {
                            this.sessionService.lpUserId = response.lpUserId;
                            this.establishSignalRConnection(this.sessionService.lpUserId); //Establish the SignalR connection whenever the userId changes

                            this.sessionService.profileId = response.profileId;
                            this.sessionService.multiWhoEnabled = response.multiWhoEnabled;
                            this.sessionService.salesforceInstanceUrl = response.salesforceInstanceUrl;
                            this.sessionService.searchHintHistory = (response.searchHintHistory ?? "").split("|");
                            this.sessionService.sidePanelSplitterHeight = response.sidePanelSplitterHeight;
                            //***
                            this.sessionService.searchOnlyNameFields = response.searchOnlyNameFields;
                            this.sessionService.searchOnlyNameFieldsVisibility = response.searchOnlyNameFieldsVisibility;
                            //***
                            this.sessionService.autoSelectAttachmentsEnabled = response.autoSelectAttachmentsEnabled
                            this.sessionService.autoSelectAttachmentsIgnore = response.autoSelectAttachmentsIgnore
                            this.sessionService.autoSelectAttachmentsVisibility = response.autoSelectAttachmentsVisibility;

                            this.sessionService.removeAllVisibility = response.removeAllVisibility;
                            //***
                            this.sessionService.ignoreSearchHintsEnabled = response.ignoreSearchHintsEnabled;
                            this.sessionService.ignoreSearchHints = response.ignoreSearchHints;
                            this.sessionService.ignoreSearchHintsVisibility = response.ignoreSearchHintsVisibility;
                            //***
                            this.sessionService.subjectBodyEnabled = response.subjectBodyEnabled;
                            this.sessionService.subjectBodyVisibility = response.subjectBodyVisibility;
                            //***
                            this.sessionService.editModeMessageEnabled = response.editModeMessageEnabled;
                            this.sessionService.editModeMessageVisibility = response.editModeMessageVisibility;
                            //***
                            //this.sessionService.timeScoutEnabled = response.timeScoutEnabled;
                            //this.sessionService.timeScoutVisibility = response.timeScoutVisibility;
                            //***
                            this.sessionService.connectionsEnabled = response.connectionsEnabled;
                            this.sessionService.connectionsVisibility = response.connectionsVisibility;
                            //***
                            this.sessionService.salesforceConnected = response.salesforceConnected;
                            Utility.salesforceInstanceUrl = response.salesforceInstanceUrl;
                            Utility.salesforceManagedAppEndPoint = response.salesforceManagedAppEndPoint;
                            this.sessionService.salesforceUserName = response.salesforceUserName;
                            this.sessionService.salesforceOAuthMode = response.salesforceOAuthMode;
                            this.sessionService.salesforceOAuthEndPoint = response.salesforceOAuthEndPoint;


                            //***
                            this.sessionService.supportVisibility = response.supportVisibility;
                            this.sessionService.knowledgeBaseVisibility = response.knowledgeBaseVisibility;
                            this.sessionService.chatVisibility = response.chatVisibility;
                            //***
                            this.sessionService.microsoftConnected = response.microsoftConnected;
                            this.sessionService.microsoftManagedAppEndPoint = response.microsoftManagedAppEndPoint;
                            this.sessionService.microsoftManagedAppScope = response.microsoftManagedAppScope;
                            this.sessionService.microsoftUserName = response.microsoftUserName;

                            this.sessionService.followUpTableName = response.followUpTableName;
                            this.sessionService.followUpVisibility = response.followUpVisibility;

                            this.sessionService.calendarSyncEnabled = response.calendarSyncEnabled;
                            this.sessionService.emailSyncEnabled = response.emailSyncEnabled;
                            this.sessionService.contactSyncEnabled = response.contactSyncEnabled;

                            this.sessionService.miniSnapFormTask = response.miniSnapFormTask;
                            this.sessionService.miniSnapFormEvent = response.miniSnapFormEvent;

                            this.sessionService.sidekickVisibility = response.sidekickVisibility;
                            this.sessionService.openInBrowserVisibility = response.openInBrowserVisibility;
                            this.sessionService.headerCreateVisibility = response.headerCreateVisibility;
                            this.sessionService.cardEditVisibility = response.cardEditVisibility;
                            this.sessionService.cardCreateVisibility = response.cardCreateVisibility;
                            this.sessionService.cardQuickCommentVisibility = response.cardQuickCommentVisibility;
                            this.sessionService.helpVisibility = response.helpVisibility;
                            this.sessionService.autoExpandVisibility = response.autoExpandVisibility;
                            this.sessionService.sortVisibility = response.sortVisibility;
                            this.sessionService.primaryWhoVisibility = response.primaryWhoVisibility;
                            this.sessionService.discoverySuccessCount = response.discoverySuccessCount;
                            //***
                            this.sessionService.hasActivated = response.hasActivated;
                            this.sessionService.appVersion = response.appVersion;
                            this.sessionService.isLicenseActive = response.isLicenseActive;
                            //***
                            this.sessionService.suggestUsingConversation = response.suggestUsingConversation;
                            this.sessionService.suggestUsingConversationVisibility = response.suggestUsingConversationVisibility;
                            //***
                            this.sessionService.suggestUsingRelevance = response.suggestUsingRelevance;
                            this.sessionService.suggestUsingRelevanceVisibility = response.suggestUsingRelevanceVisibility;

                            if (response.groups && response.groups.length > 0) {
                                this.groups.splice(0, this.groups.length);
                                for (const group of response.groups)
                                    this.groups.push(group);
                            }

                            if (response.groupPreferences && response.groupPreferences.length > 0) {
                                this.groupPreferences.splice(0, this.groupPreferences.length);
                                response.groupPreferences.forEach((x) => this.groupPreferences.push(x));
                            }

                            if (response.snapFormHeaders && response.snapFormHeaders.length > 0) {
                                this.snapFormHeaders.splice(0, this.snapFormHeaders.length);
                                response.snapFormHeaders.forEach(item => {
                                    this.snapFormHeaders.push(item)

                                    //While the isFormExists properites are false. Test if the current header should set them to true.
                                    this.isTaskMiniFormExist = this.isTaskMiniFormExist || (item.tableName === "Task" && item.hasMini);
                                    this.isEventMiniFormExist = this.isEventMiniFormExist || (item.tableName === "Event" && item.hasMini);

                                    //Check if contact, account and lead table exist. If exist then get image color and url.
                                    switch (item.tableName) {
                                        case "Contact":
                                            this._canImportContact = true;
                                            this._contactImageUrl = item.imageUrl;
                                            this._contactImagebgColor = item.color;
                                            break;

                                        case "Account":
                                            this._canImportAccount = true;
                                            this._accountImageUrl = item.imageUrl;
                                            this._accountImagebgColor = item.color;
                                            break;

                                        case "Lead":
                                            this._canImportLead = true;
                                            this._leadImageUrl = item.imageUrl;
                                            this._leadImagebgColor = item.color;
                                            break;

                                        default:
                                            break;
                                    }
                                });
                            }

                            //We need to manually open the cookie consent popup if this user hasnt consented yet
                            if (!response.hasCookieConsent)
                                this.ccService.fadeIn();

                            //this.timeScoutOrganizer = response.timeScoutOrganizer ?? new TimeScoutOrganizer();
                            //this.timeScoutSubject.next(this.timeScoutOrganizer);

                            if (loadClientState)
                                this.setClientState(response.clientState);
                            this.bootstrapSnapFormHeaderSubject.next(true);

                            if (!this.bootstrapCompleted) {
                                this.officeService.onPouchUpdate().subscribe((pouch: IPouch) => {
                                    this.setPouch(pouch);
                                });

                                this.miniSnapFormService.snapFormsSubject.subscribe(resp => {
                                    if (resp) {
                                        //when the miniform is initialized extract any existing values from the reference packet so they are in sync
                                        this._miniFormValues = this.extractSnapFormValuesFromCustomSyncFields(this.pouch.referencePacket.customSyncFields);

                                        //If subjectBodyEnabled then we need to stuff the pouch values
                                        if (this.sessionService.subjectBodyEnabled) {
                                            this.isBodyFromReferencePacket = false; //reset this value, used in apiHtmlToText

                                            //Fetch the current values of subject and body from the pouch
                                            let pouchSubject = "";
                                            let pouchBody = "";

                                            if (this.pouch instanceof EmailPouch) {
                                                const emailPouch: EmailPouch = this.pouch as EmailPouch;
                                                pouchSubject = emailPouch.subject;
                                                pouchBody = emailPouch.body;
                                            }
                                            else if (this.pouch instanceof CalendarPouch) {
                                                const calendarPouch: CalendarPouch = this.pouch as CalendarPouch;
                                                pouchSubject = calendarPouch.subject;
                                                pouchBody = calendarPouch.body;
                                            }

                                            //If a subject value doesn't currently exist in the collected values then insert the pouch value
                                            if (!this._miniFormValues.fieldValues.some(x => x.fieldName === "Subject" && x.value)) {
                                                this._miniFormValues.fieldValues.push(new SnapFormValue("Subject", pouchSubject));
                                            }
                                            //If a description value doesn't currently exist in the collected values then insert the pouch value
                                            if (!this._miniFormValues.fieldValues.some(x => x.fieldName === "Description" && x.value))
                                                this._miniFormValues.fieldValues.push(new SnapFormValue("Description", pouchBody));
                                            else
                                                this.isBodyFromReferencePacket = true; //Set to true if the value already existed in the reference packet
                                        }

                                        this.miniSnapFormService.mergeValues(this._miniFormValues);
                                    }
                                });


                                this.onSnapFormsSaved.subscribe((fieldValues: Map<string, string>) => {
                                    if (fieldValues) {
                                        const id = fieldValues.get("Id");
                                        this.cardMaster.cards.filter(x => x.id === id).forEach(card => {
                                            card.cells.forEach(cell => {
                                                this.mergeCellValues(cell, fieldValues);
                                            });
                                            card.assignHeader();
                                        });


                                        this.panes.forEach(pane => {
                                            pane.decks.forEach(deck => {
                                                deck.cards.filter(card => card.id === id).forEach(card => {
                                                    card.cells.forEach(cell => {
                                                        this.mergeCellValues(cell, fieldValues);
                                                    });
                                                    card.assignHeader();
                                                })

                                                deck.groups.forEach(group => {
                                                    group.cards.filter(card => card.id === id).forEach(card => {
                                                        card.cells.forEach(cell => {
                                                            this.mergeCellValues(cell, fieldValues);
                                                        });
                                                        card.assignHeader();
                                                    });
                                                })
                                            });
                                        });
                                    }
                                });
                            }

                            const action = new RemoteAction("");
                            action.signal = SignalType.BootstrapCompleted;
                            BroadcastHelper.postMessage(action);
                            this.bootstrapCompleted = true;

                            //Start the tour if this is the users first time connecting
                            if (this.sessionService.salesforceConnected && this.sessionService.microsoftConnected && this.isStartTourNextBootstrap)
                                this.startTour();

                            //Show the edit mode message if required
                            if (this.sessionService.editModeMessageEnabled && this.pouch.isComposeMode) {
                                //Identify pouch and then use the correct flag to identify if sync is enabled
                                if ((this.pouch.pouchClass === PouchClass.Email && this.sessionService.emailSyncEnabled) ||
                                    (this.pouch.pouchClass === PouchClass.Calendar && this.sessionService.calendarSyncEnabled))
                                    this.toastService.send('Pending Synchronization', '', 'Outlook items that are in edit mode will not appear instantly in Salesforce and instead require a sync to be run.', 5000, 'Select');
                                else
                                    this.toastService.send('Synchronization Disabled', '', 'Any selections made to this item will only be acted upon after sync is reenabled.', 5000, 'Select');
                            }
                        }
                        finally {
                            this.setFooterStatus(false);
                            this.bootStrapSubject.next(true);

                            if (response.errorMessage) {
                                Utility.debug(response.errorMessage, "Bootstrap Error",)
                                this.panelService.displayModal(PanelType.BootstrapError);
                            }
                            else if (!response.isLicenseActive)
                                this.panelService.displayModal(PanelType.LicenseExpired);
                            else {
                                //now that we were successful clear any modals that might have been up from previous problems
                                this.panelService.clearModal();

                                if (loadClientState && !this.isTourMode)
                                    this.refresh();

                                //Automatically sign the user in if not connected to MS
                                if (!this.sessionService.microsoftConnected && this.sessionService.isOfficeIdentitySupported) {
                                    try {
                                        this.GraphLogin().subscribe((response: GraphLoginResponse) => {
                                            if (response.success) {
                                                this.sessionService.microsoftConnected = true;
                                            }
                                            else {
                                                //this.toastService.send("Error Authorizing Microsoft Connection", "", response.message, 0, "Microsoft");
                                            }

                                            this.checkConnections();
                                        });
                                    }
                                    catch (exception) {
                                        Utility.debug(JSON.stringify(exception));
                                    }
                                }
                                else
                                    this.checkConnections();
                            }
                        }
                    });
                }
            }
            finally {
                this.bootstrapBusy = 0;
            }
        }
        else {
            Utility.debug("Bootstrap is busy.");
        }
    }
    apiBootstrapCall(): Observable<BootstrapResponse> {
        const request = new BootstrapRequest(this.sessionService);
        return this.httpClient.post(this.bootstrapUrl, JSON.stringify(request)).pipe(map((data) => new BootstrapResponse().deserialize(data)));
    }

    //***
    //***
    private async checkConnections() {
        if (!this.sessionService.hasActivated) {
            //Immediately open the Splash screen
            this.bsModalRef = this.modalService.show(StartSplashComponent, {
                animated: true,
                class: 'full-screen-dialog',
            });
        } else if (!this.sessionService.salesforceConnected || !this.sessionService.microsoftConnected)
            this.panelService.displayModal(PanelType.Preferences); //Instantly open the preference panel if they are not connected to SF
    }

    //***
    //***
    private mergeCellValues(cell: Cell, fieldValues: Map<string, string>) {
        if (fieldValues.has(cell.name)) {
            const val = fieldValues.get(cell.name);
            if (val) {
                cell.value = val;
                if (cell.dataType === CellValueType.D) {
                    //Format the dates depending on what values are present
                    if (LPDateTime.isDateTimeString(val)) {
                        const actualDateTime = new Date(val);
                        cell.displayValue = actualDateTime.toLocaleString(undefined, {
                            dateStyle: 'short',
                            timeStyle: 'short'
                        });
                    }
                    else if (LPDateTime.isDate(val)) {
                        const actualDateTime = new Date(val);
                        //remove any offset because we want to take the date value as is
                        actualDateTime.setMinutes(actualDateTime.getMinutes() + actualDateTime.getTimezoneOffset());
                        cell.displayValue = actualDateTime.toLocaleDateString();
                    }
                    else if (LPDateTime.isTime(val)) {
                        const actualDateTime = new Date(`2000-01-01 ${val}`);
                        cell.displayValue = actualDateTime.toLocaleTimeString(undefined, {
                            timeStyle: 'short'
                        });
                    }
                }
                else if (cell.dataType === CellValueType.N) {
                    //format all numbers with a decimal precision of 2
                    cell.displayValue = parseFloat(val).toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                    });
                }
                else {
                    //if its not a date or number then just set the displayValue the same as the value
                    cell.displayValue = val;
                }
            }
            else {
                //if the field exists in the collection but the value is empty then blank out the value and displayvalue
                cell.value = "";
                cell.displayValue = "";
            }
        }
    }


    //***  Api.Search  Api.Search  Api.Search  Api.Search
    //***  Api.Search  Api.Search  Api.Search  Api.Search
    //***
    public search(searchType: SearchType, searchHints: string[]): boolean {
        let result = false;

        //Only Search if we are connected to SF
        if (this.sessionService.salesforceConnected) {
            result = this.processBangBang(searchType, searchHints);
            if (!result && searchHints && searchHints.length > 0 && !Utility.isNullOrEmpty(Utility.toDelimitedString(searchHints, ""))) {
                let rowCount = 0;
                this.setFooterStatus(true)
                this.apiSearchCall(searchType, searchHints).subscribe((response: SearchResponse) => {
                    try {
                        if (this.contextToken === response.contextToken) {
                            this.assertContext(response.contextToken);
                            const dataset = new DataSet();
                            if (response?.dataSet?.cards)
                                response.dataSet.cards.forEach(x => dataset.cards.push(Object.assign(new Card(), x)));

                            const targetDecks: Deck[] = [];
                            if (searchType === SearchType.Document || this.isTourMode) {
                                this.cardMaster.Clear();
                                for (const deck of this.decks) {
                                    deck.groups.splice(0, deck.groups.length);
                                    deck.cards.splice(0, deck.cards.length);
                                    targetDecks.push(deck);
                                }
                            }

                            else if (searchType === SearchType.SearchBar) {
                                this.setDeckVisibility(this.cardMaster.cards, DeckType.Search, false);
                                targetDecks.push(this.findDeck(DeckType.Search));
                            }

                            else if (searchType === SearchType.SideKick) {
                                this.setDeckVisibility(this.cardMaster.cards, DeckType.SideKickRoot, false);
                                this.setDeckVisibility(this.cardMaster.cards, DeckType.SideKickRelated, false);
                                targetDecks.push(this.findDeck(DeckType.SideKickRoot));
                                targetDecks.push(this.findDeck(DeckType.SideKickRelated));
                            }

                            this.cardMaster.clearOrphans();
                            this.cardMaster.upsert(dataset.cards);

                            for (const deck of targetDecks) {
                                deck.groups.splice(0, deck.groups.length);
                                deck.cards.splice(0, deck.cards.length);
                            }

                            this.suggestionSource = SuggestionSource.Undefined;
                            if (response.dataSet) {
                                if (response.dataSet.suggestionSource)
                                    this.suggestionSource = response.dataSet.suggestionSource;

                                if (Array.isArray(response.dataSet.cards))
                                    rowCount = response.dataSet.cards.length;
                            }


                            this.dealDecks(targetDecks, this.cardMaster.cards);
                            this.setClientState(response.clientState);

                            this.searchPanePipe.next(this.findPane(PaneType.Search));
                            this.selectPanePipe.next(this.findPane(PaneType.Select));
                            this.sideKickPanePipe.next(this.findPane(PaneType.SideKick));
                        }
                    } catch (error) {
                        Utility.debug(error, "Search Error");
                    }
                    finally {
                        this.setFooterStatus(false, rowCount);
                    }
                });
            }
        }

        return result;
    }
    apiSearchCall(searchType: SearchType, searchHints: string[]): Observable<SearchResponse> {
        let pouchClone: EmailPouch | undefined = undefined;
        if (this.pouch && searchType === SearchType.Document && this.pouch.pouchClass === PouchClass.Email && this.sessionService.connectionsEnabled) {
            pouchClone = this.clonePouch(this.pouch as EmailPouch) as EmailPouch;
            pouchClone.attachments = [];
        }

        const request = new SearchRequest(this.sessionService.lpUserId, this.contextToken, this.pouch.conversationId, searchType, this.pouch.referencePacket, this.pouch.pouchClass, this.GetAttachments(this.pouch), searchHints, this.findDeck(DeckType.Select).getState(), pouchClone);
        return this.httpClient.post(this.searchUrl, JSON.stringify(request)).pipe(map((data) => new SearchResponse().deserialize(data)));
    }


    //***
    //***
    clonePouch(sourcePouch: IPouch) {
        let pouch: IPouch = null;
        switch (sourcePouch.pouchClass) {
            case PouchClass.Email:
                pouch = new EmailPouch().deserialize(sourcePouch as EmailPouch);
                break;

            case PouchClass.Calendar:
                pouch = new CalendarPouch().deserialize(sourcePouch as CalendarPouch);
                break;

            default:
                break;
        }
        return pouch;
    }


    //***  Api.Select  Api.Select  Api.Select  Api.Select
    //***  Api.Select  Api.Select  Api.Select  Api.Select
    //***
    public select(action: SelectCardAction, cards: Card[]): Promise<boolean> {
        return new Promise((resolve) => {
            this.isCardSelecting = true;
            //Fetch the attachment contents before sending to the server
            this.officeService.loadAttachmentContents(this.pouch).then(() => {
                this.setFooterStatus(true);
                const clientState = new ClientState();
                clientState.paneStates.push(this.findPane(PaneType.Select).getState());
                for (const card of cards)
                    clientState.cardStates.push(card.getState());
                this.apiSelectCall(action, clientState).subscribe((response: SelectResponse) => {
                    try {
                        this.assertContext(response.contextToken);
                        if (!response.success) {
                            this.toastService.send("Salesforce Update Failed", "", response.message, 5000, "select");
                            this.dealDecks(this.decks, this.cardMaster.setState(response.clientState.cardStates));
                            this.setClientState(response.clientState);
                            this.searchPanePipe.next(this.findPane(PaneType.Search));
                            this.selectPanePipe.next(this.findPane(PaneType.Select));
                            resolve(false);
                        }
                        else {
                            this.pouch.referencePacket = cloneDeep(response.referencePacket);

                            this.cardMaster.clearOrphans();
                            this.dealDecks(this.decks, this.cardMaster.setState(response.clientState.cardStates));
                            this.setClientState(response.clientState);

                            this.searchPanePipe.next(this.findPane(PaneType.Search));
                            this.selectPanePipe.next(this.findPane(PaneType.Select));

                            this.officeService.setReferencePacket(response.referencePacketAction === PropertySetAction.Set ? this.pouch.referencePacket : null, response.category, this)
                                .then((result) => {
                                    if (response?.message)
                                        this.toastService.send("Salesforce Updated", "", response.message, 1500, "select");
                                })
                                .catch((err) => {
                                    throw err;
                                    resolve(false);
                                })
                            resolve(true);
                        }
                        resolve(true);
                    } catch (ex) {
                        this.toastService.send("Salesforce Update Failed", "", ex, 5000, "select");
                        Utility.debug(ex, "Select Error");
                        resolve(false);
                    }
                    finally {
                        this.isCardSelecting = false;
                        this.setFooterStatus(false);
                    }
                });
            });
        });
    }
    apiSelectCall(action: SelectCardAction, clientState: ClientState): Observable<SelectResponse> {
        const request = new SelectRequest(action, clientState, this.contextToken, this.sessionService.lpUserId, cloneDeep(this.pouch));
        return this.httpClient.post<SelectResponse>(this.selectUrl, JSON.stringify(request)).pipe(map((data) => { return new SelectResponse().deserialize(data) }));
    }


    //***
    //***
    private processBangBang(searchType: SearchType, searchHints: string[]): boolean {
        const topic = "bang";
        const duration = 2000;
        let result = false;
        if (searchType === SearchType.SearchBar) {
            const hint = (searchHints[0] ?? "").toLowerCase().trim();

            if (hint.startsWith("!!")) {
                result = true;

                if (hint === "!!bootstrap") {
                    this.toastService.send("Bootstrap", "", "Panel bootstrap initiated.", duration, topic);
                    this.bootstrap(true, false);
                }

                else if (hint === "!!showpacket")
                    this.showReferencePacket();

                else if (hint === "!!showversion")
                    this.showVersion();

                else if (hint === "!!whoami")
                    this.whoAmI();

                else if (hint === "!!showpouch")
                    this.showPouch();

                else if (hint.startsWith("!!ping"))
                    this.ping(hint);

                else if (hint.startsWith("!!showdoc"))
                    this.showDocument();

                else if (hint === "!!clearpacket") {
                    this.clearReferencePacket();
                    this.refresh();
                    this.toastService.send("Reference Packet", "", "Reference packet has been cleared", duration, topic);
                }

                else if (hint === "!!discover") {
                    this.toastService.send("Discovery", "", "Discovery of your Salesforce environment has started and may take up to 20 seconds to complete.  This panel will refresh when discovery is complete.", duration, topic);
                    this.discoveryEnqueue();
                }

                else {
                    result = false;
                }
            }
        }
        return result;
    }


    //***
    //***
    private whoAmI() { Utility.debug(`EmailAddress: ${this.sessionService.officeUserEmailAddress}  DisplayName=${this.sessionService.officeUserEmailAddress}`); }


    //***
    //***
    refresh(): void {
        this.search(SearchType.Document, this.pouch.searchHints);
        this.searchRefreshSubject.next(true);
    }


    //***  Api.RemoteAction  Api.RemoteAction  Api.RemoteAction  Api.RemoteAction
    //***  Api.RemoteAction  Api.RemoteAction  Api.RemoteAction  Api.RemoteAction
    //***
    apiRemoteAction(remoteAction: RemoteAction) {
        const request = new RemoteRequest(this.sessionService.lpUserId, [remoteAction]);
        this.httpClient.post<RemoteResponse>(this.remoteActionUrl, JSON.stringify(request)).subscribe((response: RemoteResponse) => {
            try {
                this.setFooterStatus(true);
                for (const action of response.actions) {
                    BroadcastHelper.postMessage(action);
                }
            }
            catch (ex) { Utility.debug(ex, "RemoteAction Error") }
            finally {
                this.setFooterStatus(false);
            }
        });
    }


    //***
    //***
    logAppend(logEntry: any) {
        try {
            let errorMessage = '';

            if (logEntry instanceof Error)
                errorMessage = logEntry.message;
            else
                errorMessage = logEntry;

            const request = new LogAppendRequest(this.sessionService.lpUserId, errorMessage);
            this.httpClient.post<EmptyResponse>(this.logAppendUrl, JSON.stringify(request)).subscribe((response: EmptyResponse) => {
            });
        }
        catch { }
    }



    //***  Api.HtmlToText  Api.HtmlToText  Api.HtmlToText  Api.HtmlToText
    //***  Api.HtmlToText  Api.HtmlToText  Api.HtmlToText  Api.HtmlToText
    //***
    apiHtmlToText(payload: string) {
        if (this.pouch && this.pouch.pouchClass === PouchClass.Email) {
            this.apiHtmlToTextCall(payload).subscribe((response: SimpleResponse) => {
                const email = this.pouch as EmailPouch;
                if (email)
                    if (this.pouch && this.contextToken === response.contextToken) {
                        email.body = response.result;

                        //Due to a timing issue where apiHtmlToTextCall() comes back after the miniform is initialized we have to update the value in the miniform
                        //Inject the new text version of the body into the miniformValues if the fieldValue is supposed to be coming from the pouch
                        if (this.sessionService.subjectBodyEnabled && !this.isBodyFromReferencePacket) {
                            //Find the description field
                            let descriptionValue = this._miniFormValues.fieldValues.find(x => x.fieldName === "Description")
                            if (descriptionValue)
                                descriptionValue.value = email.body; //If it exists update it
                            else
                                this._miniFormValues.fieldValues.push(new SnapFormValue("Description", email.body)); //Otherwise, add it to the collection

                            //Merge the new value back into the miniform
                            this.miniSnapFormService.mergeValues(this._miniFormValues);
                        }
                    }
            });
        }
    }
    apiHtmlToTextCall(payload: string): Observable<SimpleResponse> {
        const request = new SimpleRequest(this.sessionService.lpUserId, this.sessionService.officeUserEmailAddress, this.contextToken, payload);
        return this.httpClient.post<SimpleResponse>(this.htmlToTextUrl, JSON.stringify(request)).pipe(map((data) => new SimpleResponse().deserialize(data)));
    }


    //***  Api.SetPrimary  Api.SetPrimary  Api.SetPrimary
    //***  Api.SetPrimary  Api.SetPrimary  Api.SetPrimary
    //***
    public setPrimary(card: Card) {
        if (card?.canSetPrimary) {
            this.pouch.referencePacket.referenceItems.forEach(x => x.isPrimary = x.id === card.id)
            card.isPrimary = true;
            this.select(SelectCardAction.SetPrimary, []);
        }
    }


    //***
    //***
    private refreshPrimaryContact(): void {
        if (this?.pouch?.referencePacket?.referenceItems) {
            const contacts = this.pouch.referencePacket.referenceItems.filter(x => this.isContact(x.id));
            if (contacts && contacts.length > 0) {
                let primary = contacts.find(x => x.isPrimary);
                if (!primary)
                    primary = contacts[0]

                const primaryId = primary.id;
                contacts.forEach(x => x.isPrimary = x.id === primaryId);

                this.findDeck(DeckType.Select).cards.forEach(x => {
                    x.isPrimary = x.id === primaryId;
                    x.canSetPrimary = this.isContact(x.id) && this.sessionService.multiWhoEnabled && x.isCheckBoxVisible && x.isCheckBoxEnabled;
                })
            }
        }
    }


    //***
    //***
    public get hasFollowUpTask(): boolean { return !Utility.isNullOrEmpty(this?.pouch?.referencePacket?.followUpHostRecordId); }


    //***
    //***
    public get isRelated(): boolean { return !Utility.isNullOrEmpty(this?.pouch?.referencePacket?.hostRecordId); }


    //***
    //***
    public get hasSelections(): boolean { return this.pouch?.referencePacket !== null && this.pouch.referencePacket.referenceItems.length > 0; }


    //***
    //***
    public get relatedTaskUrl(): string {
        if (this.isRelated)
            return Utility.getSalesforceUrl(this.pouch.referencePacket.hostRecordId);
    }


    //***
    //***
    public get canAcceptSuggestions(): boolean {
        const suggest = this.findDeck(DeckType.Suggest)
        const select = this.findDeck(DeckType.Select)
        return suggest.isVisible && suggest.cards.length > 0 && select.cards.length === 0;
    }


    //***
    //***
    public get canClearSelections(): boolean {
        const select = this.findDeck(DeckType.Select)
        return select.isVisible && select.cards.some(x => x.isCheckBoxVisible && x.isCheckBoxEnabled);
    }
    public clearSelections(): void {
        if (this.canClearSelections)
            this.select(SelectCardAction.Removed, this.findDeck(DeckType.Select).cards);
    }


    //***  SalesforceLogin  SalesforceLogin  SalesforceLogin
    //***  SalesforceLogin  SalesforceLogin  SalesforceLogin
    //***
    SalesforceLogin(authCode: string, salesforceOAuthEndPoint: string) {
        const loginRequest: SalesforceLoginRequest = new SalesforceLoginRequest(this.sessionService.lpUserId, authCode, salesforceOAuthEndPoint);
        return this.httpClient.post<SalesforceLoginResponse>(this.salesforceLoginUrl, JSON.stringify(loginRequest));
    }


    //***  DealDecks  DealDecks  DealDecks  DealDecks
    //***  DealDecks  DealDecks  DealDecks  DealDecks
    //***
    private dealDecks(decks: Deck[], cards: Card[]) {
        for (const deck of decks) {
            for (const card of cloneDeep(cards) as Card[]) {
                const existingCard = deck.cards.find(x => x.id === card.id);
                if (card.getDeckVisibility(deck.deckType)) {
                    if (!existingCard) {
                        let group = deck.groups.find((x) => x.id === card.groupId);
                        if (!group) {
                            group = this.groups.find((x) => x.id === card.groupId).Clone();
                            deck.groups.push(group);
                        }

                        if (deck.deckType === DeckType.Select)
                            card.isChecked = true;

                        deck.cards.push(card);
                        group.cards.push(card);
                        card.deck = deck;
                        card.group = group;
                    }
                    else {
                        existingCard.setState(card.getState());
                        existingCard.deck = deck;
                        existingCard.isPrimary = card.isPrimary;
                        existingCard.canSetPrimary = card.canSetPrimary;
                    }
                }
                else if (existingCard) {
                    deck.cards.splice(deck.cards.indexOf(existingCard), 1);
                    const group = deck.groups.find(x => x.id === existingCard.groupId);
                    if (group)
                        group.cards.splice(group.cards.indexOf(existingCard), 1);
                    card.deck = null;
                    card.group = null;
                }
            }

            for (let i = deck.groups.length - 1; i >= 0; i--)
                if (deck.groups[i].cards.length === 0)
                    deck.groups.splice(i, 1);
        }
        this.refreshPrimaryContact();
    }


    //***  ContextToken  ContextToken  ContextToken
    //***  ContextToken  ContextToken  ContextToken
    //***
    private _contextToken: string | undefined;
    private get contextToken(): string | undefined {
        return this._contextToken;
    }
    private updateContext() {
        this._contextToken = Date.now().toString();
    }
    public assertContext(responseContext: string) {
        if (this.contextToken !== responseContext)
            throw new Error("The context has changed.");
    }


    //  StateData  StateData  StateData  StateData
    //  StateData  StateData  StateData  StateData

    //***  Groups  Groups  Groups  Groups
    //***  Groups  Groups  Groups  Groups
    //***
    private _groups: Group[] = [];
    private get groups(): Group[] {
        return this._groups ?? (this._groups = []);
    }
    private set groups(value: Group[]) {
        this._groups = value;
    }


    //***  GroupPreferences  GroupPreferences  GroupPreferences
    //***  GroupPreferences  GroupPreferences  GroupPreferences
    //***
    private _groupPreferences: GroupPreference[] | undefined;
    private get groupPreferences(): GroupPreference[] { return this._groupPreferences ?? (this._groupPreferences = []) }
    private set groupPreferences(value: GroupPreference[]) {
        this._groupPreferences = value;
        this.preferencesSubject.next(value);
    }


    //***  SnapFormHeader  SnapFormHeader  SnapFormHeader  SnapFormHeader
    //***  SnapFormHeader  SnapFormHeader  SnapFormHeader  SnapFormHeader
    //***
    private _snapFormHeaders: SnapFormHeader[] = [];
    public get snapFormHeaders(): SnapFormHeader[] { return this._snapFormHeaders ?? (this._snapFormHeaders = []); }
    public set snapFormHeaders(value: SnapFormHeader[]) { this._snapFormHeaders = value; }


    //***  Decks  Decks  Decks  Decks
    //***  Decks  Decks  Decks  Decks
    //***
    private _decks: Deck[] = null;
    private get decks(): Deck[] {
        if (this._decks === null) {
            this._decks = [];
            for (const decktypeItem of Object.values(DeckType))
                this.decks.push(new Deck(decktypeItem));
        }
        return this._decks;
    }
    private set decks(value: Deck[]) { this._decks = value; }


    //***  FindDeck  FindDeck  FindDeck
    //***  FindDeck  FindDeck  FindDeck
    //***
    public findDeck(deckType: DeckType): Deck {
        return this.decks.find((x) => x.deckType === deckType);
    }


    //***  Panes  Panes  Panes
    //***  Panes  Panes  Panes
    //***
    private _panes: Pane[] = null;
    private get panes(): Pane[] {
        if (!this._panes) {
            this._panes = [];
            this._panes.push(new Pane(PaneType.Search, [this.findDeck(DeckType.Search)]));
            this._panes.push(new Pane(PaneType.Select, [this.findDeck(DeckType.Select), this.findDeck(DeckType.Suggest),]));
            this._panes.push(new Pane(PaneType.SideKick, [this.findDeck(DeckType.SideKickRoot), this.findDeck(DeckType.SideKickRelated),]));
        }
        return this._panes;
    }
    private set panes(value: Pane[]) { this._panes = value; }


    //***  FindPane  FindPane  FindPane
    //***  FindPane  FindPane  FindPane
    //***
    private findPane(paneType: PaneType): Pane { return this.panes.find((x) => x.paneType === paneType); }


    //***  CardMaster  CardMaster  CardMaster  CardMaster
    //***  CardMaster  CardMaster  CardMaster  CardMaster
    //***
    private _cardMaster: CardSet = null;
    private get cardMaster(): CardSet { return this._cardMaster ?? (this._cardMaster = new CardSet()) }


    //***  Pouch  Pouch  Pouch  Pouch
    //***  Pouch  Pouch  Pouch  Pouch
    //***
    public static POUCH: IPouch;


    //***
    //***
    private _pouch: IPouch;
    private get pouch(): IPouch { return this._pouch ?? (this._pouch = new EmptyPouch()); }
    private set pouch(value: IPouch) { SublimeService.POUCH = value ?? new EmptyPouch(); this._pouch = value; }

    //***
    //***
    public getPouch(): IPouch { return this.pouch; }
    public setPouch(value: IPouch): void {
        this.pouch = value;

        //Clear all open toasts when the pouch is changed
        this.toastService.clearAll();

        if (this.pouch.pouchClass === PouchClass.Undefined) {
            //Create empty panes and display a message in the select billboard
            const searchPane = new Pane(PaneType.Search, [new Deck(DeckType.Search)]);
            this.searchPanePipe.next(searchPane);

            const selectPane = new Pane(PaneType.Select, [new Deck(DeckType.Select), new Deck(DeckType.Suggest)]);
            selectPane.billboard = new Header(null, "Outlook Item Does Not Support Being Related to Salesforce", "", "", true);
            this.selectPanePipe.next(selectPane);
        }
        else {
            if (this.officeService) {
                if (this.officeService.lastOfficeEvent === OfficeEvent.ItemChanged || this.officeService.lastOfficeEvent === OfficeEvent.RecipientsChanged || this.officeService.lastOfficeEvent === OfficeEvent.AttachmentsChanged) {
                    this.initializeMiniForm();

                    this.updateContext();

                    if (value.pouchClass === PouchClass.Email) {
                        const email = value as EmailPouch;
                        if (email)
                            this.apiHtmlToText(email.body);
                    }
                    this.search(SearchType.Document, value.searchHints);
                }
            }
        }
    }


    //***  SetState  SetState  SetState
    //***  SetState  SetState  SetState
    //***
    private setClientState(state: ClientState): void {
        if (state) {
            if (state.paneStates) {
                for (const value of state.paneStates) {
                    const target = this.findPane(value.paneType);
                    if (target)
                        target.setState(value);

                    for (const deckState of value.deckStates) {
                        const target = this.decks.find(x => x.deckType === deckState.deckType);
                        if (target)
                            target.setState(deckState);
                    }
                }
            }
        }
    }


    //***  SetDeckVisibility  SetDeckVisibility  SetDeckVisibility
    //***  SetDeckVisibility  SetDeckVisibility  SetDeckVisibility
    //***
    private setDeckVisibility(cards: Card[], deckType: DeckType, visible: boolean): void {
        for (const card of cards)
            card.setDeckVisibility(deckType, visible);
    }


    //***  GetAttachments  GetAttachments  GetAttachments
    //***  GetAttachments  GetAttachments  GetAttachments
    //***
    public GetAttachments(pouch: IPouch): Attachment[] {
        if (pouch === null)
            return null;

        else if (pouch instanceof EmailPouch) {
            const emailPouch: EmailPouch = pouch as EmailPouch;
            return emailPouch.attachments;
        }
        else if (pouch instanceof CalendarPouch) {
            const calendarPouch: CalendarPouch = pouch as CalendarPouch;
            return calendarPouch.attachments;
        }

        else
            return null;
    }


    //***  BehaviorSubjects  BehaviorSubjects  BehaviorSubjects  BehaviorSubjects
    //***  BehaviorSubjects  BehaviorSubjects  BehaviorSubjects  BehaviorSubjects
    //***
    public searchPanePipe: BehaviorSubject<Pane> = new BehaviorSubject<Pane>(null);
    public selectPanePipe: BehaviorSubject<Pane> = new BehaviorSubject<Pane>(null);
    public sideKickPanePipe: BehaviorSubject<Pane> = new BehaviorSubject<Pane>(null);
    public preferencesSubject: BehaviorSubject<GroupPreference[]> = new BehaviorSubject<GroupPreference[]>(null);
    public footerStatusSubject: BehaviorSubject<FooterStatus> = new BehaviorSubject<FooterStatus>(null);
    public bootstrapSnapFormHeaderSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
    /*public timeScoutSubject: BehaviorSubject<TimeScoutOrganizer> = new BehaviorSubject<TimeScoutOrganizer>(null);*/
    public searchRefreshSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
    public bootStrapSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
    public onSnapFormsSaved: BehaviorSubject<Map<string, string>>;


    //  Misc  Misc  Misc  Misc
    //  Misc  Misc  Misc  Misc


    //***
    //***
    private isContact(id: string) { return id?.substr(0, 3) === "003"; }
    //**
    showPouch() { Utility.debug(this.pouch, "!!showpouch") }
    //**
    showDocument() { Utility.debug(Office.context.mailbox.item, "!!showdocument") }
    //**
    showReferencePacket() { Utility.debug(this.pouch.referencePacket, "!!showpacket") }
    //**
    showVersion() { this.toastService.send("ShowVersion", undefined, `Version: ${environment.LP_Version}`, 10000, "showversion"); }


    //**
    //**
    private setFooterStatus(isSpinning: boolean, rowCount = 0) {
        const value = this.footerStatusSubject.value ?? new FooterStatus(isSpinning, 0, 0);
        if (isSpinning === true || isSpinning === false)
            value.isSpinning = isSpinning;

        if (rowCount && rowCount > 0)
            value.rowCount = rowCount;
        this.footerStatusSubject.next(value);
    }


    //  QuickComment  QuickComment  QuickComment  QuickComment
    //  QuickComment  QuickComment  QuickComment  QuickComment
    //***
    saveQuickComment(comment: string, tableName: string, recordId: string) {
        this.setFooterStatus(true);
        this.apiQuickCommentCall(comment, tableName, recordId)
            .subscribe((response: QuickCommentResponse) => {
                try {
                    if (!response || !response.success)
                        this.toastService.send('Quick Comment', '', response.message, 3000);
                }
                finally {
                    this.setFooterStatus(false);
                }
            });
    }
    apiQuickCommentCall(comment: string, tableName: string, recordId: string): Observable<QuickCommentResponse> {
        const request = new QuickCommentRequest(this.sessionService.lpUserId, tableName, recordId, comment, new Date());
        return this.httpClient.post(this.quickCommentUrl, JSON.stringify(request)).pipe(map((data) => new QuickCommentResponse().deserialize(data)));
    }


    //***  Api.Utility/Ping  Api.Utility/Ping  Api.Utility/Ping  Api.Utility/Ping
    //***  Api.Utility/Ping  Api.Utility/Ping  Api.Utility/Ping  Api.Utility/Ping
    //***
    //**
    //**
    ping(value: string) {
        this.httpClient.get(`${this.utilityPingUrl}/${value}`, { responseType: "text" }).toPromise()
            .then((response) => {
                if (response) {
                    if (response.startsWith("Ping:")) {
                        Utility.debug(response, "Ping");
                    }
                    else {
                        const parts = response.split("|");
                        if (parts.length === 3) {
                            this.sessionService.lpUserId = parts[0];
                            this.establishSignalRConnection(this.sessionService.lpUserId); //Establish the SignalR connection whenever the userId changes

                            this.sessionService.officeUserEmailAddress = parts[1];
                            this.sessionService.officeUserDisplayName = parts[2];
                            this.whoAmI();
                            this.bootstrap(true, false);
                            this.refresh();
                        }
                    }
                }
            });
    }


    //***  Api.Utility/Guid  Api.Utility/Guid  Api.Utility/Guid  Api.Utility/Guid
    //***  Api.Utility/Guid  Api.Utility/Guid  Api.Utility/Guid  Api.Utility/Guid
    //***
    apiUtilityGuid(valueToConvert = ""): Observable<string> { return this.httpClient.get(`${this.utilityGuidUrl}/${valueToConvert}`, { responseType: "text" }); }


    //***  GraphLogin  GraphLogin  GraphLogin
    //***  GraphLogin  GraphLogin  GraphLogin
    //***
    GraphLogin(authCode?: string) {
        const loginRequest: GraphLoginRequest = new GraphLoginRequest(this.sessionService.lpUserId, authCode);
        return this.httpClient.post<GraphLoginResponse>(this.graphLoginUrl, JSON.stringify(loginRequest));
    }


    //   OfficeServiceInterface   OfficeServiceInterface   OfficeServiceInterface
    //   OfficeServiceInterface   OfficeServiceInterface   OfficeServiceInterface
    //**
    clearReferencePacket() {
        this.officeService.setReferencePacket(null, this.currentCategory, this)
            .then(() => {
                this.refresh();
            })
            .catch(() => {
                // do nothing
            })
    }


    //***
    //***
    public get currentCategory(): string {
        let category = "";

        if (this.pouch.pouchClass === PouchClass.Email)
            category = this.sessionService.categoryEmail;
        else if (this.pouch.pouchClass === PouchClass.Calendar)
            category = this.sessionService.categoryCalendar;

        return category;
    }


    //  MiniForm MiniForm MiniForm MiniForm
    //  MiniForm MiniForm MiniForm MiniForm
    private _miniFormValues: MiniFormResult;
    public get miniFormValues(): MiniFormResult {
        return this._miniFormValues;
    }
    public set miniFormValues(value: MiniFormResult) {
        this._miniFormValues = value;
        if (value) {
            //The miniform values must be in sync between the dialog and this sidepanel minisnapformservice
            this.miniSnapFormService.mergeValues(value);

            //Make sure a referencePacket exists because there are some scenarios where it might be null at this point
            if (!this.pouch.referencePacket)
                this.pouch.referencePacket = new ReferencePacket();

            //Update in-memory reference packet with new values
            this.pouch.referencePacket.customSyncFields = this.generateCustomSyncFieldsFromMini(value.fieldValues);

            let isUpdateRefPacket = false;
            //If this has already been saved to SF then update the SF record with new values
            if (this.pouch.referencePacket.hostRecordId) {
                this.select(SelectCardAction.Reapply, []).then((response) => {
                    if (response) {
                        //If saving to SF was a success we should update the refpacket
                        isUpdateRefPacket = true;
                    }
                });
            }

            //Save updated reference packet if it was already saved to the item
            //Only if there is at least 1 item and either isUpdateRefPacket is true or there is no hostRecordId yet
            if (this.pouch.referencePacket.referenceItems.length >= 1 && ((isUpdateRefPacket) || (!this.pouch.referencePacket.hostRecordId))) {
                this.officeService.setReferencePacket(this.pouch.referencePacket, this.currentCategory, this)
                    .then(() => {
                        // do nothing
                    })
                    .catch((err) => {
                        Utility.debug(err, "miniFormValues.set()")
                        throw err;
                    });
            }
        }
    }


    //***
    //***
    public initializeMiniForm() {
        if (this.miniFormTableName) {
            //for the sidepanel copy of the miniform just set the isPouchComposeMode directly instead of with the propertybag
            this.miniSnapFormService.isPouchComposeMode = this.pouch.isComposeMode;
            this.miniSnapFormService.prepareMiniForm(this.miniFormTableName);
        }
    }


    //***
    //***
    public get miniFormTableName(): string {
        let tableName;

        if (this.pouch.pouchClass === PouchClass.Email && this.isTaskMiniFormExist)
            tableName = "Task";
        else if (this.pouch.pouchClass === PouchClass.Calendar && this.isEventMiniFormExist)
            tableName = "Event";

        return tableName;
    }


    //***
    //***
    public get isMiniFormValid(): boolean { return this.miniSnapFormService.isAllFieldsValid(); }


    //***
    //***
    public generateCustomSyncFieldsFromMini(values: SnapFormValue[]): CustomSyncFields {
        const result = new CustomSyncFields();

        result.tableName = this.miniSnapFormService.snapForms.tableName;
        result.tableLabel = this.miniSnapFormService.snapForms.tableLabel;
        result.recordTypeId = this.miniSnapFormService.snapForms.selectedSnapForm.recordTypeId;
        result.recordTypeLabel = this.miniSnapFormService.snapForms.selectedSnapForm.recordType.label;

        result.fields = [];
        values.forEach(item => {
            const syncField = new CustomSyncField();
            syncField.fieldName = item.fieldName;
            syncField.value = item.value;
            syncField.displayValue = item.displayValue;
            result.fields.push(syncField);
        });

        return result;
    }


    //***
    //***
    public extractSnapFormValuesFromCustomSyncFields(syncField: CustomSyncFields): MiniFormResult {
        const result = new MiniFormResult();
        result.recordTypeId = syncField.recordTypeId;
        syncField.fields.forEach(item => {
            const newValue = new SnapFormValue();
            newValue.fieldName = item.fieldName;
            newValue.value = item.value;
            newValue.displayValue = item.displayValue;
            result.fieldValues.push(newValue);
        });

        return result;
    }

    //***
    // Miniforms must know if this pouch isComposeMode so add it to the given collection
    //***
    public processComposeModeForMiniForm(collectedValues: SnapFormValue[]) {
        //For MiniForms we have to add this value so that the snapform will know if the pouch is in compose mode or not
        collectedValues.push(new SnapFormValue("LPMiniFormIsComposeMode", this.pouch.isComposeMode.toString()));
    }


    //***
    //***
    private _canImportContact = false;
    public get canImportContact(): boolean { return this._canImportContact; }

    //***
    //***
    private _contactImageUrl = "";
    public get contactImageUrl(): string { return this._contactImageUrl; }


    //***
    //***
    private _contactImagebgColor = "";
    public get contactImagebgColor(): string { return this._contactImagebgColor; }


    //***
    //***
    private _canImportAccount = false;
    public get canImportAccount(): boolean { return this._canImportAccount; }


    //***
    //***
    private _accountImageUrl = "";
    public get accountImageUrl(): string { return this._accountImageUrl; }


    //***
    //***
    private _accountImagebgColor = "";
    public get accountImagebgColor(): string { return this._accountImagebgColor; }


    //***
    //***
    private _canImportLead = false;
    public get canImportLead(): boolean { return this._canImportLead; }


    //***
    //***
    private _leadImageUrl = "";
    public get leadImageUrl(): string { return this._leadImageUrl; }


    //***
    //***
    private _leadImagebgColor = "";
    public get leadImagebgColor(): string {
        return this._leadImagebgColor;
    }


    ////***  TimeScoutOrganizer  TimeScoutOrganizer  TimeScoutOrganizer  TimeScoutOrganizer
    ////***  TimeScoutOrganizer  TimeScoutOrganizer  TimeScoutOrganizer  TimeScoutOrganizer
    ////***
    //private _timeScoutOrganizer: TimeScoutOrganizer = null;
    //public get timeScoutOrganizer(): TimeScoutOrganizer { return this._timeScoutOrganizer; }
    //public set timeScoutOrganizer(value: TimeScoutOrganizer) { this._timeScoutOrganizer = value; }


    ////***
    ////***
    //public refreshTimeScout() {
    //    this.apiRefreshTimeScoutCall().subscribe((response: RefreshTimeScoutResponse) => {
    //        try {
    //            if (response) {
    //                this.timeScoutOrganizer = response.timeScoutOrganizer;
    //                this.timeScoutSubject.next(this.timeScoutOrganizer);
    //            }
    //        } catch (error) {
    //            Utility.debug(error, "TimeScout Error");
    //        }
    //    });
    //}
    //apiRefreshTimeScoutCall(): Observable<RefreshTimeScoutResponse> {
    //    const request = new RefreshTimeScoutRequest(this.sessionService.lpUserId);
    //    return this.httpClient.post(this.timeScoutUrl, JSON.stringify(request)).pipe(map((data) => new RefreshTimeScoutResponse().deserialize(data)));
    //}


    //***
    //***
    apiAssistTokenCall(): Observable<AssistTokenResponse> {
        const request = new AssistTokenRequest(this.sessionService.lpUserId);
        return this.httpClient.post(this.assistTokenUrl, JSON.stringify(request)).pipe(map((data) => new AssistTokenResponse().deserialize(data)));
    }


    //***
    //***
    public calendarSync() {
        this.apiCalendarSyncCall().subscribe((response: SyncResponse) => {
            try {
                if (response)
                    this.toastService.send("Background Sync", "", "Calendar synchronization started", 3000, "sync");
            } catch (error) {
                Utility.debug(error, "CalendarSync Error");
            }
        });
    }
    apiCalendarSyncCall(): Observable<SyncResponse> {
        const request = new SyncRequest(this.sessionService.lpUserId, PouchClass.Calendar);
        return this.httpClient.post(this.syncUrl, JSON.stringify(request)).pipe(map((data) => new SyncResponse().deserialize(data)));
    }


    //***
    //***
    public emailSync() {
        this.apiEmailSyncCall().subscribe((response: SyncResponse) => {
            try {
                if (response)
                    this.toastService.send("Background Sync", "", "Email synchronization started", 3000, "sync");
            } catch (error) {
                Utility.debug(error, "EmailSync Error");
            }
        });
    }
    apiEmailSyncCall(): Observable<SyncResponse> {
        const request = new SyncRequest(this.sessionService.lpUserId, PouchClass.Email);
        return this.httpClient.post(this.syncUrl, JSON.stringify(request)).pipe(map((data) => new SyncResponse().deserialize(data))); //=> new RefreshTimeScoutResponse().deserialize(data)));
    }


    //***
    //***
    public contactSync() {
        this.apiContactSyncCall().subscribe((response: SyncResponse) => {
            try {
                if (response)
                    this.toastService.send("Background Sync", "", "Contact synchronization started", 3000, "sync");
            }
            catch (error) {
                Utility.debug(error, "ContactSync Error");
            }
        });
    }
    apiContactSyncCall(): Observable<SyncResponse> {
        const request = new SyncRequest(this.sessionService.lpUserId, PouchClass.Contact);
        return this.httpClient.post(this.syncUrl, JSON.stringify(request)).pipe(map((data) => new SyncResponse().deserialize(data))); //=> new RefreshTimeScoutResponse().deserialize(data)));
    }


    //***  SignalRService  SignalRService  SignalRService  SignalRService
    //***  SignalRService  SignalRService  SignalRService  SignalRService
    private establishSignalRConnection(lpUserId: string) {
        //if the new lpUserId is not empty and is different than the currentUserId
        if (lpUserId && lpUserId !== this.signalRService.currentUserId) {
            this.signalRService.startHubConnection(lpUserId).then(() => {
                this.addSignalRMessageListeners();
                this.signalRService.joinGroup(lpUserId);
            }).catch(err => console.log('Error while starting SignalR connection: ' + err))
        }
    }

    //**
    //**
    public broadcastReBootstrap(force: boolean = true, loadClientState = true) {
        //If connected to SignalR then rebootstrap using SignalR otherwise just refresh the currect client
        if (this.signalRService.IsConnected)
            this.signalRService.broadcastReBootstrap(this.sessionService.lpUserId);
        else
            this.bootstrap(force, loadClientState);
    }

    //**
    //**
    private addSignalRMessageListeners() {
        //Listener for BroadcastReBootstrap SignalR method
        this.signalRService.hubConnection.on('BroadcastReBootstrap', (incomingMessage) => {
            this._ngZone.run(() => {
                this.toastService.send("Notice", "", "Refreshing Data...", 3000, "signalr");
                this.bootstrap(true);
            });
        });

        this.signalRService.hubConnection.on('BroadcastToast', (incomingMessage, duration) => {
            this._ngZone.run(() => {
                this.toastService.send("Notice", "", incomingMessage, duration, "signalr");
            });
        });
    }


    //***
    //***
    public isStartTourNextBootstrap: boolean = false; //Flag used to start a tour after the next bootstrap occurs
    public isTourMode: boolean = false; //Used to know when a Tour is happening
    private isTourListenersExist: boolean = false; //Used to know if tour event listeners are set up
    //***
    //***
    public startTour() {
        this.isStartTourNextBootstrap = false; //Clear the bootstrap flag
        this.isTourMode = true; //Turn on the isTourMode flag when the Tour starts

        //Fetch Tour Data
        this.search(SearchType.SearchBar, ["!!sample"]);

        var steps: INgxbStepOption[];

        //Create the Tour Steps
        steps = [
            {
                anchorId: 'start-tour',
                content: 'Welcome! This guided tour will walk you through the LinkPoint Blade features. Just click ‘Next’ to proceed.',
                title: 'LinkPoint Blade Tutorial',
                placement: 'bottom',
            },
            {
                anchorId: 'tourSearchBox',
                content: 'This is the search box where you can enter any term to find a specific item in Salesforce.',
                title: 'Search',
                placement: 'bottom',
            },
            {
                anchorId: 'tourSearchResults',
                content: 'These are the items in Salesforce that match the search criteria.',
                title: 'Search Results',
                placement: 'bottom',
                isOptional: true,
            },

            //The following contain the correct positioning if the tour highlights them from the search deck
            {
                anchorId: 'tourSidekick',
                content: 'This arrow will open another window that will display all related data from Salesforce.',
                title: 'Sidekick',
                placement: 'bottom',
                isOptional: true,
            },
            {
                anchorId: 'tourEdit',
                content: 'This icon will allow you to modify this Salesforce item.',
                title: 'Edit',
                placement: 'bottom',
                isOptional: true,
            },
            {
                anchorId: 'tourCreate',
                content: 'This icon will allow you to create a new child object in Salesforce for the given item.',
                title: 'Create',
                placement: 'bottom',
                isOptional: true,
            },
            {
                anchorId: 'tourQuickComment',
                content: 'This icon will allow you to append an extra comment to the description of this Salesforce item.',
                title: 'Quick Comment',
                placement: 'bottom',
                isOptional: true,
            },
            {
                anchorId: 'tourOpenInSF',
                content: 'This icon will open a new browser tab directly to this item in Salesforce.',
                title: 'Open in Salesforce',
                placement: 'bottom',
                isOptional: true,
            },

            {
                anchorId: 'tourSuggestResults',
                content: 'These are our suggested Salesforce items that should be related to this email.',
                title: 'Suggestions',
                placement: 'top',
                containerClass: 'tourSuggestResultsClass', //Manually adjust position because the default is wrong in this section
                isOptional: true,
            },
            {
                anchorId: 'tourSuggestAccept',
                content: 'You can accept all of the given Suggestions at once by clicking this button.',
                title: 'Accept All',
                placement: 'top',
                containerClass: 'tourSuggestAcceptClass',  //Manually adjust position because the default is wrong in this section
                isOptional: true,
            },
            {
                anchorId: 'tourPrimary',
                content: 'Clicking this star on a particular item will mark this item as the primary reference.',
                title: 'Primary',
                placement: 'right',
                isOptional: true,
            },

            //The following contain the correct positioning classes if the tour highlights them from the suggest deck
            //{
            //    anchorId: 'tourSidekick',
            //    content: 'This arrow will launch another window show all child items from Salesforce of the particular item.',
            //    title: 'Sidekick',
            //    placement: 'top',
            //    containerClass: 'tourSidekickClass',  //Manually adjust position because the default is wrong in this section
            //    isOptional: true,
            //},
            //{
            //    anchorId: 'tourEdit',
            //    content: 'This icon will allow you to modify this Salesforce item.',
            //    title: 'Edit',
            //    placement: 'top',
            //    containerClass: 'tourActionOuterClass',  //Manually adjust position because the default is wrong in this section
            //    isOptional: true,
            //},
            //{
            //    anchorId: 'tourCreate',
            //    content: 'This icon will allow you to create a new child object in Salesforce for the given item.',
            //    title: 'Create',
            //    placement: 'top',
            //    containerClass: 'tourActionInnerClass',  //Manually adjust position because the default is wrong in this section
            //    isOptional: true,
            //},
            //{
            //    anchorId: 'tourQuickComment',
            //    content: 'This icon will allow you to append an extra comment to the description of this Salesforce item.',
            //    title: 'Quick Comment',
            //    placement: 'top',
            //    containerClass: 'tourActionInnerClass',  //Manually adjust position because the default is wrong in this section
            //    isOptional: true,
            //},
            //{
            //    anchorId: 'tourOpenInSF',
            //    content: 'This icon will open a new browser tab directly to this item in Salesforce.',
            //    title: 'Open in Salesforce',
            //    placement: 'top',
            //    containerClass: 'tourActionOuterClass',  //Manually adjust position because the default is wrong in this section
            //    isOptional: true,
            //},

            {
                anchorId: 'tourMiniForm',
                content: 'This button will allow you to populate any extra fields for the recorded activity.',
                title: 'Extra Activity Fields',
                placement: 'top',
                isOptional: true,
            },
            {
                anchorId: 'tourPref',
                content: 'This button will allow you to view and modify your preferences.',
                title: 'Preferences',
                placement: 'top',
            },
        ];

        //Set the Tour Settings
        var settings = {
            enableBackdrop: true,
            disablePageScrolling: true
        };

        //Set up listeners if they dont exist yet
        if (!this.isTourListenersExist) {
            //Subscribe to Tour Events
            this.tourService.end$.subscribe(() => {
                this.isTourMode = false; //Turn off the isTourMode flag when the Tour ends
                //Fetch Pouch Data
                this.refresh();
            });

            this.isTourListenersExist = true;
        }

        //Initialize the Tour
        this.tourService.initialize(steps, settings);

        //wait an extra 2 seconds to give the sample query time to complete
        setTimeout(() => {
            this.tourService.start();
        }, 2000);
    }
}
