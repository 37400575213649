import { Injectable, NgZone } from "@angular/core";
import { Router } from '@angular/router';
import { take } from "rxjs/operators";
import { SublimeService } from "./sublime.service";
import Utility from '../../shared/utility';
import { MacHandlerSnapFormService } from "../../snap-forms/services/mac-handler-snapform.service";
import { SessionService } from "./session.service";
import { Card } from "../../models/card";
import { SelectCardAction } from '../../models/enums';
import { ToastNotificationService } from "./toast-notification.service";

@Injectable({
    providedIn: "root",
})
export class MiniFormDialogService {

    //***
    //***
    constructor(private sublimeService: SublimeService, private macHandler: MacHandlerSnapFormService,
        private sessionService: SessionService, private router: Router, private _ngZone: NgZone, private toastService: ToastNotificationService) {

    }

    //***
    //***
    public async openMiniForm(messageReceivedhandler: (args: { message: string, origin: string | undefined }) => void, eventReceivedhandler: (args: { message: string, origin: string | undefined }) => void, cards?: Card[]) {
        //Stuff the propertyBag before opening the dialog in order to pass pre-existing values to the new form
        if (this.sublimeService.miniFormValues) {
            let collectedValues = this.sublimeService.miniFormValues.fieldValues;
            this.sublimeService.processComposeModeForMiniForm(collectedValues);

            this.sessionService.setPropertyBag(this.sessionService.propertyBagAuthToken, await this.sessionService.forceTokenUpdate()).subscribe(
                (response) => {
                    this.sessionService.setPropertyBag(this.sessionService.propertyBagInjectSnapFormValues, collectedValues).subscribe(
                        (response) => {
                            this.sessionService.setPropertyBag(this.sessionService.propertyBaginjectSnapFormRecordType, this.sublimeService.miniFormValues.recordTypeId).subscribe(
                                (response) => {
                                    if (this.sessionService.isMAC)
                                        this.handleMac(messageReceivedhandler, eventReceivedhandler, cards);
                                    else
                                        this.launchDialog(messageReceivedhandler, eventReceivedhandler);
                                },
                                (error) => {
                                    Utility.debug(error);
                                }
                            );
                        },
                        (error) => {
                            Utility.debug(error);
                        }
                    );
                },
                (error) => {
                    Utility.debug(error);
                }
            );
        }
        else {
            if (this.sessionService.isMAC)
                this.handleMac(messageReceivedhandler, eventReceivedhandler, cards);
            else
                this.launchDialog(messageReceivedhandler, eventReceivedhandler);
        }
    }

    //**
    //**
    private handleMac(messageReceivedhandler: (args: { message: string, origin: string | undefined }) => void, eventReceivedhandler: (args: { message: string, origin: string | undefined }) => void, cards?: Card[]) {
        //initialize the mac miniform depending on if there is a card passed in or not
        if (cards && cards.length == 1)
            this.macHandler.initializeMacFormMiniWithCard(this.sublimeService.miniFormTableName, cards[0]);
        else
            this.macHandler.initializeMacFormMini(this.sublimeService.miniFormTableName);

        //initial result will always be blank so subscribe for 2 values
        this.macHandler.miniFormSubject.pipe(take(2)).subscribe((value) => {
            if (value) {
                this._ngZone.run(() => {
                    this.sublimeService.miniFormValues = value;

                    //If the origin did not come from the mini form button then try to add to sf
                    if (cards) {
                        this.sublimeService.select(SelectCardAction.Added, cards).then((response) => {
                            if (!response) {
                                //reopen the miniform if there is a sf error during select
                                this.openMiniForm(messageReceivedhandler, eventReceivedhandler, cards);
                            }
                        });
                    } else {
                        //show a toast from the footer
                        this.toastService.send('Success', "", "Values updated for selected items.", 3000, "SnapForm");
                    }
                });
            }
        });
    }

    //***
    //***
    public officeDialog: Office.Dialog;
    private officeDialogUrl = window.location.origin + "#/";
    private async launchDialog(messageReceivedhandler: (args: { message: string, origin: string | undefined }) => void, eventReceivedhandler: (args: { message: string, origin: string | undefined }) => void) {
        if (this.sublimeService.miniFormTableName) {
            const url = this.officeDialogUrl + this.router.serializeUrl(this.router.createUrlTree([`/SnapForm/${this.sessionService.lpUserId}/${this.sublimeService.miniFormTableName}/Mini/`]));

            await Office.context.ui.displayDialogAsync(url, { height: this.sessionService.dialogHeightMedium, width: this.sessionService.dialogWidthSmall, promptBeforeOpen: false, displayInIframe: true },
                (result) => {
                    if (result.status === Office.AsyncResultStatus.Failed) {
                        Utility.debug(`Dialog failed: ${result.error.code} ${result.error.message}`);
                    } else {
                        this.officeDialog = result.value;
                        this.officeDialog.addEventHandler(Office.EventType.DialogMessageReceived, messageReceivedhandler);
                        this.officeDialog.addEventHandler(Office.EventType.DialogEventReceived, eventReceivedhandler);
                    }
                }
            );
        }
    }
}
