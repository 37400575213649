<div class="lp-search-panel"
     style="height: 100vh; display: grid; grid-template-rows: auto auto 2fr auto auto auto; overflow: hidden">
    <app-header></app-header>
    <app-lp-search-bar style="margin-top: .25rem; margin-bottom: 1px;"></app-lp-search-bar>

    <div tourAnchor="tourSearchResults" style="overflow-y: auto; overflow-x: hidden;">
        <app-lp-billboard *ngIf="searchBillboard.isVisible"
                          class="lp-search-billboard"
                          [title]="searchBillboard.title"
                          [subTitle]="searchBillboard.subTitle"></app-lp-billboard>
        <app-lp-deck *ngIf="searchDeck.isVisible"
                     [deck]="searchDeck"
                     [displayGroups]="true"
                     [displayTableLabel]="false"
                     [displayCardCells]="true"
                     [groupHeaderBackgroundColor]="groupHeaderBackgroundColor"
                     [groupHeaderForegroundColor]="groupHeaderForegroundColor"></app-lp-deck>
    </div>


    <div class="resize-handle"
         (mousedown)="setResizeMode($event, true)"></div>
    <div #resizableContainer
         tourAnchor="tourSelect"
         class="resizable-container"
         [style.height.px]="resizableContainerHeight"
         (window:mouseup)="setResizeMode($event, false)"
         style="overflow-y: auto; overflow-x: hidden; display: grid; grid-template-rows: auto 1fr auto">
        <div>
            <ng-template #headerTemplate>
                <app-open-link *ngIf="canOpenRelatedTask"
                               style="float: right; margin-right: 4px;"
                               [urlLink]="relatedTaskUrl"
                               [componentState]="OpenRelatedTaskState"
                               [lpTooltip]="OpenRelatedTaskToolTip"
                               delay="750"></app-open-link>
                <app-lp-svg *ngIf="canFollowUp"
                            style="float: right; margin-right: 4px;"
                            [imagePoints]="followUpImagePoints"
                            [opacity]="followUpImageOpacity"
                            role="button"
                            lpTooltip="Create/Edit Follow-Up Task"
                            delay="750"
                            (click)="followUp_Click()"></app-lp-svg>
                <app-lp-svg *ngIf="canClearSelections"
                            style="float: right; margin-right: 8px;"
                            [imagePoints]="removeAllImagePoints"
                            [opacity]="isCardSelectingOpacity"
                            [style.pointer-events]="isCardSelectingMousePointer"
                            role="button"
                            lpTooltip="Remove All Selections"
                            delay="750"
                            (click)="clearSelections_Click()"></app-lp-svg>
            </ng-template>

            <app-lp-header class="noselect"
                           [title]="selectPaneTitle"
                           [subTitle]="selectPaneSubTitle"
                           [backgroundColor]="deckHeaderBackgroundColor"
                           [foregroundColor]="deckHeaderForegroundColor"
                           [imagePoints]="shareImagePoints"
                           [imageSize]="22"
                           [filler]="headerTemplate"></app-lp-header>
            <div *ngIf="canAcceptSuggestions"
                 tourAnchor="tourSuggestAccept"
                 role="button"
                 class="text-center lp-accept-suggestion"
                 [style.pointer-events]="isCardSelectingMousePointer"
                 (click)="acceptSuggestion_Click()">
                {{acceptSuggestionLabel}}
            </div>
        </div>

        <div tourAnchor="tourSuggestResults" style="overflow-y: auto; overflow-x: hidden; background-color: white; border-bottom-left-radius: 5px; border-bottom-right-radius: 5px;">
            <app-lp-billboard *ngIf="selectBillboard.isVisible"
                              class="lp-select-billboard"
                              [title]="selectBillboard.title"
                              [subTitle]="selectBillboard.subTitle"></app-lp-billboard>
            <app-lp-deck *ngIf="suggestDeck.isVisible"
                         [deck]="suggestDeck"
                         [displayGroups]="false"
                         [displayTableLabel]="true"
                         [displayCardCells]="false"></app-lp-deck>
            <app-lp-deck *ngIf="selectDeck.isVisible"
                         [deck]="selectDeck"
                         [displayGroups]="false"
                         [displayTableLabel]="true"
                         [displayCardCells]="false"></app-lp-deck>
        </div>
        <app-footer></app-footer>
    </div>
    <div class="toast-footer">
        <app-toast-notification></app-toast-notification>
    </div>
</div>
