import { IRequest } from "./interfaces/IRequest.interface";

export class LogAppendRequest implements IRequest {
    public lpUserId: string;
    public logEntry: string;

    constructor(lpUserId: string, logEntry: string) {
        this.lpUserId = lpUserId;
        this.logEntry = logEntry;
    }
}
