<div>
    <snap-lp-field-label [snapFormField]="snapFormField"></snap-lp-field-label>

    <div style="width: 100%; display: grid; grid-template-columns: auto min-content min-content;">

        <div class="lp-read-only-element lp-display-value"
             (click)="highlightButton()" (dblclick)="lookup()">
            {{displayValue}}
        </div>

        <button type="button"
                class="btn lookup-button"
                (click)="clear()"
                [disabled]="!snapFormField.isEnabled ? 'disabled': null">
            <app-lp-svg [imagePoints]="clearImagePoints"
                        [imageSize]="26"
                        style="align-self: flex-end;"
                        [imageBackgroundColor]="imageBackgroundColor"
                        [imageForegroundColor]="imageForegroundColor"
                        lpTooltip="Clear value"></app-lp-svg>
        </button>

        <button #lookupButton type="button"
                class="btn lookup-button"
                (click)="lookup()"
                [disabled]="!snapFormField.isEnabled ? 'disabled': null">
            <app-lp-svg [imagePoints]="lookupImagePoints"
                        [imageSize]="26"
                        style="align-self: flex-end;"
                        [imageBackgroundColor]="imageBackgroundColor"
                        [imageForegroundColor]="imageForegroundColor"
                        lpTooltip="Select lookup value"></app-lp-svg>
        </button>
    </div>
</div>
