import { IDeserializable } from "./interfaces/IDeserializable.interface";


export class QuickCommentRequest implements IDeserializable {

    //***  
    public lpUserId: string;
    public tableName: string;
    public recordId: string;
    public contextToken: boolean;
    public comment: string;
    public time: Date;

    //***
    //***
    constructor(lpUserId: string, tableName: string, recordId: string, comment: string, time: Date) {
        this.lpUserId = lpUserId;
        this.tableName = tableName;
        this.recordId = recordId;
        this.comment = comment;
        this.time = time;
    }

    deserialize(input: QuickCommentRequest): this {
        Object.assign(this, input);

        return this;
    }

}
