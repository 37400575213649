<div>
    <snap-lp-field-label [snapFormField]="snapFormField"></snap-lp-field-label>
    <div>
        <textarea class="lp-input-textarea-element"
                  [id]="snapFormField.fieldName"
                  [name]="snapFormField.fieldName"
                  [rows]="displayLines"
                  [(ngModel)]="value"
                  [disabled]="!snapFormField.isEnabled ? 'disabled': null"
                  [attr.maxlength]="snapFormField.maxLength"></textarea>
    </div>
</div>
