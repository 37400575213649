import { Component, Input, NgZone, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { take } from 'rxjs/operators';
import { PanelService } from '../../core/services/panelService';
import { SessionService } from '../../core/services/session.service';
import { SublimeService } from '../../core/services/sublime.service';
import { ToastNotificationService } from '../../core/services/toast-notification.service';
import { MiniFormDialogService } from '../../core/services/miniFormDialog.service';
import { Card } from '../../models/card';
import { Cell } from '../../models/cell';
import { DeckType, PanelType, SearchType, SelectCardAction, Visibility } from '../../models/enums';
import Utility from '../../shared/utility';
import { MacHandlerSnapFormService } from '../../snap-forms/services/mac-handler-snapform.service';

@Component({
    selector: 'app-lp-card',
    templateUrl: './lp-card.component.html',
    styleUrls: ['./lp-card.component.scss']
})
export class LPCardComponent implements OnInit {
    public readonly sidekickImagePoints: string = Utility.svgChevronRight;
    public readonly sidekickImageBackground: string = Utility.LPTransparent;
    public readonly sidekickImageForeground: string = Utility.LPOrange;

    private hover: boolean;

    @Input() card: Card;
    @Input() displayCardCells: boolean;
    @Input() displayTableLabel: boolean;
    @Input() displaySidekick = true;
    @Input() isFirst: boolean; //Used to mark the first card in a group

    get effectiveDisplaySidekick(): boolean { return this.sessionService.sidekickVisibility == Visibility.Visible && this.displaySidekick && this.card.groupId !== "_Connections"; }


    get canImportContact(): boolean { return (this.card.isConnectionsTable && this.sublimeService.canImportContact); }
    get canImportAccount(): boolean { return (this.card.isConnectionsTable && this.sublimeService.canImportAccount); }
    get canImportLead(): boolean { return (this.card.isConnectionsTable && this.sublimeService.canImportLead); }
    get canSnapFormCreate(): boolean { return this.sessionService.cardCreateVisibility == Visibility.Visible }
    get canSnapFormEdit(): boolean { return this.card.isUpdateable && this.sessionService.cardEditVisibility == Visibility.Visible }
    get canQuickComment(): boolean { return this.card.supportsQuickComment && this.sessionService.cardQuickCommentVisibility == Visibility.Visible; }
    //This card is allowed to be enabled and there are no other cards currently being selected
    get isCheckboxEnabled(): boolean { return this.card.isCheckBoxEnabled && !this.sublimeService.isCardSelecting; }


    //***
    public get canOpenLink(): boolean { return this.sessionService.openInBrowserVisibility == Visibility.Visible && !Utility.isNullOrEmpty(this.card.url); }


    //**
    //**
    get leftCells(): Cell[] {
        const result: Cell[] = [];
        const visCells = this.visCells;
        for (let i = 0; i < visCells.length; i += 2)
            result.push(visCells[i]);
        return result;
    }


    //**
    //**
    get rightCells(): Cell[] {
        const result: Cell[] = [];
        const visCells = this.visCells;
        for (let i = 1; i < visCells.length; i += 2)
            result.push(visCells[i]);
        return result;
    }


    //**
    //**
    get visCells(): Cell[] {
        const result: Cell[] = [];
        for (const cell of this.card.cells)
            if (cell.isVisible)
                result.push(cell);
        return result;
    }





    //**
    //**
    mouseEnter() { this.hover = true; }
    mouseLeave() { this.hover = false; }


    //***
    //***
    mouseDoubleClick(event: MouseEvent) {
        if (event && event.ctrlKey === true) {
            Utility.debug(this, `Card: ${this.card.tableLabel}.${this.card.header.title}`);
            event.cancelBubble = true;
        }
    }




    //**
    //**
    async onSelectChange(checked: boolean) {
        if (checked) {
            if (this.sublimeService.isMiniFormValid) {
                this.sublimeService.select(SelectCardAction.Added, [this.card]).then((response) => {
                    if (!response) {
                        //reopen the miniform if there is a sf error during select
                        this.miniFormDialogService.openMiniForm(this.processOfficeDialogMessage, this.processOfficeDialogEvent, [this.card]);
                    }
                });
            }
            else {
                await this.miniFormDialogService.openMiniForm(this.processOfficeDialogMessage, this.processOfficeDialogEvent, [this.card]);
            }
        }
        else {
            this.sublimeService.select(SelectCardAction.Removed, [this.card]);
        }
    }

    //***
    //*** Fired when dialog calls Office.context.ui.messageParent()
    private processOfficeDialogMessage = (arg: any) => {
        this._ngZone.run(() => {
            if (arg.message) {
                this.sublimeService.miniFormValues = JSON.parse(arg.message);
                this.sublimeService.select(SelectCardAction.Added, [this.card]).then((response) => {
                    if (!response) {
                        //reopen the miniform if there is a sf error during select
                        this.miniFormDialogService.openMiniForm(this.processOfficeDialogMessage, this.processOfficeDialogEvent, [this.card]);
                    }
                });
            }
            else {
                //if the user cancels out of the dialog then undo their selection
                this.undoSelection();
            }
            this.miniFormDialogService.officeDialog.close();
        });
    };


    //***
    //*** Fired when dialog is closed by the user
    private processOfficeDialogEvent = (arg) => { this.undoSelection(); };


    //**
    //**
    private undoSelection() {
        this.card.isChecked = false;
        this.toastService.send('Selection Undone', "", "You must fill in required fields to apply selection", 5000, "Select");
    }


    //**
    //**
    public get canSetPrimary(): boolean { return this.card.canSetPrimary && this.sessionService.primaryWhoVisibility == Visibility.Visible }


    //**
    //**
    setPrimary_Click() {
        //Do nothing if a selection is happening
        if (!this.sublimeService.isCardSelecting) {
            this.sublimeService.setPrimary(this.card);
        }
    }


    //**
    //**
    public get primaryImagePoints(): string { return this.card.isPrimary && !this.sublimeService.isCardSelecting ? Utility.svgStarLit : Utility.svgStarDim; }
    public get primaryTooltip() { return this.card.isPrimary ? "This is the primary contact" : ""; }


    //**
    //**
    constructor(private sublimeService: SublimeService, private panelService: PanelService, private macHandler: MacHandlerSnapFormService,
        private sessionService: SessionService, private router: Router, private _ngZone: NgZone, private toastService: ToastNotificationService, private miniFormDialogService: MiniFormDialogService) {
        this.card = null;
        this.displayCardCells = true;
        this.displayTableLabel = true;
        this.hover = false;
    }


    //**
    //**
    ngOnInit() {
        //If this is a card in a sidekick deck, we need to get its isChecked value from the sidepanel
        if (this.card.deck.deckType === DeckType.SideKickRoot || this.card.deck.deckType === DeckType.SideKickRelated) {
            const sourcePane = this.sublimeService.selectPanePipe.value;
            const selectDeck = sourcePane.decks.find(x => x.deckType === DeckType.Select);
            this.card.isChecked = selectDeck.cards.some(x => x.id === this.card.id && x.isChecked)
        }
    }


    //**
    //**
    get isShowHoverItems(): boolean { return this.hover || this.sublimeService.isTourMode; }
    get isShowHoverActions(): boolean { return this.hover || this.sublimeService.isTourMode || this.sessionService.isMAC; }

    //**
    //**
    get isLinkValid(): boolean { return this.card.url ? true : false; }


    //**
    //**
    sidekickClick(card: Card) {
        this.sublimeService.search(SearchType.SideKick, [card.id]);
        this.panelService.displayModal(PanelType.Sidekick);
    }
}
