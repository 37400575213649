import { Card } from "./card";
import { SuggestionSource } from "./enums";
import { IDeserializable } from "./interfaces/IDeserializable.interface";

export class DataSet implements IDeserializable {
    public queryDuration: number;
    public cards: Card[] = [];
    public suggestionSource: SuggestionSource = SuggestionSource.Undefined;

    deserialize(input: DataSet): this {
        Object.assign(this, input);
        // Iterate over all cards and map them to a proper model.
        if (Array.isArray(input?.cards)) {
            this.cards = input.cards.map((card) => new Card().deserialize(card));
        }
        return this;
    }
}

