<div class="container-small lp-no-gutters">

    <div class="row lp-no-gutters"
         *ngFor="let row of rowFields">

        <div class="col-sm-6 lp-no-gutters"
             style="padding-right: 3px;">
            <snap-lp-field [snapFormField]="row.leftField"></snap-lp-field>
        </div>

        <div class="col-sm-6 lp-no-gutters"
             style="padding-left: 3px;">
            <snap-lp-field [snapFormField]="row.rightField"></snap-lp-field>
        </div>

    </div>

</div>