import { IDeserializable } from "./interfaces/IDeserializable.interface";

export class AssistTokenRequest implements IDeserializable {
    public lpUserId: string;

    constructor(lpUserId: string) {
        this.lpUserId = lpUserId;
    }

    deserialize(input: AssistTokenRequest): this {
        Object.assign(this, input);

        return this;
    }

}
