import Utility from "../../../shared/utility";
import { SnapFormService } from "../../services/snapform.service";
import { LPFieldType } from "../enums";
import { SnapFormField } from "../snapFormField";
import { Visitor } from "./Visitor";

export class PicklistDependentValidator extends Visitor {
    //***
    private service: SnapFormService;


    //***
    //***
    constructor(service: SnapFormService) {
        super();
        this.service = service;
    }


    //***
    //***
    canVisit(field: SnapFormField): boolean {
        return super.commonCanVisitFieldType(field, [LPFieldType.Boolean, LPFieldType.Picklist, LPFieldType.MultiPicklist]) && field.isDependentPicklist;
    }


    //***
    //*** Used on child/dependent fields where their value depends on the parents selection
    //***
    visit(field: SnapFormField, value: string) {
        if (super.commonVisit(field, value)) {
            const parentField = this.service.snapForms.selectedSnapForm.findField(field.fieldName);
            const parentValue = this.service.getValue(parentField.fieldName);
            const picklistEntry = parentField.picklistValues.find(x => x.value === parentValue);
            const picklistIndex = parentField.picklistValues.indexOf(picklistEntry);

            if (field.isRequired || !Utility.isNullOrEmpty(value)) {
                const validValues = field.picklistValues.filter(x => x.validForIndex.find(x => x === picklistIndex));
            }
        }
    }
}
