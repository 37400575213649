import LPDateTime from "../../../shared/LPDateTime";
import Utility from "../../../shared/utility";
import { LPFieldType } from "../enums";
import { SnapFormField } from "../snapFormField";
import { Visitor } from "./Visitor";

export class DateValidator extends Visitor {
    //***
    //***
    canVisit(field: SnapFormField): boolean {
        return super.commonCanVisitFieldType(field, [LPFieldType.Date]); //, LPFieldType.DateTime]);
    }

    //***
    //***
    visit(field: SnapFormField, value: string) {
        if (super.commonVisit(field, value))
            if (value && !LPDateTime.isDate(value)) {
                field.validationMessage = "Invalid date value";
            }
    }
}
