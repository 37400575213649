import { IDeserializable } from "./interfaces/IDeserializable.interface";
import { ResponseBase } from "./responseBase";

export class SyncResponse extends ResponseBase implements IDeserializable {
    deserialize(input: any): this {
        Object.assign(this, input);
        super.deserialize(input);
        return this;
    }
}
