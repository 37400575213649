import { IDeserializable } from "./interfaces/IDeserializable.interface";

export class LPEmailAddress implements IDeserializable {
    public displayName: string;
    public address: string;

    //***
    //***
    constructor(displayName?: string, address?: string) {
        this.displayName = displayName ?? "";
        this.address = address ?? "";
    }

    deserialize(input: LPEmailAddress): this {
        Object.assign(this, input);
        return this;
    }

    //***
    //***
    public static FromOfficeEmailAddresses(
        officeEmailAddresses: Office.EmailAddressDetails[]
    ): LPEmailAddress[] {
        let result: LPEmailAddress[] = [];
        officeEmailAddresses.forEach((x) =>
            result.push(this.FromOfficeEmailAddress(x))
        );
        return result;
    }

    //***
    //***
    public static FromOfficeEmailAddress(
        officeEmailAddress: Office.EmailAddressDetails
    ): LPEmailAddress {
        const result = new LPEmailAddress(
            officeEmailAddress.displayName,
            officeEmailAddress.emailAddress
        );
        return result;
    }
}
