import { AfterViewInit, Component, ElementRef, HostListener, NgZone, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { cloneDeep, orderBy } from "lodash";
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { OfficeService } from '../../core/services/office.service';
import { SessionService } from '../../core/services/session.service';
import { SublimeService } from '../../core/services/sublime.service';
import { ToastNotificationService } from '../../core/services/toast-notification.service';
import { MiniFormDialogService } from "../../core/services/miniFormDialog.service";
import { Deck } from '../../models/deck';
import { EmailPouch } from '../../models/emailPouch';
import { ComponentState, DeckType, SuggestionSource, SelectCardAction, Visibility } from '../../models/enums';
import { Header } from '../../models/header';
import { IPouch } from '../../models/interfaces/IPouch.interface';
import { Pane } from '../../models/pane';
import { RemoteAction } from '../../models/remoteAction';
import { ConfirmDialogComponent } from '../../shared/confirm-dialog/confirm-dialog.component';
import Utility from '../../shared/utility';
import { SnapFormValue } from '../../snap-forms/models/snapFormValue';
import { MacHandlerSnapFormService } from '../../snap-forms/services/mac-handler-snapform.service';

@Component({
    selector: 'app-lp-search-panel',
    templateUrl: './lp-search-panel.component.html',
    styleUrls: ['./lp-search-panel.component.scss']
})
export class LPSearchPanelComponent implements OnInit, AfterViewInit {
    public readonly shareImagePoints = Utility.svgShare;
    public readonly removeAllImagePoints = Utility.svgPlaylistRemove;
    public readonly followUpImagePoints = Utility.svgAlarmClock;

    public readonly deckHeaderBackgroundColor = Utility.LPBlue;
    public readonly deckHeaderForegroundColor = Utility.LPWhite;

    public readonly groupHeaderBackgroundColor = Utility.LPLightGray;
    public readonly groupHeaderForegroundColor = Utility.LPBlue;

    public searchPane: Pane = null;
    public searchDeck: Deck = null;
    public searchBillboard: Header = null;

    public selectPane: Pane = null;
    public suggestDeck: Deck = null;
    public selectDeck: Deck = null;
    public selectBillboard: Header = null;

    public selectPaneTitle = "";
    public selectPaneSubTitle = "";
    public selectPaneImagePoints = "";

    public acceptSuggestionLabel = "";
    public acceptSuggestionVisible = false;
    public OpenRelatedTaskState: ComponentState = ComponentState.Hidden;

    bsModalRef?: BsModalRef;
    private bootStrapCallCount = 0;

    constructor(private router: Router,
        private sublimeService: SublimeService,
        private sessionService: SessionService,
        private _ngZone: NgZone,
        private toastService: ToastNotificationService,
        private modalService: BsModalService,
        private officeService: OfficeService,
        private macHandler: MacHandlerSnapFormService,
        private miniFormDialogService: MiniFormDialogService,
    ) {
        this.sublimeService.bootStrapSubject.subscribe(() => {
            if (this.bootStrapCallCount++ === 1) {
                // dont want change in one window to affect another.  so ignore the initial first value..its always 150. Take the second...and ignore anything after that.
                this.resizableContainerHeight = this.sessionService.sidePanelSplitterHeight;
            }
        });
    }


    //***
    //***
    private fixSelectPaneHeader(): void {
        if (this.selectDeck && this.suggestDeck) {
            const selectDeckVisible = this.selectDeck && this.selectDeck.cards.length > 0 && this.selectDeck.isVisible;

            this.selectPaneTitle = selectDeckVisible ? "Related to Salesforce" : "Suggested Records";

            this.selectPaneSubTitle = this.sublimeService.getPouch().isComposeMode ? "Pending Synchronization" : "";

            if (!selectDeckVisible && this.sublimeService.suggestionSource) {
                if (this.sublimeService.suggestionSource === SuggestionSource.EmailThread)
                    this.selectPaneSubTitle = "Conversation History";

                else if (this?.sublimeService?.suggestionSource === SuggestionSource.Relevance)
                    this.selectPaneSubTitle = "Recently Viewed";
            }

            if (this.suggestDeck?.cards) {
                if (!this.suggestDeck?.cards)
                    this.acceptSuggestionLabel = "";
                else if (this.suggestDeck.cards.length > 1)
                    this.acceptSuggestionLabel = "Accept All Suggestions";
                else if (this.suggestDeck.cards.length === 1)
                    this.acceptSuggestionLabel = "Accept this Suggestion";
                else
                    this.acceptSuggestionLabel = "";
                this.acceptSuggestionVisible = this.suggestDeck.isVisible && !Utility.isNullOrEmpty(this.acceptSuggestionLabel);
            }
        }
    }

    //***  Open Related Task
    //***
    public get canOpenRelatedTask(): boolean {
        //return this.sublimeService.isRelated;

        if (this.sublimeService.getPouch()?.referencePacket) {
            return true;
        }
        else {
            return false;
        }
    }


    //***
    //***
    public getOpenRelatedTaskState() {
        const pouch = this.sublimeService.getPouch()
        if (!pouch)
            this.OpenRelatedTaskState = ComponentState.Hidden
        else if (pouch?.referencePacket?.hostRecordId)
            this.OpenRelatedTaskState = ComponentState.Enabled;
        else if (pouch?.referencePacket?.referenceItems?.length > 0)
            this.OpenRelatedTaskState = ComponentState.Disabled;
        else
            this.OpenRelatedTaskState = ComponentState.Hidden
    }


    //***
    //***
    public get OpenRelatedTaskToolTip(): string {
        if (this.OpenRelatedTaskState === ComponentState.Enabled)
            return "Open Related Task";
        else if (this.OpenRelatedTaskState === ComponentState.Disabled)
            return "Pending synchronization";
        else
            return "";
    }


    //***
    //***
    public get relatedTaskUrl(): string { return this.sublimeService.relatedTaskUrl; }


    //*** FollowUp
    //***
    public get canFollowUp(): boolean { return this.sublimeService.hasSelections && !Utility.isNullOrEmpty(this.sessionService.followUpTableName) && this.sessionService.followUpVisibility == Visibility.Visible; }
    public get followUpImageOpacity(): number { return this.sublimeService.hasFollowUpTask && !this.sublimeService.isCardSelecting ? Utility.LPLitOpacity : Utility.LPDimOpacity; }
    public get isCardSelectingOpacity(): number { return !this.sublimeService.isCardSelecting ? Utility.LPLitOpacity : Utility.LPDimOpacity; }
    public get isCardSelectingMousePointer(): string { return !this.sublimeService.isCardSelecting ? "auto" : "none"; }


    //***
    //***
    async followUp_Click() {
        //Do nothing if a selection is happening
        if (!this.sublimeService.isCardSelecting) {
            // Create a payload to pass via the PropertyBag mechanism
            const payload: SnapFormValue[] = [];
            const pouch = this.sublimeService.getPouch() as EmailPouch;
            if (pouch && !pouch.referencePacket.followUpHostRecordId) {
                payload.push(new SnapFormValue("Subject", `Follow Up: ${pouch.subject}`.trim()));
                payload.push(new SnapFormValue("Description", pouch.body));
                payload.push(new SnapFormValue("FollowUpHostRecordId", pouch.referencePacket.hostRecordId));


                // Look for a contact first... primary picked first, if none, then take any
                let who = pouch.referencePacket.referenceItems.find((x) => x.id.startsWith("003") && x.isPrimary === true) ?? pouch.referencePacket.referenceItems.find((x) => x.id.startsWith("003"));
                // if no contacts found, take a who
                if (!who)
                    who = pouch.referencePacket.referenceItems.find((x) => x.id.startsWith("00Q"));
                if (who)
                    payload.push(new SnapFormValue("WhoId", who.id));

                // no process the what...only if the who is null or its a contact.  (leads cant be related to a what)
                if (!who || who.id.startsWith("003")) {
                    const what = pouch.referencePacket.referenceItems.find((x) => !x.id.startsWith("003") && !x.id.startsWith("00Q") && !x.tableName.startsWith("_"));
                    if (what) {
                        payload.push(new SnapFormValue("WhatId", what.id));
                        payload.push(new SnapFormValue("WhatTableName", what.tableName));
                    }
                }
            }

            //Pass the authtoken to the dialog
            this.sessionService.setPropertyBag(this.sessionService.propertyBagAuthToken, await this.sessionService.forceTokenUpdate()).subscribe(
                (response) => {
                    if (payload.length > 0)
                        this.sessionService.setPropertyBag(this.sessionService.propertyBagInjectSnapFormValues, payload).subscribe(async () => {
                            if (this.sessionService.isMAC)
                                this.macHandler.initializeMacFormFull(this.sessionService.followUpTableName, pouch.referencePacket.followUpHostRecordId);
                            else
                                this.launchFollowUpDialog("");
                        });
                    else
                        if (this.sessionService.isMAC)
                            this.macHandler.initializeMacFormFull(this.sessionService.followUpTableName, pouch.referencePacket.followUpHostRecordId);
                        else
                            this.launchFollowUpDialog(pouch.referencePacket.followUpHostRecordId);
                },
                (error) => {
                    Utility.debug(error);
                }
            );
        }
    }


    //***
    //***
    private async launchFollowUpDialog(recordId: string) {
        const url = (this.officeDialogUrl + this.router.serializeUrl(this.router.createUrlTree([`/SnapForm/${this.sessionService.lpUserId}/${this.sessionService.followUpTableName}/full/${recordId}`]))).replace("#//", "#/");
        await Office.context.ui.displayDialogAsync(url, { height: this.sessionService.dialogHeightLarge, width: this.sessionService.dialogWidthMedium, promptBeforeOpen: false, displayInIframe: true },
            (result) => {
                if (result.status === Office.AsyncResultStatus.Failed) {
                    Utility.debug(`Dialog failed: ${result.error.code} ${result.error.message}`);
                }
                else {
                    this.modalDialog = result.value;
                    this.modalDialog.addEventHandler(Office.EventType.DialogMessageReceived, this.processFollowUpDialogMessage);
                    this.modalDialog.addEventHandler(Office.EventType.DialogEventReceived, this.processFollowUpDialogEvent);
                }
            }
        );
    }


    //***
    public modalDialog: Office.Dialog;

    //***
    //***
    private officeDialogUrl = window.location.origin + "#/";
    private toastTopic = "SnapForm";


    //***
    //*** Fired when dialog calls Office.context.ui.messageParent()
    private processFollowUpDialogMessage = (arg: any) => {
        this._ngZone.run(() => {
            if (arg.message) {
                //Make a copy of the current reference packet
                const pouchCopy: IPouch = cloneDeep(this.sublimeService.getPouch());

                if (pouchCopy.referencePacket) {
                    //Update it with the new followup id
                    pouchCopy.referencePacket.followUpHostRecordId = arg.message;

                    //Save the updated reference packet to the Outlook object
                    this.officeService.setReferencePacket(pouchCopy.referencePacket, this.sublimeService.currentCategory, this.sublimeService)
                        .then(() => {
                            this.toastService.send('Success', "", "Follow Up created.", 3000, this.toastTopic);

                            //Save it back to the local referencePacket
                            this.sublimeService.setPouch(pouchCopy);
                        })
                        .catch((err) => {
                            throw err;
                        })
                }
            }
            this.modalDialog.close();
        });
    };

    //***
    //*** Fired when dialog is closed by the user
    private processFollowUpDialogEvent = (arg) => { };


    //***  Accept Suggestions
    //***
    get canAcceptSuggestions(): boolean { return this.sublimeService.canAcceptSuggestions }

    //***
    //***
    public acceptSuggestions(): void {
        this.sublimeService.select(SelectCardAction.Added, this.sublimeService.findDeck(DeckType.Suggest).cards).then((response) => {
            if (!response) {
                //reopen the miniform if there is a sf error during select
                this.miniFormDialogService.openMiniForm(this.processMiniOfficeDialogMessage, this.processMiniOfficeDialogEvent, this.sublimeService.findDeck(DeckType.Suggest).cards);
            }
        });
    }


    //***
    //***
    async acceptSuggestion_Click() {
        if (this.sublimeService.isMiniFormValid) {
            this.acceptSuggestions();
        }
        else {
            await this.miniFormDialogService.openMiniForm(this.processMiniOfficeDialogMessage, this.processMiniOfficeDialogEvent, this.sublimeService.findDeck(DeckType.Suggest).cards);
        }
    }


    //***
    //Fired when dialog calls Office.context.ui.messageParent()
    //***
    private processMiniOfficeDialogMessage = (arg: any) => {
        this._ngZone.run(() => {
            if (arg.message) {
                this.sublimeService.miniFormValues = JSON.parse(arg.message);
                this.acceptSuggestions();
            }
            else {
                this.undoSelection();
            }
            this.miniFormDialogService.officeDialog.close();
        });
    };


    //*** Fired when dialog is closed by the user
    //*** if the user cancels out of the dialog then undo their selection
    private processMiniOfficeDialogEvent = () => { this.undoSelection(); };

    //**
    //**
    private undoSelection() {
        //loop through all cards and unselect them
        this.sublimeService.findDeck(DeckType.Suggest).cards.forEach(x => x.isChecked = false);

        this.toastService.send('Selection Undone', "", "You must fill in required fields to apply selection", 5000, "Select");
    }


    searchBillboardVisible(): boolean {
        // ToDo - ND ... why are we returning a constant?
        const result = (this.searchBillboard && this.searchBillboard.title) ? true : false;
        return false;
    }
    searchDeckVisible(): boolean { return (this.searchDeck && this.searchDeck.isVisible === true) ? true : false; }


    selectBillboardVisible(): boolean { return (this.selectBillboard && this.selectBillboard.title) ? true : false; }
    suggestDeckVisible(): boolean { return (this.suggestDeck && this.suggestDeck.isVisible === true) ? true : false; }
    selectDeckVisible(): boolean { return (this.selectDeck && this.selectDeck.isVisible === true) ? true : false; }


    //***
    //***
    ngOnInit() {
        this.sublimeService.searchPanePipe.subscribe(sourcePane => {
            if (sourcePane) {
                if (this.searchPane) {
                    this.searchPane.setState(sourcePane.getState());
                }
                else {
                    this.searchPane = sourcePane;
                }

                this.searchBillboard = sourcePane.billboard ?? new Header(null, "", "", "", false);
                this.searchDeck = sourcePane.decks.find(x => x.deckType === DeckType.Search);
                this.searchDeck.groups = orderBy(this.searchDeck.groups, ['rank'], ['asc']);
                this.searchDeck.groups.forEach(x => x.cards = orderBy(x.cards, ['sortValue']));
                this.fixSelectPaneHeader();
                this.getOpenRelatedTaskState();
            }
        });

        this.sublimeService.selectPanePipe.subscribe(sourcePane => {
            if (sourcePane) {
                if (this.selectPane)
                    this.selectPane.setState(sourcePane.getState());
                else
                    this.selectPane = sourcePane;

                this.selectBillboard = sourcePane.billboard ?? new Header(null, "", "", "", false);
                this.suggestDeck = sourcePane.decks.find(x => x.deckType === DeckType.Suggest);
                this.selectDeck = sourcePane.decks.find(x => x.deckType === DeckType.Select);
                this.selectDeck.cards = orderBy(this.selectDeck.cards, ['whoWhatRank', 'titleRank'], ['asc', 'asc']);
                this.fixSelectPaneHeader();
                this.getOpenRelatedTaskState();
            }
        });
    }

    //***
    //***
    get canClearSelections(): boolean { return this.sublimeService.canClearSelections && this.sessionService.removeAllVisibility == Visibility.Visible; }

    //***
    //***
    clearSelections_Click() { this.openRemoveConfirmModal(); }

    //***
    //***
    openRemoveConfirmModal() {
        const confirmInitialState = { questionText: 'Are you sure that you want to remove all of your selections?' };
        this.bsModalRef = this.modalService.show(ConfirmDialogComponent,
            { class: 'modal-sm sidepanel-confirm', initialState: confirmInitialState });

        //If the user confirmed: close this window
        this.bsModalRef.content.isConfirmed.subscribe((value) => {
            if (value) {
                this.sublimeService.clearSelections();
            }
        });
    }


    //***
    // Resizable Container Code
    //***
    @ViewChild("resizableContainer") public resizableContainer: ElementRef;
    public resizableContainerHeight = 150;  //Initial height is 150 or the min-height from css whichever is larger
    private resizableContainerPosition: { left: number, top: number, right: number, bottom: number };  //holds the coordinates of the container edges
    public mouse: { x: number, y: number }; //holds the coordinates of the mouse pointer
    public isResizing = false;  //indicates if we are currently resizing or not

    //***
    // Fires when everything is rendered
    //***
    ngAfterViewInit() {
        this.resizableContainerHeight = this.sessionService.sidePanelSplitterHeight;
        this.loadResizableContainer();
    }

    //***
    // Get and store the current coordinates of the container edges
    //***
    private loadResizableContainer() {
        const { left, top, right, bottom } = this.resizableContainer.nativeElement.getBoundingClientRect();
        this.resizableContainerPosition = { left, top, right, bottom };
    }

    //***
    // Toggle if we are resizing or not
    //***
    setResizeMode(event: MouseEvent, isResizing: boolean) {
        this.isResizing = isResizing;
        if (isResizing) {
            event.preventDefault();  //prevent normal dragging behavior such as highlighting when resizing
        }
        else {
            this.loadResizableContainer();
        }
    }


    //***
    // Keep track of the mouse coordinates whenever the mouse moves
    //***
    @HostListener('window:mousemove', ['$event'])
    onMouseMove(event: MouseEvent) {
        this.mouse = { x: event.clientX, y: event.clientY };

        if (this.isResizing) {
            this.resize();
        }
    }

    //***
    //***
    @HostListener('window:mouseup', ['$event'])
    onMouseUp(event: MouseEvent) {
        if (this.isResizing && this.sessionService.sidePanelSplitterHeight !== this.resizableContainerHeight) {
            this.sessionService.sidePanelSplitterHeight = this.resizableContainerHeight;
            this.sublimeService.apiRemoteAction(new RemoteAction(`UserPreference.SidePanelSplitterHeight=${this.resizableContainerHeight}`));
        }
    }
    //***
    // Calculate height while resizing
    //***
    private resize() {
        //The Height is the distance from the mouse to the bottom of the container. Do not let it go below 0.
        this.resizableContainerHeight = Number(this.mouse.y < this.resizableContainerPosition.bottom) ? this.resizableContainerPosition.bottom - this.mouse.y : 0;
    }
}
