import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { PreferencesComponent } from "../../components/preferences/preferences.component";
import { OfficeService } from "../../core/services/office.service";
import { PanelService } from "../../core/services/panelService";
import { SessionService } from "../../core/services/session.service";
import { SublimeService } from "../../core/services/sublime.service";
import { ToastNotificationService } from "../../core/services/toast-notification.service";
import { PanelType } from "../../models/enums";
import { StartSplashComponent } from "../../shared/start-splash/start-splash.component";
import { SnapFormComponent } from "../../snap-forms/components/lp-snap-form/lp-snap-form.component";
import { LicenseExpiredComponent } from "../license-expired/license-expired.component";
import { LPSidekickComponent } from "../lp-sidekick/lp-sidekick.component";
import { BootstrapErrorComponent } from "../bootstrap-error/bootstrap-error.component";

@Component({
    selector: "app-main",
    templateUrl: "./main.component.html",
    styleUrls: ["./main.component.scss"],
})
export class MainComponent {

    showOutlookButtons = false;
    conversationId = "ConvoId";
    searchHint: string = null;

    //We have to create this lookup dictionary because minification changes the string name of the components so we cant find a component by name directly.
    //Components also must be added to the entryComponents section of the app.module before being added here.
    modalComponentsMap = {
        [PanelType.Sidekick]: LPSidekickComponent,
        [PanelType.Preferences]: PreferencesComponent,
        [PanelType.LicenseExpired]: LicenseExpiredComponent,
        [PanelType.SnapForm]: SnapFormComponent,
        [PanelType.BootstrapError]: BootstrapErrorComponent,
    };
    normalConfig = {
        animated: true,
        keyboard: true,
        backdrop: true,
        ignoreBackdropClick: true,
        class: "lp-modal"
    };
    noEscConfig = {
        animated: true,
        keyboard: false,
        backdrop: true,
        ignoreBackdropClick: true,
        class: "lp-modal"
    };
    bsModalRef?: BsModalRef;

    //***
    //***
    constructor(
        private sublimeService: SublimeService,
        private sessionService: SessionService,
        private toastNotificationService: ToastNotificationService,
        private officeService: OfficeService,
        private panelService: PanelService,
        private modalService: BsModalService,
        private router: Router
    ) { }

    //***
    //***
    public get overlayDisplayValue(): string { return this.sublimeService.isTourMode ? 'block' : 'none'; }

    //***
    //***
    ngOnInit() {

        this.panelService.currentPanel.subscribe((aPanelType) => {
            if (aPanelType === PanelType.Undefined) {
                this.bsModalRef?.hide();
            }
            else {
                //get component content and display in modal
                this.bsModalRef?.hide();
                const modalContentComponent = this.modalComponentsMap[aPanelType];

                //Dont allow escaping out of the License Expired panel and Error panel
                if (aPanelType === PanelType.LicenseExpired || aPanelType === PanelType.BootstrapError) {
                    this.bsModalRef = this.modalService.show(modalContentComponent, this.noEscConfig);
                } else {
                    this.bsModalRef = this.modalService.show(modalContentComponent, this.normalConfig);
                }
            }
        });
    }
}
