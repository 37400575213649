import { IDeserializable } from "../../models/interfaces/IDeserializable.interface";
import { DependencyMap } from "./dependencyMap";
import { SnapFormPicklist } from "./snapFormPicklist";
import { SnapFormValue } from "./snapFormValue";

export class SnapFormRecordType implements IDeserializable {

    //***
    public readonly masterRecordTypeId = "012000000000000AAA";
    public layoutId: string;
    public id: string;
    public name: string;
    public label: string;
    public isAvailable = false;
    public isMaster = false;
    public isDefaultRecordType = false;


    //***
    //***
    private _defaultValues: SnapFormValue[] = [];
    public get defaultValues(): SnapFormValue[] { return this._defaultValues ?? (this._defaultValues = []); }
    public set defaultValues(value: SnapFormValue[]) { this._defaultValues = value; }


    //***
    //***
    private _picklists: SnapFormPicklist[] = [];
    public get picklists(): SnapFormPicklist[] { return this._picklists ?? (this._picklists = []); }
    public set picklists(value: SnapFormPicklist[]) { this._picklists = value; }


    //***
    //***
    private _dependencyMaps: DependencyMap[] = [];
    public get dependencyMaps(): DependencyMap[] { return this._dependencyMaps ?? (this._dependencyMaps = []); }
    public set dependencyMaps(value: DependencyMap[]) { this._dependencyMaps = value; }


    //***
    //***
    deserialize(input: any): this {
        Object.assign(this, input);

        this.defaultValues = []
        if (Array.isArray(input.defaultValues))
            for (const source of input.defaultValues)
                this.defaultValues.push(new SnapFormValue().deserialize(source));

        this.picklists = [];
        if (Array.isArray(input.picklists))
            for (const source of input.picklists)
                this.picklists.push(new SnapFormPicklist().deserialize(source));

        this.dependencyMaps = [];
        if (Array.isArray(input.dependencyMaps))
            for (const source of input.dependencyMaps)
                this.dependencyMaps.push(new DependencyMap().deserialize(source));

        return this;
    }
}
