import { Component, OnInit } from "@angular/core";

@Component({
    selector: "app-dialog-oauth-callback",
    templateUrl: "./dialog-oauth-callback.component.html",
    styleUrls: ["./dialog-oauth-callback.component.scss"],
})
export class DialogOauthCallbackComponent implements OnInit {
    //***
    //***
    ngOnInit() {
        this.complete();
    }

    //***
    //***
    complete() {
        //SF is sending the response back incorrectly like this:
        //https://localhost:44345/?code=aPrxxWbemCeRRVFBnh5YLEmywGDQqXVQj94Unq6gYcDoASiHCJVrmmLmeatsojBit0yezKwpjA%3D%3D#/sfoauth
        //So we cant use built-in methods of extracting the query param.
        //Instead we need to manually use string manipulation.
        let decodedUrl = decodeURIComponent(window.location.href);
        var authCode = decodedUrl.match("code=(.*)#")[1];

        //Send message back to parent
        if (authCode) {
            Office.context.ui.messageParent(authCode);
        }
    }
}
