import { Injector, NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { AngularSvgIconModule } from "angular-svg-icon";
import { BsDropdownModule } from "ngx-bootstrap/dropdown";
import { BsModalRef, ModalModule } from "ngx-bootstrap/modal";
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { SetAppInjector } from "./appInjector";
import { DialogLoginComponent } from "./components/dialog-login/dialog-login.component";
import { DialogOauthCallbackComponent } from "./components/dialog-oauth-callback/dialog-oauth-callback.component";
import { FooterComponent } from './components/footer/footer.component';
import { GraphDialogLoginComponent } from "./components/graph-dialog-login/graph-dialog-login.component";
import { GraphDialogOauthCallbackComponent } from "./components/graph-dialog-oauth-callback/graph-dialog-oauth-callback.component";
import { HeaderComponent } from './components/header/header.component';
import { LPBillboardComponent } from "./components/lp-billboard/lp-billboard.component";
import { LPCardComponent } from "./components/lp-card/lp-card.component";
import { LPCellComponent } from "./components/lp-cell/lp-cell.component";
import { LPDeckComponent } from "./components/lp-deck/lp-deck.component";
import { LPGroupComponent } from "./components/lp-group/lp-group.component";
import { LPHeaderComponent } from "./components/lp-header/lp-header.component";
import { LPSearchBarComponent } from "./components/lp-search-bar/lp-search-bar.component";
import { LPSearchPanelComponent } from "./components/lp-search-panel/lp-search-panel.component";
import { LPSidekickComponent } from "./components/lp-sidekick/lp-sidekick.component";
import { MainComponent } from "./components/main/main.component";
import { MicrosoftLoginComponent } from "./components/microsoft-login/microsoft-login.component";
import { PreferencesComponent } from './components/preferences/preferences.component';
import { LicenseExpiredComponent } from './components/license-expired/license-expired.component';
import { SalesforceLoginComponent } from "./components/salesforce-login/salesforce-login.component";
import { CoreModule } from "./core/core.module";
import { GraphOauthGuardService } from "./core/services/graphOauthGuard.service";
import { BillboardComponent } from "./shared/bill-board/bill-board.component";
import { SharedModule } from "./shared/shared.module";
import { JohnComponent } from './shim-components/john/john.component';
import { NartComponent } from './shim-components/nart/nart.component';
import { SnapFormsModule } from "./snap-forms/snapForms.module";
import { TourNgxBootstrapModule } from "ngx-ui-tour-ngx-bootstrap";
import { AuthInterceptor } from "./core/services/authInterceptor";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { NgcCookieConsentModule, NgcCookieConsentConfig } from 'ngx-cookieconsent';
import { CookieService } from 'ngx-cookie-service';
import { environment } from './../environments/environment';
import { BootstrapErrorComponent } from "./components/bootstrap-error/bootstrap-error.component";

const cookieConfig: NgcCookieConsentConfig =
{
    "cookie": {
        "domain": environment.cookieDomain // -<< domain will change base on your running env
    },
    "position": "bottom-right",
    "theme": "edgeless",
    "autoOpen": false,  //We need to manually open the consent popup
    "palette": {
        "popup": {
            "background": "#15394c",
            "text": "#ffffff",
            "link": "#ffffff"
        },
        "button": {
            "background": "#f89632",
            "text": "#ffffff",
            "border": "transparent"
        }
    },
    "type": "info",
    "content": {
        "message": "This app uses cookies to ensure the best experience.",
        "dismiss": "Got it!",
        "link": "Learn more",
        "href": "https://cookiesandyou.com",
        "policy": "Cookie Policy"
    }
}


@NgModule({
    declarations: [
        AppComponent,
        LPSearchBarComponent,
        BillboardComponent,
        SalesforceLoginComponent,
        HeaderComponent,
        FooterComponent,
        PreferencesComponent,
        LicenseExpiredComponent,
        BootstrapErrorComponent,
        DialogLoginComponent,
        DialogOauthCallbackComponent,
        MainComponent,
        JohnComponent,
        NartComponent,
        LPCardComponent,
        LPDeckComponent,
        LPGroupComponent,
        LPHeaderComponent,
        LPCellComponent,
        LPSearchPanelComponent,
        LPBillboardComponent,
        LPSidekickComponent,
        GraphDialogOauthCallbackComponent,
        GraphDialogLoginComponent,
        MicrosoftLoginComponent
    ],
    imports: [
        BrowserModule,
        FormsModule,
        ModalModule.forRoot(),
        AppRoutingModule,
        SharedModule,
        CoreModule,
        SnapFormsModule,
        BrowserAnimationsModule,
        AngularSvgIconModule.forRoot(),
        TooltipModule.forRoot(),
        BsDropdownModule.forRoot(),
        TourNgxBootstrapModule.forRoot(),
        NgcCookieConsentModule.forRoot(cookieConfig),
    ],
    providers: [
        BsModalRef,
        CookieService,
        GraphOauthGuardService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true
        },
    ],
    bootstrap: [AppComponent],
})
export class AppModule {
    constructor(injector: Injector) {
        SetAppInjector(injector);
    }
}
