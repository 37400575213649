import { Attachment } from "./attachment";
import { PouchClass } from "./enums";
import { IDeserializable } from "./interfaces/IDeserializable.interface";
import { IPouch } from "./interfaces/IPouch.interface";
import { LPEmailAddress } from "./lpEmailAddress";
import { PouchBase } from "./pouchBase";

export class EmailPouch extends PouchBase implements IPouch, IDeserializable {
    public from: LPEmailAddress;
    public to: LPEmailAddress[] = [];
    public cc: LPEmailAddress[] = [];
    public bcc: LPEmailAddress[] = [];
    public sendDate: Date;
    public subject: string;
    public body: string;
    public isInBound: boolean;


    //***
    //***
    constructor() {
        super(PouchClass.Email)
    }


    //***
    //***
    deserialize(input: EmailPouch): this {
        Object.assign(this, input);

        super.deserialize(input);
        this.from = new LPEmailAddress().deserialize(input.from);
        if (input.to) {
            this.to = input.to.map((to) => new LPEmailAddress().deserialize(to));
        }
        if (input.cc) {
            this.cc = input.cc.map((cc) => new LPEmailAddress().deserialize(cc));
        }
        if (input.bcc) {
            this.bcc = input.to.map((bcc) => new LPEmailAddress().deserialize(bcc));
        }
        if (input.attachments) {
            this.attachments = input.attachments.map((attachment) => new Attachment().deserialize(attachment));
        }
        return this;
    }


    //***
    public get searchHints(): string[] {
        const result = [];
        if (this.isInBound) {
            result.push(this.from.address);
            for (const item of this.to) {
                result.push(item.address);
            }
            for (const item of this.cc) {
                result.push(item.address);
            }
            for (const item of this.bcc) {
                result.push(item.address);
            }
        }
        else {
            for (const item of this.to) {
                result.push(item.address);
            }
            for (const item of this.cc) {
                result.push(item.address);
            }
            for (const item of this.bcc) {
                result.push(item.address);
            }
            result.push(this.from.address);
        }
        return result;
    }

    //***
    public get naturalKey(): string {
        return (
            this.sendDate.toString() + "." + this.from?.address + "." + this.subject
        );
    }
}
