import Utility from "../app/shared/utility";
import { panelMessageHandler } from "./panelMessageHandler";


//**
//**
function sendEventToPanel(eventType) {
    panelMessageHandler(eventType);
}


//**
//**
function onRecipientsChanged(eventArgs) {
    sendEventToPanel(Office.EventType.RecipientsChanged);
}


//**
//**
function onAttachmentsChanged(eventArgs) {
    sendEventToPanel(Office.EventType.AttachmentsChanged);
}


//**
//**
function onAppointmentTimeChanged(eventArgs) {
    sendEventToPanel(Office.EventType.AppointmentTimeChanged);
}


//**
//**
function registerRecipientsChangedHandler() {
    if (Office?.context?.requirements) {
        if (Office.context.requirements.isSetSupported("Mailbox", "1.7")) {
            try {
                Office.context.mailbox.item.addHandlerAsync(Office.EventType.RecipientsChanged, onRecipientsChanged,
                    (asyncResult) => {
                        if (asyncResult.status === Office.AsyncResultStatus.Failed)
                            Utility.debug(asyncResult.error, "registerRecipientsChangedHandler");
                    }
                );
            }
            catch (e) {
                Utility.debug(e, "registerRecipientsChangedHandler");
            }
        }
    }
}


//**
//**
function registerAttachmentsChangedHandler() {
    if (Office?.context?.requirements) {
        if (Office.context.requirements.isSetSupported("Mailbox", "1.8")) {
            try {
                Office.context.mailbox.item.addHandlerAsync(Office.EventType.AttachmentsChanged, onAttachmentsChanged,
                    (asyncResult) => {
                        if (asyncResult.status === Office.AsyncResultStatus.Failed)
                            Utility.debug(asyncResult.error, "registerAttachmentsChangedHandler");
                    });
            }
            catch (e) {
                Utility.debug(e, "registerAttachmentsChangedHandler");
            }
        }
    }
}


//**
//**
function registerAppointmentTimeChangedHandler() {
    if (Office?.context?.requirements) {
        if (Office.context.requirements.isSetSupported("Mailbox", "1.7")) {
            try {
                Office.context.mailbox.item.addHandlerAsync(Office.EventType.AppointmentTimeChanged, onAppointmentTimeChanged,
                    (asyncResult) => {
                        if (asyncResult.status === Office.AsyncResultStatus.Failed)
                            Utility.debug(asyncResult.error, "registerAppointmentTimeChangedHandler");
                    });
            }
            catch (e) {
                Utility.debug(e, "registerAppointmentTimeChangedHandler");
            }
        }
    }
}




//**
//**
function onItemChanged(eventArgs) {
    sendEventToPanel(Office.EventType.ItemChanged);
    registerRecipientsChangedHandler();
    registerAttachmentsChangedHandler();
    registerAppointmentTimeChangedHandler();
}


//**
//**
function registerItemChangeHandler() {
    if (Office?.context?.mailbox && Office.context.requirements.isSetSupported("Mailbox", "1.5")) {
        try {
            Office.context.mailbox.addHandlerAsync(Office.EventType.ItemChanged, onItemChanged,
                (asyncResult) => {
                    if (asyncResult.status === Office.AsyncResultStatus.Failed)
                        Utility.debug(asyncResult.error, "registerItemChangeHandler");
                }
            );
        }
        catch (e) {
            Utility.debug(e, "registerItemChangeHandler");
        }
    }
}


//**
//**
function registerEventHandlers() {
    if (typeof Office !== "undefined") {
        registerItemChangeHandler();
        registerRecipientsChangedHandler();
        registerAttachmentsChangedHandler();
        registerAppointmentTimeChangedHandler();
    }
}


//**
//**
export function connectToOfficeJS() {
    registerEventHandlers();
}
