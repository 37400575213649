import { Component, Input } from '@angular/core';
import { SnapFormField } from '../../models/snapFormField';
import { SnapFormService } from '../../services/snapform.service';

@Component({
    selector: 'snap-lp-empty',
    templateUrl: './lp-empty.component.html',
    styleUrls: ['./lp-empty.component.scss']
})
export class LPEmptyComponent {

    //***
    //***
    @Input() snapFormField: SnapFormField;


    //***
    //***
    get value(): string { return "" }
    set value(value: string) { ; }


    //***
    //***
    constructor(private snapFormService: SnapFormService) { }
}
