import { Component, Input } from '@angular/core';
import { SnapFormField } from '../../models/snapFormField';
import { SnapFormService } from '../../services/snapform.service';

@Component({
    selector: 'snap-lp-text-area',
    templateUrl: './lp-text-area.component.html',
    styleUrls: ['./lp-text-area.component.scss']
})
export class LPTextAreaComponent {

    //***
    @Input() snapFormField: SnapFormField;


    //***
    //***
    get value(): string { return this.snapFormService.getValue(this.snapFormField.fieldName); }
    set value(value: string) { this.snapFormService.setValue(this.snapFormField.fieldName, value.toString()); }


    //***
    //***
    get displayLines(): number { return this.snapFormField.displayLines + 1 }


    //***
    //***
    constructor(private snapFormService: SnapFormService) { }
}
