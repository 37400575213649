<div *ngIf="!sessionService.microsoftConnected">
  <button *ngIf="loginState === loginStateType.Initial" class="btn btn-sm btn-primary" (click)="login()">
    <span>Connect to Microsoft</span>
  </button>
  <button *ngIf="loginState === loginStateType.Dialog" class="btn btn-sm btn-primary" disabled>
    <span>Waiting for dialog... </span>
    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
  </button>
  <button *ngIf="loginState === loginStateType.Server" class="btn btn-sm btn-primary" disabled>
    <span>Establishing Connection... </span>
    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
  </button>
  <div class="error-message">{{ errorMessage }}</div>
</div>
<div *ngIf="sessionService.microsoftConnected">
    <p>Connected as: <b>{{ sessionService.microsoftUserName }}</b></p>
  <button class="btn btn-sm btn-primary" (click)="disconnect()">
    Disconnect
  </button>
</div>
