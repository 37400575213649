import { IDeserializable } from "./interfaces/IDeserializable.interface";

export class CustomSyncField implements IDeserializable {
    public fieldName: string;
    public label: string;
    public value: string;
    public displayValue: string;

    deserialize(input: CustomSyncField): this {
        Object.assign(this, input);
        return this;
    }
}
