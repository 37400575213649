import { IDeserializable } from "./interfaces/IDeserializable.interface";

export class ReferenceItem implements IDeserializable {
    public id: string;
    public label: string;
    public tableName: string;
    public tableLabel: string;
    public isPrimary: boolean;
    public attachmentId: string;
    public attachmentName: string;

    deserialize(input: any): this {
        Object.assign(this, input);
        return this;
    }
}
