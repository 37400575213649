<div class="modal-body text-center">
    <p>{{ questionText }}</p>
    <button type="button"
            class="btn btn-default"
            (click)="confirm()">
        Yes
    </button>
    <button type="button"
            class="btn btn-primary"
            (click)="decline()">
        No
    </button>
</div>
