<div class="modal-body text-center">
    <div>
        <div class="lp-text-box-header row no-gutters p-2">

            <div class="col-10 text-truncate">
                <span>
                    <app-lp-svg [imagePoints]="imagePoints"></app-lp-svg>{{title}}
                </span>
            </div>

            <div class="col-2">
                <img class="pr-1"
                     (click)="confirm()"
                     src="/assets/icons/check-circle-green.png" />
                <img (click)="decline()"
                     src="/assets/icons/close-red-circle.png" />
            </div>
        </div>
        <textarea class="lp-text-box"
                  rows="3"
                  [(ngModel)]="value"> </textarea>
    </div>
</div>
