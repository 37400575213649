import { Component, Input } from '@angular/core';
import { SnapFormField } from '../../models/snapFormField';
import Utility from '../../../shared/utility';

@Component({
    selector: 'snap-lp-field-label',
    templateUrl: './lp-field-label.component.html',
    styleUrls: ['./lp-field-label.component.scss']
})
export class LPFieldLabelComponent {

    public readonly imagePoints: string = Utility.svgInformation;
    public readonly imageBackgroundColor: string = Utility.LPTransparent;
    public readonly imageForegroundColor: string = Utility.LPOrange;

    @Input() snapFormField: SnapFormField;


    //***
    //***
    private _fieldName = '';
    get fieldName(): string { return this._fieldName ?? this.snapFormField?.fieldName ?? ''; }
    @Input() set fieldName(value: string) { this._fieldName = value; }


    //***
    //***
    private _label: string;
    get label(): string { return this._label ?? this.snapFormField?.label ?? ''; }
    @Input() set label(value: string) { this._label = value; }


    //***
    //***
    private _tooltip: string;
    get tooltip(): string {
        if (this._tooltip)
            return this._tooltip;
        else if (this.snapFormField)
            return this.snapFormField?.tooltip;
        else
            return '';
    }
    @Input() set tooltip(value: string) { this._tooltip = value; }


    //***
    //***
    get isTooltipVisible(): boolean { return !Utility.isNullOrEmpty(this?.tooltip ?? ''); }

    //***
    //***
    get debugProperties(): string {
        const nl = "&#013;&#010;"
        let result = '';
        if (this.snapFormField) {
            result = `fieldName=${this.snapFormField.fieldName}&013;&010;fieldType=${this.snapFormField.fieldType}${nl}maxLength=${this.snapFormField.maxLength}${nl}tabOrder=${this.snapFormField.tabOrder}${nl}ordinalColumn=${this.snapFormField.ordinalColumn}`
            if (this.snapFormField.isDependentPicklist || this.snapFormField.isController)
                result = result + `${nl}isController=${this.snapFormField.isController}${nl}isDependentPicklist=${this.snapFormField.isDependentPicklist}`;
            result = result + "${nl}[to be removed]";
        }
        return result;
    }
}
