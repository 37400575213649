import { Component, Input, OnInit, TemplateRef } from '@angular/core';
import { Header } from '../../models/header';
import Utility from '../../shared/utility';

@Component({
    selector: 'app-lp-header',
    templateUrl: './lp-header.component.html',
    styleUrls: ['./lp-header.component.scss']
})
export class LPHeaderComponent implements OnInit {

    @Input() title = "";
    @Input() subTitle = "";
    @Input() subTitleLabel = "";
    @Input() backgroundColor: string; // = Utility.LPLightGray;
    @Input() foregroundColor: string; // = Utility.LPBlue;

    @Input() imageUrl = "";
    @Input() imagePoints = "";
    @Input() imageBackgroundColor = Utility.LPOrange;
    @Input() imageForegroundColor = Utility.LPWhite;
    @Input() imageTooltip = "";
    @Input() imageSize = 25;
    @Input() filler: TemplateRef<any>;

    @Input() padding = 3;


    private _header: Header;
    get header(): Header {
        return this._header;
    }
    @Input() set header(value: Header) {
        this.title = value.title;
        this.subTitle = value.subTitle;
        this.subTitleLabel = value.subTitleLabel;

        this.imageUrl = value?.image?.link;
        this.imageBackgroundColor = value?.image?.color;
        this.imageTooltip = value?.image?.tooltip;
    }


    get showTitle(): boolean {
        return !Utility.isNullOrEmpty(this.title);
    }

    get showSubTitle(): boolean {
        return !Utility.isNullOrEmpty(this.subTitle);
    }

    //Determine if the Subtitle is a boolean, date, or number and if it is then display the field label
    get showSubTitleLabel(): boolean {
        return !Utility.isNullOrEmpty(this.subTitle) && !Utility.isNullOrEmpty(this.subTitleLabel) &&
            (this.isBool(this.subTitle) || this.isDate(this.subTitle) || this.isNumeric(this.subTitle));
    }

    get showImage(): boolean {
        return (Utility.isNullOrEmpty(this.imagePoints) && Utility.isNullOrEmpty(this.imageUrl)) ? false : true;
    }

    get showImageSvg(): boolean {
        return Utility.isNullOrEmpty(this.imagePoints) ? false : true;
    }

    get showImageUrl(): boolean {
        return Utility.isNullOrEmpty(this.imageUrl) ? false : true;
    }

    get paddingPixels(): string {
        return `${this.padding}px`;
    }

    private _roundedBottom: boolean = false;
    get roundedBottom(): boolean {
        return this._roundedBottom;
    }
    @Input() set roundedBottom(value: boolean) {
        this._roundedBottom = value;
        this.roundedBottomPixels = value ? "5px" : "0px";
    }

    roundedBottomPixels: string = "";


    constructor() {
    }

    ngOnInit() {
    }

    //***
    //***
    isNumeric(value: string): boolean {
        return value && !isNaN(Number(value));
    }

    //***
    //***
    isDate(value: string): boolean {
        return value && !isNaN(new Date(value).getDate());
    }

    //***
    //***
    isBool(value: string): boolean {
        return value && (value.toLowerCase() === "true" || value.toLowerCase() === "false");
    }
}
