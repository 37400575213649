import { Component, Input } from '@angular/core';
import Utility from '../../../shared/utility';
import { SignatureField } from '../../models/signatureField';

@Component({
    selector: 'app-lp-signature-section',
    templateUrl: './lp-signature-section.component.html',
    styleUrls: ['./lp-signature-section.component.scss']
})
export class LPSignatureSectionComponent {

    @Input() signatureFields: SignatureField[] = [];

    public readonly checkMarkImagePoints: string = Utility.svgCheck;
    public readonly checkMarkImageBackground: string = Utility.LPTransparent;
    getCheckMarkImageForeground(field: SignatureField): string { return field?.selected ? Utility.LPOrange : Utility.LPTransparent; }
}
