import { LPExceptionCode } from "./enums";
import { IDeserializable } from "./interfaces/IDeserializable.interface";
import { IResponse } from "./interfaces/IResponse.interface";
import { RemoteAction } from "./remoteAction";

export class ResponseBase implements IResponse, IDeserializable {
    //***
    public callbacks: string[] = [];
    public responseDuration: number;

    //***
    public errorCode: LPExceptionCode
    public errorMessage: string;
    public actions: RemoteAction[];


    //***
    //***
    deserialize(input: ResponseBase): this {
        Object.assign(this, input);
        if (input.actions)
            this.actions = input.actions.map((item) => new RemoteAction().deserialize(item));
        else
            this.actions = [];
        return this;
    }
}
