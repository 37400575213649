import { IDeserializable } from "../../models/interfaces/IDeserializable.interface";
import { SnapFormValue } from "./snapFormValue";

export class MiniFormResult implements IDeserializable {

    //***
    public recordTypeId: string;

    //***
    //***
    public fieldValues: SnapFormValue[] = [];


    //***
    //***
    deserialize(input: any): this {
        Object.assign(this, input);

        if (Array.isArray(input?.fieldValues))
            this.fieldValues = input.fieldValues.map((fieldValue) => new SnapFormValue().deserialize(fieldValue));
        return this;
    }
}
