import { IDeserializable } from "../../models/interfaces/IDeserializable.interface";
import { SnapFormLookupField } from "./snapFormLookupField";

export class SnapFormLookupRow implements IDeserializable {
    id: string;
    nameField: string; //Used for displayValue on lp-lookup.component
    fields: SnapFormLookupField[];

    constructor() {
        this.id = "";
        this.nameField = "";
        this.fields = [];
    }

    deserialize(input: SnapFormLookupRow): this {
        Object.assign(this, input);
        if (Array.isArray(input?.fields))
            this.fields = input.fields.map((field) => new SnapFormLookupField().deserialize(field));
        return this;
    }
}
