import { DeckState } from "./deckState";
import { PaneType } from "./enums";
import { Header } from "./header";
import { IDeserializable } from "./interfaces/IDeserializable.interface";

export class PaneState implements IDeserializable {
    public paneType: PaneType;
    public billboard: Header;
    public deckStates: DeckState[] = [];

    deserialize(input: PaneState): this {
        Object.assign(this, input);

        if (input?.billboard)
            this.billboard = new Header().deserialize(input.billboard);
        if (Array.isArray(input?.deckStates)) {
            this.deckStates = input.deckStates.map((deckState) => new DeckState().deserialize(deckState));
        }
        return this;
    }
}
