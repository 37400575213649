<div class="box" [ngClass]="this.sessionService.isMAC ? 'lp-mac-box-width': ''">

    <!--  Title  Title  Title-->
    <!--  Title  Title  Title-->
    <div class="row no-gutters header">
        <div class="col-auto no-gutters align-self-center">
            <svg class=" lp-header-image"
                 viewBox="0 0 28 28">
                <path stroke="1" fill="white"
                      d="M9.5,3A6.5,6.5 0 0,1 16,9.5C16,11.11 15.41,12.59 14.44,13.73L14.71,14H15.5L20.5,19L19,20.5L14,15.5V14.71L13.73,14.44C12.59,15.41 11.11,16 9.5,16A6.5,6.5 0 0,1 3,9.5A6.5,6.5 0 0,1 9.5,3M9.5,5C7,5 5,7 5,9.5C5,12 7,14 9.5,14C12,14 14,12 14,9.5C14,7 12,5 9.5,5Z" />
            </svg>
        </div>
        <div class="col no-gutters align-self-center">
            <div class="lp-title-large lp-font-white">
                {{title}}
            </div>

            <div class="lp-sub-title lp-font-white">
                Look-Up
            </div>
        </div>
        <div class="col-auto no-gutters align-self-center">
            <button type="button"
                    class="btn btn-warning lp-header-button"
                    (click)="submit()">
                Ok
            </button>
            <button type="button"
                    class="btn btn-warning lp-header-button"
                    (click)="cancel()">
                Cancel
            </button>
        </div>
    </div>

    <!--  SearchBar  SearchBar  SearchBar-->
    <!--  SearchBar  SearchBar  SearchBar-->
    <div class="row no-gutters search">
        <div class="col no-gutters align-self-center">
            <input class="lp-input-element"
                   [(ngModel)]="searchHint"
                   type="text"
                   (keyup.enter)="search()">
        </div>
        <div class="col-auto no-gutters align-self-center">
            <svg class="lp-search-image"
                 viewBox="0 0 24 24"
                 tooltip="Clear"
                 (click)="clear()"
                 *ngIf="isClearVisible">
                <path fill="white"
                      d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z" />
            </svg>
            <svg class="lp-search-image"
                 viewBox="0 0 24 24"
                 tooltip="Search"
                 (click)="search()"
                 *ngIf="isSearchVisible">
                <path fill="white"
                      d="M9.5,3A6.5,6.5 0 0,1 16,9.5C16,11.11 15.41,12.59 14.44,13.73L14.71,14H15.5L20.5,19L19,20.5L14,15.5V14.71L13.73,14.44C12.59,15.41 11.11,16 9.5,16A6.5,6.5 0 0,1 3,9.5A6.5,6.5 0 0,1 9.5,3M9.5,5C7,5 5,7 5,9.5C5,12 7,14 9.5,14C12,14 14,12 14,9.5C14,7 12,5 9.5,5Z" />
            </svg>
        </div>
    </div>

    <!--  SelectionTable  SelectionTable  SelectionTable-->
    <!--  SelectionTable  SelectionTable  SelectionTable-->
    <div class="row no-gutters content"
         *ngIf="selectedTable">
        <div class="content-box">
            <div class="content-source row no-gutters" *ngIf="isSourceVisible">
                <div class="col-auto no-gutters lp-no-gutters">
                    <label class="source-label" for="tables">Source:</label>
                </div>
                <div class="col no-gutters lp-no-gutters">
                    <select class="lp-input-element"
                            name="tables"
                            id="tables"
                            [(ngModel)]="selectedTableName">
                        <option *ngFor="let item of tables"
                                [ngValue]="item.name">
                            {{item.label}}
                        </option>
                    </select>
                </div>
            </div>
            <div class="content-table scrollable">
                <div class="table table-hover"
                     (dblclick)="onRowDoubleClick($event)">
                    <thead>
                        <tr>
                            <th *ngFor="let field of selectedTable.rows[0].fields">
                                {{field.label}}
                            </th>
                        </tr>
                    </thead>

                    <tbody>
                        <tr *ngFor="let row of selectedTable.rows"
                            (click)="rowSelected(row)">
                            <td *ngFor="let field of row.fields"
                                [class.selected]="row === selectedRow">
                                {{field.value}}
                            </td>
                        </tr>
                    </tbody>
                </div>
            </div>
        </div>
    </div>
</div>
