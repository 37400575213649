<div class="lp-container">
    <div class="lp-title"
         *ngIf="isTitleVisible">
        {{title}}
    </div>

    <div class="lp-sub-title"
         *ngIf="isSubTitleVisible">
        {{subTitle}}
    </div>
</div>