import { SnapForms } from "./snapForms";
import { IDeserializable } from "../../models/interfaces/IDeserializable.interface";
import { ResponseBase } from "../../models/responseBase";


export class GetSnapFormResponse extends ResponseBase implements IDeserializable {

    //***
    //***
    isPouchComposeMode: boolean;
    form: SnapForms;

    deserialize(input: any): this {
        Object.assign(this, input);

        if (input?.form)
            this.form = new SnapForms().deserialize(input.form);
        return this;
    }

}
