import { IDeserializable } from "../../models/interfaces/IDeserializable.interface";

export class SnapFormLookupField implements IDeserializable {
    name: string;
    label: string;
    value: string;


    constructor() {
        this.name = "";
        this.label = "";
        this.value = "";
    }

    deserialize(input: SnapFormLookupField): this {
        Object.assign(this, input);

        return this;
    }

}
