import { Component, Input } from '@angular/core';
import { SessionService } from '../../../core/services/session.service';
import Utility from '../../../shared/utility';
import { LPFormMode } from '../../models/enums';
import { SignatureField } from '../../models/signatureField';
import { SnapForms } from '../../models/snapForms';


@Component({
    selector: 'snap-lp-forms',
    templateUrl: './lp-forms.component.html',
    styleUrls: ['./lp-forms.component.scss'],
})
export class LPFormsComponent {

    //***
    //***
    constructor(public sessionService: SessionService) {

    }

    //***
    //***
    private _snapForms: SnapForms;
    get snapForms(): SnapForms { return this?._snapForms; }
    @Input() set snapForms(value: SnapForms) { this._snapForms = value; }


    //***
    //***
    private _formMode: LPFormMode;
    get formMode(): LPFormMode { return this?._formMode; }
    @Input() set formMode(value: LPFormMode) { this._formMode = value; }


    //***
    //***
    private _signatureFields: SignatureField[];
    get signatureFields(): SignatureField[] { return this?._signatureFields; }
    @Input() set signatureFields(value: SignatureField[]) { this._signatureFields = value; }


    //***
    //***
    get isFormModeSignature(): boolean { return (this.formMode === LPFormMode.Signature && Array.isArray(this.signatureFields) && this.signatureFields.length > 0); }


    //***
    //***
    get isRecordTypeVisible(): boolean {

        if (!this.snapForms?.forms)
            return false;
        else if (!this.snapForms.isRecordTypeSelectorRequired)
            return false;
        else if (this?.snapForms.forms.length > 1)
            return true;
        else
            return false;
    }


    //***
    //***
    mouseDoubleClick(event: MouseEvent) {
        if (event && event.ctrlKey === true) {
            Utility.debug(this, `SnapForms: ${this.snapForms.tableName}`);
            event.cancelBubble = true;
        }
    }

    //***
    //***
    get title(): string { return `${this.snapForms?.entryMode} ${this.snapForms?.tableLabel}`; }
}
