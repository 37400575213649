import { MiniSnapFormService } from "../../services/mini-snapform.service";
import { LPFieldType } from "../enums";
import { SnapFormField } from "../snapFormField";
import { Visitor } from "./Visitor";

export class MiniPicklistControllingValidator extends Visitor {

    private service: MiniSnapFormService;

    //***
    //***
    constructor(service: MiniSnapFormService) {
        super();
        this.service = service;
    }

    //***
    //***
    canVisit(field: SnapFormField): boolean {
        return super.commonCanVisitFieldType(field, [LPFieldType.Boolean, LPFieldType.Picklist, LPFieldType.MultiPicklist]) && field.isController;
    }

    //***
    //***  Used on parent/controller fields that must force their child/dependent fields to get validated
    //***
    visit(field: SnapFormField, value: string) {
        if (super.commonVisit(field, value))
            for (const child of this.service.snapForms.selectedSnapForm.allFields.filter(x => x.dependentPickListFieldNames.find(y => y === field.fieldName)))
                child.validate(this.service.getValue(field.fieldName));
    }
}
