<div class="lp-container">

    <!--  Header  Header  Header  Header-->
    <!--  Header  Header  Header  Header-->
    <!--  -->
    <snap-lp-section-header [title]="snapFormSection.label"
                            [isTitleVisible]="snapFormSection.isLabelVisible"
                            [subTitle]="''"></snap-lp-section-header>



    <!--  Body  Body  Body  Body-->
    <!--  Body  Body  Body  Body-->
    <!--  -->
    <div class="lp-body">
        <snap-lp-section-body-top-down *ngIf="snapFormSection.tabOrder=='TopToBottom'"
                                       [snapFormSection]="snapFormSection"></snap-lp-section-body-top-down>
        <snap-lp-section-body-left-right *ngIf="snapFormSection.tabOrder=='LeftToRight'"
                                         [snapFormSection]="snapFormSection"></snap-lp-section-body-left-right>
        <snap-lp-section-body-single *ngIf="snapFormSection.tabOrder=='Single'"
                                         [snapFormSection]="snapFormSection"></snap-lp-section-body-single>
    </div>



    <!--  Footer  Footer  Footer  Footer-->
    <!--  Footer  Footer  Footer  Footer-->
    <!--  -->
    <snap-lp-section-footer [label]="snapFormSection.footerLabel"></snap-lp-section-footer>
</div>
