<div class="modal-header">
    <h4 class="modal-title pull-left pl-2">Preferences</h4>
    <button type="button"
            class="btn-close close pull-right"
            aria-label="Close"
            (click)="onClose()">
        <img src='assets/icons/close-circle-red.png'
             width="21"
             height="21"
             style="vertical-align: initial;"/>
    </button>
</div>


<div class="modal-body lp-preferences-container"
     (dblclick)="mouseDoubleClick($event)">

    <!--  GeneralPreferences  GeneralPreferences  GeneralPreferences-->
    <!--  GeneralPreferences  GeneralPreferences  GeneralPreferences-->
    <div class="rounded lp-preference-container clearfix  pt-1 mb-1 mr-2 ml-2">
        <div class="row no-gutters">
            <div class="col-12 pr-0">

                <div class="align-left pl-1 pr-1 noselect"
                     (dblclick)="onGeneralDoubleClick($event)">
                    <img width="24"
                         height="24"
                         class="align-middle"
                         src="assets/icons/tune-orange.png"/>
                    <div class="d-inline-block pl-2 pr-2 align-middle">
                        <app-title title="General"></app-title>
                        <div class="lp-preference-subtitle">Preferences</div>
                    </div>
                    <div class="float-right"
                         (click)="generalExpanded = !generalExpanded">
                        <ng-template [ngIf]="!generalExpanded">
                            <div>
                                <img src='assets/icons/chevron-down.png'/>
                            </div>
                        </ng-template>
                        <ng-template [ngIf]="generalExpanded">
                            <div>
                                <img src='assets/icons/chevron-up.png'/>
                            </div>
                        </ng-template>
                    </div>
                </div>


                <div *ngIf="generalExpanded" class="lp-expanded-container lp-preference-text p-2">
                    <!--  searchOnlyNameFields  searchOnlyNameFields  searchOnlyNameFields-->
                    <!--  searchOnlyNameFields  searchOnlyNameFields  searchOnlyNameFields-->
                    <div *ngIf="searchOnlyNameFieldsVisibility!='Hidden'">
                        <input type="checkbox"
                               [disabled]="searchOnlyNameFieldsVisibility=='Disabled'"
                               [(ngModel)]="searchOnlyNameFields" />Search only Salesforce 'name' fields.
                    </div>


                    <!--  autoSelectAttachments  autoSelectAttachments-->
                    <!--  autoSelectAttachments  autoSelectAttachments-->
                    <div *ngIf="autoSelectAttachmentsVisibility!='Hidden'"
                         class="row no-gutters">
                        <div class="float-left">
                            <input #attachmentsCheck
                                   [disabled]="autoSelectAttachmentsVisibility=='Disabled'"
                                   type="checkbox"
                                   [(ngModel)]="autoSelectAttachmentsEnabled"/>
                        </div>
                        <div class="col-11 float-right">
                            Auto Select Attachments.<br/>
                            Except those containing these words.(one word per line)
                            <textarea [disabled]="!attachmentsCheck.checked || autoSelectAttachmentsVisibility=='Disabled'"
                                      (change)='onAutoSelectAttachmentsIgnore($event)'
                                      [value]="sessionService.autoSelectAttachmentsIgnore"
                                      rows="3"
                                      class="lp-textarea"></textarea>
                        </div>
                    </div>


                    <!--  ignoreSearchHints  ignoreSearchHints  ignoreSearchHints-->
                    <!--  ignoreSearchHints  ignoreSearchHints  ignoreSearchHints-->
                    <div *ngIf="ignoreSearchHintsVisibility!='Hidden'"
                         class="row no-gutters">
                        <div class="float-left">
                            <input #ignoreemailCheck
                                   [disabled]="ignoreSearchHintsVisibility=='Disabled'"
                                   type="checkbox"
                                   [(ngModel)]="ignoreSearchHintsEnabled"/>
                        </div>
                        <div class="col-11 float-right">
                            Do not search for the following email addresses or domains.
                            <textarea [disabled]="!ignoreSearchHintsEnabled || ignoreSearchHintsVisibility=='Disabled'"
                                      [value]="sessionService.ignoreSearchHints"
                                      (change)='onIgnoreSearchHints($event)'
                                      rows="3"
                                      class="lp-textarea"></textarea>
                        </div>
                    </div>


                    <!--  SubjectBodyEdit  SubjectBodyEdit  SubjectBodyEdit-->
                    <!--  SubjectBodyEdit  SubjectBodyEdit  SubjectBodyEdit-->
                    <div *ngIf="subjectBodyVisibility!='Hidden'"
                         class="row no-gutters">
                        <div class="float-left">
                            <input type="checkbox"
                                   [disabled]="subjectBodyVisibility=='Disabled'"
                                   [(ngModel)]="subjectBodyEnabled"/>
                        </div>
                        <div class="col-11 float-right">
                            Allow me to edit subject/body when I record to salesforce.
                        </div>
                    </div>

                    <div *ngIf="editModeMessageVisibility!='Hidden'"
                         class="row no-gutters">
                        <div class="float-left">
                            <input type="checkbox"
                                   [disabled]="editModeMessageVisibility=='Disabled'"
                                   [(ngModel)]="editModeMessageEnabled"/>
                        </div>
                        <div class="col-11 float-right">
                            Show a reminder message when in Edit Mode.
                        </div>
                    </div>



                    <div *ngIf="suggestUsingRelevanceVisibility!='Hidden' || suggestUsingConversationVisibility!='Hidden'"
                         class="row no-gutters">
                        <div class="float-left">
                            Suggest items to record by using...
                        </div>
                        <div *ngIf="suggestUsingConversationVisibility!='Hidden'"
                             class="col-11 float-right" style="margin-left: 18px;">
                            <input type="checkbox"
                                   [disabled]="suggestUsingConversationVisibility=='Disabled'"
                                   [(ngModel)]="suggestUsingConversation" />Email conversation history
                        </div>
                        <div *ngIf="suggestUsingRelevanceVisibility!='Hidden'"
                             class="col-11 float-right" style="margin-left: 18px;">
                            <input type="checkbox"
                                   [disabled]="suggestUsingRelevanceVisibility=='Disabled'"
                                   [(ngModel)]="suggestUsingRelevance" />Salesforce relevance
                        </div>
                    </div>


                    <!--<div class="row no-gutters"
                         *ngIf="timeScoutVisibility != 'Hidden'">
                        <div class="float-left">
                            <input type="checkbox"
                                   [disabled]="timeScoutVisibility=='Disabled'"
                                   [(ngModel)]="timeScoutEnabled"/>
                        </div>
                        <div class="col-11 float-right">
                            TimeScout Enabled
                        </div>
                    </div>-->

                    <div class="row no-gutters"
                         *ngIf="connectionsVisibility != 'Hidden'">
                        <div class="float-left">
                            <input type="checkbox"
                                   [disabled]="connectionsVisibility=='Disabled'"
                                   [(ngModel)]="connectionsEnabled"/>
                        </div>
                        <div class="col-11 float-right">
                            Connections Enabled
                        </div>
                    </div>

                    <div class="row no-gutters">
                        <div class="float-left">
                            <input type="checkbox"
                                   [(ngModel)]="performDiscoveryOnClose"/>
                        </div>
                        <div class="col-11 float-right">
                            Perform Salesforce discovery when this panel is closed.
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>


    <!--  Support  Support  Support-->
    <!--  Support  Support  Support-->
    <div *ngIf="supportVisibility=='Visible' && (knowledgeBaseVisibility=='Visible' || chatVisibility=='Visible')"
         class="rounded lp-preference-container clearfix  pt-1 mb-1 mr-2 ml-2">
        <div class="row no-gutters">
            <div class="col-12 pr-0">
                <div class="align-left pl-1 pr-1 noselect"
                     (dblclick)="onSupportDoubleClick($event)">
                    <img width="24"
                         height="24"
                         src="assets/icons/chat-question-outline.png"/>
                    <div class="d-inline-block pl-2 pr-2 align-middle">
                        <app-title title="LinkPoint Support"></app-title>
                        <div class="lp-preference-subtitle">Resources</div>
                    </div>
                    <div class="float-right"
                         (click)="supportExpanded = !supportExpanded">
                        <ng-template [ngIf]="!supportExpanded">
                            <div>
                                <img src='assets/icons/chevron-down.png'/>
                            </div>
                        </ng-template>
                        <ng-template [ngIf]="supportExpanded">
                            <div>
                                <img src='assets/icons/chevron-up.png'/>
                            </div>
                        </ng-template>
                    </div>
                </div>
                <div *ngIf="supportExpanded"
                     class="lp-expanded-container lp-preference-text p-2">

                    <a *ngIf="knowledgeBaseVisibility=='Visible'"
                       href="https://www.linkpoint360.com/kb/"
                       class="lp-link"
                       target="_blank">Open Support Knowledge Base</a><br/>

                    <a *ngIf="chatVisibility=='Visible'"
                       href="http://messenger.providesupport.com/messenger/linkpoint360.html"
                       class="lp-link"
                       target="_blank">Chat with LinkPoint360 Support</a><br/>
                </div>
            </div>
        </div>
    </div>

    <!--Microsoft (Only visible if client doesn't support Identity SSO)-->
    <div *ngIf="isMicrosoftConnectionVisible" class="rounded lp-preference-container clearfix  pt-1 mb-1 mr-2 ml-2">
        <div class="row no-gutters">
            <div class="col-12 pr-0">

                <div class="align-left pl-1 pr-1">
                    <img width="24" height="24" class="align-middle" src="assets/icons/microsoft-office.png"/>
                    <div class="d-inline-block pl-2 pr-2 align-middle">
                        <app-title title="Microsoft"></app-title>
                        <div class="lp-preference-subtitle">Connection</div>
                    </div>
                    <div class="float-right" (click)="microsoftExpanded = !microsoftExpanded">
                        <ng-template [ngIf]="!microsoftExpanded">
                            <div>
                                <img src='assets/icons/chevron-down.png'/>
                            </div>
                        </ng-template>
                        <ng-template [ngIf]="microsoftExpanded">
                            <div>
                                <img src='assets/icons/chevron-up.png'/>
                            </div>
                        </ng-template>
                    </div>
                </div>

                <div *ngIf="microsoftExpanded" class="lp-expanded-container lp-preference-text p-2">
                    <app-microsoft-login></app-microsoft-login>
                </div>
            </div>
        </div>
    </div>

    <!--  Salesforce  Salesforce  Salesforce-->
    <!--  Salesforce  Salesforce  Salesforce-->
    <div class="rounded lp-preference-container clearfix  pt-1 mb-1 mr-2 ml-2">
        <div class="row no-gutters">
            <div class="col-12 pr-0">
                <div class="align-left pl-1 pr-1 noselect"
                     (dblclick)="onSalesforceDoubleClick($event)">
                    <img width="24"
                         height="24"
                         class="align-middle"
                         src="assets/icons/salesforce.png"/>
                    <div class="d-inline-block pl-2 pr-2 align-middle">
                        <app-title title="Salesforce"></app-title>
                        <div class="lp-preference-subtitle">Connection</div>
                    </div>
                    <div class="float-right"
                         (click)="salesforceExpanded = !salesforceExpanded">
                        <ng-template [ngIf]="!salesforceExpanded">
                            <div>
                                <img src='assets/icons/chevron-down.png'/>
                            </div>
                        </ng-template>
                        <ng-template [ngIf]="salesforceExpanded">
                            <div>
                                <img src='assets/icons/chevron-up.png'/>
                            </div>
                        </ng-template>
                    </div>
                </div>

                <div *ngIf="salesforceExpanded" class="lp-expanded-container lp-preference-text p-2">
                    <app-salesforce-login></app-salesforce-login>
                </div>
            </div>
        </div>
    </div>

    <!--Sync-->
    <div class="rounded lp-preference-container clearfix  pt-1 mb-1 mr-2 ml-2">
        <div class="row no-gutters">
            <div class="col-12 pr-0">
                <div class="align-left pl-1 pr-1 noselect"
                     (dblclick)="onSyncDoubleClick($event)">
                    <img width="24"
                         height="24"
                         class="align-middle"
                         src="assets/icons/sync.png"/>
                    <div class="d-inline-block pl-2 pr-2 align-middle">
                        <app-title [title]="syncGroupTitle"></app-title>
                        <div class="lp-preference-subtitle">Settings</div>
                    </div>
                    <div class="float-right"
                         (click)="syncExpanded = !syncExpanded">
                        <ng-template [ngIf]="!syncExpanded">
                            <div>
                                <img src='assets/icons/chevron-down.png'/>
                            </div>
                        </ng-template>
                        <ng-template [ngIf]="syncExpanded">
                            <div>
                                <img src='assets/icons/chevron-up.png'/>
                            </div>
                        </ng-template>
                    </div>
                </div>


                <!--Expanded-->
                <div *ngIf="syncExpanded" class="lp-expanded-container lp-preference-text p-2">
                    <div>
                        <a class="lp-link"
                           (click)="onOpenBladeAssistClick($event)">Open {{syncGroupTitle}} Settings</a>
                    </div>

                    <div *ngIf="canEmailSync || canCalendarSync"
                         style="margin-top: 5px;"
                         class="row  no-gutters">
                        <!--  EmailSync  EmailSync  EmailSync-->
                        <!--  EmailSync  EmailSync  EmailSync-->
                        <div *ngIf="canEmailSync"
                             class="col-2 float-left">
                            <div role="button">
                                <app-lp-svg [imageSize]="22"
                                            [imagePoints]="emailSyncImagePoint"
                                            [imageBackgroundColor]="imageBackgroundColor"
                                            [imageForegroundColor]="imageForegroundColor"
                                            lpTooltip="Initiate Background Email Sync"
                                            (click)="startEmailSync()"></app-lp-svg>
                            </div>
                        </div>


                        <!--  CalendarSync  CalendarSync  CalendarSync-->
                        <!--  CalendarSync  CalendarSync  CalendarSync-->
                        <div *ngIf="canCalendarSync"
                             class="col-2 float-left">
                            <div role="button">
                                <app-lp-svg [imageSize]="22"
                                            [imagePoints]="calendarSyncImagePoint"
                                            [imageBackgroundColor]="imageBackgroundColor"
                                            [imageForegroundColor]="imageForegroundColor"
                                            lpTooltip="Initiate Background Calendar Sync"
                                            (click)="startCalendarSync()"></app-lp-svg>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>


    <!--Table Preferences-->
    <div *ngFor="let item of preferences"
         class="mb-1">
        <div class="rounded lp-preference-container clearfix pl-2 pr-2 pt-1 pb-1 mr-2 ml-2">
            <div class="row">
                <div class="col-12 pr-0">

                    <div class="align-left">
                        <img class="rounded"
                             [style.background]="item.color"
                             width="24" height="24"
                             [src]="item.link"/>
                        <div class="d-inline-block pl-2 pr-2 align-middle">
                            <app-title [title]="item.label"></app-title>
                            <div class="lp-preference-subtitle">Preferences</div>
                        </div>
                    </div>


                    <div class="row no-gutters lp-preference-text">
                        <div class="float-left">
                            <input type="checkbox"
                                   [checked]="item.effectiveIsEnabled"
                                   (change)='onEnabledChange($event, item)'/>
                            <!--[disabled]="sessionService.extraQueriesExist"-->
                            <!--|| sessionService.extraQueriesExist"-->
                        </div>
                        <div class="col-11 float-right">
                            Search, create, and edit records
                        </div>
                    </div>


                    <div *ngIf="isAutoExpandVisible"
                         class="row no-gutters lp-preference-text">
                        <div class="float-left">
                            <input type="checkbox"
                                   [checked]="item.isExpanded"
                                   (change)='onExpandedChange($event, item)'/>
                        </div>
                        <div class="col-11 float-right">
                            Automatically expand in the search results
                        </div>
                    </div>
                </div>
            </div>


            <!--  SortField  SortField  SortField-->
            <!--  SortField  SortField  SortField-->
            <div class="row lp-preference-text"
                 *ngIf="item.isSortFieldsAvailable && isSortVisible">
                <div class="col-12 pr-0">
                    <div>Sort Field:</div>
                    <div>
                        <select class="lp-dropdown"
                                [(ngModel)]="item.sortFieldName"
                                (change)="onSortFieldNameChange(item)">
                            <option *ngFor="let sortField of item.visibleCellFieldNames | keyvalue"
                                    value={{sortField.key}}>
                                {{sortField.value}}
                            </option>
                        </select>&nbsp;&nbsp;
                        <a class="btn-lp"
                           (click)="onAscendingDescendingClick(item)">
                            <span *ngIf="item.sortAscending">
                                <img src="../../../assets/icons/sort-ascending.png"
                                     title="sort ascending"/>
                            </span>
                            <span *ngIf="!item.sortAscending">
                                <img src="../../../assets/icons/sort-descending.png"
                                     title="sort descending"/>
                            </span>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal-footer"></div>
