import { Component } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { SublimeService } from '../../core/services/sublime.service';
import { SessionService } from '../../core/services/session.service';
import { PanelService } from '../../core/services/panelService';
import { PanelType } from "../../models/enums";

@Component({
    selector: 'app-start-splash',
    templateUrl: './start-splash.component.html',
    styleUrls: ['./start-splash.component.scss']
})
export class StartSplashComponent {
    showButton = false;

    //***
    //***
    constructor(public bsModalRef: BsModalRef, private sublimeService: SublimeService, private sessionService: SessionService, private panelService: PanelService) {
        //When the bootstrap is completed
        this.sublimeService.bootStrapSubject.subscribe((value) => {
            //If the user has already activated simply close the window without user interaction
            if(this.sessionService.hasActivated){
                this.close();
            } else {
                this.showButton = value; //show the button for the new user to close it when they are ready
            }                
        });
    }

    
    //***
    //***
    close(): void {
        this.bsModalRef?.hide();
        this.sublimeService.isStartSplashClosed = true; //mark the splash screen as closed

        //Instantly open the preference panel if they are not connected to SF
        if (!this.sessionService.salesforceConnected)
            this.panelService.displayModal(PanelType.Preferences);
    }
    
}
