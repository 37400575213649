import { CardState } from "./cardState";
import { PaneState } from "./paneState";
import { IDeserializable } from "./interfaces/IDeserializable.interface";

export class ClientState implements IDeserializable {
    public paneStates: PaneState[] = [];
    public cardStates: CardState[] = [];

    deserialize(input: ClientState): this {
        Object.assign(this, input);
        if (input?.paneStates) {
            this.paneStates = input.paneStates.map((paneState) =>
                new PaneState().deserialize(paneState)
            );
        }
        if (input?.cardStates) {
            this.cardStates = input.cardStates.map((cardState) =>
                new CardState().deserialize(cardState)
            );
        }
        return this;
    }
}
