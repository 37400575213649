import { Component, Input, NgZone, OnInit, TemplateRef } from "@angular/core";
import { Router } from "@angular/router";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { SessionService } from "../../core/services/session.service";
import { SublimeService } from "../../core/services/sublime.service";
import { ToastNotificationService } from "../../core/services/toast-notification.service";
import { PouchClass, Visibility } from "../../models/enums";
import { SnapFormMenu } from "../../models/snapFormMenu";
import { AboutDialogComponent } from "../../shared/about-dialog/about-dialog.component";
import Utility from "../../shared/utility";
import { MacHandlerSnapFormService } from "../../snap-forms/services/mac-handler-snapform.service";

@Component({
    selector: "app-header",
    templateUrl: "./header.component.html",
    styleUrls: ["./header.component.scss"],
})
export class HeaderComponent implements OnInit {
    @Input() displayCloseButton = false;
    @Input() titleDescription = "";

    public readonly helpImagePoints: string = Utility.svgHelpQuestion;
    public readonly refreshImagePoints: string = Utility.svgRefresh;
    public readonly imageBackgroundColor: string = Utility.LPBlue;
    public readonly imageForegroundColor: string = Utility.LPWhite;
    public readonly fontColor: string = Utility.LPWhite;

    pouchClass: PouchClass = PouchClass.Email;
    readonly foregroundColor: string = Utility.LPBackgroundBlue;
    readonly backgroundColor: string = Utility.LPBlue;

    ngOnInit(): void {
        this.sublimeService.bootstrapSnapFormHeaderSubject.subscribe(res => {
            if (res) {
                if (Array.isArray(this.sublimeService.snapFormHeaders) && this.sublimeService.snapFormHeaders.length > 0) {
                    this.snapFormHeaderLoaded = true;

                    //Clear any previous values and build menu
                    this.snapFormMenu = [];
                    this.sublimeService.snapFormHeaders.forEach((x) => this.snapFormMenu.push(new SnapFormMenu(x.tableName, x.tableLabel, x.imageUrl, x.color, x.joinFieldName)));
                }
            }
        });

        if (Array.isArray(this.sublimeService.snapFormHeaders) && this.sublimeService.snapFormHeaders.length > 0) {
            this.snapFormHeaderLoaded = true;

            //Clear any previous values and build menu
            this.snapFormMenu = [];
            this.sublimeService.snapFormHeaders.forEach((x) => this.snapFormMenu.push(new SnapFormMenu(x.tableName, x.tableLabel, x.imageUrl, x.color, x.joinFieldName)));
        }

        //this.sublimeService.timeScoutSubject.subscribe(res => {
        //    if (this.sublimeService.timeScoutOrganizer) {
        //        this.timeScoutLoaded = true;
        //    }
        //});
    }


    //***
    //***
    public snapFormMenu: SnapFormMenu[] = [];
    private snapFormHeaderLoaded = false;
    public get snapFormHeaderVisible(): boolean { return this.snapFormHeaderLoaded && this.sessionService.headerCreateVisibility == Visibility.Visible; }

    public get resroucesVisibility(): Visibility { return this.sessionService.helpVisibility; }

    //***
    //***
    public refresh(): void { this.sublimeService.refresh(); }

    //***
    //***
    onclickHelp() { Utility.openUrl(Utility.LPHelpUrl); }

    //***
    //***
    onclickTutorial() {
        this.sublimeService.startTour();
    }

    //***
    //***
    onclickAbout() {
        this.bsModalRef = this.modalService.show(AboutDialogComponent);
    }

    //***
    //***
    public get helpVisible(): boolean { return this.sessionService.helpVisibility == Visibility.Visible; }


    ////***
    ////***
    //private timeScoutLoaded = false;
    //public get timeScoutVisible(): boolean { return this.sessionService.timeScoutVisibility && this.sessionService.timeScoutEnabled && this.timeScoutLoaded; }


    //***
    // SnapForm Menu Code
    //***
    public readonly imagePoints: string = Utility.svgFileDocumentEdit;
    private toastTopic = "SnapForm";


    public officeDialog: Office.Dialog;
    private officeDialogUrl = window.location.origin + "#/";

    //***
    //***
    async onclick(tableName) {

        //Pass the authtoken to the dialog
        this.sessionService.setPropertyBag(this.sessionService.propertyBagAuthToken, await this.sessionService.forceTokenUpdate()).subscribe(
            (response) => {
                if (this.sessionService.isMAC)
                    this.macHandler.initializeMacFormFull(tableName);
                else
                    this.launchDialog(tableName);
            },
            (error) => {
                Utility.debug(error);
            }
        ); 
    }

    //***
    //***
    private async launchDialog(tableName: string) {
        const url = this.officeDialogUrl + this.router.serializeUrl(this.router.createUrlTree([`/SnapForm/${this.sessionService.lpUserId}/${tableName}/Full`]));
        await Office.context.ui.displayDialogAsync(url, { height: this.sessionService.dialogHeightLarge, width: this.sessionService.dialogWidthMedium, promptBeforeOpen: false, displayInIframe: true },
            (result) => {
                if (result.status === Office.AsyncResultStatus.Failed) {
                    Utility.debug(`Dialog failed: ${result.error.code} ${result.error.message}`);
                }
                else {
                    this.officeDialog = result.value;
                    this.officeDialog.addEventHandler(Office.EventType.DialogMessageReceived, this.processOfficeDialogMessage);
                    this.officeDialog.addEventHandler(Office.EventType.DialogEventReceived, this.processOfficeDialogEvent);
                }
            }
        );
    }

    //***
    //*** Fired when dialog calls Office.context.ui.messageParent()
    private processOfficeDialogMessage = (arg: any) => {
        this._ngZone.run(() => {
            if (arg.message) {
                this.officeDialog.close();
                this.toastService.send('Success', "", "Salesforce item created.", 3000, this.toastTopic);
            }
            this.officeDialog.close();
        });
    };

    //***
    //*** Fired when dialog is closed by the user
    private processOfficeDialogEvent = (arg) => {
    };


    //***
    //***
    constructor(
        private bsModalRef: BsModalRef,
        private sublimeService: SublimeService,
        private router: Router,
        private sessionService: SessionService,
        private toastService: ToastNotificationService,
        private modalService: BsModalService,
        private _ngZone: NgZone,
        private macHandler: MacHandlerSnapFormService,
    ) { }
}


