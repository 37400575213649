<div class="lp-container">

    <div>
        <div class="field-wrapper">
            <div class="requiredIndicator"
                 tooltip="This field requires an answer"
                 delay="750"
                 [class.required]="snapFormField.isRequired"></div>
            <div class="field-content"
                 [ngSwitch]="snapFormField.fieldType">

                <div [ngSwitch]="snapFormField.fieldType">

                    <!-- *** -->
                    <snap-lp-input *ngSwitchCase="'String'"
                                   inputType="text"
                                   [snapFormField]="snapFormField"></snap-lp-input>

                    <!-- *** -->
                    <snap-lp-input *ngSwitchCase="'Date'"
                                   inputType="date"
                                   [snapFormField]="snapFormField"></snap-lp-input>

                    <!-- *** -->
                    <snap-lp-input *ngSwitchCase="'Time'"
                                   inputType="time"
                                   [snapFormField]="snapFormField"></snap-lp-input>


                    <!-- *** -->
                    <snap-lp-task-reminder *ngSwitchCase="'TaskReminderDateTime'"
                                           [snapFormField]="snapFormField"></snap-lp-task-reminder>

                    <!-- *** -->
                    <snap-lp-event-reminder *ngSwitchCase="'EventReminderDateTime'"
                                                        [snapFormField]="snapFormField"></snap-lp-event-reminder>

                    <!-- *** -->
                    <snap-lp-date-time *ngSwitchCase="'DateTime'"
                                       [snapFormField]="snapFormField"></snap-lp-date-time>

                    <!-- *** -->
                    <snap-lp-input *ngSwitchCase="'Email'"
                                   inputType="email"
                                   [snapFormField]="snapFormField"></snap-lp-input>

                    <!-- *** -->
                    <snap-lp-input *ngSwitchCase="'Phone'"
                                   inputType="tel"
                                   [snapFormField]="snapFormField"></snap-lp-input>

                    <!-- *** -->
                    <snap-lp-input *ngSwitchCase="'Url'"
                                   inputType="url"
                                   [snapFormField]="snapFormField"></snap-lp-input>

                    <!-- *** -->
                    <snap-lp-boolean *ngSwitchCase="'Boolean'"
                                     [snapFormField]="snapFormField"></snap-lp-boolean>

                    <!-- *** -->
                    <snap-lp-input *ngSwitchCase="'Currency'"
                                   inputType="number"
                                   [snapFormField]="snapFormField"></snap-lp-input>

                    <!-- *** -->
                    <snap-lp-input *ngSwitchCase="'Double'"
                                   inputType="number"
                                   [snapFormField]="snapFormField"></snap-lp-input>

                    <!-- *** -->
                    <snap-lp-input *ngSwitchCase="'Int'"
                                   inputType="number"
                                   [snapFormField]="snapFormField"></snap-lp-input>

                    <!-- *** -->
                    <snap-lp-input *ngSwitchCase="'Long'"
                                   inputType="number"
                                   [snapFormField]="snapFormField"></snap-lp-input>

                    <!-- *** -->
                    <snap-lp-input *ngSwitchCase="'Percent'"
                                   inputType="number"
                                   [snapFormField]="snapFormField"></snap-lp-input>

                    <!-- *** -->
                    <snap-lp-combobox *ngSwitchCase="'Combobox'"
                                      [snapFormField]="snapFormField"></snap-lp-combobox>

                    <!-- *** -->
                    <snap-lp-picklist *ngSwitchCase="'Picklist'"
                                      [snapFormField]="snapFormField"></snap-lp-picklist>

                    <!-- *** -->
                    <snap-lp-multipicklist *ngSwitchCase="'MultiPicklist'"
                                           [snapFormField]="snapFormField"></snap-lp-multipicklist>

                    <!-- *** -->
                    <snap-lp-read-only *ngSwitchCase="'AutoNumber'"
                                       [snapFormField]="snapFormField"></snap-lp-read-only>

                    <!-- *** -->
                    <snap-lp-read-only *ngSwitchCase="'Label'"
                                       [snapFormField]="snapFormField"></snap-lp-read-only>

                    <!-- *** -->
                    <snap-lp-lookup *ngSwitchCase="'Reference'"
                                    [snapFormField]="snapFormField"></snap-lp-lookup>

                    <!-- *** -->
                    <snap-lp-text-area *ngSwitchCase="'TextArea'"
                                       [snapFormField]="snapFormField"></snap-lp-text-area>

                    <!-- *** -->
                    <snap-lp-empty *ngSwitchCase="'EmptySpace'"
                                   [snapFormField]="snapFormField"></snap-lp-empty>

                    <!-- *** -->
                    <snap-lp-empty *ngSwitchDefault
                                   [snapFormField]="snapFormField"></snap-lp-empty>
                </div>
            </div>
        </div>
        <div #validationMessage class="validation-message">{{ snapFormField.validationMessage }}</div>
    </div>
