import { Component, Input } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { SessionService } from '../../../core/services/session.service';
import { ToastNotificationService } from '../../../core/services/toast-notification.service';
import { ConfirmDialogComponent } from '../../../shared/confirm-dialog/confirm-dialog.component';
import Utility from '../../../shared/utility';
import { LPEntryMode, LPFormMode } from '../../models/enums';
import { MiniFormResult } from '../../models/miniFormResult';
import { MacHandlerSnapFormService } from '../../services/mac-handler-snapform.service';
import { SnapFormService } from '../../services/snapform.service';


@Component({
    selector: 'snap-lp-forms-header',
    templateUrl: './lp-forms-header.component.html',
    styleUrls: ['./lp-forms-header.component.scss'],
})
export class LPFormsHeaderComponent {
    private readonly toastTopic = "SnapForm";

    @Input() title: string;
    @Input() subTitle: string;
    @Input() imageUrl: string;
    @Input() imageBackgroundColor: string;
    @Input() editUrl: string;
    bsModalRef?: BsModalRef;
    public isCreateMode = false;

    public isSubmitting: boolean = false;

    //***
    //***
    public get openAfterCreate(): boolean { return this.snapFormService.snapForms.openAfterCreate; }
    public set openAfterCreate(value: boolean) {
        if (value !== this.snapFormService.snapForms.openAfterCreate)
            this.snapFormService.snapForms.openAfterCreate = value;
    }


    //***
    //***
    public get hasEditUrl(): boolean { return this.editUrl && !Utility.isNullOrEmpty(this.editUrl); }
    public get isTitleVisible(): boolean { return !Utility.isNullOrEmpty(this.title); }
    public get isSubTitleVisible(): boolean { return !Utility.isNullOrEmpty(this.subTitle); }
    public get isImageVisible(): boolean { return !Utility.isNullOrEmpty(this.imageUrl); }
    public get isMiniForm(): boolean { return this.snapFormService.snapForms && this.snapFormService.snapForms.formMode === LPFormMode.Mini; }


    //***
    //***
    constructor(private snapFormService: SnapFormService, private modalService: BsModalService, private toastService: ToastNotificationService,
        private sessionService: SessionService, private macHandler: MacHandlerSnapFormService) {
        this.title = ""
        this.subTitle = ""
        if (snapFormService.snapForms)
            this.isCreateMode = (snapFormService.snapForms.formMode !== LPFormMode.Mini && snapFormService.snapForms.entryMode === LPEntryMode.Create) ? true : false;
    }

    //***
    //***
    submitForm() {
        if (!this.isSubmitting) {
            this.isSubmitting = true; //activate spinner

            if (this.snapFormService.isAllFieldsValid()) {
                this.snapFormService.submitSnapForm().then((response) => {
                    if (response.isSuccess) {
                        if (this.sessionService.isMAC)
                            this.macHandler.submitForm(response.id);
                        else
                            this.closeWindow(response.id);
                    }
                    else {
                        this.toastService.send('Save Failed', "", response.message, 5000, this.toastTopic);
                        this.isSubmitting = false;
                    }                        
                });
            }
            else {
                //alert user about validation errors through toast
                this.toastService.send("Save Failed", "Validation errors found.", "Please fix any errors on the form and try again.", 5000, this.toastTopic);
                this.isSubmitting = false;
            }
        }        
    }

    //***
    //***
    submitMini() {
        if (!this.isSubmitting) {
            this.isSubmitting = true; //activate spinner

            if (this.snapFormService.isAllFieldsValid()) {
                const result = new MiniFormResult();
                result.recordTypeId = this.snapFormService.snapForms.selectedSnapForm.recordTypeId;
                result.fieldValues = this.snapFormService.filteredMasterValues();

                if (this.sessionService.isMAC)
                    this.macHandler.submitMiniForm(JSON.stringify(result));
                else
                    this.closeWindow(JSON.stringify(result));

                this.isSubmitting = false;
            }
            else {
                //alert user about validation errors through toast
                this.toastService.send("Save Failed", "Validation errors unresolved", "Please fix any errors on the form and try again.", 0, this.toastTopic);
                this.isSubmitting = false;
            }
        }        
    }

    //***
    //***
    cancel() {
        if (this.snapFormService.isFormDirty())
            this.openConfirmModal();
        else {
            if (this.sessionService.isMAC)
                this.macHandler.cancelForm();
            else
                this.closeWindow();
        }            
    }

    //***
    //***
    closeWindow(message = ""): void { Office.context.ui.messageParent(message); }

    //***
    //***
    openConfirmModal() {
        const confirmInitialState = { questionText: 'Would you like to abandon the changes you made?' };
        this.bsModalRef = this.modalService.show(ConfirmDialogComponent, { class: 'modal-sm', initialState: confirmInitialState });

        //If the user confirmed: close this window
        this.bsModalRef.content.isConfirmed.subscribe((value) => {
            if (value) {
                if (this.sessionService.isMAC)
                    this.macHandler.cancelForm();
                else
                    this.closeWindow();
            }                
        });
    }
}
