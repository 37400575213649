import { IDeserializable } from "../../models/interfaces/IDeserializable.interface";
import { LPFormMode } from "./enums";


export class GetSnapFormRequest implements IDeserializable {

    //***
    lpUserId: string;
    //***
    tableName: string;
    //***
    id: string;
    //***
    formMode: LPFormMode;

    //***
    //***
    constructor(lpUserId: string, tableName: string, formMode: LPFormMode, id: string = null) {
        this.lpUserId = lpUserId ?? '';
        this.tableName = tableName ?? '';
        this.formMode = formMode;
        this.id = id ?? '';
    }

    deserialize(input: GetSnapFormRequest): this {
        Object.assign(this, input);
        return this;
    }
}
