import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
    selector: 'app-confirm-dialog',
    templateUrl: './confirm-dialog.component.html',
    styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogComponent {
    @Input() questionText: string;
    @Output() isConfirmed = new EventEmitter<boolean>();


    constructor(public bsModalRef: BsModalRef) { }


    //***
    //***
    confirm(): void {
        this.isConfirmed.emit(true);
        this.bsModalRef?.hide();
    }

    //***
    //***
    decline(): void {
        this.isConfirmed.emit(false);
        this.bsModalRef?.hide();
    }

}
