import { Component, Input } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { PanelService } from '../../../core/services/panelService';
import { SublimeService } from '../../../core/services/sublime.service';
import Utility from '../../utility';


@Component({
    selector: 'app-quick-comment',
    templateUrl: './quick-comment.component.html',
    styleUrls: ['./quick-comment.component.scss']
})
export class QuickCommentComponent {
    public readonly imagePoints: string = Utility.svgQuote;

    @Input() imageForegroundColor: string = Utility.LPOrange;
    @Input() imageBackgroundColor: string = Utility.LPTransparent;


    @Input() tableName = "";
    @Input() recordId = "";
    bsModalRef?: BsModalRef;
    comment: string;

    //**
    //**
    constructor(private sublimeService: SublimeService, private panelService: PanelService, private modalService: BsModalService) { }


    //**
    //**
    onclick() {
        this.panelService.getInput("Add Comment to Description", "/assets/icons/comment-quote-outline-orange.png", "").subscribe(res => {
            if (res)
                this.sublimeService.saveQuickComment(res, this.tableName, this.recordId);
        });
    }
}
