import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";

@Component({
    selector: 'app-graph-dialog-login',
    templateUrl: './graph-dialog-login.component.html',
    styleUrls: ['./graph-dialog-login.component.scss']
})
export class GraphDialogLoginComponent implements OnInit {
    private loginUrlDomain = "https://login.microsoftonline.com/common";
    private loginUrlEndPoint = "/oauth2/v2.0/authorize";
    private redirectUrl: string;
    private scope: string;
    private loginUrlParams: string;
    private cid: string;

    //***
    //***
    constructor(private activatedRoute: ActivatedRoute) { }

    //***
    //***
    ngOnInit() {
        //Get the urls from the query param that the parent sent
        this.activatedRoute.queryParams.subscribe((params) => {
            this.cid = decodeURIComponent(params["cid"]);
            this.redirectUrl = decodeURIComponent(params["redirect"]);
            this.scope = decodeURIComponent(params["scope"]);

            //now that we have the redirectUrl we can build the paramaters
            this.loginUrlParams = `?client_id=${encodeURIComponent(this.cid)}&redirect_uri=${encodeURIComponent(this.redirectUrl)}&scope=${encodeURIComponent(this.scope)}&response_type=code&response_mode=query`;;
        });

        this.start();
    }

    //***
    //***
    start() {
        let finalLoginUrl =
            this.loginUrlDomain + this.loginUrlEndPoint + this.loginUrlParams;
        window.location.href = finalLoginUrl;
    }
}
