import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { SessionService } from '../../../core/services/session.service';
import { FocusService } from '../../../shared/focus.service';
import Utility from '../../../shared/utility';
import { SnapFormField } from '../../models/snapFormField';
import { SnapFormLookupTable } from '../../models/SnapFormLookupTable';
import { SnapFormService } from '../../services/snapform.service';

@Component({
    selector: 'snap-lp-lookup-dialog',
    templateUrl: './lp-lookup-dialog.component.html',
    styleUrls: ['./lp-lookup-dialog.component.scss'],
})
export class LPLookupDialogComponent implements OnInit, AfterViewInit {
    @Input() snapFormField: SnapFormField;
    @Input() title: string;
    @Input() searchHint: string;
    @Input() tables: SnapFormLookupTable[];
    @Input() selectedTable: SnapFormLookupTable;
    @Output() selectedValue = new EventEmitter<SnapFormLookupTable>();

    closeButtonName?: string;
    selectedRow: SnapFormLookupTable;

    private _selectedTableName: string;
    get selectedTableName(): string {
        return this._selectedTableName;
    }
    @Input() set selectedTableName(value: string) {
        this._selectedTableName = value;
        if (this._selectedTableName)
            this.selectedTable = this.tables.find(x => x.name === this.selectedTableName);
    }


    //***
    //***
    constructor(public bsModalRef: BsModalRef, private snapFormService: SnapFormService, private focusService: FocusService, public sessionService: SessionService) {
    }


    //***
    //***
    ngOnInit() {
        if (this.searchHint) {
            this.search();
        }
    }


    //***
    //Set the cursor focus on first input that is a descendant of snap-lp-lookup-dialog
    //***
    ngAfterViewInit() { this.focusService.setFocusFirstMatch('snap-lp-lookup-dialog input'); }


    //***
    //***
    get isClearVisible(): boolean { return !Utility.isNullOrEmpty(this.searchHint); }
    get isSearchVisible(): boolean { return (this?.searchHint ?? "").length > 0; }
    get isSourceVisible(): boolean { return this?.tables?.length > 1; }
    cancel() { this.bsModalRef.hide(); }
    clear() { this.searchHint = ""; }
    rowSelected(row: any) { this.selectedRow = row; }
    onRowDoubleClick(event: MouseEvent) { this.submit() }

    //***
    //***
    submit() {
        this.selectedValue.emit(this.selectedRow);
        this.bsModalRef.hide();
    }


    //***
    //***
    search() {
        this.snapFormService.lookUpSnapForm(this.snapFormField.fieldName, this.searchHint, this.snapFormField.referenceTo).then((response) => {
            this.tables = response.tables;
            if (this.tables && this.tables.length > 0)
                this.selectedTableName = this.tables[0].name;
        },
            (error) => { ; }
        );
    }
}
