import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { cloneDeep } from "lodash";
import { BehaviorSubject, Observable } from "rxjs";
import { map } from "rxjs/operators";
import { environment } from "../../../environments/environment";
import { AuthInterceptor } from "../../core/services/authInterceptor";
import { SessionService } from "../../core/services/session.service";
import { FooterStatus } from "../../models/footerStatus";
import { PropertyBagResponse } from "../../models/propertyBagResponse";
import LPDateTime from "../../shared/LPDateTime";
import Utility from "../../shared/utility";
import { LPEntryMode, LPFieldType, LPFormMode } from "../models/enums";
import { GetSnapFormRequest } from "../models/getSnapFormRequest";
import { GetSnapFormResponse } from "../models/getSnapFormResponse";
import { LookUpSnapFormRequest } from "../models/lookUpSnapFormRequest";
import { LookUpSnapFormResponse } from "../models/lookUpSnapFormResponse";
import { MiniFormResult } from "../models/miniFormResult";
import { SnapConstants } from "../models/snapConstants";
import { SnapForm } from "../models/snapForm";
import { SnapFormFieldEventArgument } from "../models/SnapFormFieldEventArgument";
import { SnapFormPicklistValue } from "../models/snapFormPicklistValue";
import { SnapFormRecordType } from "../models/snapFormRecordType";
import { SnapForms } from "../models/snapForms";
import { SnapFormValue } from "../models/snapFormValue";
import { SubmitSnapFormRequest } from "../models/submitSnapFormRequest";
import { SubmitSnapFormResponse } from "../models/submitSnapFormResponse";
import { DateValidator } from "../models/Validators/DateValidator";
import { EmailAddressValidator } from "../models/Validators/EmailAddressValidator";
import { IVisitor } from "../models/Validators/IVisitor";
import { LengthValidator } from "../models/Validators/LengthValidator";
import { MiniPicklistControllingValidator } from "../models/Validators/MiniPicklistControllingValidator";
import { MiniPicklistDependentValidator } from "../models/Validators/MiniPicklistDependentValidator";
import { NumberValidator } from "../models/Validators/NumberValidator";
import { PicklistSimpleValidator } from "../models/Validators/PicklistSimpleValidator";
import { RequiredValidator } from "../models/Validators/RequiredValidator";
import { TimeValidator } from "../models/Validators/TimeValidator";


//This Service is used solely by the sublime service to keep track of the underlying miniform that will get saved with any references.
//This file should be nearly identical to snapform.service.ts in the same folder
@Injectable()
export class MiniSnapFormService {
    //***
    private get getSnapFormsUrl() {
        return this.sessionService.API_ENDPOINT + "api/snapform";
    }
    //***
    private get submitSnapFormsUrl() {
        return this.sessionService.API_ENDPOINT + "api/snapform/submit";
    }
    //***
    private get lookUpSnapFormsUrl() {
        return this.sessionService.API_ENDPOINT + "api/snapform/lookup";
    }


    //***
    public snapFormsSubject = new BehaviorSubject<SnapForms>(null);
    //***
    public onSnapFormFieldChange = new BehaviorSubject<SnapFormFieldEventArgument>(null);
    //***
    public footerStatusSubject: BehaviorSubject<FooterStatus> = new BehaviorSubject<FooterStatus>(null);
    //***
    public setSpinnerStatusSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(undefined);
    //***
    private get masterValues(): SnapFormValue[] {
        let result: SnapFormValue[] = [];
        if (this.snapForms)
            result = this.snapForms.fieldValues;

        return result;
    }
    //***
    private initialValuesString = "";
    //***
    private visitors: IVisitor[];
    //***
    public isPouchComposeMode = false;


    //***
    //***
    constructor(private httpClient: HttpClient, private sessionService: SessionService) {
        this.snapFormsSubject = new BehaviorSubject<SnapForms>(this.snapForms);
        this.footerStatusSubject = new BehaviorSubject<FooterStatus>(new FooterStatus(false, 0, 0));
        this.setSpinnerStatusSubject = new BehaviorSubject<boolean>(false);

        this.visitors = [];
        this.visitors.push(new RequiredValidator());

        this.visitors.push(new PicklistSimpleValidator());
        this.visitors.push(new MiniPicklistControllingValidator(this));
        this.visitors.push(new MiniPicklistDependentValidator(this));

        this.visitors.push(new DateValidator());
        this.visitors.push(new EmailAddressValidator());
        this.visitors.push(new LengthValidator());
        this.visitors.push(new NumberValidator());
        this.visitors.push(new TimeValidator());
    }


    //***
    //***
    private _snapForms: SnapForms;
    get snapForms(): SnapForms { return this._snapForms; }
    set snapForms(value: SnapForms) {
        this._snapForms = value;
        this.isTask = value?.tableName === SnapConstants.Task;
        this.isEvent = value?.tableName === SnapConstants.Event;
    }

    //***  LookUpSnapForms  LookUpSnapForms  LookUpSnapForms
    //***  LookUpSnapForms  LookUpSnapForms  LookUpSnapForms
    //***
    public lookUpSnapForm(fieldName: string, searchHint: string, referenceTables: string[]): Promise<LookUpSnapFormResponse> {
        this.setFooterStatus(true);
        return this.apiLookUpSnapFormCall(fieldName, searchHint, referenceTables)
            .toPromise()
            .then((response) => {
                this.setFooterStatus(false);
                return response;
            });
    }

    //***
    //***
    private apiLookUpSnapFormCall(fieldName: string, searchHint: string, referenceTables: string[]): Observable<LookUpSnapFormResponse> {
        const request = new LookUpSnapFormRequest(this.sessionService.lpUserId, this.snapForms.tableName, fieldName, searchHint, referenceTables);
        const requestJson: string = Utility.jsonStringifyWithGetter(request);
        return this.httpClient.post(this.lookUpSnapFormsUrl, requestJson).pipe(map((data) => new LookUpSnapFormResponse().deserialize(data)));
    }


    //***  SubmitSnapForms  SubmitSnapForms  SubmitSnapForms
    //***  SubmitSnapForms  SubmitSnapForms  SubmitSnapForms
    //***
    public submitSnapForm(): Promise<SubmitSnapFormResponse> {
        this.setFooterStatus(true);
        return this.apiSubmitSnapFormCall()
            .toPromise()
            .then((response) => {
                this.setFooterStatus(false);
                if (response && response.isSuccess) {
                    const map = new Map<string, string>();
                    this.snapForms.selectedSnapForm.allFields.forEach(formField => {
                        const source = this.getMasterEntry(formField.fieldName);
                        if (source)
                            map.set(formField.fieldName, source.value);
                    })
                    map.set(SnapConstants.Id, this.snapForms.id);
                    if (map.has(SnapConstants.FirstName) && map.has(SnapConstants.LastName))
                        map.set(SnapConstants.Name, `${map.get(SnapConstants.FirstName).trim()} ${map.get(SnapConstants.LastName).trim()}`.trim());
                    this.sessionService.setPropertyBag(this.sessionService.propertyBagSnapFormSaved, Utility.toMapString(map, "=", "\n")).subscribe();
                    Utility.openUrl(response.editUrl);
                }
                return response;
            });
    }


    //***
    //***
    private apiSubmitSnapFormCall(): Observable<SubmitSnapFormResponse> {
        //Filter the list of MasterValues to only the ones relevant to the current form
        const filteredValues: Map<string, string> = new Map<string, string>();
        this.snapForms.selectedSnapForm.allFields.forEach(formField => {
            const source = this.getMasterEntry(formField.fieldName);
            if (source)
                filteredValues.set(formField.fieldName, source.value);
        });

        if (this.snapForms.tableName === SnapConstants.Event) {
            const isAllDay = filteredValues.get(SnapConstants.IsReminderSet) === SnapConstants.True;
            if (isAllDay && filteredValues.has(SnapConstants.TaskReminderDateTime))
                filteredValues.delete(SnapConstants.TaskReminderDateTime);

            else if (!isAllDay && filteredValues.has(SnapConstants.EventReminderDateTime))
                filteredValues.delete(SnapConstants.EventReminderDateTime);
        }


        const request = new SubmitSnapFormRequest(this.sessionService.lpUserId, this.snapForms.tableName, this.snapForms.id, filteredValues, this.snapForms.openAfterCreate);
        const requestJson: string = JSON.stringify(request, this.submitRequestReplacer);
        return this.httpClient.post(this.submitSnapFormsUrl, requestJson).pipe(map((data) => new SubmitSnapFormResponse().deserialize(data)));
    }

    //Used to Serialize a Map into an easier to use Json format
    private submitRequestReplacer(key, value) {
        if (value instanceof Map)
            return Array.from(value.entries()); //Serialize it as an array of arrays
        else
            return value;
    }


    //***  GetSnapForms  GetSnapForms  GetSnapForms
    //***  GetSnapForms  GetSnapForms  GetSnapForms
    //***
    public getSnapForms(tableName: string, formMode: LPFormMode, recordId: string = null) {
        this.snapForms = null;

        this.sessionService.getPropertyBag(this.sessionService.propertyBagAuthToken).subscribe(
            (response: PropertyBagResponse) => {
                if (response.value)
                    this.sessionService.officeAuthToken = response.value;

                this.apiGetSnapFormsCall(tableName, formMode, recordId).subscribe(response => {
                    //If this is a miniform we need to know if the pouch is in compose mode or not
                    if (formMode === LPFormMode.Mini)
                        this.isPouchComposeMode = response.isPouchComposeMode;

                    if (response?.form) {
                        this.prepareForms(response.form);
                        this.snapForms = response.form;
                        this.snapFormsSubject.next(response.form);
                        this.initialValuesString = this.currentMasterValuesString();
                        this.sessionService.getPropertyBag(this.sessionService.propertyBaginjectSnapFormRecordType).subscribe(
                            (response: PropertyBagResponse) => {
                                if (response.value)
                                    this.changeRecordType(response.value);
                            },
                            (error) => {
                                Utility.debug(error, "getSnapFormsError");
                            }
                        );
                    }
                });
            },
            (error) => {
                Utility.debug(error, "getSnapFormsError");
            }
        );
    }

    //***
    //***
    private apiGetSnapFormsCall(tableName: string, formMode: LPFormMode, recordId: string): Observable<GetSnapFormResponse> {
        const request = new GetSnapFormRequest(this.sessionService.lpUserId, tableName, formMode, recordId);
        const requestJson: string = Utility.jsonStringifyWithGetter(request);
        return this.httpClient.post(this.getSnapFormsUrl, requestJson).pipe(map((data) => new GetSnapFormResponse().deserialize(data)));
    }


    //***
    //***
    public prepareMiniForm(tableName: string) {
        if (tableName === "Task" && this.sessionService.miniSnapFormTaskExists)
            this.snapForms = new SnapForms().deserialize(cloneDeep(this.sessionService.miniSnapFormTask));
        else if (tableName === "Event" && this.sessionService.miniSnapFormEventExists)
            this.snapForms = new SnapForms().deserialize(cloneDeep(this.sessionService.miniSnapFormEvent));
        else
            this.snapForms = undefined;

        if (this.snapForms) {
            this.prepareForms(this.snapForms);
            this.snapFormsSubject.next(this.snapForms);
            this.initialValuesString = this.currentMasterValuesString();
            this.sessionService.getPropertyBag(this.sessionService.propertyBaginjectSnapFormRecordType).subscribe(
                (response: PropertyBagResponse) => {
                    this.changeRecordType(response.value);
                },
                (error) => {
                    Utility.debug(error, "getSnapFormsError");
                }
            );
        }
    }


    //***
    //***
    private prepareForms(source: SnapForms): void {
        this.snapForms = source;

        // RecordTypes
        const isCreate = source.entryMode === LPEntryMode.Create;
        let recordTypeId: string = null;
        if (!isCreate)
            recordTypeId = source.fieldValues.find(x => x.fieldName === "RecordTypeId")?.value;


        const result: SnapForm[] = [];
        const remove: SnapFormRecordType[] = [];
        for (const recType of source.recordTypes) {
            // if its an edit and the edit record uses a retired record type, we still neeed to honor it and inclde.
            let take = false;

            if (source.recordTypes.length === 1)
                take = true;
            else if (recType.id === recType.masterRecordTypeId)
                take = false;
            else if (recType.isAvailable)
                take = true;
            else if (!isCreate && !Utility.isNullOrEmpty(recordTypeId) && recType.id === recordTypeId)
                take = true;

            if (take) {
                let form = source.forms.find(x => x.id === recType.layoutId);
                if (!form)
                    throw new Error(`RecordType references a layout that does not exist  RecordType=${recType.id}  LayoutId=${recType.layoutId}`);

                form = cloneDeep(form);
                form.recordType = recType;
                result.push(form);
            }
            else {
                remove.push(recType);
            }
        }
        source.forms = result;
        for (const recType of remove) {
            const index = source.recordTypes.indexOf(recType);
            if (index >= 0)
                source.recordTypes.splice(index, 1);
        }

        const boolPicklistValues: SnapFormPicklistValue[] = [];
        boolPicklistValues.push(SnapFormPicklistValue.Create(SnapConstants.False, true, true, SnapConstants.True, null));
        boolPicklistValues.push(SnapFormPicklistValue.Create(SnapConstants.False, true, true, SnapConstants.True, null));


        // SnapFormFields
        for (const form of source.forms) {
            //If this is a mini in ComposeMode we need to find and remove the subject and description fields
            if (source.formMode === LPFormMode.Mini && this.isPouchComposeMode) {
                let index = form.editSections[0].fields.findIndex(x => x.fieldName === "Subject");
                if (index >= 0)
                    form.editSections[0].fields.splice(index, 1);

                index = form.editSections[0].fields.findIndex(x => x.fieldName === "Description");
                if (index >= 0)
                    form.editSections[0].fields.splice(index, 1);
            }

            for (const field of form.allFields) {
                // Assign Validators
                for (const val of this.visitors)
                    if (val.canVisit(field))
                        field.validators.push(val);

                // Assign PicklistValues (clone each entry to avoid issues)
                if (field.isPicklist) {
                    field.picklistValues.splice(0, field.picklistValues.length);
                    const source = form.recordType.picklists.find(x => x.fieldName === field.fieldName);
                    if (source?.values)
                        source.values.forEach(x => field.picklistValues.push(cloneDeep(x)))
                }
                else if (field.fieldType === LPFieldType.Combobox) {
                    field.picklistValues.splice(0, field.picklistValues.length);
                    const source = form.recordType.picklists.find(x => x.fieldName === field.fieldName);
                    if (source?.values)
                        source.values.forEach(x => field.picklistValues.push(cloneDeep(x)))
                }

                if (field.fieldType === LPFieldType.Boolean && (field.isController || field.isDependentPicklist))
                    field.picklistValues = cloneDeep(boolPicklistValues);
            }
        }


        // SelectedSnapForm
        source.selectedSnapForm = null;
        if (source.entryMode === LPEntryMode.Update) {
            const recTypeValue = source.fieldValues.find(x => x.fieldName === SnapConstants.RecordTypeId);
            if (recTypeValue)
                source.selectedSnapForm = source.forms.find(x => x.recordTypeId === recTypeValue.value);
        }
        if (!source.selectedSnapForm)
            source.selectedSnapForm = source.forms.find(x => x.recordType.isDefaultRecordType);
    }


    //***
    private getMasterEntry(fieldName: string): SnapFormValue { return this.masterValues.find(x => x.fieldName === fieldName); }

    //***
    //***
    getValue(fieldName: string): string {
        const result = this.getMasterEntry(fieldName);
        return result ? result.value : undefined;
    }
    setValue(fieldName: string, newValue: string) {
        if (!Utility.isNullOrEmpty(fieldName)) {
            let changed = false;
            let fieldValue = this.getMasterEntry(fieldName);
            if (!fieldValue) {
                this.setPriorValue(fieldName, newValue);
                fieldValue = new SnapFormValue(fieldName, newValue)
                this.masterValues.push(fieldValue);
                changed = true;
            }
            else if (fieldValue.value !== newValue) {
                this.setPriorValue(fieldName, fieldValue.value);
                fieldValue.value = newValue;
                changed = true;
            }

            if (changed) {
                if (this.snapForms) {
                    const fieldDef = this.snapForms.selectedSnapForm.findField(fieldName);
                    this.snapForms.selectedSnapForm.fixDependencyStateField(this.snapForms.fieldValues, fieldDef);
                    if (fieldDef)
                        fieldDef.validate(newValue);
                }
                this.fixReminderState(fieldName);
            }

            if (newValue === "!!dumpservice")
                Utility.debug(this, "MiniSnapFormService");
            else if (newValue === "!!dumpvalues")
                this.dumpValues(fieldName);
            else if (newValue === "!!watchvalues")
                this.watchValues = !this.watchValues;

            if (this.watchValues)
                this.dumpValues(fieldName);
        }
    }


    //***
    //***
    private getPriorValue(fieldName: string) {
        const priorName = `prior${fieldName}`;
        let result = this.getMasterEntry(priorName);
        if (!result) {
            const buffer = this.getMasterEntry(priorName)
            if (buffer) {
                result = new SnapFormValue(priorName, buffer.value, buffer.displayValue);
                this.masterValues.push(result);
            }
        }
        return result ? result.value : undefined;
    }
    private setPriorValue(fieldName: string, newValue: string) {
        if (!Utility.isNullOrEmpty(fieldName)) {
            const priorName = `prior${fieldName}`;
            let fieldValue = this.getMasterEntry(priorName);
            if (!fieldValue) {
                fieldValue = new SnapFormValue(priorName, newValue)
                this.masterValues.push(fieldValue);
            }
            else if (fieldValue.value !== newValue) {
                fieldValue.value = newValue;
            }
        }
    }


    //***
    //***
    getDisplayValue(fieldName: string): string {
        const result = this.getMasterEntry(fieldName);
        return result ? result.displayValue : "";
    }
    //***
    //***
    setDisplayValue(fieldName: string, newValue: string) {
        const target = this.getMasterEntry(fieldName);
        if (target)
            target.displayValue = newValue;
        else
            this.masterValues.push(new SnapFormValue(fieldName, "", newValue));
    }


    //***
    //***
    public fixReminderState(fieldName: string) {
        try {
            if (this.snapForms && this.snapForms.formMode === LPFormMode.Full) {
                if (this.isTask || this.isEvent) {
                    const fieldDef = this.snapForms.selectedSnapForm.findField(fieldName);
                    if (fieldDef) {
                        const masterValue = this.getMasterEntry(fieldName);
                        if (!masterValue)
                            throw new Error("MasterValueShould Exist")

                        if (this.isTask)
                            this.fixReminderStateTask(fieldName);

                        else if (this.isEvent)
                            this.fixReminderStateEvent(fieldName);
                    }
                }
            }
        }
        catch (ex) { Utility.debug(ex); }
    }


    //***
    //***
    private fixReminderStateTask(fieldName: string): void {
        if (this.isTask && fieldName === SnapConstants.ActivityDate) {
            const sourceValue = this.getMasterEntry(fieldName);
            if (!Utility.isNullOrEmpty(sourceValue.value)) {
                const parts = sourceValue.value.split(" ");
                const targetValue = this.getMasterEntry(SnapConstants.TaskReminderDateTime);
                this.setValue(`date${targetValue.fieldName}`, parts[0]);
            }
        }
    }


    //***
    //***
    private fixReminderStateEvent(fieldName: string): void {
        if (this.isEvent && fieldName === SnapConstants.StartDateTime) {
            const currentStartString = this.getValue(fieldName);

            // when start changes, change the end so they difference is the same as it was prior
            // endDate = currentStart.AddMinutes(priorStart - currentEnd))
            if (!Utility.isNullOrEmpty(currentStartString)) {
                let currentEndString = this.getValue(SnapConstants.EndDateTime)
                const priorStartString = this.getPriorValue(fieldName) ?? currentStartString;

                if (LPDateTime.isDate(priorStartString) && LPDateTime.isDate(currentEndString)) {
                    // Cast to date to determine number of minutes between prior start/end values
                    const priorStartDate = new Date(priorStartString);
                    let currentEndDate = new Date(currentEndString);
                    const minutesDiff = LPDateTime.minuteDifference(priorStartDate, currentEndDate);

                    const currentStartDate = new Date(currentStartString);

                    // Advance the end date by the minute difference we computed just above
                    currentEndDate = LPDateTime.addMinutes(currentStartDate, minutesDiff);

                    // cast it back to a string.
                    currentEndString = LPDateTime.toDateTimeString(currentEndDate)

                    // split and assign the final values
                    const parts = currentEndString.split(" ");
                    this.setValue(SnapConstants.EndDateTime, currentEndString);
                    this.setValue(`date${SnapConstants.EndDateTime}`, parts[0]);
                    this.setValue(`time${SnapConstants.EndDateTime}`, parts[1]);
                }
            }
        }
    }

    public isTask = false;
    public isEvent = false;

    //***
    //***
    private _closedTaskStatus: string[];
    public get closedTaskStatus(): string[] {
        if (!this._closedTaskStatus) {
            this._closedTaskStatus = [];
            const pickList = this.snapForms.selectedSnapForm.recordType.picklists.find(x => x.fieldName === SnapConstants.ClosedTaskStatus);
            if (pickList?.values)
                pickList.values.forEach(x => this._closedTaskStatus.push(x.value));
        }
        return this._closedTaskStatus;
    }



    //***
    //***
    public isClosedTaskStatus(value: string): boolean { return this.closedTaskStatus.length > 0 && this.closedTaskStatus.includes(value); }


    //***
    //***
    private watchValues = false;
    private dumpValues(fieldName: string): void {
        const result: string[] = [];
        this.masterValues.sort((a, b) => a.fieldName.toLowerCase() < b.fieldName.toLowerCase() ? -1 : (a.fieldName.toLowerCase() > b.fieldName.toLowerCase() ? 1 : 0)).forEach(x => result.push(`${(x.fieldName === fieldName ? "*" : "")}${x.fieldName}=${x.value}`));
        Utility.debug(Utility.toDelimitedString(result, "\n"), "---MasterValues---");
    }


    //***
    //***
    isFormDirty() { return this.initialValuesString !== this.currentMasterValuesString(); }


    //***
    //***
    currentMasterValuesString(): string {
        let result = "";
        if (this.masterValues)
            result = JSON.stringify(Array.from(this.masterValues.entries()));
        return result;
    }

    //***
    //*** Return only the values of the fields in the currently selected Snapform
    filteredMasterValues(): SnapFormValue[] {
        let result: SnapFormValue[] = [];
        if (this.masterValues)
            result = this.masterValues.filter(x => this.snapForms.selectedSnapForm.hasField(x.fieldName));
        return result;
    }


    //***
    //***
    isAllFieldsValid(): boolean {
        //If there are no invalid fields return true
        return this.validateAllFields().size === 0;
    }

    //***
    //***
    private validateAllFields(): Map<string, string> {
        //Collection of invalid fields and their error messages
        const result = new Map<string, string>();
        if (this.snapForms) {
            this.snapForms.selectedSnapForm.editSections.forEach(currentSection => {
                currentSection.fields.forEach(currentField => {
                    //Validate the field
                    currentField.validate(this.getValue(currentField.fieldName));

                    //If there were any errors add it to the result
                    if (currentField.validationMessage) {
                        Utility.debug(currentField.validationMessage, "Validation Error");
                        result.set(currentField.fieldName, currentField.validationMessage);
                    }
                });
            });
        }
        return result;
    }

    //***
    //***
    private setFooterStatus(isSpinning: boolean = null, rowCount: number = null) {
        const value = this.footerStatusSubject.value ?? new FooterStatus(isSpinning, 0, 0);
        if (isSpinning)
            value.isSpinning = isSpinning;
        if (rowCount)
            value.rowCount = rowCount;
        this.footerStatusSubject.next(value);
    }

    //***
    // Used to merge given values into existing form values
    //***
    public mergeValues(values: MiniFormResult) {
        this.changeRecordType(values.recordTypeId);

        values.fieldValues.forEach(item => {
            const target = this.getMasterEntry(item.fieldName);
            if (target) {
                target.value = item.value;
                target.displayValue = item.displayValue
            }
            else {
                this.masterValues.push(item);
            }
        });
    }

    //***
    //***
    public changeRecordType(recordTypeId: string) {
        if (this.snapForms?.selectedSnapForm?.recordTypeId !== recordTypeId) {
            const newSnapForm = this.snapForms.forms.find(x => x.recordTypeId === recordTypeId);
            if (newSnapForm)
                this.snapForms.selectedSnapForm = newSnapForm;
        }
    }
}
