import Utility from "../shared/utility";
import { CellValueType } from "./enums";
import { IDeserializable } from "./interfaces/IDeserializable.interface";

export class Cell implements IDeserializable {
    public name: string;
    public label: string;
    public value: string;
    public displayValue: string;
    public defaultValue: string;



    //***
    //***
    private _isVisible = true;
    public get isVisible(): boolean {
        if (this._isVisible)
            return this._isVisible;
        else
            return false;
    }
    public set isVisible(value: boolean) {
        this._isVisible = value;
    }


    //***
    //***
    private _dataType: CellValueType = CellValueType.S;
    public get dataType(): CellValueType {
        if (this._dataType)
            return this._dataType;
        else
            return CellValueType.S;
    }
    public set dataType(value: CellValueType) {
        this._dataType = value;
    }


    //***
    //***
    get bestValue(): string {
        if (!Utility.isNullOrEmpty(this.displayValue))
            return this.displayValue;

        else if (!Utility.isNullOrEmpty(this.value))
            return this.value;

        else if (this.defaultValue)
            return this.defaultValue;

        else
            return '-';
    }


    //***
    //***
    deserialize(input: Cell): this {
        Object.assign(this, input);
        return this;
    }
}
