import { ResponseBase } from "./responseBase";
import { Group } from "./group";
import { GroupPreference } from "./groupPreference";
import { ClientState } from "./clientState";
import { IDeserializable } from "./interfaces/IDeserializable.interface";
import { SnapFormHeader } from "./snapFormHeader";
/*import { TimeScoutOrganizer } from "./timeScoutOrganizer";*/
import { ProfileId, Visibility } from "./enums";
import { SnapForms } from "../snap-forms/models/snapForms";

export class BootstrapResponse extends ResponseBase implements IDeserializable {
    public lpUserId: string;
    //***
    public searchOnlyNameFields = false;
    public searchOnlyNameFieldsVisibility = Visibility.Visible;
    //***
    public autoSelectAttachmentsEnabled = false;
    public autoSelectAttachmentsIgnore = "";
    public autoSelectAttachmentsVisibility = Visibility.Visible;
    //***
    public supportVisibility = Visibility.Visible;
    public knowledgeBaseVisibility = Visibility.Visible;
    public chatVisibility = Visibility.Visible;


    //***
    public ignoreSearchHintsEnabled = false;
    public ignoreSearchHints = "";
    public ignoreSearchHintsVisibility = Visibility.Visible;
    //***
    public followUpTableName: string
    public followUpVisibility = Visibility.Visible
    //***
    public removeAllVisibility = Visibility.Visible
    //***
    public multiWhoEnabled = false;
    public sidePanelSplitterHeight = 150;
    public sidekickVisibility = Visibility.Visible;
    public openInBrowserVisibility = Visibility.Visible;
    public profileId: ProfileId = ProfileId.StandardUser;
    //***
    public groups: Group[] = [];
    public groupPreferences: GroupPreference[] = [];
    public snapFormHeaders: SnapFormHeader[] = [];
    public miniSnapFormTask: SnapForms;
    public miniSnapFormEvent: SnapForms;
    public clientState: ClientState = new ClientState();
    /*public timeScoutOrganizer: TimeScoutOrganizer = new TimeScoutOrganizer();*/
    //***
    public subjectBodyEnabled = false;
    public subjectBodyVisibility = Visibility.Visible;
    //***
    public editModeMessageEnabled = false;
    public editModeMessageVisibility = Visibility.Visible;
    ////***
    //public timeScoutEnabled = false;
    //public timeScoutVisibility = Visibility.Visible;
    //***
    public connectionsEnabled = false;
    public connectionsVisibility = Visibility.Visible;
    //***

    public salesforceInstanceUrl: string;
    public salesforceManagedAppEndPoint: string;
    public salesforceConnected = false;
    public salesforceUserName = "";
    public orgMySalesforceUrl = "";
    public salesforceOAuthMode = "";
    public salesforceOAuthEndPoint = "";
    //***
    public microsoftManagedAppEndPoint: string;
    public microsoftManagedAppScope: string;
    public microsoftConnected = false;
    public microsoftUserName = "";
    //***
    public calendarSyncEnabled = false;
    public emailSyncEnabled = false;
    public contactSyncEnabled = false;
    public searchHintHistory = "";
    //***
    public hasActivated = false;
    public appVersion: string;
    public isLicenseActive = false;
    public hasCookieConsent = false;

    //***
    public headerCreateVisibility = Visibility.Visible;
    public cardCreateVisibility = Visibility.Visible;
    public cardEditVisibility = Visibility.Visible;
    //***
    public cardQuickCommentVisibility = Visibility.Visible;
    public helpVisibility = Visibility.Visible;
    public primaryWhoVisibility = Visibility.Visible;
    //***
    public autoExpandVisibility = Visibility.Visible;
    public sortVisibility = Visibility.Visible;
    //***
    public discoverySuccessCount: number = 0;
    //***
    public suggestUsingConversation = true;
    public suggestUsingConversationVisibility = Visibility.Visible
    //***
    public suggestUsingRelevance = true;
    public suggestUsingRelevanceVisibility = Visibility.Visible;


    //***
    //***
    deserialize(input: any): this {
        Object.assign(this, input);

        if (Array.isArray(input.groups))
            this.groups = input.groups.map((group) => new Group().deserialize(group));

        if (Array.isArray(input.groupPreferences))
            this.groupPreferences = input.groupPreferences.map((groupPreference) => new GroupPreference().deserialize(groupPreference));

        if (Array.isArray(input.snapFormHeaders))
            this.snapFormHeaders = input.snapFormHeaders.map((snapFormHeader) => new SnapFormHeader().deserialize(snapFormHeader));

        if (input?.clientState)
            this.clientState = new ClientState().deserialize(input.clientState);

        return this;
    }
}
