export enum PaneType {
    Search = "Search",
    Select = "Select",
    SideKick = "SideKick",
    SidekickRoot = "SidekickRoot",
    SidekickRelated = "SidekickRelated",
    GeneralPreferences = "GeneralPreferences",
    Preference = "Preference",
}


export enum DeckType {
    Search = "Search",
    Select = "Select",
    Suggest = "Suggest",
    SideKickRoot = "SideKickRoot",
    SideKickRelated = "SideKickRelated",
}

export enum CellType {
    Spacer = "Spacer",
    Data = "Data",
}

export enum ProfileId {
    StandardUser = "StandardUser",
    OrganizationAdmin = "OrganizationAdmin",
    LinkPointAdmin = "LinkPointAdmin",
    SystemAdmin = "SystemAdmin"
}


export enum SelectCardAction {
    Added = "Added",
    Removed = "Removed",
    SetPrimary = "SetPrimary",
    Reapply = "Reapply"
}

//***
export enum SearchType {
    Undefined = "Undefined",
    SearchBar = "SearchBar", // <-- Only the first hint will be considered
    Document = "Document", // <-- SearchScope is disregarded and SearchScope.EmailAddress is used
    SideKick = "SideKick", // <-- Only one hint used, it must be the business card recordId.
}

//***
export enum PouchClass {
    Undefined = "Undefined",
    Email = "Email",
    Calendar = "Calendar",
    Contact = "Contact",
    Task = "Task"
}


//***
export enum PropertySetAction {
    Undefined = "Undefined",
    Set = "Set",
    Clear = "Clear"
}


export enum Visibility {
    Visible = "Visible",
    Disabled = "Disabled",
    Hidden = "Hidden",
}


export enum LPVisibility {
    Undefined = "Undefined",
    Visible = "Visible",
    Disabled = "Disabled",
    Hidden = "Hidden",
}

export enum CellValueType {
    S = "S",
    D = "D",
    B = "B",
    N = "N",
}

export enum PanelType {
    Undefined = "Undefined",
    Sidekick = "Sidekick",
    Preferences = "Preferences",
    LicenseExpired = "LicenseExpired",
    SnapForm = "SnapForm",
    BootstrapError = "BootstrapError"
}

//***
//***
export enum ActionType {
    SetProperty = "SetProperty",
    Signal = "Signal"
}

//***
//***
export enum BusyStatus {
    Busy = "Busy",
    Free = "Free",
    Tenatative = "Tenatative",
    OutOfOffice = "OutOfOffice",
    Completed = "Completed",
    Canceled = "Canceled",
    Undefined = "Undefined"
}


//***
//***
export enum SignalType {
    Undefined,
    SalesforceDiscoveryStarted = "SalesforceDiscoveryStarted",
    SalesforceDiscoveryCompleted = "SalesforceDiscoveryCompleted",
    ExpiredLicense = "ExpiredLicense",
    ExpiredBootstrap = "ExpiredBootstrap",
    ExpiredSalesforceAuth = "ExpiredSalesforceAuth",
    DisconnectGraph = "DisconnectGraph",
    DisconnectSalesforce = "DisconnectSalesforce",
    BootstrapCompleted = "BootstrapCompleted"

}

//***
//***
export enum TargetType {
    GroupPreference = "GroupPreference",
    UserPreference = "UserPreference",
}


//***
//***
export enum SuggestionSource {
    Undefined = "Undefined",
    EmailThread = "EmailThread",
    Relevance = "Relevance"
}

export enum PacketMode {
    Read,
    Edit
}

export enum OfficeEvent {
    Undefined = "Undefined",
    ItemChanged = "ItemChanged",
    RecipientsChanged = "RecipientsChanged",
    AttachmentsChanged = "AttachmentsChanged",
    AppointmentTimeChanged = "AppointmentTimeChanged",
    ReferencePacketSet = "ReferencePacketSet"
}

//Defines the 3 stages of logging into an Oauth Service
//Initial: nothing is happening/waiting for user to initiate
//Dialog: the dialog is open, waiting for the user to login
//Server: we have received the tokens from Oauth and are processing them on the server
export enum LoginState {
    Initial = 'Initial',
    Dialog = 'Dialog',
    Server = 'Server'
}

export enum ComponentState {
    Hidden = "Hidden",
    Disabled = "Disabled",
    Enabled = "Enabled"
}

//***
//***
export enum LPExceptionCode {
    None = "None",
    GenericException = "GenericException",
    ArgumentException = "ArgumentException",
    InvalidDataException = "InvalidDataException",
    InvalidOperationException = "InvalidOperationException",
    SerializationException = "SerializationException",
    TimeoutException = "TimeoutException",
    UnauthorizedAccessException = "UnauthorizedAccessException",
    Undefined = "Undefined"
}
