<div style="display: flex; justify-content: center;"
     [style.width]="imageSizePixels"
     [style.height]="imageSizePixels"
     [style.background-color]="imageBackgroundColor"
     [style.border-radius]="borderRadiusPixels"
     [style.padding]="paddingPixels"
     [style.opacity]="opacity"
     [tooltip]="lpTooltip"
     delay="750">
    <svg viewBox="0 0 24 24"
         [style.width]="imageSizePixels"
         [style.background-color]="imageBackgroundColor">
        <path [attr.fill]="imageForegroundColor"
              [attr.d]="imagePoints" />
    </svg>
</div>
