import { Injectable } from "@angular/core";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { Card } from "../../models/card";
import { PanelType } from "../../models/enums";
import { DataEntryTextboxComponent } from "../../shared/command-components/data-entry-textbox/data-entry-textbox.component";


//***
@Injectable()
export class PanelService {
    selectedCard = new BehaviorSubject<Card>(null);
    currentPanel = new BehaviorSubject<PanelType>(PanelType.Undefined);
    bsModalRef?: BsModalRef;

    constructor(private modalService: BsModalService) { }

    setCard(card: Card) {
        this.selectedCard.next(card);
        this.displayModal(PanelType.Sidekick);
    }


    //**
    //**
    displayModal(panel: PanelType) { this.currentPanel.next(panel); }


    //**
    //**
    clearModal() { this.currentPanel.next(PanelType.Undefined); }


    //**
    //**
    getInput(title: string, iconPath: string, defaultValue: string): Observable<string> {

        this.bsModalRef = this.modalService.show(DataEntryTextboxComponent, { class: 'modal-sm lp-modal-sm' });
        this.bsModalRef.content.iconPath = iconPath;
        this.bsModalRef.content.title = title;
        this.bsModalRef.content.value = defaultValue;

        const inputSubject = new Subject<string>();
        let result: string = null;

        this.bsModalRef.content.onClose.subscribe(save => {
            if (save) {
                result = this.bsModalRef.content.value;
            }
            inputSubject.next(result);
        });
        return inputSubject.asObservable();
    }
}
