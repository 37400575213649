import { IDeserializable } from "./interfaces/IDeserializable.interface";
import { IPouchKey } from "./interfaces/IPouchKey.interface";

export class PouchKey implements IPouchKey, IDeserializable {
    public id: string = this.newGuid();
    public timestamp: Date = new Date();
    public recurringInstanceDate: Date;
    public fullId: string;
    public isRecurringInstance: boolean;

    //***
    //***
    deserialize(input: PouchKey): this {
        Object.assign(this, input);
        return this;
    }


    //**
    //**
    newGuid(): string {
        let result = ""

        for (let k = 0; k < 32; k++) {
            const randomValue = Math.random() * 16 | 0;
            if (k === 8 || k === 12 || k === 16 || k === 20) {
                result += "-"
            }
            result += (k === 12 ? 4 : (k === 16 ? (randomValue & 3 | 8) : randomValue)).toString(16);
        }
        return result;
    }

}
