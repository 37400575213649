import { cloneDeep } from "lodash";
import { IDeserializable } from "../../../app/models/interfaces/IDeserializable.interface";
import Utility from "../../shared/utility";
import { DependencyMap } from "./dependencyMap";
import { LPEntryMode, LPFieldType } from "./enums";
import { SnapFormField } from "./snapFormField";
import { SnapFormPicklistValue } from "./snapFormPicklistValue";
import { SnapFormRecordType } from "./snapFormRecordType";
import { SnapFormSection } from "./snapFormSection";
import { SnapFormValue } from "./snapFormValue";

export class SnapForm implements IDeserializable {
    id: string;
    recordTypeId: string;
    recordType: SnapFormRecordType;
    //***
    findField(fieldName: string): SnapFormField { return this.allFields.find(x => x.fieldName === fieldName); }
    hasField(fieldName: string): boolean { return this.allFields.some(x => x.fieldName === fieldName); }


    //***
    //***
    private _editSections: SnapFormSection[];
    get editSections(): SnapFormSection[] { return this._editSections ?? (this._editSections = []); }
    set editSections(value: SnapFormSection[]) { this._editSections = value; }


    //***
    //***
    private _entryMode: LPEntryMode = LPEntryMode.Undefined;
    public get entryMode(): LPEntryMode { return this._entryMode; }
    public set entryMode(value: LPEntryMode) {
        this._entryMode = value;
        for (const item of this.editSections)
            item.entryMode = value;
    }


    //***
    //***
    private _allFields: SnapFormField[];
    get allFields(): SnapFormField[] {
        if (!this._allFields || this._allFields.length === 0) {
            this._allFields = [];
            for (const section of this.editSections)
                for (const field of (section.fields))
                    this._allFields.push(field);
        }
        return this._allFields;
    }


    //***
    //***
    deserialize(input: any): this {
        Object.assign(this, input);
        this.editSections = [];
        this._allFields = [];
        if (Array.isArray(input?.editSections))
            for (const source of input.editSections)
                this.editSections.push(new SnapFormSection().deserialize(source))

        if (Array.isArray(input?.allFields))
            for (const section of this.editSections)
                for (const field of (section.fields))
                    this._allFields.push(field);
        return this;
    }


    //***
    //***
    public fixDependencyState(fieldValues: SnapFormValue[]) {
        const maps = this.recordType.dependencyMaps;
        if (maps && maps.length > 0)
            maps.forEach(map => this.fixDependencyStateMap(fieldValues, map));
    }

    //***
    //***
    public fixDependencyStateField(fieldValues: SnapFormValue[], field: SnapFormField) {
        if (field) {
            const maps = this.recordType.dependencyMaps;
            if (maps && maps.length > 0)
                this.fixDependencyStateMap(fieldValues, maps.find(x => x.fieldName === field.fieldName));
        }
    }


    //***
    //***
    private fixDependencyStateMap(fieldValues: SnapFormValue[], map: DependencyMap) {
        if (map) {
            const allFields = this.allFields;
            const parentField = allFields.find(x => x.fieldName === map.fieldName);
            if (parentField) {
                let parentValue = fieldValues.find(x => x.fieldName === map.fieldName);
                if (!parentValue) {
                    parentValue = new SnapFormValue(parentField.fieldName, "", "")
                    fieldValues.push(parentValue);
                }

                map.children.forEach(childMap => {
                    const childField = allFields.find(x => x.fieldName === childMap.fieldName);
                    if (childField) {
                        let childValue = fieldValues.find(x => x.fieldName === childMap.fieldName);
                        if (!childValue) {
                            childValue = new SnapFormValue(childMap.fieldName, "", "");
                            fieldValues.push(childValue);
                        }

                        if (childField.fieldType === LPFieldType.Picklist)
                            this.fixDependencyPicklist(parentField, parentValue, childField, childValue);

                        else if (childField.fieldType === LPFieldType.MultiPicklist)
                            this.fixDependencyMultiPicklist(parentField, parentValue, childField, childValue);
                    }
                });
            }
        }
    }


    //***
    //***
    private fixDependencyPicklist(parentField: SnapFormField, parentValue: SnapFormValue, childField: SnapFormField, childValue: SnapFormValue) {
        let fullPicklist = this.recordType.picklists.find(x => x.fieldName === childField.fieldName);
        if (fullPicklist) {
            // clone it to avoid any issues
            fullPicklist = cloneDeep(fullPicklist);
            const subsetPicklist: SnapFormPicklistValue[] = [];

            // build up a new sublist of values
            const parentControllerValue = fullPicklist.controllerValues.find(x => x.value === parentValue.value);
            if (parentControllerValue) {
                fullPicklist.values.forEach(plValue => {
                    if (plValue.value === "" || (plValue.isActive && plValue.validForIndex.includes(parentControllerValue.index)))
                        subsetPicklist.push(plValue);
                });
            }

            // if we simply replace the old list with the new (and the currently selected item is in both lists, wee will lose it and the selected item defaults to the first.)
            // instead we first remove any items in the actual list that are NOT in the new list.
            for (let i = childField.picklistValues.length - 1; i >= 0; i--)
                if (!subsetPicklist.some(x => x.value === childField.picklistValues[i].value))
                    childField.picklistValues.splice(i, 1);

            // next, we add all the items from the new list that are not in the old list
            for (let i = 0; i < subsetPicklist.length; i++)
                if (!childField.picklistValues.some(y => y.value === subsetPicklist[i].value))
                    childField.picklistValues.splice(i, 0, subsetPicklist[i]);

            childField.validationMessage = "";
            childField.dependencyComment = "";
            childField.isRequired = childField.orginalIsRequired;
            if (!childField.picklistValues.some(x => !Utility.isNullOrEmpty(x.value))) {
                childField.dependencyComment = `Not applicable for current value of '${parentField.label}s'`;
                childValue.value = "";
                childField.isRequired = false;
            }
        }
    }


    //***
    //***
    private fixDependencyMultiPicklist(parentField: SnapFormField, parentValue: SnapFormValue, childField: SnapFormField, childValue: SnapFormValue) {
        // Clear out the fields existing permitted values.
        if (childField.picklistValues.length > 0)
            childField.picklistValues.slice(0, childField.picklistValues.length)

        // Get the defined full picklist.
        let fullPicklist = this.recordType.picklists.find(x => x.fieldName === childField.fieldName);
        if (fullPicklist) {
            // clone it to avoid messing up the default values.
            fullPicklist = cloneDeep(fullPicklist);

            // Find the "controllerValue" using the parent field name.  its index will be used to identify which picklist values are permitted for the  parent value
            const parentControllerValue = fullPicklist.controllerValues.find(x => x.value === parentValue.value);

            // scan all the picklist values looking for any that are valid for the parentControllerValue.index
            const subsetPicklist: SnapFormPicklistValue[] = [];
            if (parentControllerValue)
                fullPicklist.values.forEach(plValue => {
                    if (plValue.isActive && plValue.validForIndex.includes(parentControllerValue.index))
                        subsetPicklist.push(plValue);
                });

            // try to find the new selected value of the filtered list.
            let selectedPickListValue: SnapFormPicklistValue = undefined;
            if (subsetPicklist.length > 0) {
                // first try to find a picklist value that matches the fieldvalue
                if (!Utility.isNullOrEmpty(childValue.value))
                    selectedPickListValue = subsetPicklist.find(x => x.value === childValue.value);

                // if none, try to find the default value of the filtered list.
                if (!selectedPickListValue)
                    selectedPickListValue = subsetPicklist.find(x => x.isDefaultValue);

                // finally, take the first one.
                if (!selectedPickListValue)
                    selectedPickListValue = subsetPicklist[0]
            }

            subsetPicklist.forEach(x => x.isDefaultValue = false);
            if (selectedPickListValue)
                selectedPickListValue.isDefaultValue = true;
            childField.picklistValues = subsetPicklist;

            childField.validationMessage = "";
            childField.dependencyComment = "";
            childField.isRequired = childField.orginalIsRequired;
            if (!childField.picklistValues.some(x => !Utility.isNullOrEmpty(x.value))) {
                childField.dependencyComment = `Not applicable for current value of '${parentField.label}s'`;
                childValue.value = "";
                childField.isRequired = false;
            }
        }
    }
}
