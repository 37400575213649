import { Component, Input } from '@angular/core';
import Utility from '../../../shared/utility';
import { SnapFormField } from '../../models/snapFormField';
import { SnapFormService } from '../../services/snapform.service';

@Component({
    selector: 'snap-lp-picklist',
    templateUrl: './lp-picklist.component.html',
    styleUrls: ['./lp-picklist.component.scss']
})
export class LPPicklistComponent {

    //***
    //***
    @Input() snapFormField: SnapFormField;


    //***
    //***
    get value(): string { return this.snapFormService.getValue(this.snapFormField.fieldName) ?? ""; }
    set value(value: string) { this.snapFormService.setValue(this.snapFormField.fieldName, value); }

    //***
    //***
    get dependencyCommentVisible(): boolean { return !Utility.isNullOrEmpty(this.snapFormField.dependencyComment); }


    //***
    //***
    get isEnabled(): boolean { return this.snapFormField.isEnabled && this.snapFormField.picklistValues.length > 0 && Utility.isNullOrEmpty(this.snapFormField.dependencyComment); }



    //***
    //***
    constructor(private snapFormService: SnapFormService) { }
}
