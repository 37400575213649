import { DeckType, LPVisibility } from "./enums";
import { IDeserializable } from "./interfaces/IDeserializable.interface";

export class DeckState implements IDeserializable {
    public deckType: DeckType;
    public cardCount: number;
    public isVisible: boolean;
    public checkBoxState: LPVisibility;

    deserialize(input: DeckState): this {
        Object.assign(this, input);
        return this;
    }
}
