import { DeckType, LPVisibility } from "./enums";
import { IDeserializable } from "./interfaces/IDeserializable.interface";

export class CardState implements IDeserializable {
  public id: string;
  public groupId: string;
  public checkBoxState: LPVisibility;
  public isChecked: boolean;
  public visibleDecks: DeckType[] = [];

  deserialize(input: CardState): this {
    Object.assign(this, input);
    return this;
  }
}
