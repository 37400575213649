import { AfterViewInit, Component, Input } from '@angular/core';
import { FocusService } from '../../../shared/focus.service';
import { SnapForm } from '../../models/snapForm';

@Component({
    selector: 'snap-lp-form',
    templateUrl: './lp-form.component.html',
    styleUrls: ['./lp-form.component.scss'],
})
export class LPFormComponent implements AfterViewInit {
    //***
    @Input() snapForm: SnapForm;

    //***
    //***
    constructor(private focusService: FocusService) {
    }

    //***
    //***
    ngAfterViewInit() {
        //Set the cursor focus on first input, select, textarea that is a descendant of the snap-lp-form
        this.focusService.setFocusFirstMatch('snap-lp-form :is(input, select, textarea)');
    }

}
