export class DependencyMap {

    //***
    fieldName: string
    //***
    parent: DependencyMap


    //***
    get isRoot(): boolean { return this.parent ? true : false; }
    set isRoot(value: boolean) { }

    //***
    get isLeaf(): boolean { return this.children.length > 0; }
    set isLeaf(value: boolean) { }


    //***
    private _children: DependencyMap[] = []
    get children(): DependencyMap[] {
        if (!this._children)
            this._children = []
        return this._children;
    }
    set children(value: DependencyMap[]) { this._children = value; }

    //***
    //***
    deserialize(input: any): this {
        Object.assign(this, input);

        this.children = [];
        if (Array.isArray(input.children))
            for (const source of input.children)
                this.children.push(new DependencyMap().deserialize(source));
        return this;
    }
}
