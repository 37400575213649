<div class="lp-container">
    <div class="row no-gutters">

        <div *ngIf="isImageVisible"
             [style.background]="imageBackgroundColor"
             style="border-radius: 5px; margin-right: 4px"
             class="col-auto no-gutters lp-center-content align-self-center">
            <img [src]="imageUrl"
                 width="26"/>
        </div>

        <div class="col no-gutters align-self-center">
            <div>
                <div class="lp-title lp-font-white"
                     style="display: inline-block"
                     *ngIf="isTitleVisible">
                    {{title}}
                </div>

                <app-open-link *ngIf="hasEditUrl"
                               style="display: inline-block; align-self: center; margin-left: 4px;"
                               [urlLink]="editUrl"
                               [lpTooltip]="'Open in Salesforce'" delay="750"></app-open-link>
            </div>
            <div style="display: flex; color: white; margin: -4px 0px 0px 0px"
                 *ngIf="isCreateMode">
                <input class="align-self-center"
                       style="display: inline-block; margin: 3px 3px 0px 0px;"
                       type="checkbox"
                       [id]="openAfterCreate"
                       [name]="openAfterCreate"
                       [(ngModel)]="openAfterCreate">
                <label class="align-self-center"
                       style="font-size: 10px; margin: 0px; display: inline-block" for="openAfterCreate">Open in Salesforce after saving</label><br/>
            </div>
        </div>

        <div *ngIf="isSubmitting" class="col-auto no-gutters align-self-center">
            <button *ngIf="!isMiniForm" disabled
                    type="button"
                    class="btn btn-warning lp-button-medium">
                <span class="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"></span>
            </button>
            <button type="button" disabled
                    class="btn btn-warning lp-button-medium">
                Cancel
            </button>
        </div>
        <div *ngIf="!isSubmitting" class="col-auto no-gutters align-self-center">
            <button *ngIf="!isMiniForm"
                    type="button"
                    class="btn btn-warning lp-button-medium"
                    (click)="submitForm()">
                Ok
            </button>
            <button *ngIf="isMiniForm"
                    type="button"
                    class="btn btn-warning lp-button-medium"
                    (click)="submitMini()">
                Ok
            </button>
            <button type="button"
                    class="btn btn-warning lp-button-medium"
                    (click)="cancel()">
                Cancel
            </button>
        </div>
    </div>
</div>
