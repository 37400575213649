import { IDeserializable } from "../../models/interfaces/IDeserializable.interface";

export class SubmitSnapFormRequest implements IDeserializable {

    lpUserId: string;
    tableName: string;
    id: string;
    fieldValues: Map<string, string>;
    openAfterCreate: boolean

    //***
    //***
    constructor(lpUserId: string, tableName: string, id: string, fieldValues: Map<string, string>, openAfterCreate: boolean) {
        this.lpUserId = lpUserId ?? '';
        this.tableName = tableName ?? '';
        this.id = id ?? '';
        this.fieldValues = fieldValues ?? new Map<string, string>();
        this.openAfterCreate = openAfterCreate;
    }


    deserialize(input: SubmitSnapFormRequest): this {
        Object.assign(this, input);
        if (input?.fieldValues) {
            this.fieldValues = new Map<string, string>();
            for (const value in input.fieldValues)
                this.fieldValues.set(value, input.fieldValues[value])
        }
        return this;
    }

}
