import { IDeserializable } from "../../models/interfaces/IDeserializable.interface";

export class LookUpSnapFormRequest implements IDeserializable {

    //***
    lpUserId: string;
    tableName: string;
    fieldName: string;
    searchHint: string;
    referenceTables: string[];

    //***
    //***
    constructor(lpUserId: string, tableName: string, fieldName: string, searchHint: string, referenceTables: string[]) {
        this.lpUserId = lpUserId ?? '';
        this.tableName = tableName ?? '';
        this.fieldName = fieldName ?? '';
        this.searchHint = searchHint ?? '';
        this.referenceTables = referenceTables ?? [];
    }

    deserialize(input: LookUpSnapFormRequest): this {
        Object.assign(this, input);
        return this;
    }
}
