import { Component, OnInit } from '@angular/core';
import { SublimeService } from '../../core/services/sublime.service';
import { Deck } from '../../models/deck';
import { DeckType } from "../../models/enums";
import { Header } from "../../models/header";
import { Pane } from "../../models/pane";
import Utility from '../../shared/utility';

@Component({
    selector: 'app-lp-sidekick',
    templateUrl: './lp-sidekick.component.html',
    styleUrls: ['./lp-sidekick.component.scss']
})
export class LPSidekickComponent implements OnInit {
    billboard: Header = null;

    pane: Pane = null;
    sideKickRootDeck: Deck = null;
    sideKickRelatedDeck: Deck = null;
    public readonly groupHeaderBackgroundColor = Utility.LPLightGray;
    public readonly groupHeaderForegroundColor = Utility.LPBlue;
    readonly titleDescription = "Record Details";

    //For some unknown reason this is necessary to fix a build error
    readonly keyImagePoints: string = '';
    readonly cartImagePoints: string = '';
    readonly chatImagePoints: string = '';
    readonly phoneImagePoints: string = '';


    constructor(private sublimeService: SublimeService) { }

    ngOnInit() {

        this.sublimeService.sideKickPanePipe.subscribe(sourcePane => {
            if (sourcePane) {
                this.billboard = sourcePane.billboard;
                this.sideKickRootDeck = sourcePane.decks.find(x => x.deckType === DeckType.SideKickRoot);
                this.sideKickRelatedDeck = sourcePane.decks.find(x => x.deckType === DeckType.SideKickRelated);
            }
        });
    }
}
