<div class="modal-header">
    <h4 class="about-title pull-left">LinkPoint Blade For Salesforce®</h4>
    <button type="button" class="btn-close close pull-right" aria-label="Close" (click)="bsModalRef?.hide()">
        <span aria-hidden="true" class="visually-hidden">&times;</span>
    </button>
</div>
<div class="modal-body">
    <p class="about-body">Use LinkPoint Blade to view, record, create, and sync between email and Salesforce with simple and seamless email integration for any platform.</p>
    <div class="about-version">Version: {{this.sessionService.appVersion}}</div>
</div>
