import { AppInjector } from "../app/appInjector";
import { OfficeService } from "../app/core/services/office.service";
import { PanelService } from "../app/core/services/panelService";

export function panelMessageHandler(eventType) {
    if (AppInjector && eventType) {
        const officeService = AppInjector.get(OfficeService);
        officeService.onOfficeEvent(eventType);

        const panelService = AppInjector.get(PanelService);
        panelService.clearModal();
    }
    else {
        console.log("app injector is not available");
    }
}

window["panelMessageHandler"] = panelMessageHandler;
