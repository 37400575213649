import { orderBy } from "lodash";
import { Card } from "./card";
import { DeckState } from "./deckState";
import { DeckType, LPVisibility } from "./enums";
import { Group } from "./group";
import { IDeserializable } from "./interfaces/IDeserializable.interface";

export class Deck implements IDeserializable {
    public deckType: DeckType;
    public isVisible = false;
    public checkBoxState: LPVisibility;
    public groups: Group[] = [];
    public cards: Card[] = [];

    deserialize(input: Deck): this {
        Object.assign(this, input);

        if (Array.isArray(input?.groups)) {
            this.groups = input.groups.map((group) => new Group().deserialize(group));
        }
        if (Array.isArray(input?.cards)) {
            this.cards = input.cards.map((card) => new Card().deserialize(card));
        }
        return this;
    }

    //***
    //***
    public get sortedCards(): Card[] { return orderBy(this.cards, ["sortValue"]); }


    //***
    //***
    public get tableLabelVisible(): boolean { return this.deckType === DeckType.Suggest || this.deckType === DeckType.Select; }


    //***
    //***
    public get cellsVisible(): boolean { return this.deckType === DeckType.Search || this.deckType === DeckType.SideKickRoot || this.deckType === DeckType.SideKickRelated };


    //***
    //***
    public clear() {
        for (const group of this.groups)
            group.clear();
        this.groups.splice(0, this.groups.length);
        this.cards.splice(0, this.cards.length);
    }


    //***
    //***
    constructor(deckType?: DeckType) { this.deckType = deckType; }


    //***
    //***
    public getState(): DeckState {
        const result = new DeckState();
        //*
        result.deckType = this.deckType;
        result.cardCount = this.cards.length;
        result.checkBoxState = this.checkBoxState;
        result.isVisible = this.isVisible
        //*
        return result;
    }

    //***
    //***
    public setState(state: DeckState) {
        if (state && this.deckType === state.deckType) {
            //*
            this.checkBoxState = state.checkBoxState;
            this.isVisible = state.isVisible ? true : false;
            //*
        }
    }
}
