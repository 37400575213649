import { Injectable } from "@angular/core";
import { Header } from "../../models/header";
import { ToastNotification } from "../../models/toastNotification";


@Injectable({
    providedIn: "root",
})
export class ToastNotificationService {
    private fadeOutAnimationMilliseconds = 0;
    notifications: ToastNotification[] = [];

    //***
    //***
    constructor() {
        this.notifications = [];
    }

    //***
    // Used to set how long to wait for the fade out animation
    //***
    setAnimationTime(animationMilliseconds: number) {
        this.fadeOutAnimationMilliseconds = animationMilliseconds;
    }

    //***
    // Add a notification to the collection
    //***
    send(title: string, subTitle: string, description: string, millisecondsToDisplay: number, topic: string = "") {
        //If there is any data to display at all then add it to the collection
        if (title || subTitle || description) {
            const newHeader = new Header(null, title, subTitle, description, true);
            const newNotification = new ToastNotification(newHeader, millisecondsToDisplay, topic);

            this.removeTopicNotifications(topic);
            this.notifications.push(newNotification);

            //subscribe to this newNotification's getRemoved observable to know when it's ready to remove itself
            newNotification.getRemovedNotification()
                .subscribe((notificationToRemove: ToastNotification) => {
                    //if this is a valid notification and it was marked as removed then process it
                    this.removeNotification(notificationToRemove);
                });
        }
    }

    //***
    //***
    clearAll() {
        // clear all alerts
        this.notifications = [];
    }

    //***
    //***
    removeTopicNotifications(topic: string) {
        if (topic) {
            let topicNotifications = this.notifications.filter(x => x.topic === topic);
            topicNotifications.forEach(item => {
                item.remove();
            });
        }
    }

    //***
    //***
    removeNotification(notificationToRemove: ToastNotification) {
        //if this is a valid notification and it was marked as removed then process it
        if (notificationToRemove && notificationToRemove.state === "removed") {
            //set a timer and wait for the fading out animation
            setTimeout(() => {
                //remove the item from the current collection of notifications
                let index = this.notifications.indexOf(notificationToRemove);
                if (index > -1) {
                    this.notifications.splice(index, 1);
                }
            }, this.fadeOutAnimationMilliseconds);
        }
    }
}
