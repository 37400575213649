import { IDeserializable } from "../../models/interfaces/IDeserializable.interface";
import { ResponseBase } from "../../models/responseBase";


export class SubmitSnapFormResponse extends ResponseBase implements IDeserializable {

    isSuccess: boolean;
    id: string;
    editUrl = "";
    message: string;


    deserialize(input: any): this {
        Object.assign(this, input);
        return this;
    }
}
