import { Component, Input } from '@angular/core';
import { SnapFormField } from '../../models/snapFormField';
import { SnapFormService } from '../../services/snapform.service';

@Component({
    selector: 'snap-lp-input',
    templateUrl: './lp-input.component.html',
    styleUrls: ['./lp-input.component.scss']
})
export class LPInputComponent {

    //***
    @Input() snapFormField: SnapFormField;
    @Input() inputType: string;


    //***
    //***
    get value(): string { return this.snapFormService.getValue(this.snapFormField.fieldName); }
    set value(value: string) { this.snapFormService.setValue(this.snapFormField.fieldName, value.toString()); }


    //***
    //***
    constructor(private snapFormService: SnapFormService) { }
}
