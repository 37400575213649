<div style="height: 100%; overflow: hidden;">
    <tour-step-template>
        <ng-template #tourStep let-step="step">
            <button class="btn btn-sm btn-default top-right-close-button" (click)="tourService.end()">
                <img src='assets/icons/close-red-circle.png'
                     width="18"
                     height="18"
                     style="vertical-align: initial;" />
            </button>

            <p class="tour-step-content" [innerHTML]="step?.content"></p>
            <div class="tour-step-navigation">
                <button *ngIf="tourService.hasPrev(step)"
                        class="btn btn-sm btn-default left"
                        (click)="tourService.prev()">
                    « {{ step?.prevBtnTitle }}
                </button>
                <button *ngIf="tourService.hasNext(step)"
                        class="btn btn-sm btn-default right"
                        (click)="tourService.next()">
                    {{ step?.nextBtnTitle }} »
                </button>

            </div>
        </ng-template>
    </tour-step-template>
    <div class="centered-tour-element" tourAnchor="start-tour"></div>
    <router-outlet style="height: 100%; overflow: hidden"></router-outlet>
</div>

