import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { SessionService } from '../../core/services/session.service';
import { SimpleRequest } from '../../models/SimpleRequest';
import { SimpleResponse } from '../../models/SimpleResponse';
import { LPFormMode } from '../../snap-forms/models/enums';
import { SnapForms } from '../../snap-forms/models/snapForms';
import { SnapFormService } from '../../snap-forms/services/snapform.service';
import { map } from "rxjs/operators";

@Component({
    selector: 'app-john',
    templateUrl: './john.component.html',
    styleUrls: ['./john.component.scss']
})
export class JohnComponent implements OnInit {
    getValue = "";
    postValue = "";
    externalValue = "";

    //***
    //***
    constructor(private sessionService: SessionService, private httpClient: HttpClient) {
        sessionService.lpUserId = "8069062b-6790-b3f3-5b89-400068d3db15";
        sessionService.officeUserEmailAddress = "johnm@linkpoint360.com";
    }

    //***
    //***
    ngOnInit(): void {
    }

    private utilityGuidUrl = "https://lp-function-devtest-blade-sublimeapi.azurewebsites.net/api/utility/sidepanel/guid";
    //***
    //***
    callGet() {
        console.log("Into the call get");
        //this.httpClient.get(`${this.utilityGuidUrl}/test`, { responseType: "text" }).subscribe((guidResponse: string) => {
        //    console.log("Into the get response");
        //    this.getValue = guidResponse;
        //});

        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'text/plain; charset=utf-8' },
            responseType: 'text'
        };
        fetch(this.utilityGuidUrl, requestOptions)
            .then((response) => response.text())
            .then((data) => {
                console.log("Into the get response2: " + data);
                this.getValue = data;
            });
    }

    private htmlToTextUrl = "https://lp-function-devtest-blade-sublimeapi.azurewebsites.net/api/HtmlToText";
    //***
    //***
    callPost() {
        console.log("Into the call post");
        const request = new SimpleRequest(this.sessionService.lpUserId, this.sessionService.officeUserEmailAddress, Date.now().toString(), "Hello World");
        //this.httpClient.post<SimpleResponse>(this.htmlToTextUrl, JSON.stringify(request)).pipe(map((data) => new SimpleResponse().deserialize(data))).subscribe((response: SimpleResponse) => {
        //    console.log("Into the post response");
        //    this.postValue = response.result;
        //});
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(request)
        };
        fetch(this.htmlToTextUrl, requestOptions)
            .then(response => response.json())
            .then((data) => {
                console.log("Into the post response");
                this.postValue = data.result;
            });
    }

    private externalUrl = "https://reqres.in/api/users?page=1";
    //***
    //***
    callExternal() {
        console.log("Into the external get");
        //this.httpClient.get(this.externalUrl).subscribe((response: string) => {
        //    console.log("Into the external response");
        //    this.externalValue = JSON.stringify(response);
        //});
        fetch(this.externalUrl)
            .then((response) => response.json())
            .then((data) => {
                console.log("Into the external response");
                this.externalValue = JSON.stringify(data);
            });
    }
}
