import { Attachment } from "./attachment";
import { PouchClass } from "./enums";
import { IDeserializable } from "./interfaces/IDeserializable.interface";
import { IPouch } from "./interfaces/IPouch.interface";
import { PouchKey } from "./pouchKey";
import { ReferencePacket } from "./referencePacket";

export abstract class PouchBase implements IDeserializable {
    public attachments: Attachment[];
    public conversationId: string;
    public exception: string;
    public isComposeMode: boolean;
    public pouchKey: PouchKey;
    public readonly pouchClass: PouchClass;
    public abstract searchHints: string[]
    public referencePacket: ReferencePacket;

    //***
    //***
    constructor(pouchClass: PouchClass) {
        this.attachments = [];
        this.conversationId = null;
        this.exception = null;
        this.isComposeMode = false;
        this.pouchKey = new PouchKey();
        this.pouchClass = pouchClass
        this.referencePacket = new ReferencePacket();
    }


    //***
    //***
    deserialize(input: IPouch): this {
        Object.assign(this, input);

        //***
        if (input?.referencePacket)
            this.referencePacket = new ReferencePacket().deserialize(input.referencePacket);

        //***
        if (Array.isArray(input?.attachments)) {
            this.attachments = input.attachments.map((attachment) => new Attachment().deserialize(attachment));
        }

        //***
        if (input?.pouchKey)
            this.pouchKey = new PouchKey().deserialize(input.pouchKey);
        return this;
    }
}
