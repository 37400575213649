import { IDeserializable } from "../../models/interfaces/IDeserializable.interface";
import { LPEntryMode, LPTabOrder } from "./enums";
import { SnapFormField } from "./snapFormField";


export class SnapFormSection implements IDeserializable {

    id: string;
    parentId: string;
    label: string;
    isLabelVisible: boolean;
    tabOrder: LPTabOrder;
    columnCount: number;
    rowCount: number;
    footerLabel: string;


    //***
    //***
    private _fields: SnapFormField[] = [];
    public get fields(): SnapFormField[] {
        if (!this._fields)
            this._fields = [];
        return this._fields;
    }
    public set fields(value: SnapFormField[]) { this._fields = value; }


    //***
    //***
    private _entryMode: LPEntryMode = LPEntryMode.Undefined;
    public get entryMode(): LPEntryMode {
        return this._entryMode;
    }
    public set entryMode(value: LPEntryMode) {
        this._entryMode = value;
        for (const item of this.fields)
            item.entryMode = value;
    }

    //***
    //***
    deserialize(input: any): this {
        Object.assign(this, input);
        this.fields = [];
        if (Array.isArray(input.fields))
            for (const source of input.fields)
                this.fields.push(new SnapFormField().deserialize(source));
        return this;
    }
}
