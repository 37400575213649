import { BroadcastChannel } from 'broadcast-channel';
import { AppInjector } from '../appInjector';
import { SublimeService } from '../core/services/sublime.service';
import { SignalType } from '../models/enums';
import { RemoteAction } from '../models/remoteAction';

export default class BroadcastHelper {
    public static readonly BroadcastChannel = "LPBroadcastChannel";


    // Braodcast Channel API
    //Broadcast channel handler
    public static broadcastChannelHandler(remoteAction: RemoteAction) {
        if (AppInjector) {
            const sublimeService = AppInjector.get(SublimeService);
            switch (remoteAction.signal) {
                case SignalType.ExpiredBootstrap:
                    sublimeService.broadcastReBootstrap(true, false);
                    break;

                case SignalType.ExpiredLicense:
                    break;

                case SignalType.ExpiredSalesforceAuth:
                    break;

                case SignalType.SalesforceDiscoveryCompleted:
                    break;

                case SignalType.SalesforceDiscoveryStarted:
                    break;

                default:
                    break;
            }
        }
    }


    // Braodcast channel add event handler
    public static registerBroadcastHandler() {
        const channel: BroadcastChannel = new BroadcastChannel(BroadcastHelper.BroadcastChannel);
        channel.addEventListener('message', BroadcastHelper.broadcastChannelHandler);
    }

    public static postMessage(action: RemoteAction) {
        const channel = new BroadcastChannel(BroadcastHelper.BroadcastChannel);
        channel.postMessage(action);
    }
}
