import { Component, Input } from '@angular/core';
import { Deck } from '../../models/deck';
import Utility from '../../shared/utility';

@Component({
    selector: 'app-lp-deck',
    templateUrl: './lp-deck.component.html',
    styleUrls: ['./lp-deck.component.scss']
})
export class LPDeckComponent {

    @Input() deck: Deck;
    @Input() displayGroups: boolean;
    @Input() displayCardCells: boolean;
    @Input() displayTableLabel: boolean;

    @Input() groupHeaderBackgroundColor: string;
    @Input() groupHeaderForegroundColor: string;
    @Input() displaySidekick = true;

    //***
    //***
    mouseDoubleClick(event: MouseEvent) {
        if (event && event.ctrlKey === true) {
            Utility.debug(this, `Deck: ${this.deck.deckType}`);
            event.cancelBubble = true;
        }
    }


    //***
    //***
    constructor() {
        this.deck = null;
        this.displayGroups = true;
        this.displayTableLabel = false;
    }
}
