<div style="width: 100%; display: grid; grid-template-columns: auto min-content min-content;"
     class="search-bar">

    <div tourAnchor="tourSearchBox" style="width: 100%; display: grid; grid-template-columns: auto min-content min-content; background-color: white; border-radius: 5px;">

        <div class="btn-group full-width"
             dropdown
             #dropdown="bs-dropdown">
            <input [(ngModel)]="searchText"
                   [ngModelOptions]="{ standalone: true }"
                   type="text"
                   class="search-textbox"
                   placeholder=" Search..."
                   maxlength="100"
                   (keyup.enter)="search()" />

            <a class="inline-link"
               *ngIf="searchHistory.length>0"
               routerLink="" dropdownToggle>
                <img class="inline-link-img"
                     src="assets/icons/chevron-down.png"
                     alt="Search History" />
            </a>
            <ul *dropdownMenu
                class="dropdown-menu noselect full-width"
                role="menu">
                <list-item *ngFor="let item of searchHistory"
                           (click)="selectItem(item)">
                    {{item}}
                </list-item>
            </ul>

            <a class="inline-link"
               *ngIf="isShowClearButton"
               routerLink="" (click)="clear()">
                <img class="inline-link-img"
                     src="assets/icons/close-circle.png"
                     alt="Clear" />
            </a>

            <a class="inline-link"
               *ngIf="isShowSearchButton"
               routerLink="" (click)="search()">
                <img class="inline-link-img"
                     src="assets/icons/magnify.png" alt="Search" />
            </a>
        </div>


    </div>
</div>
