import { IDeserializable } from "./interfaces/IDeserializable.interface";
import { ResponseBase } from "./responseBase";


export class SimpleResponse extends ResponseBase implements IDeserializable {
    public contextToken: string | undefined;
    public result: string | undefined;

    //***
    //***
    deserialize(source: SimpleResponse): this {
        Object.assign(this, source);
        return this;
    }
}
