<div>
    <label class="lp-label"
           style="color: white"
           for="recordTypeSelector">
        Record Type:
    </label>

    <select class="lp-input-element"
            style="padding: 3px 4px 3px 4px; margin-right: 6px;"
            id="recordTypeSelector"
            name="recordTypeSelector"
            [ngModel]="snapForms.selectedSnapForm"
            (ngModelChange)="onSelectedRecordTypeChange($event)">
        <option *ngFor="let form of snapForms.forms"
                [ngValue]="form">
            {{form.recordType.label}}
        </option>
    </select>

</div>
