<div class="lp-sidekick"
     style="height: 95vh; display: grid; grid-template-rows: min-content min-content auto; overflow: hidden">

    <app-header [displayCloseButton]="true"
                [titleDescription]="titleDescription"></app-header>

    <div class="lp-sidekick-card"
         *ngFor="let card of sideKickRootDeck?.sortedCards">
        <app-lp-card [card]="card"
                     [displayCardCells]="true"
                     [displayTableLabel]="true"
                     [displaySidekick]="false"></app-lp-card>
    </div>

    <div style="overflow-y: auto; overflow-x: hidden; height:100%; border-radius: 5px; border: 1px solid transparent;">

        <app-lp-deck [deck]="sideKickRelatedDeck"
                     [displayGroups]="true"
                     [displayCardCells]="true"
                     [displayTableLabel]="false"
                     [groupHeaderBackgroundColor]="groupHeaderBackgroundColor"
                     [groupHeaderForegroundColor]="groupHeaderForegroundColor"></app-lp-deck>
    </div>
</div>
