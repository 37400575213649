import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { DialogLoginComponent } from "./components/dialog-login/dialog-login.component";
import { DialogOauthCallbackComponent } from "./components/dialog-oauth-callback/dialog-oauth-callback.component";
import { GraphDialogLoginComponent } from "./components/graph-dialog-login/graph-dialog-login.component";
import { GraphDialogOauthCallbackComponent } from "./components/graph-dialog-oauth-callback/graph-dialog-oauth-callback.component";
import { MainComponent } from "./components/main/main.component";
import { GraphOauthGuardService } from "./core/services/graphOauthGuard.service";
import { JohnComponent } from "./shim-components/john/john.component";
import { NartComponent } from "./shim-components/nart/nart.component";
import { SnapFormComponent } from "./snap-forms/components/lp-snap-form/lp-snap-form.component";

const routes: Routes = [
    { path: "", component: MainComponent, pathMatch: "full", canActivate: [GraphOauthGuardService], },
    { path: "home", component: MainComponent, pathMatch: "full" },
    { path: "loginDialog", component: DialogLoginComponent, pathMatch: "full" },
    { path: "sfoauth", component: DialogOauthCallbackComponent, pathMatch: "full" },
    { path: "john", component: JohnComponent, pathMatch: "full" },
    { path: "nart", component: NartComponent, pathMatch: "full" },
    { path: "SnapForm/:userId/:tableName/:formMode", component: SnapFormComponent, pathMatch: "full" },
    { path: "SnapForm/:userId/:tableName/:formMode/:id", component: SnapFormComponent, pathMatch: "full" },
    { path: "graphLoginDialog", component: GraphDialogLoginComponent, pathMatch: "full" },
    { path: "graphoauth", component: GraphDialogOauthCallbackComponent, pathMatch: "full", },
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { useHash: true })],
    exports: [RouterModule],
})
export class AppRoutingModule { }
