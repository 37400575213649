import { Component, Input, OnInit } from '@angular/core';
import Utility from '../../../shared/utility';
import { SnapConstants } from '../../models/snapConstants';
import { SnapFormField } from '../../models/snapFormField';
import { SnapFormPicklistValue } from '../../models/snapFormPicklistValue';
import { SnapFormService } from '../../services/snapform.service';

@Component({
    selector: 'snap-lp-task-reminder',
    templateUrl: './lp-task-reminder.component.html',
    styleUrls: ['./lp-task-reminder.component.scss']
})
export class LPTaskReminderComponent implements OnInit {
    //***
    //***
    private _setSnapFormField: SnapFormField;
    get snapFormField(): SnapFormField { return this._setSnapFormField; }
    @Input() set snapFormField(value: SnapFormField) { this._setSnapFormField = value; }


    //***
    //***
    public get value(): string { return this.snapFormService.getValue(this.snapFormField.fieldName); }
    public set value(value: string) {
        if (value !== this.value && !this.scatterBusy) {
            this.snapFormService.setValue(this.snapFormField.fieldName, value);
            this.scatter();
        }
    }


    //***
    //***
    public get isAllDayEvent(): boolean { return this.snapFormService.getValue(SnapConstants.IsAllDayEvent) === SnapConstants.True }


    //***
    //***
    public get isReminderSet(): boolean { return this.snapFormService.getValue(SnapConstants.IsReminderSet) === SnapConstants.True }


    //***
    //***
    public get status(): string { return this.snapFormService.getValue(SnapConstants.Status);; }


    //***
    //***
    public get isStatusClosed(): boolean { return this.snapFormService.isClosedTaskStatus(this.status); }


    //***
    //***
    get isEnabled(): boolean { return !this.isStatusClosed && this.isReminderSet; }
    set isEnabled(value: boolean) { ; }


    //***
    get isVisible(): boolean {
        if (this.snapFormService.isTask)
            return true;
        else if (this.snapFormService.isEvent)
            return this.isAllDayEvent;
        else
            return true;
    }
    set isVisible(value: boolean) { ; }



    //***
    //***
    private gather() {
        if (this.scatterBusy === 0)
            this.value = `${this.date} ${this.time}`.trim();
    }


    //***
    //***
    private scatterBusy = 0;
    private scatter(): void {
        if (this.scatterBusy++ === 0) {
            try {
                this.isEnabled = this.snapFormService.isClosedTaskStatus(this.snapFormService.getValue(SnapConstants.Status));
                const value = this.value;
                let dValue = "";
                let tValue = "";

                if (!Utility.isNullOrEmpty(value)) {
                    const parts = value.split(" ");
                    if (parts.length > 1) {
                        dValue = parts[0];
                        tValue = parts[1];
                    }
                    if (Utility.isNullOrEmpty(dValue))
                        tValue = "";
                }
                this.date = dValue;
                this.time = tValue;
            }
            finally {
                this.scatterBusy = 0;
            }
        }
    }



    //***
    //***
    public get date(): string { return this.snapFormService.getValue(this.dateFieldName); }
    public set date(value: string) {
        this.snapFormService.setValue(this.dateFieldName, value);
        this.gather();
    }
    private get dateFieldName(): string { return `date${this.snapFormField.fieldName}`; }


    //***
    //***
    public timeValues: SnapFormPicklistValue[] = [];
    public get time(): string {
        let result = "";
        if (!Utility.isNullOrEmpty(this.value)) {
            const parts = this.value.split(" ");
            if (parts.length > 1)
                result = parts[1];
        }
        return result;
    }
    public set time(value: string) {
        if (!Utility.isNullOrEmpty(value))
            this.value = `${this.date} ${value}`;
        else
            this.value = "";
    }



    //***
    //***
    ngOnInit() {
        if (this.snapFormService.snapForms) {
            const pickValues = this.snapFormService.snapForms.selectedSnapForm.recordType.picklists.find(x => x.fieldName === SnapConstants.TaskReminderDateTime);
            if (pickValues)
                this.timeValues = pickValues.values;
        }
        this.scatter();
    }


    //***
    //***
    constructor(public snapFormService: SnapFormService) { }
}
