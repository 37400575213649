import { Component, Input, NgZone, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BsDropdownConfig } from 'ngx-bootstrap/dropdown';
import { SessionService } from '../../../core/services/session.service';
import { SublimeService } from '../../../core/services/sublime.service';
import { ToastNotificationService } from '../../../core/services/toast-notification.service';
import { EmailPouch } from '../../../models/emailPouch';
import { SnapFormHeader } from '../../../models/snapFormHeader';
import { SnapFormMenu } from '../../../models/snapFormMenu';
import { SnapFormValue } from '../../../snap-forms/models/snapFormValue';
import { MacHandlerSnapFormService } from '../../../snap-forms/services/mac-handler-snapform.service';
import Utility from '../../utility';

@Component({
    selector: 'app-create-record',
    templateUrl: './create-record.component.html',
    styleUrls: ['./create-record.component.scss'],
    providers: [{ provide: BsDropdownConfig, useValue: { isAnimated: false, autoClose: true } }]
})
export class CreateRecordComponent implements OnInit {
    public readonly imagePoints: string = Utility.svgFileDocumentEdit;


    public snapFormMenu: SnapFormMenu[] = [];
    public officeDialog: Office.Dialog;
    private officeDialogUrl = window.location.origin + "#/";
    private toastTopic = "SnapForm";

    @Input() dropdownClass: string;
    @Input() tableName: string;

    @Input() parentId: string;
    @Input() parentLabel: string;

    @Input() imageBackgroundColor: string = Utility.LPTransparent;
    @Input() imageForegroundColor: string = Utility.LPOrange;
    @Input() imageSize: number;



    //***
    constructor(private router: Router, public sublimeService: SublimeService, private sessionService: SessionService, private toastService: ToastNotificationService,
        private _ngZone: NgZone, private macHandler: MacHandlerSnapFormService,) { }


    //***
    //***
    ngOnInit(): void {
        let snapFormHeader: SnapFormHeader = null;
        //this.iconUrl = "../../../../assets/icons/" + this.lpIconName;
        if (this.tableName) {
            snapFormHeader = this.sublimeService.snapFormHeaders.find(x => x.tableName === this.tableName);
            if (snapFormHeader) {
                snapFormHeader.items.forEach((x) => {
                    this.snapFormMenu.push(new SnapFormMenu(x.tableName, x.tableLabel, x.imageUrl, x.color, x.joinFieldName));
                });
            }
        }
        else {
            if (Array.isArray(this.sublimeService.snapFormHeaders)) {
                this.sublimeService.snapFormHeaders.forEach((x) => {
                    this.snapFormMenu.push(new SnapFormMenu(x.tableName, x.tableLabel, x.imageUrl, x.color, x.joinFieldName));
                });
            }
        }
    }


    //***
    //***
    public get hasSnapFormMenus(): boolean {
        if (Array.isArray(this.snapFormMenu) && this.snapFormMenu.length > 0)
            return true;
        else
            return false;
    }


    //***
    //***
    async onclick(tableName: string) {
        const selectedMenuItem: SnapFormMenu = this.snapFormMenu.find(x => { return x.tableName === tableName; })

        //Pass the authtoken to the dialog
        this.sessionService.setPropertyBag(this.sessionService.propertyBagAuthToken, await this.sessionService.forceTokenUpdate()).subscribe(
            (response) => {
                //if there is a joinFieldName we need to inject the parent values into it before launching the snapform.
                if (selectedMenuItem && selectedMenuItem.joinFieldName) {
                    //Build the snapformvalue to inject into the form
                    const parentValues: SnapFormValue[] = [];
                    parentValues.push(new SnapFormValue(selectedMenuItem.joinFieldName, this.parentId, this.parentLabel));
                    if (Utility.equalsCaseInsensative(tableName, "Task") || Utility.equalsCaseInsensative(tableName, "Event") || Utility.equalsCaseInsensative(tableName, "Case") || Utility.equalsCaseInsensative(tableName, "Opportunity")) {
                        var p = this.sublimeService.getPouch() as EmailPouch;
                        if (p) {
                            parentValues.push(new SnapFormValue("Subject", p.subject));
                            parentValues.push(new SnapFormValue("Description", p.body));
                        }
                    }
                    this.sessionService.setPropertyBag(this.sessionService.propertyBagInjectSnapFormValues, parentValues).subscribe(
                        () => {
                            if (this.sessionService.isMAC)
                                this.macHandler.initializeMacFormFull(tableName);
                            else
                                this.launchDialog(tableName);
                        },
                        (error) => {
                            Utility.debug(error);
                        }
                    );
                }
                else {
                    if (this.sessionService.isMAC)
                        this.macHandler.initializeMacFormFull(tableName);
                    else
                        this.launchDialog(tableName);
                }
            },
            (error) => {
                Utility.debug(error);
            }
        );
    }
    //***
    //***
    private async launchDialog(tableName: string) {
        const url = this.officeDialogUrl + this.router.serializeUrl(this.router.createUrlTree([`/SnapForm/${this.sessionService.lpUserId}/${tableName}/Full`]));
        await Office.context.ui.displayDialogAsync(url, { height: this.sessionService.dialogHeightLarge, width: this.sessionService.dialogWidthMedium, promptBeforeOpen: false, displayInIframe: true }, (result) => {
            if (result.status === Office.AsyncResultStatus.Failed) {
                Utility.debug(`Dialog failed: ${result.error.code} ${result.error.message}`);
            }
            else {
                this.officeDialog = result.value;
                this.officeDialog.addEventHandler(Office.EventType.DialogMessageReceived, this.processOfficeDialogMessage);
                this.officeDialog.addEventHandler(Office.EventType.DialogEventReceived, this.processOfficeDialogEvent);
            }
        });
    }


    //***
    //Fired when dialog calls Office.context.ui.messageParent()
    //***
    private processOfficeDialogMessage = (arg: any) => {
        this._ngZone.run(() => {
            // ToDo - add record to existing dataset
            // this.sessionService.getPropertyBag(this.sessionService.propertyBagSnapFormSaved).subscribe((response: PropertyBagResponse) => this.sublimeService.onSnapFormsSaved.next(Utility.toMap(response.value)));

            if (arg?.message) {
                this.officeDialog.close();
                this.toastService.send('Success', "", "Salesforce item created.", 3000, this.toastTopic);
            }

            this.officeDialog.close();
        });
    };

    //***
    //Fired when dialog is closed by the user
    //***
    private processOfficeDialogEvent = (arg) => {
        // do nothing
    };
}
