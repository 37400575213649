import { IDeserializable } from "./interfaces/IDeserializable.interface";
import { IRequest } from "./interfaces/IRequest.interface";

export class PropertyBagRequest implements IRequest, IDeserializable {

    //***
    lpUserId: string;
    propertyName: string;
    value: string;


    //***
    //***
    constructor(lpUserId: string, propertyName: string, value: string) {
        this.lpUserId = lpUserId;
        this.propertyName = propertyName;
        this.value = value;
    }

    deserialize(input: PropertyBagRequest): this {
        Object.assign(this, input);
        return this;
    }
}
