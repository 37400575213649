import { LPFieldType } from "../enums";
import { SnapFormField } from "../snapFormField";
import { Visitor } from "./Visitor";
import Utility from "../../../shared/utility";

export class PicklistSimpleValidator extends Visitor {
    //***
    //***
    canVisit(field: SnapFormField): boolean {
        return super.commonCanVisitFieldType(field, [LPFieldType.Picklist, LPFieldType.MultiPicklist]) && !field.isDependentPicklist;
    }

    //***
    //*** Used on fields that don't have a parent
    //***
    visit(field: SnapFormField, value: string) {
        if (super.commonVisit(field, value)) {
            if (!Utility.isNullOrEmpty(value)) {
                for (const part of value.split(";")) {
                    if (!field.picklistValues.find(x => x.value === part)) {
                        field.validationMessage = "Invalid picklist value";
                        break;
                    }
                }
            }
        }
    }
}
