<div class="main-content">
    <div >
        <img src="../../assets/icons/LinkPointBladeIcon80t.png"
             title="LinkPoint Blade" />
    </div>

    <h5>LinkPoint Blade For Salesforce®</h5>

    <p>Use LinkPoint Blade to view, record, create, and sync between email and Salesforce with simple and seamless email integration for any platform.</p>

    <div *ngIf="showButton">
        <button  type="button" class="btn btn-primary" (click)="close()">
            Get Started
        </button>
    </div>
    <div *ngIf="!showButton">
        <span class="spinner-border spinner-border-sm lp-spinner"
              role="status"
              aria-hidden="true"></span>
    </div>

</div>
