import LPDateTime from "../../../shared/LPDateTime";
import Utility from "../../../shared/utility";
import { LPFieldType } from "../enums";
import { SnapFormField } from "../snapFormField";
import { Visitor } from "./Visitor";

export class TimeValidator extends Visitor {
    //***
    //***
    canVisit(field: SnapFormField): boolean {
        return super.commonCanVisitFieldType(field, [LPFieldType.Time]); //, LPFieldType.DateTime]);
    }


    //***
    //***
    visit(field: SnapFormField, value: string) {
        if (super.commonVisit(field, value))
            if (!Utility.isNullOrEmpty(value) && !LPDateTime.isTime(value)) {
                field.validationMessage = "Invalid time value";
            }
    }
}
