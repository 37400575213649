<div class="flex-container lp-header"
     [style.background-color]="backgroundColor"
     [style.color]="foregroundColor"
     [style.padding]="paddingPixels"
     [style.border-bottom-left-radius]="roundedBottomPixels"
     [style.border-bottom-right-radius]="roundedBottomPixels">


    <!--  CheckBox  CheckBox  CheckBox-->
    <div class="flex-item-side lp-image-border">
        <img *ngIf="showImageUrl"
             class="lp-image"
             [src]="imageUrl"
             [style.background]="imageBackgroundColor" />

        <div *ngIf="showImageSvg"
             style="margin-top: 3px;"
             class="lp-image">
            <app-lp-svg [imagePoints]="imagePoints"
                        [imageBackgroundColor]="imageBackgroundColor"
                        [imageForegroundColor]="imageForegroundColor"
                        [imageSize]="imageSize"
                        [lpTooltip]="imageTooltip"
                        delay="750"></app-lp-svg>
        </div>
    </div>

    <!--  Titles  Titles  Titles-->
    <div class="flex-item-center">

        <div *ngIf="showTitle"
             class="lp-title">
            {{title}}
        </div>

        <div *ngIf="showSubTitle"
             class="lp-sub-title">
            <span *ngIf="showSubTitleLabel">{{subTitleLabel}}: </span>
            {{subTitle}}
        </div>
    </div>


    <!--  Sidekick  Sidekick  Sidekick-->
    <div class="flex-item-side">
        <ng-container [ngTemplateOutlet]="filler"></ng-container>
    </div>

    <!--</div>-->
</div>
