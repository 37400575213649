import { Component, Input, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import Utility from '../../utility';

@Component({
    selector: 'app-data-entry-textbox',
    templateUrl: './data-entry-textbox.component.html',
    styleUrls: ['./data-entry-textbox.component.scss']
})
export class DataEntryTextboxComponent implements OnInit {

    @Input() value: string;
    @Input() iconPath: string;
    @Input() title: string;

    readonly imagePoints: string = Utility.svgQuote;
    onClose: Subject<boolean> = new Subject<boolean>();

    constructor(public bsModalRef: BsModalRef) { }

    ngOnInit(): void {
    }

    //**
    //**
    confirm() {
        this.onClose.next(true);
        this.bsModalRef?.hide();

    }

    //**
    //**
    decline() {
        this.onClose.next(false);
        this.bsModalRef?.hide();
    }
}
