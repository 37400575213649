import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { SessionService } from '../../../core/services/session.service';
import { FocusService } from '../../../shared/focus.service';
import { HighlighterService } from '../../../shared/highlighter.service';
import { SnapFormField } from '../../models/snapFormField';

@Component({
    selector: 'snap-lp-field',
    templateUrl: './lp-field.component.html',
    styleUrls: ['./lp-field.component.scss'],
})
export class LPFieldComponent implements OnInit {
    @Input() snapFormField: SnapFormField;

    @ViewChild('validationMessage') validationElement: ElementRef;



    //***
    //***
    ngOnInit() {
        this.snapFormField.validationSubject.subscribe(resp => {
            if (resp) {
                this.highlighterService.highlightElement(this.validationElement);

                //scrolling into view inside of the panel causes everything to shift leaving some components hidden under others.
                //Cant figure out a working solution so disabling when the snapform is in the panel for now
                if (!this.sessionService.isMAC) {
                    //Set the scroll focus on the first snap-lp-field that has a child div with a class of validation-message and has message content
                    this.focusService.setScrollFirstMatch('snap-lp-field :has(> div.validation-message:not(:empty)) ');
                }
                
            }
        });
    }

    //***
    //***
    constructor(private highlighterService: HighlighterService, private focusService: FocusService, private sessionService: SessionService) { }
}
