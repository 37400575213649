<a class="btn-lp"
[style.opacity]="opacity"
[style.pointer-events]="getMousePointer"
(click)="onclick()">
    <span>
        <app-lp-svg [imagePoints]="imagePoints"
                    [imageBackgroundColor]="imageBackgroundColor"
                    [imageForegroundColor]="imageForegroundColor"
                    [lpTooltip]="lpTooltip"
                    delay="750"></app-lp-svg>
    </span>
</a>
