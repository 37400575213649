import { Component, Input, NgZone, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SessionService } from '../../../core/services/session.service';
import { SublimeService } from '../../../core/services/sublime.service';
import { ToastNotificationService } from '../../../core/services/toast-notification.service';
import { PropertyBagResponse } from '../../../models/propertyBagResponse';
import { MacHandlerSnapFormService } from '../../../snap-forms/services/mac-handler-snapform.service';
import Utility from '../../utility';

@Component({
    selector: 'app-update-record',
    templateUrl: './update-record.component.html',
    styleUrls: ['./update-record.component.scss']
})
export class UpdateRecordComponent implements OnInit {
    public readonly imagePoints: string = Utility.svgPencil;
    public officeDialog: Office.Dialog;
    private officeDialogUrl = window.location.origin + "#/";
    private toastTopic = "SnapForm";

    @Input() tableName: string;
    @Input() id: string;
    @Input() imageBackgroundColor = Utility.LPTransparent;
    @Input() imageForegroundColor = Utility.LPOrange;


    //***
    //***
    constructor(private router: Router, private sessionService: SessionService, private toastService: ToastNotificationService, private _ngZone: NgZone,
        private sublimeService: SublimeService, private macHandler: MacHandlerSnapFormService,) { }


    //***
    //***
    ngOnInit(): void {
        let inputTableName = this.tableName;
        let inputId = this.id;
    }


    //***
    //***
    async onclick(tableName) {
        //Pass the authtoken to the dialog
        this.sessionService.setPropertyBag(this.sessionService.propertyBagAuthToken, await this.sessionService.forceTokenUpdate()).subscribe(
            (response) => {
                if (this.sessionService.isMAC)
                    this.macHandler.initializeMacFormFull(tableName, this.id);
                else
                    this.launchDialog(tableName);
            },
            (error) => {
                Utility.debug(error);
            }
        ); 
    }

    //***
    //***
    private async launchDialog(tableName: string) {
        const url = this.officeDialogUrl + this.router.serializeUrl(this.router.createUrlTree([`/SnapForm/${this.sessionService.lpUserId}/${this.tableName}/Full/${this.id}`]));

        await Office.context.ui.displayDialogAsync(url, { height: this.sessionService.dialogHeightLarge, width: this.sessionService.dialogWidthMedium, promptBeforeOpen: false, displayInIframe: true },
            (result) => {
                if (result.status === Office.AsyncResultStatus.Failed) {
                    Utility.debug(`Dialog failed: ${result.error.code} ${result.error.message}`);
                }
                else {
                    this.officeDialog = result.value;
                    this.officeDialog.addEventHandler(Office.EventType.DialogMessageReceived, this.processOfficeDialogMessage);
                    this.officeDialog.addEventHandler(Office.EventType.DialogEventReceived, this.processOfficeDialogEvent);
                }
            }
        );
    }


    //***
    //*** Fired when dialog calls Office.context.ui.messageParent()
    private processOfficeDialogMessage = (arg: any) => {
        this._ngZone.run(() => {
            // update all occurances of this record
            this.sessionService.getPropertyBag(this.sessionService.propertyBagSnapFormSaved).subscribe((response: PropertyBagResponse) => this.sublimeService.onSnapFormsSaved.next(Utility.toMap(response.value)));
            if (arg.message) {
                this.officeDialog.close();
                this.toastService.send('Success', "", "Salesforce item updated.", 3000, this.toastTopic);
            }
            this.officeDialog.close();
        });
    };

    //***
    //*** Fired when dialog is closed by the user
    private processOfficeDialogEvent = (arg) => {
    };
}
