import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { SessionService } from '../../core/services/session.service';
import LPDateTime from '../LPDateTime';

@Component({
    selector: 'app-about-dialog',
    templateUrl: './about-dialog.component.html',
    styleUrls: ['./about-dialog.component.scss']
})
export class AboutDialogComponent {
    @Input() questionText: string;
    @Output() isConfirmed = new EventEmitter<boolean>();


    //***
    //***
    constructor(public bsModalRef: BsModalRef, public sessionService: SessionService) { }

    //***
    //***
    confirm(): void {
        this.isConfirmed.emit(true);
        this.bsModalRef?.hide();
    }

    //***
    //***
    decline(): void {
        this.isConfirmed.emit(false);
        this.bsModalRef?.hide();
    }
}
