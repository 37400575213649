import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { SharedModule } from '../shared/shared.module';
import { LPBooleanComponent } from './components/lp-boolean/lp-boolean.component';
import { LPComboBoxComponent } from './components/lp-combobox/lp-combobox.component';
import { LPDateTimeComponent } from './components/lp-date-time/lp-date-time.component';
import { LPEmptyComponent } from './components/lp-empty/lp-empty.component';
import { LPEventReminderComponent } from './components/lp-event-reminder/lp-event-reminder.component';
import { LPFieldLabelComponent } from './components/lp-field-label/lp-field-label.component';
import { LPFieldComponent } from './components/lp-field/lp-field.component';
import { LPFormComponent } from './components/lp-form/lp-form.component';
import { LPFormsHeaderComponent } from './components/lp-forms-header/lp-forms-header.component';
import { LPFormsComponent } from './components/lp-forms/lp-forms.component';
import { LPInputComponent } from './components/lp-input/lp-input.component';
import { LPLookupDialogComponent } from './components/lp-lookup-dialog/lp-lookup-dialog.component';
import { LPLookupComponent } from './components/lp-lookup/lp-lookup.component';
import { LPMultiPickListComponent } from './components/lp-multipicklist/lp-multipicklist.component';
import { LPPicklistComponent } from './components/lp-picklist/lp-picklist.component';
import { LPReadOnlyComponent } from './components/lp-read-only/lp-read-only.component';
import { LPRecordTypeComponent } from './components/lp-record-type/lp-record-type.component';
import { LPSectionBodyLeftRightComponent } from './components/lp-section-body-left-right/lp-section-body-left-right.component';
import { LPSectionBodySingleComponent } from './components/lp-section-body-single/lp-section-body-single.component';
import { LPSectionBodyTopDownComponent } from './components/lp-section-body-top-down/lp-section-body-top-down.component';
import { LPSectionFooterComponent } from './components/lp-section-footer/lp-section-footer.component';
import { LPSectionHeaderComponent } from './components/lp-section-header/lp-section-header.component';
import { LPSectionComponent } from './components/lp-section/lp-section.component';
import { LPSignatureSectionComponent } from './components/lp-signature-section/lp-signature-section.component';
import { SnapFormComponent } from './components/lp-snap-form/lp-snap-form.component';
import { LPTaskReminderComponent } from './components/lp-task-reminder/lp-task-reminder.component';
import { LPTextAreaComponent } from './components/lp-text-area/lp-text-area.component';
import { MacHandlerSnapFormService } from './services/mac-handler-snapform.service';
import { MiniSnapFormService } from './services/mini-snapform.service';
import { SnapFormService } from './services/snapform.service';




@NgModule({
    declarations: [
        LPBooleanComponent,
        LPComboBoxComponent,
        LPDateTimeComponent,
        LPFieldComponent,
        LPFieldLabelComponent,
        LPFormComponent,
        LPFormsComponent,
        LPFormsHeaderComponent,
        LPInputComponent,
        LPLookupComponent,
        LPMultiPickListComponent,
        LPPicklistComponent,
        LPReadOnlyComponent,
        LPEmptyComponent,
        LPRecordTypeComponent,
        LPSectionBodyLeftRightComponent,
        LPSectionBodyTopDownComponent,
        LPSectionBodySingleComponent,
        LPSectionComponent,
        LPSectionFooterComponent,
        LPSectionHeaderComponent,
        LPTextAreaComponent,
        LPFormsComponent,
        LPLookupDialogComponent,
        SnapFormComponent,
        LPSignatureSectionComponent,
        LPTaskReminderComponent,
        LPEventReminderComponent
    ],

    imports: [
        BrowserModule,
        FormsModule,
        ReactiveFormsModule,
        BrowserAnimationsModule,
        BsDatepickerModule.forRoot(),
        TooltipModule.forRoot(),
        BsDropdownModule.forRoot(),
        TabsModule.forRoot(),
        SharedModule,
    ],
    providers: [
        SnapFormService,
        MiniSnapFormService,
        MacHandlerSnapFormService,
    ],

    exports: [
        LPFieldComponent,
        LPFormComponent,
        LPFormsComponent,
        LPFormsHeaderComponent,
        LPRecordTypeComponent,
        LPSectionBodyLeftRightComponent,
        LPSectionBodyTopDownComponent,
        LPSectionComponent,
        LPSectionFooterComponent,
        LPSectionHeaderComponent,
        LPFieldLabelComponent,
        LPDateTimeComponent,
        LPLookupComponent,
        SnapFormComponent
    ],

    bootstrap: [LPLookupDialogComponent]

})
export class SnapFormsModule { }
